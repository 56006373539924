import React from 'react';
import { Table, Td } from 'components/atoms/HistoryTable';
import HistoryRow from 'components/atoms/HistoryRow';

interface Props {
  headerTxt: string;
  store: Array<any>;
}

const HistoryTable: React.FC<Props> = ({ headerTxt, store }) => {
  return (
    <Table>
      <thead>
        <tr>
          <Td width='77' $txtBold>
            유형
          </Td>
          <Td width='128'>날짜</Td>
          <Td>내용</Td>
          <Td width='128'>{headerTxt}</Td>
        </tr>
      </thead>
      <tbody>
        {store.map(item => (
          <HistoryRow key={item.id} {...item} unit={headerTxt} />
        ))}
      </tbody>
    </Table>
  );
};

export default HistoryTable;
