export const packagesAge = [
  { label: '전연령', value: 'amin=&amax=' },
  { label: '0 - 12개월', value: 'amin=1&amax=1' },
  { label: '12 - 24개월', value: 'amin=2&amax=2' },
  { label: '2 - 3세', value: 'amin=3&amax=4' },
  { label: '4 - 5세', value: 'amin=5&amax=6' },
  { label: '6 - 7세', value: 'amin=7&amax=8' },
];

export const packagesSort = [
  { label: '최신순', value: 'by_release_date' },
  { label: '인기순', value: 'by_popularity' },
  { label: '판매량순', value: 'by_transaction' },
  { label: '높은 가격순', value: 'by_high_price' },
  { label: '낮은 가격순', value: 'by_low_price' },
  { label: '인스타 인기순', value: 'by_instagram' },
];

export const playsAge = [
  { label: '만 0세', value: '1' },
  { label: '만 1세', value: '2' },
  { label: '만 2세', value: '3' },
  { label: '만 3세', value: '4' },
  { label: '만 4세', value: '5' },
  { label: '만 5세', value: '6' },
  { label: '만 6-7세', value: '7' },
];

export const searchAge = [
  { label: '전체', value: '-1' },
  { label: '예비맘', value: '0' },
  { label: '만 0세', value: '1' },
  { label: '만 1세', value: '2' },
  { label: '만 2세', value: '3' },
  { label: '만 3세', value: '4' },
  { label: '만 4세', value: '5' },
  { label: '만 5세', value: '6' },
  { label: '만 6세', value: '7' },
];
