import React, { useCallback } from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';

import { Tab } from 'components/hooks/useTabs';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import {
  Background,
  TabButtonPC,
  TabContainer,
  WrapDestopTab,
} from 'components/organisms/PackageDetail/Tabs';

interface Porps {
  show: boolean;
  tabs: Tab[];
  curIndex: number;
  onClick: any;
}

const StickeyTabs = ({ show, tabs, curIndex, onClick }: Porps) => {
  const onClickTab = useCallback(
    (idx: number) => () => {
      onClick(idx)();
      setTimeout(() => {
        scroller.scrollTo(`TabContent-${idx}`, {
          offset: -150,
          duration: 250,
          smooth: true,
        });
      }, 100);
    },
    [onClick],
  );

  return useDesktopShow(
    show ? (
      <WrapTab>
        <WrapDestopTab $isStickey>
          <TabContainer
            store={tabs}
            Button={TabButtonPC}
            curIndex={curIndex}
            onClick={onClickTab}
          />
        </WrapDestopTab>
      </WrapTab>
    ) : (
      <></>
    ),
  );
};

export default StickeyTabs;

const WrapTab = styled(Background)`
  position: fixed;
  z-index: 990;
  top: 0;
  width: 100%;
  border-bottom: solid 1px #d5d5d5;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.05);
`;
