import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import styled from 'styled-components';
import cx from 'classnames';
import { AiOutlineMenu } from 'react-icons/ai';

import { DISPLAY } from 'interfaces/layout';
import { PAGE_URL } from 'configs/path';

import { useToggle } from 'components/hooks/useToggle';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { useOnClickOutside } from 'components/hooks/useOnClickOutside';

import { Wrapper } from 'components/atoms';
import { ClearButton, ClearLink } from 'components/atoms/Clear';
import AllCategoryBox from './AllCateogryBox';

const CategoryBar: React.FC<any> = observer(() => {
  const location = useLocation();
  const { layout } = useStores();

  const [toggleAllCategory, onClickToggleAll] = useToggle(false);
  const [toggleCategory, onClickToggle] = useToggle(false);

  const AllCategoryBoxRef = useRef(null);
  const CategoryBoxRef = useRef(null);

  const onClickAllCategory = useCallback(() => {
    onClickToggle(false);
    onClickToggleAll();
  }, [onClickToggle, onClickToggleAll]);

  useEffect(() => {
    onClickToggleAll(false);
    onClickToggle(false);
  }, [location, onClickToggle, onClickToggleAll]);

  useOnClickOutside(AllCategoryBoxRef, () => {
    if (toggleAllCategory) onClickToggleAll();
  });
  useOnClickOutside(CategoryBoxRef, () => {
    if (toggleCategory) onClickToggle();
  });

  return (
    <>
      <WrapCategoryBar>
        <WrapItem ref={AllCategoryBoxRef} $dt={layout.displayType} $len={6}>
          <AllCategoryBox isOpen={toggleAllCategory} />
          {useDesktopShow(
            <AllItem>
              <CategoryButton
                className={cx({ open: toggleAllCategory })}
                onClick={onClickAllCategory}>
                <MenuIcon /> {!toggleAllCategory ? '전체 카테고리' : '카테고리 닫기'}
              </CategoryButton>
            </AllItem>,
          )}
          <Item>
            <ClearLink to={PAGE_URL.HOME}>홈</ClearLink>
          </Item>
          <Item>
            <ClearLink to={PAGE_URL.PACKAGES_BEST}>BEST</ClearLink>
          </Item>
          <Item>
            <ClearLink to={PAGE_URL.PACKAGE_GROWTHKIT}>성장놀이터</ClearLink>
          </Item>
          <Item>
            <ClearLink to={PAGE_URL.PACKAGE_WORKBOOK}>워크북</ClearLink>
          </Item>
          <Item>
            <ClearLink to={PAGE_URL.EVENTS}>이벤트</ClearLink>
          </Item>
          <Item>
            <ClearLink to={PAGE_URL.PLAYS}>무료 활동지</ClearLink>
          </Item>
        </WrapItem>
      </WrapCategoryBar>
      <Hr />
    </>
  );
});

export default CategoryBar;

const WrapCategoryBar = styled(Wrapper)`
  ${({ theme }) => theme.tablet`
    text-align:center;
  `}
`;
const Hr = styled.hr`
  margin: 0;
  border: 0;
  border-bottom: 1px solid #eaeaea;
`;

const WrapItem = styled.ul<{ $dt: DISPLAY; $len: number }>`
  margin: 0;
  padding: 0;

  &::after {
    content: '';
    clear: both;
    display: block;
  }

  ${({ theme }) => theme.tablet`
    display: inline-block;
  `}

  ${({ theme, $dt, $len }) => theme.desktop`
    & > li {
      width: ${100 / ($len + ($dt === DISPLAY.DESKTOP ? 1 : 0))}%;
      border-right: 2px solid #eaeaea;
    }
  `}
`;
const Item = styled.li`
  position: relative;
  float: left;
  margin: 16px 0;

  line-height: 1.5;
  font-size: 16px;
  text-align: center;
  list-style: none;

  &:last-child {
    border-right: 0;
  }

  ${({ theme }) => theme.tablet`
    margin: 11px 0;
    padding: 0 8px;
    font-size: 12px;
  `}
`;
const AllItem = styled(Item)`
  position: relative;
  margin: 0;
  padding: 16px 0 16px 35px;
  background-color: #f8f8f8;

  font-weight: bold;
  border-right: 0 !important;
`;

const MenuIcon = styled(AiOutlineMenu)`
  position: absolute;
  top: 18px;
  left: 20px;
  margin-top: -2px;
  width: 30px;
  height: 26px;
`;

const CategoryButton = styled(ClearButton)`
  display: inline;
  font-weight: inherit;

  &.open {
    font-weight: bold;
    color: ${({ theme }) => theme.mainActive};

    ${MenuIcon} {
      color: ${({ theme }) => theme.mainActive};
    }
  }
`;
