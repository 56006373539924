import styled from 'styled-components';

export const Form = styled.form`
  ${({ theme }) => theme.tablet`
    margin: 0 auto;
    width: 280px;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const WrapInput = styled.div`
  position: relative;
  margin-top: 16px;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin: 20px auto 40px;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const Label = styled.label`
  vertical-align: top;

  ${({ theme }) => theme.desktop`
    display: inline-block;
    padding: 8px 0;
    width: 175px;
  `}

  ${({ theme }) => theme.tablet`
    font-size: 12px;
  `}
`;

export const Input = styled.input`
  line-height: 1.5;
  outline: none;

  &::placeholder {
    color: #b3b3b3;
  }

  ${({ theme }) => theme.desktop`
    padding: 7px 8px;
    width: 235px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;
  `}

  ${({ theme }) => theme.tablet`
    padding: 0 0 10px;
    width: 100%;
    border-width: 0 0 2px 0;
    border-bottom: solid 2px #eaeaea;
    font-size: 14px;
  `}
`;

export const InputErr = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.desktop`
    margin-left: 175px;
    margin-top: 8px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
  `}
`;
