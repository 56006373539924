import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { useStores } from 'stores';
import { usePackageDetailStore } from 'pages/PackageDetail/store';
import { useTabletShow } from 'components/hooks/useLayoutShow';
import { useToggle } from 'components/hooks/useToggle';
import { ClearButton, DownButton } from 'components/atoms';
import { SlideYTransition } from 'components/atoms/Animation';
import { OptionsBoxContainer } from './OptionsBox';
import { QuantityBoxContainer } from './QuantityBox';
import { TotalPriceContainer } from './TotalPrice';
import { BasketButton } from '../BasketButton';

interface Porps {
  show: boolean;
}

const StickeyBasket = observer(({ show }: Porps) => {
  const { layout } = useStores();
  const store = usePackageDetailStore();
  const [toggleLayer, setToggleShow] = useToggle(false);
  const onClickBasket = useCallback(
    (toggleLayer: boolean, close?: boolean) => async () => {
      if (close) {
        layout.CloseDimmed();
        setToggleShow(false);
      } else {
        if (!toggleLayer) {
          layout.OpenDimmed();
          setToggleShow(true);
        } else {
          store.onClickCart({
            dimmedZIndex: 1000,
            onOpenStart: () => {
              setToggleShow();
            },
          });
        }
      }
    },
    [layout, setToggleShow, store],
  );

  return useTabletShow(
    <>
      <SlideYTransition in={show} timeout={500} classNames='slide-up' unmountOnExit>
        <WrapBasket>
          <BasketButton
            Render={DesignedBasketButton}
            onClick={onClickBasket(toggleLayer)}
            model={store.packageDetail}
          />
        </WrapBasket>
      </SlideYTransition>
      <SlideYTransition in={toggleLayer} timeout={500} classNames='slide-up' unmountOnExit>
        <BasketLayer>
          <OptionName>{store.packageDetail.title}</OptionName>
          <OptionsBoxContainer />
          <TotalPriceContainer />
          <QuantityBoxContainer />
          <LayerCloseButton onClick={onClickBasket(toggleLayer, true)}>
            <DownButton />
          </LayerCloseButton>
        </BasketLayer>
      </SlideYTransition>
    </>,
  );
});

export default StickeyBasket;

const WrapBasket = styled.div`
  position: fixed;
  z-index: 998;
  bottom: 0;
  padding: 12px 0;
  width: 100%;
  height: 80px;
  line-height: 1.5;

  background: #fff;
  border: solid 1px #d5d5d5;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.05);

  ${({ theme }) => theme.tablet`
    z-index: 1000;
    bottom: 0;
    padding: 10px 20px;
    height: 60px;
  `}
`;

const BasketLayer = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 60px;
  padding: 20px 20px 10px;
  width: 100%;

  background: #fff;
`;
const LayerCloseButton = styled(ClearButton)`
  position: absolute;
  top: -21px;
  left: 50%;
  transform: translateX(-50%);
  width: 38px;
  height: 21px;
  border-radius: 12px 12px 0 0;
  background-color: #fff;

  & > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate3d(0, 0, 1, -45deg) skew(5deg, 5deg);
    color: ${({ theme }) => theme.subActiveColor};
  }
`;

const OptionName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${({ theme }) => theme.desktop`
    width: 600px;
    line-height: 56px;
  `}

  ${({ theme }) => theme.tablet`
    line-height: 1.5;
  `}
`;

const DesignedBasketButton = styled(ClearButton)`
  float: right;
  margin-left: 16px;
  padding: 15px 0;
  width: 240px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
  color: #fff;

  ${({ theme }) => theme.tablet`
    padding: 6px 0;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
  `}

  &:disabled {
    background-color: ${({ theme }) => theme.tabNonActieColor};
    cursor: not-allowed;
  }
`;
