import axios from 'axios';
import { API_URL } from 'configs/path';
import { ResPackage } from 'stores/models/PackageModel';

interface Res {
  packages: ResPackage[];
}

class PackagesRepository {
  fetch(query: string): Promise<Res> {
    return axios.get(`${API_URL.PACKAGES}?${query}`);
  }

  fetchSearch(query: string): Promise<Res> {
    return axios.get(`${API_URL.SEARCH_PACKAGES}?${query}`);
  }
}

export default new PackagesRepository();
