import React, { useCallback } from 'react';
import { useHistory, generatePath, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import Dropdown from 'react-dropdown';

import { PackagesMatchParams, PAGE_URL } from 'configs/path';
import { useStores } from 'stores';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import BreadCrumbFrame from 'components/atoms/BreadCrumbFrame';
import { ClearLink, DropDownOpenIcon, DropDownCloseIcon } from 'components/atoms';

const PackagesBreadCrumb: React.FC<any> = observer(() => {
  const { main, sub } = useRouteMatch<PackagesMatchParams>().params;
  const history = useHistory();
  const { layout } = useStores();

  const onChangeMainCategroy = useCallback(
    ({ value }) => history.push(generatePath(PAGE_URL.PACKAGES_MAIN, { main: value })),
    [history],
  );
  const onChangeSubCategroy = useCallback(
    (main: string) =>
      ({ value }: any) => {
        history.push(generatePath(PAGE_URL.PACKAGES_SUB, { main, sub: value }));
      },
    [history],
  );

  const breadCrumbItems = [];
  breadCrumbItems.push(<BreadCrumbLink to={PAGE_URL.HOME}>홈</BreadCrumbLink>);

  if (main) {
    const items = layout.packageCategory.map(({ me }) => ({
      value: me.category,
      label: me.categoryName,
    }));

    breadCrumbItems.push(
      <PageDropDown
        options={items}
        onChange={onChangeMainCategroy}
        value={decodeURIComponent(main)}
        arrowOpen={<DropDownOpenIcon $css={DropDownIcon} />}
        arrowClosed={<DropDownCloseIcon $css={DropDownIcon} />}
      />,
    );
  }

  const subCategory = layout.getCateogryModel(decodeURIComponent(main))?.children;

  if (sub && subCategory) {
    const items = subCategory.map(({ category, categoryName }) => ({
      value: category,
      label: categoryName,
    }));

    breadCrumbItems.push(
      <PageDropDown
        options={items}
        onChange={onChangeSubCategroy(main)}
        value={decodeURIComponent(sub)}
        arrowOpen={<DropDownOpenIcon $css={DropDownIcon} />}
        arrowClosed={<DropDownCloseIcon $css={DropDownIcon} />}
      />,
    );
  }

  return useDesktopShow(
    <BreadCrumbFrame items={breadCrumbItems} prefixKey={'__packages_breadCrumb_'} />,
  );
});

PackagesBreadCrumb.defaultProps = {
  subCategory: [],
};

const BreadCrumbLink = styled(ClearLink)`
  display: inline-block;

  line-height: 26px;
  font-size: 12px;
  color: #b3b3b3;
`;

const PageDropDown = styled(Dropdown)`
  display: inline-flex;

  .Dropdown-control {
    padding: 3px;
    min-width: 132px;
    height: 26px;
    border: solid 1px #d5d5d5;
    border-radius: 3px;

    line-height: 1.5;
    font-size: 12px;
    color: #b3b3b3;
  }

  .Dropdown-option {
    font-size: 12px;
  }
`;

const DropDownIcon = css`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 10px;
  right: 8px;
`;

export default PackagesBreadCrumb;
