// API 파라미터가 정리가 안되어 있음

// Get 용
export enum COMMENTES_TYPE {
  PLAY = 'plays',
  EVENT = 'events',
  STORY = 'stories ',
}

// Post 용
export enum COMMENTABLE_TYPE {
  PLAY = 'Play',
  EVENT = 'Event',
  STORY = 'Story ',
}

export const conv_commentable_type = (type: COMMENTES_TYPE): COMMENTABLE_TYPE => {
  const rtn = {
    [COMMENTES_TYPE.PLAY]: COMMENTABLE_TYPE.PLAY,
    [COMMENTES_TYPE.EVENT]: COMMENTABLE_TYPE.EVENT,
    [COMMENTES_TYPE.STORY]: COMMENTABLE_TYPE.STORY,
  };

  return rtn[type];
};
