import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { MdShoppingCart } from 'react-icons/md';

import { PAGE_URL } from 'configs/path';
import { useStores } from 'stores';
import { useTabletShow } from 'components/hooks/useLayoutShow';
import * as Clear from 'components/atoms/Clear';

const CartButton: React.FC<any> = observer(() => {
  const { cart } = useStores();

  return useTabletShow(
    <ClearLink to={PAGE_URL.CART}>
      <CartIcon />
      {cart.cartItemLen ? <Number>{cart.cartItemLen}</Number> : null}
    </ClearLink>,
  );
});

export default CartButton;

const ClearLink = styled(Clear.ClearLink)`
  position: absolute;
  right: 20px;
  margin-top: -2px;
`;

const CartIcon = styled(MdShoppingCart)`
  width: 24px;
  height: 24px;
  color: #d5d5d5;
`;

const Number = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 15px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.mainActive};

  line-height: 15px;
  font-size: 10px;
  color: #fff;
  text-align: center;
`;
