import styled from 'styled-components';
import * as MypageInfo from 'pages/Mypage/Info/styled';

export const FormSection = styled.div<{ $noMargin?: boolean }>`
  ${({ theme }) => theme.desktop`
    margin-bottom: 72px;
  `}

  ${({ theme, $noMargin }) => theme.tablet`
    padding: 20px;
    ${!$noMargin ? `border-top: 10px solid #f8f8f8;` : null}
  `}
`;

export const H2 = styled.h2`
  display: inline-block;
  padding-bottom: 16px;
  font-size: 16px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    padding-bottom: 6px;
  `}
`;
export const H2Help = styled.div`
  display: inline-block;
  margin-left: 16px;
  font-size: 12px;
`;

export const Hr = styled.hr`
  margin: 0;
  border: 0;
  border-bottom: solid 2px #262626;

  ${({ theme }) => theme.tablet`
    display: none;
  `}
`;

export const Label = styled(MypageInfo.Label)`
  ${({ theme }) => theme.tablet`
    display: inline-block;
    padding: 5px 0px;
    width: 90px;
    font-size: 14px;
    line-height: 1.43;
  `}
`;

export const Input = styled(MypageInfo.Input)`
  vertical-align: top;

  ${({ theme }) => theme.tablet`
    padding: 5px 8px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;
    font-size: 14px;
    line-height: 1.43;
  `}
`;

export const WrapInput = styled(MypageInfo.WrapInput)`
  ${({ theme, $withBtn }) => theme.tablet`
    margin: 10px 0;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    ${Input} {
      width: ${$withBtn ? 'calc(100% - 160px)' : 'calc(100% - 90px)'};
    }
  `}
`;

export const InputButton = styled(MypageInfo.InputButton)`
  ${({ theme }) => theme.tablet`
    top: 0;
    width: 60px;
    height: 32px;
    border-radius: 3px;
    border: solid 1px ${theme.subActiveColor};
  `}
`;

export const WrapTextArea = styled(MypageInfo.WrapTextArea)`
  ${({ theme }) => theme.tablet`
    width: calc(100% - 90px);
  `}
`;

export const AddrArea = styled(MypageInfo.AddrArea)`
  ${({ theme }) => theme.tablet`
    padding: 5px 8px;
    width: calc(100% - 70px);
    height: 73px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;
  `}
`;

export const AddrButton = styled(MypageInfo.AddrButton)`
  ${({ theme }) => theme.tablet`
    top: 0;
    width: 60px;
    height: 73px;
    border-radius: 3px;
    border: solid 1px ${theme.subActiveColor};
  `}
`;

export const AddrInput = styled(MypageInfo.AddrInput)`
  ${({ theme }) => theme.tablet`
    padding: 5px 8px;
    width: calc(100% - 90px)!important;
    border-radius: 3px;
    border: solid 1px #d5d5d5;
  `}
`;

export const FormSubSection = styled.div`
  margin-bottom: 72px;
`;
