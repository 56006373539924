import React from 'react';
import styled from 'styled-components';

const DialogItem: React.FC<any> = props => {
  const { relation, dialog } = props;
  return (
    <WrapDialog>
      <Logo src={`/icons/play/${relation}.png`} alt={relation} />
      <Text>{dialog}</Text>
    </WrapDialog>
  );
};

export default DialogItem;

const WrapDialog = styled.div`
  overflow: hidden;
  margin-top: 16px;
  width: 100%;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
  `}
`;

const Logo = styled.img`
  float: left;
  margin-right: 16px;
  width: 40px;
  height: 40px;

  ${({ theme }) => theme.tablet`
    margin-right: 8px;
    width: 24px;
    height: 24px;
  `}
`;

const Text = styled.div`
  float: left;
  padding: 6px 0;
  width: calc(100% - 56px);

  ${({ theme }) => theme.tablet`
    font-size: 12px;
    padding: 3px 0;
    width: calc(100% - 32px);
  `}
`;
