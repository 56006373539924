import React from 'react';
import { observer } from 'mobx-react';
import { commaNumber } from 'utils';

import { useStores } from 'stores';
import {
  GuideTxt,
  IconEqual,
  IconPlus,
  SItemInfo,
  SItemPrice,
  SummaryItem,
  Unit,
  WrapSummary,
  OrderEstimateButton,
} from 'pages/Cart/styled';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const Summary = observer(() => {
  const { cart } = useStores();

  return (
    <WrapSummary>
      <SummaryItem>
        <SItemInfo>총 상품 금액</SItemInfo>
        <SItemPrice>
          {commaNumber(cart.totalPrice)}
          <Unit>원</Unit>
        </SItemPrice>
      </SummaryItem>
      {cart.totalPrice ? (
        <>
          <DesktopIconPlus />
          <SummaryItem>
            <SItemInfo>배송비</SItemInfo>
            <SItemPrice className='fee'>
              {commaNumber(cart.fee)}
              <Unit>원</Unit>
            </SItemPrice>
          </SummaryItem>
        </>
      ) : null}
      <DesktopIconEqual />
      <SummaryItem>
        <SItemInfo className='total'>총 결제 예정금액</SItemInfo>
        <SItemPrice className='total'>
          {commaNumber(cart.totalPriceWithFee)}
          <Unit>원</Unit>
        </SItemPrice>
      </SummaryItem>
      <GuideTxt>포인트 적립/사용은 다음 결제화면에서 확인할 수 있습니다.</GuideTxt>
      {cart.order_estimate_url ? (
        <OrderEstimateButton href={cart.order_estimate_url} target='_blank'>
          견적서 확인하기
        </OrderEstimateButton>
      ) : null}
    </WrapSummary>
  );
});

const DesktopIconPlus = () => useDesktopShow(<IconPlus />);
const DesktopIconEqual = () => useDesktopShow(<IconEqual />);

export default Summary;
