// src/styles/theme.ts
import { DefaultTheme } from 'styled-components';
import media from './media';

const theme: DefaultTheme = {
  mainActive: '#eb784b',
  subActiveColor: '#6ec8b4',
  blue: '#4696d7',
  tabActieColor: '#262626',
  tabNonActieColor: '#d5d5d5',
  FormErrColor: '#eb784b',
  TextColor: '#262626',
  headerZindex: 10,

  // 2022.09
  Gray: '#D5D5D5',
  Dark_gray: '#B3B3B3',

  ...media,
};

export { theme };
