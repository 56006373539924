import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PageLayout } from 'layouts';
import { useStores } from 'stores';
import { PAGE_URL } from 'configs/path';

import { useDesktopShow } from 'components/hooks/useLayoutShow';
import useCacheImages from 'components/hooks/useCacheImages';
import OrderStep, { ORDER_STEP } from 'components/molecules/OrderStep';
import { CartTableHead, CartTableBody, Summary } from 'components/molecules/Cart';
import {
  WrapCart,
  Table,
  WrapCartButtons,
  CartButton,
  WrapEmptyTxt,
  SubDeleteButton,
  GoPackageButton,
  EmptyCart,
} from './styled';
import { SiginInGuideBox } from 'components/organisms/SiginInGuideBox';

const EmptyCartImg = '/images/empty-cart.png';

const CartContainer: React.FC<any> = observer(() => {
  const history = useHistory();
  const { cart, layout } = useStores();

  const go = useCallback(
    (url: string, state = {}) =>
      () =>
        history.push(url, state),
    [history],
  );
  const handleBuy = useCallback(async () => {
    if (cart.checkedItems.length === 0) {
      layout.alert('한 개 이상의 상품을 선택해주세요.');
    } else {
      if (!cart.isSignIn) {
        try {
          await cart.ticketing();
          go(cart.orderUrl)();
        } catch (error) {
          layout.alert('구매할 수 없는 상품이 있습니다.<br/> 장바구니 상품의 재고를 확인해주세요.');
        }
      } else {
        go(cart.orderUrl)();
      }
    }
  }, [cart, go, layout]);

  useCacheImages([EmptyCartImg]);
  useEffect(() => {
    async function fetch() {
      await cart.fetch();

      if (cart.hasDiabledPackage) {
        layout.alert(
          '현재 구매가 불가능한 상품이 있습니다. <br/> 해당 상품을 삭제하고 진행해주세요.',
        );
      }
    }

    fetch();
  }, [cart, layout]);

  return (
    <>
      <OrderStep step={ORDER_STEP.CART} />

      {!cart.isEmpty ? (
        <>
          <SiginInGuideBox />
          <Table>
            <CartTableHead />
            <CartTableBody />
          </Table>
          <DeleteButton />
          <Summary />
          <WrapCartButtons>
            <CartButton $fill onClick={handleBuy} disabled={cart.hasDiabledPackage}>
              구매하기
            </CartButton>
          </WrapCartButtons>
        </>
      ) : (
        <WrapEmptyTxt>
          <EmptyCart src={EmptyCartImg} alt='Empty Cart' />
          <div className='txt'>아직 담은 패키지가 없습니다.</div>
          <GoPackageButton $fill onClick={go(PAGE_URL.PACKAGES_BEST)}>
            패키지 보러가기
          </GoPackageButton>
        </WrapEmptyTxt>
      )}
    </>
  );
});

export default PageLayout(CartContainer, {
  Title: '장바구니',
  Wrapper: WrapCart,
  Option: { full: true },
});

const DeleteButton: React.FC<any> = () => {
  const { cart } = useStores();

  return useDesktopShow(
    <SubDeleteButton $color='#b3b3b3' $fontColor='#262626' onClick={cart.deleteChecked}>
      선택 상품 삭제
    </SubDeleteButton>,
  );
};
