import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MypageLayout } from 'layouts/Mypage';
import { PointProvider, useInitPointHistory } from './store';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { Title, WrapperHistory } from 'components/atoms/Mypage';
import { PointTopBox, PointHistory } from 'components/organisms/Mypage/PointHistory';
import { useStores } from 'stores';

const PagePointHistory: React.FC<any> = observer(({ pgMinHeight }) => {
  useInitPointHistory();
  const { auth } = useStores();

  useEffect(() => {
    async function fetch() {
      await auth.fetch();
    }

    fetch();
  }, [auth]);

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>포인트 조회</SubHeaderTitle>
      </SubHeader>
      <WrapperHistory $pgMinHeight={pgMinHeight}>
        <Title>포인트 조회</Title>
        <PointTopBox />
        <PointHistory />
      </WrapperHistory>
    </>
  );
});

export default MypageLayout(PagePointHistory, { Provider: PointProvider, Option: { full: true } });
