import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { useStores } from 'stores';

const DimmedContainer = observer(() => {
  const { layout, modal } = useStores();
  const { dimmedOpen, dismissible, dimmedZIndex } = layout;
  const { closeCurrentModal } = modal;

  const onClickDimmed = useCallback(() => {
    if (dismissible) closeCurrentModal();
  }, [closeCurrentModal, dismissible]);

  return useMemo(
    () => (dimmedOpen ? <DimmedImpl $zIndex={dimmedZIndex} onClick={onClickDimmed} /> : null),
    [dimmedOpen, dimmedZIndex, onClickDimmed],
  );
});

export default DimmedContainer;

const DimmedImpl = styled.div<{ $zIndex: number }>`
  position: fixed;
  z-index: ${({ $zIndex }) => $zIndex};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
`;
