import React from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import cx from 'classnames';

import CategoriesModel, { PackageCategory } from 'layouts/models/CategoriesModel';
import { useStores } from 'stores';
import { ClearLink } from 'components/atoms/Clear';
import { PAGE_URL } from 'configs/path';

interface Props {
  isOpen: boolean;
}

const AllCategoryBoxContainer: React.FC<Props> = observer(({ isOpen }) => {
  const { layout } = useStores();

  return (
    <CategoryBox className={cx({ open: isOpen })}>
      <CategoryList store={layout.packageCategory} />
    </CategoryBox>
  );
});

export default AllCategoryBoxContainer;

const CategoryList = React.memo(({ store }: any) =>
  store.map(({ me, children }: CategoriesModel) => (
    <MainCategory key={`__main_category_${me.id}`}>
      <MainCategoryLink to={generatePath(PAGE_URL.PACKAGES_MAIN, { main: me.category })}>
        <CategoryLinkIcon src={me.imageUrl} />
        {me.categoryName}
      </MainCategoryLink>
      <SubCategoryBox>
        <SubCategoryList main={me} store={children} />
      </SubCategoryBox>
    </MainCategory>
  )),
);

const SubCategoryList = React.memo(({ main, store }: any) =>
  store.map(({ id, category, categoryName }: PackageCategory) => (
    <li key={`__sub_category_${id}`}>
      <CategoryLink
        to={generatePath(PAGE_URL.PACKAGES_SUB, { main: main.category, sub: category })}>
        {categoryName}
      </CategoryLink>
    </li>
  )),
);

const CategoryBox = styled.div`
  position: absolute;
  z-index: 997;
  display: flex;
  flex-wrap: wrap;
  margin-top: 56px;
  padding: 24px 53px 12px;
  width: 1194px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d5d5d5;
  border-radius: 0px 0px 25px 25px;
  background-color: #fff;
  overflow: hidden;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.25s, transform 0.05s linear 0.25s;

  &.open {
    opacity: 1;
    transform: scaleY(1);
    transition: transform 0.05s, opacity 0.25s linear 0.05s;
  }

  & > *:nth-child(7n) {
    margin-right: 0;
  }
`;

const MainCategory = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 130px;
  margin-right: 29px;
`;
const MainCategoryLink = styled(ClearLink)`
  display: block;
  padding: 8px 0;
  line-height: 1.5;
  border-bottom: solid 2px #d5d5d5;

  font-size: 16px;
  font-weight: bold;
  letter-spacing: -1px;
  color: ${({ theme }) => theme.TextColor};

  &:hover {
    color: ${({ theme }) => theme.mainActive};
  }
`;

const SubCategoryBox = styled.ul`
  margin: 0;
  padding: 8px 0 12px;

  & > li {
    list-style: none;
  }
`;
const CategoryLink = styled(ClearLink)`
  display: block;
  margin-bottom: 12px;
  line-height: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.TextColor};
  transition: color 0.12s;

  &:hover {
    color: ${({ theme }) => theme.mainActive};
  }
`;
const CategoryLinkIcon = styled.img`
  margin-right: 6px;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
`;
