import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import ListFrame from 'components/atoms/ListFrame';
import PlaysItem from 'components/molecules/Share/PlaysItem';
import { useStore } from 'pages/PackageDetail/store';

const PlaysBox: React.FC<any> = observer(() => {
  const { packageDetail } = useStore();

  if (packageDetail.plays.length) {
    return (
      <WrapPlays>
        패키지 구매 완료시,{' '}
        <strong>차이의 놀이 앱 &gt; 마이페이지 &gt; 구매한 패키지 놀이법 </strong>
        메뉴에 놀이법이 추가됩니다.
        <GuideTxt>*일부 패키지는 놀이법이 포함되지 않습니다.</GuideTxt>
        <ListFrame ListWrap={WrapContents} ListItem={PlaysItem} store={packageDetail.plays} />
      </WrapPlays>
    );
  } else {
    return <WrapPlays>해당 제품은 놀이법이 제공되지 않습니다.</WrapPlays>;
  }
});
export default PlaysBox;

const WrapPlays = styled.div`
  position: relative;
  margin-top: 24px;
  min-height: 525px;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
    font-size: 12px;
  `}
`;

const GuideTxt = styled.div`
  margin-top: 8px;
  color: #b3b3b3;

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
  `}
`;

const WrapContents = styled.div`
  ${({ theme }) => theme.desktop`
    margin-top: 56px;

    & > * {
      width: calc(25% - 22.5px);
      margin-left: 30px;
    }

    & > *:nth-child(4n + 1) {
      margin-left: 0;
    }

    & > *:nth-child(n - 5) {
      margin-top: 0;
    }

    & > *:nth-child(n + 5) {
      margin-top: 30px;
    }
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 20px;

    & > * {
      width: calc(25% - 8px);
      margin-left: 10px;
    }

    & > *:nth-child(4n + 1) {
      margin-left: 0;
    }

    & > *:nth-child(n - 5) {
      margin-top: 0;
    }

    & > *:nth-child(n + 5) {
      margin-top: 10px;
    }
  `}

  ${({ theme }) => theme.mobile`
    & > * {
      width: calc(50% - 5px);
      margin-left: 10px;
    }

    & > *:nth-child(2n + 1) {
      margin-left: 0;
    }

    & > *:nth-child(n - 3) {
      margin-top: 0;
    }

    & > *:nth-child(n + 3) {
      margin-top: 10px;
    } 
  `}
`;
