import { ClearButton } from 'components/atoms/Clear';
import styled, { css } from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';
import { Wrapper } from 'components/atoms/Mypage';

export const WrapInfo = styled(Wrapper)`
  ${({ theme }) => theme.tablet`
    padding: 0 20px 15px;
    border-top: 10px solid #f8f8f8;
    background-color: #fff;
  `}
`;

export const Label = styled.label`
  vertical-align: top;

  ${({ theme }) => theme.desktop`
    display: inline-block;
    padding: 8px 0;
    width: 102px;
  `}

  ${({ theme }) => theme.tablet`
    font-size: 14px;
  `}
`;

const InputCSS = css`
  line-height: 1.5;
  outline: none;

  &::placeholder {
    color: #b3b3b3;
  }

  ${({ theme }) => theme.desktop`
    padding: 7px 15px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;
  `}

  ${({ theme }) => theme.tablet`
    padding: 0 0 10px;
    border-width: 0 0 2px 0;
    border-bottom: solid 2px #eaeaea;
    font-size: 14px;
  `}
`;

export const InputErr = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.desktop`
    margin-left: 102px;
    margin-top: 8px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
  `}
`;

export const Input = styled.input<{ $inline?: boolean }>`
  ${InputCSS}

  ${({ theme, $inline }) =>
    $inline
      ? theme.tablet`
      width: 202px;
  `
      : null}
`;

export const Textarea = styled.textarea`
  ${InputCSS}
  resize: none;
`;

export const InputButton = styled(ClearButton)<{ $gray?: boolean }>`
  display: inline-block;
  border-radius: 3px;
  background: #fff;
  color: ${({ theme }) => theme.subActiveColor};

  ${({ theme, $gray }) => theme.desktop`
    vertical-align: top;
    margin-left: 8px;
    width: 62px;
    height: 40px;
    border: solid 1px ${theme.subActiveColor};

    ${
      $gray
        ? `
        border: solid 1px #d5d5d5;
        background-color: #f8f8f8;
        color: ${theme.TextColor};
        `
        : null
    }
  `}

  ${({ theme }) => theme.tablet`
    position: absolute;
    top: 27px;
    right: 0;
    width: 30px;
    font-size: 14px;
  `}
`;

export const WrapInput = styled.div<{ $withBtn?: boolean }>`
  position: relative;
  margin-top: 16px;
  line-height: 1.5;

  ${Input} {
    width: ${({ $withBtn }) => ($withBtn ? '216px' : '286px')};
  }

  ${({ theme, $withBtn }) => theme.tablet`
    margin: 20px auto;

    ${Input} {
      ${$withBtn ? 'padding-right: 35px' : null};
      width: 100%;
    }
  `}

  ${({ theme, $withBtn }) => theme.mobile`
    width: 100%;
  `}
`;

export const SumbitButton = styled(ClearButton)`
  margin-top: 16px;
  width: 248px;
  height: 44px;
  border-radius: 3px;
  background: ${({ theme }) => theme.subActiveColor};
  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.tablet`
    margin: 20px auto 10px;
    width: 280px;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const AddrInput = styled(Input)<{ $big?: boolean }>`
  ${({ theme, $big }) => theme.desktop`
    width: 495px!important;
    ${$big ? `height: 80px` : null};
  `}
`;
export const WrapTextArea = styled.div`
  // Textarea 아래쪽 여백 문제를 위해 Wrapper 추가
  display: inline-block;
  vertical-align: top;

  ${({ theme }) => theme.tablet`
    width: 100%;
  `}
`;
export const AddrArea = styled(Textarea)`
  display: block;
  width: 495px;

  ${({ theme }) => theme.desktop`
    height: 80px;
  `}

  ${({ theme }) => theme.tablet`
    width: 100%;
  `}
`;
export const AddrButton = styled(InputButton)`
  ${({ theme }) => theme.desktop`
    height: 80px;
  `}

  ${({ theme }) => theme.tablet`
    width: 48px;
  `}
`;
export const SendButton = styled(InputButton)`
  display: block;

  ${({ theme }) => theme.desktop`
    margin-top: 8px;
    margin-left: 102px;
    width: 286px;
  `}

  ${({ theme }) => theme.tablet`
    position: static;
    margin-top: 10px;
    padding: 10px 0;
    width: 100%;
    border-radius: 3px;
    border: solid 1px ${theme.subActiveColor};
    font-size: 16px;
  `}
`;
export const CertifyButton = styled(InputButton)`
  ${({ theme }) => theme.tablet`
    top: 4px;
  `}
`;
export const CertifyRow = styled.div`
  margin-top: 8px;

  ${({ theme }) => theme.tablet`
    position: relative;
    margin-top: 20px;
  `}
`;

export const SearchIcon = styled(AiOutlineSearch)`
  ${({ theme }) => theme.desktop`
    display: none;
  `}
  ${({ theme }) => theme.tablet`
    display: inline-block;
    vertical-align: bottom;
    margin-right: 6px;
    color: ${theme.subActiveColor};
  `}
`;
