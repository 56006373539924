import React from 'react';
import styled from 'styled-components';
import { ClearA } from 'components/atoms/Clear';

export default React.memo(function PurchaseAgainButton() {
  return (
    <Link href='https://pf.kakao.com/_xdbBvxd' target='_blank'>
      카카오톡 문의하기
    </Link>
  );
});

const Link = styled(ClearA)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  width: 280px;
  height: 44px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.subActiveColor};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: ${({ theme }) => theme.subActiveColor};
  text-align: center;
  ${({ theme }) => theme.tablet`
    margin: 10px auto;
  `}
`;
