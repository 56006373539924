import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { BEST_TYPE } from 'interfaces/Home';
import { useStores } from 'stores';
import { ClearA } from 'components/atoms/Clear';
import { ListMoreLink, Wrapper } from 'components/atoms';
import { PackagesTitle } from './styled';
import PackagesContainer from 'components/organisms/PackagesContainer';

const BestContainer: React.FC<any> = observer(() => {
  const {
    home: { best, bestType: type, fetchBest },
    layout: { isMobile },
  } = useStores();
  const fetchByType = useCallback((type: BEST_TYPE) => () => fetchBest(type), [fetchBest]);
  const { TRANSACTION, POPULARITY, INSTAGRAM, BEST } = BEST_TYPE;

  return (
    <HomeBestWrap>
      <PackagesTitle>BEST</PackagesTitle>
      <Filter>
        <Button onClick={fetchByType(TRANSACTION)} $selected={type === TRANSACTION}>
          판매량순
        </Button>
        <Button onClick={fetchByType(POPULARITY)} $selected={type === POPULARITY}>
          인기도순
        </Button>
        <Button onClick={fetchByType(INSTAGRAM)} $selected={type === INSTAGRAM}>
          리뷰순
        </Button>
        <Button onClick={fetchByType(BEST)} $selected={type === BEST}>
          판매자추천순
        </Button>
      </Filter>
      <ContainerWrap>
        <PackagesContainer store={best} withNumber={!isMobile} txtCenter />
        <MoreButton to={PAGE_URL.PACKAGES_BEST}>더보기</MoreButton>
      </ContainerWrap>
    </HomeBestWrap>
  );
});

export default BestContainer;

const HomeBestWrap = styled(Wrapper)`
  text-align: center;

  ${({ theme }) => theme.desktop`
    margin-top: 32px;
    margin-bottom: 80px;
  `}
`;

const Filter = styled.div`
  display: flex;
  justify-content: center;
`;

const Button = styled(ClearA)<{ $selected: boolean }>`
  display: block;
  margin: 40px 0;
  padding: 0 20px;
  border-right: 1px solid #b3b3b3;
  cursor: pointer;
  font-weight: ${({ $selected }) => ($selected ? 'bold' : 'normal')};

  &:last-child {
    border: 0;
  }

  ${({ theme }) => theme.tablet`
    margin: 20px 0;
    font-size: 12px;
  `}
`;

const ContainerWrap = styled.section`
  text-align: left;

  ${({ theme }) => theme.tablet`
    padding: 0 10px;
  `}
`;

const MoreButton = styled(ListMoreLink)`
  ${({ theme }) => theme.desktop`
    margin: 30px auto 100px;
  `}

  ${({ theme }) => theme.tablet`
    margin: 10px auto 40px;
  `}
`;
