import React, { useRef, useEffect, useState, useMemo } from 'react';
import { Accordion } from 'react-accessible-accordion';
import styled from 'styled-components';
import cx from 'classnames';

import { getYoutubeVid, generateKey, replaceMallUrl } from 'utils';
import PackageDetailModel, { ContentResource } from 'stores/models/PacakgeDetailModel';
import { useToggle } from 'components/hooks/useToggle';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import ComponentsTable, { InfoBox } from 'components/molecules/PackagesDetail/ComponentsTable';
import {
  PackageDeatilContentsBox,
  VideoContainer,
  ListMoreButton,
  ClearLink,
} from 'components/atoms';
import ReturnGuideAccordion from 'components/molecules/PackagesDetail/ReturnGuideAccordion';
import DeliveryGuideAccordion from 'components/molecules/PackagesDetail/DeliveryGuideAccordion';
import { ConditionalWrapper } from 'components/atoms/ConditionalWrapper';
import NewLine from 'components/atoms/NewLine';

interface Props {
  store: PackageDetailModel;
}

const ExplanationBox = React.memo(({ store }: Props) => {
  const { description, content_resources, components, infos } = store;
  const ContentsRef = useRef<HTMLDivElement>(null);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [open, onClickOpenToggle] = useToggle(false);

  useEffect(() => {
    const predictHeight = content_resources.reduce((acc, cur) => {
      const height = cur.image_height || 0;
      const width = cur.image_width || 0;
      const realHeight = (height * 780) / width || 0;
      return acc + realHeight;
    }, 0);

    if ((ContentsRef.current && ContentsRef.current.offsetHeight > 7077) || predictHeight > 7077) {
      setShowMoreButton(true);
    }
  }, [content_resources]);

  return (
    <>
      <PackageDeatilContentsBox>
        <WrapContents ref={ContentsRef} className={cx({ hidden: showMoreButton, open })}>
          {useDesktopShow(<NewLine text={description} Wrapper={P} />)}
          <br />
          <Contents resources={content_resources} />
        </WrapContents>
        {/* 스크롤 위치 고정을 위해서 버튼 제거 */}
        {showMoreButton && !open ? (
          <MoreButton onClick={onClickOpenToggle}>
            더보기
            <DownArrowButton />
          </MoreButton>
        ) : null}
        {showMoreButton && open ? <MoreButton onClick={onClickOpenToggle}>닫기</MoreButton> : null}
      </PackageDeatilContentsBox>
      <ComponentsTable components={components} withHeader BorderBottom />
      {useMemo(
        () => (
          <>
            <InfoBox $BorderBottom>
              <h2>상품정보 제공고시</h2>
              <dl>
                {infos.map(({ title, description }, idx) => (
                  <div key={generateKey(`PackageDetail_Infos_${idx}`)}>
                    <dt>{title}</dt>
                    <dd>{description}</dd>
                  </div>
                ))}
              </dl>
            </InfoBox>
            <AccordionBox allowMultipleExpanded allowZeroExpanded>
              <ReturnGuideAccordion />
              <DeliveryGuideAccordion />
            </AccordionBox>
          </>
        ),
        [infos],
      )}
    </>
  );
});

const Contents = React.memo(({ resources }: { resources: ContentResource[] }) => (
  <>
    {resources.map(({ resource_type, link_url, ...other }, idx) => {
      const key = generateKey(`PackageDetail_Contents_${idx}`);
      let link = replaceMallUrl(link_url);

      if (resource_type === 'video') {
        const vid = getYoutubeVid(other.video_url);
        return vid ? (
          <VideoContainer key={key}>
            <iframe
              title={vid}
              src={other.video_url}
              frameBorder='0'
              allow='accelerometer;  encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </VideoContainer>
        ) : null;
      } else if (resource_type === 'content_image_with_margin') {
        return (
          <ConditionalWrapper
            key={key}
            condition={link}
            wrapper={(children: React.ReactNode) => <ClearLink to={link}>{children}</ClearLink>}>
            <MarginImg src={other.image_url} alt={resource_type} />
          </ConditionalWrapper>
        );
      } else if (resource_type === 'content_image') {
        return (
          <ConditionalWrapper
            key={key}
            condition={link}
            wrapper={(children: React.ReactNode) => <ClearLink to={link}>{children}</ClearLink>}>
            <Img src={other.image_url} alt={resource_type} />
          </ConditionalWrapper>
        );
      } else if (resource_type === 'content') {
        return <P key={key} dangerouslySetInnerHTML={{ __html: other.content }} />;
      } else {
        return null;
      }
    })}
  </>
));

const WrapContents = styled.div`
  & > * {
    float: left;
    width: 100%;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  &.hidden {
    max-height: 7270px;
    overflow: hidden;

    ${({ theme }) => theme.mobile`
      max-height: 3586px;
    `}

    &.open {
      max-height: none;
    }
  }
`;

const MoreButton = styled(ListMoreButton)`
  margin-top: 39px;
  border: solid 1px ${({ theme }) => theme.mainActive};
  font-size: 24px;
  color: ${({ theme }) => theme.mainActive};
`;

const DownArrowButton = styled.div`
  display: inline-block;
  margin-left: 10px;
  width: 23px;
  height: 13px;
  color: ${({ theme }) => theme.mainActive};
  background: url('/icons/select-down-orange.svg') no-repeat;
  background-size: 100%;

  ${({ theme }) => theme.mobile`
    margin-left: 5px;
    width: 15px;
    height: 8px;
  `}
`;

const P = styled.p`
  margin: 14px 0;
  padding: 0 20px;
  line-height: 1.8;

  &:first-child,
  & + & {
    margin-top: 0;
  }

  & > h2 {
    font-size: 24px;
    line-height: 1.5;
  }

  ${({ theme }) => theme.tablet`
    padding: 0;
  `}
`;

const Img = styled.img`
  float: left;
  width: 100%;
`;

const MarginImg = styled(Img)`
  padding: 10px 20px;

  ${({ theme }) => theme.tablet`
    padding: 10px 0;
  `}
`;

const AccordionBox = styled(Accordion)`
  margin-top: 48px;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin-top: 30px;
  `}
`;

export default ExplanationBox;
