import React from 'react';
import styled from 'styled-components';
import { generateKey } from 'utils';

interface Props {
  Wrapper?: any;
  text: string;
  sticky?: boolean;
}

const NewLine: React.FC<Props> = ({ Wrapper = DefaultWrapper, text = '', sticky = false }) => {
  const processedText = (text ? text.replace(/\r/gi, '') : '').split('\n');

  return (
    <Wrapper>
      {processedText.map(line => {
        if (sticky) {
          if (line.length) {
            return (
              <span key={generateKey(`text`)}>
                {line}
                <br />
              </span>
            );
          } else {
            return null;
          }
        } else {
          return (
            <span key={generateKey(`text`)}>
              {line}
              <br />
            </span>
          );
        }
      })}
    </Wrapper>
  );
};

const DefaultWrapper = styled.div``;

export default NewLine;
