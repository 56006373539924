import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { UseFormMethods } from 'react-hook-form';
import { useCouponStore } from 'stores/local/Coupon';
import CouponCard from './CouponCard';
import { RadioButton, RadioLabel } from './styled';

interface Props {
  useForm: UseFormMethods;
}

const CouponList: React.FC<Props> = observer(({ useForm }) => {
  const { isInit, coupons } = useCouponStore();
  const { getValues, setValue } = useForm;

  const handleChange = useCallback(
    (id = '', price = '') =>
      () =>
        setValue('_coupon', { id, price }),
    [setValue],
  );

  return isInit ? (
    <>
      <RadioButton>
        <input
          id='ipt-coupon'
          type='radio'
          name='coupon'
          onChange={handleChange()}
          defaultChecked={getValues('_coupon.id') === ''}
        />
        <RadioLabel htmlFor='ipt-coupon'>선택 안함</RadioLabel>
      </RadioButton>
      {coupons.map(item => (
        <CouponCard key={item.id} item={item} handleChange={handleChange} getValues={getValues} />
      ))}
    </>
  ) : null;
});

export default CouponList;
