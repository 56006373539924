import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import SwiperCore, { Thumbs, Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { generateKey } from 'utils';

import { RollingImage } from 'stores/models/PacakgeDetailModel';
import { BgImg, RelativeDiv, LeftRightButton } from 'components/atoms';

SwiperCore.use([Thumbs, Navigation, Pagination, Autoplay]);

/******************************************************************
 * author: yongjun kim
 * cotents: watchSlides와 slidesPerGroup 같이 적용시 버그 있음
 * issue link: https://github.com/nolimits4web/swiper/issues/3523
 ******************************************************************/

const ImageSlider = ({ images }: { images: RollingImage[] }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(undefined);
  const setThumbs = useCallback(swiper => {
    setThumbsSwiper(swiper);
  }, []);
  const MainSwiperOption = {
    autoplay: true,
    thumbs: { swiper: thumbsSwiper },
    pagination: { clickable: true },
  };
  const ThumbsSwiperOption = {
    onSwiper: setThumbs,
    spaceBetween: 8,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  };
  const onClickPrevThumbs = useCallback(() => {
    if (thumbsSwiper) thumbsSwiper.slideTo(4 * (thumbsSwiper.activeIndex / 4 - 1), 300);
  }, [thumbsSwiper]);
  const onClickNextThumbs = useCallback(() => {
    if (thumbsSwiper) thumbsSwiper.slideTo(4 * (thumbsSwiper.activeIndex / 4 + 1), 300);
  }, [thumbsSwiper]);
  const emptyLen = images.length % 4 ? 4 - (images.length % 4) : 0;

  return (
    <WrapSwiper>
      <MainSwiper {...MainSwiperOption}>
        {images.map((item: RollingImage, index: number) => (
          <SwiperSlide key={`__PackageDetail_Rolling_${index}`}>
            <MainSlideImg src={item.image_url} />
          </SwiperSlide>
        ))}
      </MainSwiper>
      <WrapThumbsSwiper>
        <ThumbsSwiper {...ThumbsSwiperOption}>
          {images.map((item: RollingImage, index: number) => (
            <SwiperSlide key={`__PackageDetail_Rolling_Thumbs_${index}`}>
              <ThumbSlide $src={item.image_url} />
            </SwiperSlide>
          ))}
          {Array.from({ length: emptyLen }, () => (
            <SwiperSlide key={generateKey()} />
          ))}
        </ThumbsSwiper>
        <ThumbPrevButton onClick={onClickPrevThumbs} />
        <ThumbNextButton onClick={onClickNextThumbs} />
      </WrapThumbsSwiper>
    </WrapSwiper>
  );
};

const WrapSwiper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 582px;
  width: 582px;

  ${({ theme }) => theme.tablet`
    flex-basis: 100%;
    width: 100%;  
  `}
`;

const MainSwiper = styled(Swiper)`
  .swiper-pagination-bullet {
    margin: 0 4.5px !important;
    width: 7px;
    height: 7px;
  }

  ${({ theme }) => theme.desktop`
    box-sizing: content-box;
    margin: 0;
    padding-bottom: 100px;
    width: 582px;

    .swiper-pagination-bullets,
    &.swiper-container-horizontal > .swiper-pagination-bullets {
      width: 100%;
      bottom: 0;
    }
  `}

  ${({ theme }) => theme.tablet`
    padding-bottom: 0;
    width: 100%;

    .swiper-pagination-bullets,
    &.swiper-container-horizontal > .swiper-pagination-bullets {
      width: 100%;
      bottom: 12px;
    }
  `}
`;
const MainSlideImg = styled.img`
  ${({ theme }) => theme.desktop`
    width: 582px;
    height: 382px;
  `}

  ${({ theme }) => theme.tablet`
    width: 100%;  
  `}
`;

const WrapThumbsSwiper = styled(RelativeDiv)`
  ${({ theme }) => theme.tablet`
  display: none;
  `}
`;
const ThumbsSwiper = styled(Swiper)`
  margin: -84px 100px 0;

  .swiper-slide-thumb-active::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
`;
const ThumbSlide = styled(BgImg)`
  position: relative;
  height: 58px;
`;

const ThumbPrevButton = styled(LeftRightButton)`
  z-index: 2;
  left: 75px;
  border-left: 3px solid #d5d5d5;
  border-bottom: 3px solid #d5d5d5;
`;
const ThumbNextButton = styled(LeftRightButton)`
  z-index: 2;
  right: 75px;
  border-right: 3px solid #d5d5d5;
  border-top: 3px solid #d5d5d5;
`;

export default ImageSlider;
