import React from 'react';
import { observer, useObserver } from 'mobx-react';
import Masonry from 'react-masonry-css';
import styled from 'styled-components';
import { generateKey } from 'utils';

import { usePackageDetailStore } from 'pages/PackageDetail/store';
import { ContentResource } from 'stores/models/PacakgeDetailModel';
import { FadeIn } from 'components/atoms/Animation';

const ReviewBox: React.FC<any> = observer(() => {
  const { packageDetail } = usePackageDetailStore();

  if (packageDetail.reviews.length) {
    return (
      <WrapInsta>
        <LoadingLayer store={packageDetail} />
        <Contents reviews={packageDetail.reviews} />
      </WrapInsta>
    );
  } else {
    return (
      <WrapInsta>
        <strong>인스타그램에 회원님의 첫번째 후기를 올려주세요!</strong> <br />
        <br />
        #차이의놀이 #차이의놀이패키지
      </WrapInsta>
    );
  }
});

const LoadingLayer: React.FC<any> = ({ store }) =>
  useObserver(() => <Loading $complete={store.isLoadInsta} />);

const breakpointColumnsObj = {
  default: 2,
  768: 1,
};
const Contents = ({ reviews }: { reviews: ContentResource[] }) => (
  <Masonry
    breakpointCols={breakpointColumnsObj}
    className='insta-review-grid'
    columnClassName='insta-review-grid_column'>
    {reviews.map(({ resource_type, ...other }, idx) => (
      <Instagram
        key={generateKey(`PackageDetail_Contents_${idx}`)}
        dangerouslySetInnerHTML={{ __html: other.embed_code }}
      />
    ))}
  </Masonry>
);

const WrapInsta = styled.div`
  position: relative;
  margin-top: 24px;
  min-height: 525px;
  overflow: hidden;

  .insta-review-grid {
    display: flex;
    margin-left: -30px;
    width: auto;

    ${({ theme }) => theme.tablet`
      margin-left: -10px;
    `}
  }

  .insta-review-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;

    ${({ theme }) => theme.tablet`
      padding-left: 10px;
    `}
  }

  ${({ theme }) => theme.tablet`
    margin-top: 0;
    padding: 10px;
    background-color: #f8f8f8;
  `}
`;

const Loading = styled.div<{ $complete: boolean }>`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #fff;

  animation: ${({ $complete }) => ($complete ? FadeIn : 'none')} 0.35s;
  animation-fill-mode: forwards;
`;

const Instagram = styled.div`
  .insta-review-grid_column > & {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;

      & > iframe {
        margin: 0 !important;
      }
    }

    ${({ theme }) => theme.tablet`
      margin-bottom: 10px;
      padding: 10px 20px 10px;
      background: #fff;

      & > iframe {  
        min-width: initial!important;
        max-width: initial!important;
        border: 0!important;
      }
    `}
  }
`;

export default ReviewBox;
