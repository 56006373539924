import React from 'react';
import { useRouteMatch, generatePath } from 'react-router-dom';
import cx from 'classnames';

import { PAGE_URL, PackagesMatchParams } from 'configs/path';
import { PackageCategory } from 'layouts/models/CategoriesModel';
import { WrapFilter, WrapFilterLink, FilterLink } from 'components/styled/ListFilter';

interface IProps {
  value: number;
  category: PackageCategory[];
}

const SubCategoryFilter = ({ value, category }: IProps) => {
  const match = useRouteMatch<PackagesMatchParams>();
  const { main, sub } = match.params;
  const subParam = decodeURIComponent(sub || '');
  const lastRowItems = (category.length + 1) % 3 || 3;

  return (
    <WrapFilter>
      <FilterItem
        isActive={!subParam}
        to={generatePath(PAGE_URL.PACKAGES_MAIN, { main })}
        text={'전체'}
        lastRowItems={lastRowItems}
      />
      {category.map(({ category, categoryName }) => (
        <FilterItem
          key={`__packages_categoryFtiler_${category}`}
          isActive={subParam === category}
          to={generatePath(PAGE_URL.PACKAGES_SUB, { main, sub: category })}
          text={categoryName}
          lastRowItems={lastRowItems}
        />
      ))}
    </WrapFilter>
  );
};

SubCategoryFilter.defaultProps = {
  category: [],
};
export default SubCategoryFilter;

interface FilterItemProps {
  isActive: boolean;
  to: string;
  text: string;
  lastRowItems: number;
}

const FilterItem: React.FC<FilterItemProps> = React.memo(({ isActive, to, text, lastRowItems }) => (
  <WrapFilterLink $lastRowItems={lastRowItems}>
    <FilterLink className={cx({ active: isActive })} to={to}>
      {text}
    </FilterLink>
  </WrapFilterLink>
));
