import React from 'react';
import moment from 'moment';
import { Td } from 'components/atoms/HistoryTable';

const HistoryRow: React.FC<any> = React.memo(({ value, created_at, type, title, unit }) => {
  return (
    <tr>
      <Td $txtBold>{type}</Td>
      <Td>{moment(created_at).format('YYYY.MM.DD')}</Td>
      <Td>{title}</Td>
      <Td>
        {value} {unit}
      </Td>
    </tr>
  );
});

export default HistoryRow;
