import React, { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch, match } from 'react-router-dom';
import { observable, flow, action } from 'mobx';
import { useLocalStore } from 'mobx-react';
import qs from 'query-string';
import { Location } from 'history';

import { parseLinkHeader } from 'utils';
import { PackagesMatchParams } from 'configs/path';

import PlayModel from 'stores/models/PlayModel';
import PlaysRepository from '../repository/PlaysRepository';

export interface PlaysFilter {
  age: string;
  page: number;
  per_page: number;
}

interface storeProps {
  match: match<PackagesMatchParams>;
  history: any;
  location: Location<unknown>;
}

const createStore = ({ match, history, location }: storeProps) => {
  const store = observable(
    {
      match,
      history,
      location,
      isInit: false,
      plays: [] as PlayModel[],
      filter: {
        per_page: 24,
      } as PlaysFilter,
      pagenation: {},

      fetchFilter(search: string) {
        const { age, page } = qs.parse(search);

        this.filter.age = age ? `age=${age}` : '';
        this.filter.page = parseInt(page as string) || 1;

        this.fetchPlays();
      },
      fetchPlays() {
        const { age, ...other } = this.filter;
        const query = qs.stringify({ ...other, ...qs.parse(this.filter.age) });

        flow(function* (this: any) {
          try {
            this.isInit = false;

            const { data, headers } = yield PlaysRepository.fetchPapers(query);
            this.plays = data.plays.map((item: any) => new PlayModel(item));
            this.pagenation = parseLinkHeader(headers.link);

            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
      setFilter(query: any) {
        const newQuery = { ...qs.parse(this.location.search), ...query };

        // 소팅, 연령 변경시 페이지 초기화
        if (!query.page) newQuery.page = 1;

        // 값 없는 쿼리 데이터 제거
        for (const [key, value] of Object.entries(newQuery)) {
          if (!value) delete newQuery[key];
        }

        this.history.push({
          pathname: this.location.pathname,
          search: qs.stringify(newQuery),
        });
      },
      setProps({ match, history, location }: storeProps) {
        this.match = match;
        this.history = history;
        this.location = location;
      },
    },
    {
      fetchFilter: action,
      fetchPlays: action,
      setProps: action,
    },
  );

  return store;
};

type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const PlaysProvider = ({ children }: any) => {
  const match = useRouteMatch<PackagesMatchParams>();
  const history = useHistory();
  const location = useLocation();
  const store = useLocalStore(() => createStore({ match, history, location }));

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const usePlaysStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitPlays = () => {
  const { setProps, fetchFilter } = usePlaysStore();
  const match = useRouteMatch<PackagesMatchParams>();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setProps({ match, history, location });
    fetchFilter(location.search);
  }, [fetchFilter, history, location, match, setProps]);
};
