import { observer } from 'mobx-react-lite';
import React from 'react';

interface Props {
  Render: React.FC<{ disabled?: boolean }>;
  model: Model.PackageDetail;
  onClick: (args: any) => any;
}

export const BasketButton: React.FC<Props> = observer(
  ({ Render, model: { hasStock }, ...props }) => (
    <Render {...props} disabled={!hasStock}>
      {hasStock ? '장바구니 담기' : '제품 준비중입니다.'}
    </Render>
  ),
);
