import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observable, flow, action } from 'mobx';
import { useLocalStore } from 'mobx-react';

import { DetailMatchParams } from 'configs/path';

import PlayModel from 'stores/models/PlayModel';
import PlaysRepository from '../repository/PlaysRepository';

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      play: {} as PlayModel,

      fetchPlayDetail(id: number) {
        flow(function* (this: any) {
          try {
            this.isInit = false;

            const { data } = yield PlaysRepository.fetch(id);
            this.play = new PlayModel(data.play);

            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
    },
    {
      fetchPlayDetail: action,
    },
  );

  return store;
};

type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const PlayDetailProvider = ({ children }: any) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const usePlayDetailStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitPlayDetail = () => {
  const { fetchPlayDetail } = usePlayDetailStore();
  const { id } = useRouteMatch<DetailMatchParams>().params;

  useEffect(() => {
    fetchPlayDetail(+id);
  }, [fetchPlayDetail, id]);
};
