import axios from 'axios';
import { API_URL } from 'configs/path';
import { ResPackage } from 'stores/models/PackageModel';

interface Res {
  packages: ResPackage[];
}

class HomeRepository {
  fetchBanners(): Promise<Res> {
    return axios.get(API_URL.HOME_BANNERS);
  }

  fetchRecommendeds(): Promise<Res> {
    return axios.get(API_URL.HOME_RECOMMEND);
  }

  fetchBest(): Promise<Res> {
    return axios.get(API_URL.HOME_BEST);
  }

  fetchByTransaction(): Promise<Res> {
    return axios.get(API_URL.HOME_BY_TRANSACTION);
  }

  fetchByPopularity(): Promise<Res> {
    return axios.get(API_URL.HOME_BY_POPULARITY);
  }

  fetchByInstagram(): Promise<Res> {
    return axios.get(API_URL.HOME_BY_INSTAGRAM);
  }

  fetchNew(): Promise<Res> {
    return axios.get(API_URL.HOME_NEW);
  }
}

export default new HomeRepository();
