import { computed, extendObservable } from 'mobx';

export default class OrderModel implements Order.Order {
  id!: number;
  title!: string;
  paid_at!: Date;
  created_at!: Date;
  price!: number;
  status!: string;
  display_status!: string;
  display_tracking_numbers!: string[];
  can_cancel_order!: boolean;
  already_earned_points!: boolean;
  order_items!: Cart.OrderItem[];
  coupon_price!: number;
  used_point!: number;
  subtotal_price!: number;
  shipping_price!: number;
  extra_shipping_price!: number;

  constructor(props: Order.Order) {
    extendObservable(this, { ...props });
  }

  @computed
  get orderPrice() {
    return (
      this.subtotal_price +
      this.shipping_price +
      this.extra_shipping_price -
      this.used_point -
      this.coupon_price
    );
  }
}
