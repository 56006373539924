import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { DefaultLayout } from 'layouts/Default';
import { useStores } from 'stores';
import { DetailMatchParams } from 'configs/path';

import { WrapPage } from 'components/atoms/PurchasePage';
import Header from './components/Header';
import FailedHeader from '../Complete/components/FailedHeader';
import FailedBody from '../Complete/components/FailedBody';
import VBank from './components/VBank';
import PurchaseTable from './components/PurchaseTable';
import Payments from './components/Payments';
import PaymentsMobile from '../Complete/components/PaymentsMobile';
import Receipt from './components/Receipt';
import ShippingTable from './components/ShippingTable';
import Banner from './components/Banner';
import MainButton from './components/MainButton';
import BuyerTable from '../Complete/components/BuyerTable';
import PurchaseAgainButton from './components/PurchaseAgainButton';
import AskKakaoButton from './components/AskKakaoButton';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';

import qs from 'query-string';
import styled from 'styled-components';

export default DefaultLayout(
  observer(function PurchaseComplete() {
    const { id } = useRouteMatch<DetailMatchParams>().params;
    const { order, cart } = useStores();

    const location = useLocation();
    const { imp_uid, imp_success, error_msg } = qs.parse(location.search);

    useEffect(() => {
      async function orderCompleted() {
        if (imp_uid && imp_success) {
          try {
            await order.completed(parseInt(id), {
              imp_uid: imp_uid as string,
              imp_success: imp_success === 'true',
              error_msg: error_msg as string,
            });
          } catch (error) {
            const { status } = error.response;
            if (status === 422) {
              // throw data;
            } else {
              // throw error;
            }
          }
        } else {
          order.fetch(+id);
        }
      }

      orderCompleted();
    }, [cart, error_msg, id, imp_success, imp_uid, order]);

    const payments = useDesktopShow(<Payments />);
    const paymentsMobile = useTabletShow(<PaymentsMobile />);
    const banner = useDesktopShow(<Banner />);

    if (imp_success === 'false') {
      return (
        <WrapPage>
          <FailedHeader />
          <FailedBody />
          <ButtonWrapper>
            <PurchaseAgainButton />
            <AskKakaoButton />
          </ButtonWrapper>
        </WrapPage>
      );
    } else {
      return (
        <WrapPage>
          <Header />
          <VBank />
          <PurchaseTable />
          {payments}
          {paymentsMobile}
          <Receipt />
          <BuyerTable />
          <ShippingTable />
          {banner}
          <MainButton />
        </WrapPage>
      );
    }
  }),
);

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  ${({ theme }) => theme.tablet`
    flex-direction: column;
  `}
`;
