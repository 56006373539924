import React from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'layouts/Default';
import { PAGE_URL } from 'configs/path';
import * as atoms from 'components/atoms';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';

const ErrorPage: React.FC<any> = () => (
  <Wrapper>
    {useDesktopShow(<Illustration src='/images/error.svg' />)}
    <H1>요청하신 페이지를 찾을 수 없습니다.</H1>
    {useTabletShow(<Illustration src='/images/error.svg' />)}
    <P>
      존재하지 않는 주소를 입력하셨거나, <br />
      요청하신 페이지의 주소가 {useTabletShow(<br />)}
      변경, 삭제되어 찾을 수 없습니다.
    </P>
    <LinkButton to={PAGE_URL.HOME}>메인으로</LinkButton>
  </Wrapper>
);

export default DefaultLayout(ErrorPage, { Option: { full: true } });

const Wrapper = styled(atoms.Wrapper)`
  margin-top: 40px;
  line-height: 1.5;
  text-align: center;

  ${({ theme }) => theme.desktop`
    min-height: 660px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
  `}
`;

const H1 = styled.h1`
  margin: 26px 0 8px;
  font-size: 24px;

  ${({ theme }) => theme.tablet`
    margin: 20px 0 10px;
    font-size: 14px;
  `}
`;

const Illustration = styled.img`
  ${({ theme }) => theme.tablet`
    width: 142px;
    height: 126px;
  `}
`;

const P = styled.p`
  margin: 0;

  ${({ theme }) => theme.tablet`
    margin: 10px 0;
    font-size: 12px;
  `}
`;

const LinkButton = styled(atoms.LinkButton)`
  margin-top: 24px;
  width: 248px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
  `}

  ${({ theme }) => theme.tablet`
    margin-bottom: 30px;
    font-size: 14px;
  `}
`;
