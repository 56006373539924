import { observable } from 'mobx';

export default class CoinModel {
  @observable history: any[];

  constructor(props: Coin.RootObject) {
    this.history = props.coin_histories.map(item => {
      return {
        id: item.id,
        value: item.coin,
        created_at: item.created_at,
        type: item.display_usage.replace(/[[\]]/gi, ''), // [xx] -> xx
        title: item.title,
      };
    });
  }
}
