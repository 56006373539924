import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores';
import { generateKey, getTodayDiff } from 'utils';

import { useCommentsStore } from 'stores/local/Comments';
import { ClearButton } from 'components/atoms/Clear';

const CommentList: React.FC<any> = observer(() => {
  const { auth } = useStores();
  const store = useCommentsStore();
  const onClickDelete = useCallback((id: number) => () => store.delete(id), [store]);

  return (
    <>
      <div>
        {store.comments.comments.map(({ id, nickname, body, created_at, by_me }) => (
          <Comments key={generateKey()}>
            <Nickname>{nickname}</Nickname>
            <TimeLine>{getTodayDiff(created_at)}</TimeLine>
            {auth.isSignIn && by_me ? <DeleteButton onClick={onClickDelete(id)} /> : null}
            <Body>{body}</Body>
          </Comments>
        ))}
      </div>
      {store.comments.show_load_more ? <MoreButton onClick={store.more}>더보기</MoreButton> : null}
    </>
  );
});

export default CommentList;

const Comments = styled.div`
  padding: 16px 0;
  border-bottom: solid 2px #e9e9e9;
  line-height: 1.5;

  &:last-child {
    border-bottom: 0;
  }

  ${({ theme }) => theme.tablet`
    padding: 20px 0;
    font-size: 12px;
  `}
`;

const Nickname = styled.div`
  display: inline-block;
  font-weight: bold;
`;

const TimeLine = styled.div`
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 8px;
  font-size: 12px;
  color: #b3b3b3;

  ${({ theme }) => theme.tablet`
    margin-left: 5px;
    font-size: 10px;
  `}
`;

const DeleteButton = styled(ClearButton)`
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 4px;
  width: 14px;
  height: 18px;
  background: url('/icons/comments-delete.svg') 100% 100%;
  background-size: 11px 14px;
  background-position: center;
  background-repeat: no-repeat;

  ${({ theme }) => theme.tablet`
    vertical-align: middle;
    margin-left: 5px;
    width: 10px;
    height: 12px;
    background-size: 10px 12px;
  `}
`;

const Body = styled.div`
  margin-top: 8px;
`;

const MoreButton = styled(ClearButton)`
  width: 582px;
  height: 52px;
  margin: 48px auto 0;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  background-color: #fff;

  ${({ theme }) => theme.tablet`
    margin-top: 0;
    width: 358px;
    height: 34px;
    font-size: 12px;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;
