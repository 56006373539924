import React from 'react';
import { observer } from 'mobx-react';
import { Thead, Tr, Th } from 'pages/Cart/styled';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const OrderHistoryTableHead = observer(() => {
  return (
    <Thead>
      <Tr>
        <Th className='order-date'>주문일</Th>
        <Th className='order-info'>상품정보</Th>
        {useDesktopShow(<Th className='order-price'>주문 금액</Th>)}
        <Th className='order-status'>상태</Th>
      </Tr>
    </Thead>
  );
});

export default OrderHistoryTableHead;
