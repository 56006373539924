import axios from 'axios';
import { API_URL } from 'configs/path';
import { generatePath } from 'react-router-dom';
import { PackageDetail } from 'stores/models/PacakgeDetailModel';

interface Res {
  package: PackageDetail;
}

class PackagesRepository {
  URL = API_URL.PACKAGE;

  fetch(id: number): Promise<Res> {
    return axios.get(generatePath(API_URL.PACKAGE, { id }));
  }
}

export default new PackagesRepository();
