import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { FormSection, H2, Hr } from './styled';
import { ClearA } from 'components/atoms/Clear';
import { useStores } from 'stores';
import { observer } from 'mobx-react';

const PaymentMethods: React.FC<any> = observer(() => {
  const { order } = useStores();
  const { register, setValue } = useFormContext();

  useEffect(() => {
    const _order = order.savedOrder?.order;
    setValue('order.pay_method', _order?.pay_method ?? 'card');
  }, [setValue, order, order.isFetched]);

  return (
    <FormSection>
      <H2>결제 방법</H2>
      <Hr />
      <WrapRadioButton>
        <RadioButton>
          <input id='pmthd-card' type='radio' name='order.pay_method' value='card' ref={register} />
          <label htmlFor='pmthd-card'>신용카드</label>
          <CardInfoA href={'https://pg.nicepay.co.kr/shared/info.html'} target='_blank'>
            무이자 할부 카드 보기
          </CardInfoA>
        </RadioButton>
        <RadioButton>
          <input
            id='pmthd-vbank'
            type='radio'
            name='order.pay_method'
            value='vbank'
            ref={register}
          />
          <label htmlFor='pmthd-vbank'>가상계좌 (무통장입금)</label>
        </RadioButton>
        <RadioButton>
          <input
            id='pmthd-trans'
            type='radio'
            name='order.pay_method'
            value='trans'
            ref={register}
          />
          <label htmlFor='pmthd-trans'>계좌이체</label>
          <Guide>뱅크페이 앱 설치 필요</Guide>
        </RadioButton>
      </WrapRadioButton>
    </FormSection>
  );
});
export default PaymentMethods;

const WrapRadioButton = styled.div`
  ${({ theme }) => theme.desktop`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;
  `}
`;

const RadioButton = styled.div`
  input {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    & + label {
      position: relative;
      display: inline-block;
      padding-left: 28px;
      line-height: 24px;
      cursor: pointer;
      user-select: none;
    }

    & + label:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 20px;
      border: solid 2px #d5d5d5;
      border-radius: 20px;
    }

    &:checked + label:before {
      border-color: ${({ theme }) => theme.subActiveColor};
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      top: 9px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.subActiveColor};
    }
  }

  ${({ theme }) => theme.desktop`
    flex: 1 1 0;
  `}

  ${({ theme }) => theme.tablet`
    padding:  10px 13px;
    border-bottom: 1px solid #d5d5d5;

    &:last-child {
      border-bottom: 0;
    }
  `}
`;

const CardInfoA = styled(ClearA)`
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.subActiveColor};
`;

const Guide = styled.div`
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  color: #b3b3b3;
`;
