import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { MypageLayout } from 'layouts/Mypage';

import { Wrapper, Title } from 'components/atoms/Mypage';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { ClearButton } from 'components/atoms/Clear';
import { useStores } from 'stores';

const Deactivate: React.FC<any> = observer(({ pgMinHeight, history }) => {
  const { auth } = useStores();
  const handleDeactivate = useCallback(async () => {
    if (
      window.confirm(
        '정말 탈퇴하시겠습니까? 회원님 계정에 보유 패키지 포인트 적립금과 성장교실 코인이 있는 경우 탈퇴 즉시 소멸되고 복구가 불가능합니다.',
      )
    ) {
      await auth.deactivate();
      history.push(PAGE_URL.HOME);
    }
  }, [auth, history]);

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>탈퇴하기</SubHeaderTitle>
      </SubHeader>
      <Wrapper $pgMinHeight={pgMinHeight}>
        <Title>탈퇴하기</Title>
        <Question>탈퇴하시겠습니까?</Question>
        <Desc>
          회원님 계정에 보유 패키지 포인트 적립금과 성장교실 코인이 있는 경우 탈퇴 즉시 소멸되고
          복구가 불가능합니다.
        </Desc>
        <Button onClick={handleDeactivate}>탈퇴하기</Button>
      </Wrapper>
    </>
  );
});

export default MypageLayout(Deactivate, { Option: { full: true } });

const Question = styled.div`
  margin-top: 16px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    margin-top: 0;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  `}
`;

const Desc = styled.div`
  margin-top: 8px;
  font-size: 16px;

  ${({ theme }) => theme.tablet`
    margin: 12px 20px;
    text-align: center;
    font-size: 12px;
  `}
`;

const Button = styled(ClearButton)`
  margin-top: 16px;
  padding: 6px 34px;
  width: 248px;
  height: 44px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};

  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.tablet`
    margin: 12px auto 0;
  `}
`;
