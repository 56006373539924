import React from 'react';

export const Faq = [
  {
    category: '구매 및 배송 관련 문의',
    items: [
      {
        question: '배송은 얼마나 걸리나요?',
        answer: (
          <ul>
            <li>
              연말 연시, 기획전 행사 및 재고 부족 등의 배송 관련 특별한 이슈가 없는 경우 아래와 같이
              택배사에 주문해 주신 패키지를 출고해 드립니다.
            </li>
            <br />
            <li>평일(월~금) 오후 1시까지 주문하신 경우, 주문일 택배 출고</li>
            <li>금요일 오후 1시 이후 ~ 주말 주문은 월요일 택배 출고</li>
            <li>
              국내 택배사는 CJ대한통운입니다. 국내 택배 출고 상품은 일반적으로 평일 기준 2~3일 안에
              받아보실 수 있습니다.
            </li>
            <li>
              제주를 포함한 도서 산간 지역은 이 보다 1~2일 정도 추가로 소요될 가능성이 있습니다.
              (명절 연휴 등 전국적인 택배 물량 증가 등 이슈 발생 시 제외)
            </li>
          </ul>
        ),
      },
      {
        question: '해외 배송 가능한가요?',
        answer: (
          <ul>
            <li>
              해외에서 차이의 놀이 패키지를 받아보시기 원하신다면, 카카오톡 검색&gt; '해빛' 채널을
              통해 문의해 주세요.
            </li>
            <li>
              가장 신속한 안내를 받으실 수 있습니다. 참고로 해외 배송은 배송 지역에 따라 부과되는
              추가 배송비가 발생합니다.
            </li>
            <li>
              또한, 차이의 놀이 패키지 구매를 위해서는 최초 1회 휴대폰 문자 메시지를 통한 회원
              인증이 필요합니다.
            </li>
            <li>해외 거주하시는 경우 이 단계 진행이 불가할 수 있어, 저희가 도와 드리겠습니다.</li>
            <li>
              카카오톡에서 '해빛'을 검색하여 문의 주시면 안내 및 구매 진행 절차를 도와 드리겠습니다.
            </li>
          </ul>
        ),
      },
      {
        question: '배송상태를 알 수 있을까요?',
        answer: (
          <ul>
            <li>
              가능합니다. [마이페이지] &gt; [주문/배송 조회] 메뉴에서 운송장 번호 등록이 완료된
              상품의 배송 상황을 조회하실 수 있습니다.
            </li>
            <li>
              만약, 수령완료로 표시되어 있으나 물품 전달이 안 된 경우 카카오톡에서 '해빛'을 검색하여
              문의해 주세요.
            </li>
            <li>정확한 배송 상황을 확인하여 안내해 드리겠습니다.</li>
          </ul>
        ),
      },
      {
        question: '교환이나 환불은 어떻게하죠?',
        answer: (
          <ul>
            <li>카톡 ‘해빛’ 채널을 통한 상담 후 저희 쪽에서 반품 신청해 드리고 있습니다.</li>
            <br />
            <li>판매자 지정 택배사 : CJ 대한통운</li>
            <li>
              반품배송비 : 편도 3,500원 (최초 배송비 무료인 경우 7,000원 부과) / 제품의 사이즈가
              가로 50, 세로 30을 넘는 경우, 편도 4,000원 (최초 배송비 무료인 경우 8,000원 부과) /
              도서 산간 지역 3,000~5,000원 왕복 추가 과금
            </li>
            <li>
              교환배송비 : 7,000원 (제품의 사이즈가 가로 50, 세로 30을 넘는 경우, 8000원 부과)
            </li>
            <li>보내실 곳 : (우: 12269) 경기도 남양주시 와부읍 궁촌로 78, 1동 해빛/차이의 놀이</li>
            <br />
            <li>반품 교환 사유에 따른 요청 가능 기간은 다음과 같습니다.</li>
            <br />
            <li>구매자 단순변심 : 상품 수령 후 7일 이내 (구매자 반품배송비 부담)</li>
            <li>
              표시/광고와 상이, 상품하자 : 상품 수령 후 3개월 이내 혹은 표시/광고와 다른 사실을 안
              날로부터 30일 이내 (판매자 반품배송비 부담) 둘 중 하나 경과 시 반품/교환 불가
            </li>
          </ul>
        ),
      },
      {
        question: '패키지를 구매할때 어떤 혜택이 있나요?',
        answer: (
          <ul>
            <li>
              차이의 놀이에서 패키지 구매 금액에 따라 무료 배송 및 포인트 적립 혜택을 드리고
              있습니다.
            </li>
            <li>
              단, 결제하실 때, 적립금 포인트 사용 금액을 제외한 최종 실 결제 금액 기준으로
              적용됩니다.
            </li>
            <br />
            <ul>
              <li>
                결제금액 3만원 이상 <b>600포인트 적립</b>
              </li>
              <li>
                결제금액 5만원 이상 <b>2,000포인트 적립</b>
              </li>
              <li>
                결제금액 7만원 이상 <b>3,000포인트 적립</b>
              </li>
              <li>
                결제금액 10만원 이상 <b>5,000포인트 적립</b>
              </li>
              <li>
                결제금액 15만원 이상 <b>7,500포인트 적립</b>
              </li>
              <li>
                결제금액 20만원 이상 <b>12,000포인트 적립</b>
              </li>
              <li>
                결제금액 30만원 이상 <b>20,000포인트 적립</b>
              </li>
              <li>
                결제금액 50만원 이상 <b>33,500포인트 적립</b>
              </li>
              <li>
                결제금액 70만원 이상 <b>48,000포인트 적립</b>
              </li>
              <li>
                결제금액 90만원 이상 <b>62,000포인트 적립</b>
              </li>
              <li>
                결제금액 100만원 이상 <b>70,000포인트 적립</b>
              </li>
            </ul>
            <br />
            <li>
              다양한 특가 행사 외에 구매금액 별 사은품 증정 이벤트도 수시로 진행하고 있으니 많은
              이용 부탁 드려요!
            </li>
          </ul>
        ),
      },
      {
        question: '패키지를 구매했는데 패키지 놀이법은 어디서 보나요?',
        answer: (
          <ul>
            <li>
              놀이법이 제공되는 놀이 패키지를 구매하신 경우에는 [마이페이지] &gt; [구매한 패키지
              놀이법] 메뉴에서 놀이법을 확인할 수 있습니다.
            </li>
            <li>
              만약, 놀이법이 확인되지 않는 경우에는 카카오톡 '해빛'을 검색하여 문의 해 주시면 확인
              후 안내해 드리겠습니다.
            </li>
          </ul>
        ),
      },
      {
        question: '저희는 교육기관, 법인인데 어떻게 구매하면 되나요?',
        answer: (
          <ul>
            후불 방식 결제를 하시는 교육기관 구매 절차 안내 드립니다.
            <br />
            <br />
            <li>
              회원 가입 구매 또는 비회원 구매 가능합니다. 차이의 놀이 회원 가입은 이메일 주소와
              비밀번호만 입력하신 후 간편하게 가입하실 수 있습니다.
            </li>
            <li>
              장바구니에 구매하실 패키지 상품을 담으신 후 배송지 정보 (이름, 연락처, 주소)를 입력해
              주세요.
            </li>
            <li>
              필요하신 서류가 있는 경우 카카오톡 &gt; '해빛'으로 휴대폰 번호와 함께 연락 주시면
              거래명세서/ 세금계산서 등 필요하신 내용 안내 드리겠습니다.
            </li>
            <br />
            결제 영수증은 결제 직후 결제 단계에서 입력하신 이메일로 자동 전송되고 있으며, 마이페이지
            &gt; 주문배송조회 상세 내역에서도 확인 및 이메일 전송 가능합니다.
          </ul>
        ),
      },
      {
        question: '기관인데 세금계산서, 현금영수증은 어떻게 신청할 수 있나요?',
        answer: (
          <ul>
            세금계산서와 현금영수증은 중복 발행이 불가하여 안내 드립니다.
            <br />
            <br />
            <li>
              차이의놀이에서 '가상 계좌' 결제로 진행하시는 경우, 결제 중간 단계에서 직접 현금영수증
              신청 번호를 입력하실 수 있습니다.
            </li>
            <li>
              후불 결제 (계좌 입금) 해 주시는 경우, 현금영수증이 필요하시면 신청하실 번호를 카카오톡
              &gt; '해빛' 으로 말씀해 주세요.
            </li>
            <li>
              세금 계산서 발행을 신청하시면 1) 기관 사업자 등록증 사본, 2) 발급 희망날짜, 3)
              전자세금계산서 수신 이메일 주소 정보를 카카오톡 &gt; '해빛' 으로 말씀해 주시면 됩니다.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    category: '맞춤정보 및 주요기능',
    items: [
      {
        question: '차이의 놀이에서 아이 연령 기준은 어떻게 되나요?',
        answer: (
          <ul>
            <li>
              차이의 놀이에서는 '만'나이로 등록하신 자녀 정보에 꼭 맞는 콘텐츠를 제공해 드리고
              있습니다.
            </li>
            <li>
              차이의 놀이 연령 기준은 다음과 같으며, 자녀 정보를 등록하셔서 [맞춤정보 ] 와 앱 알림
              정보를 손 쉽게 받아 보세요.
            </li>
            <ul>
              <li>만 0세(0-11개월)</li>
              <li>만 1세(12-23개월)</li>
              <li>만 2세(24-35개월)</li>
              <li>만 3세(36-47개월)</li>
              <li>만 4세(48-59개월)</li>
              <li>만 5세(60-71개월)</li>
              <li>만 6세(72-83개월)</li>
            </ul>
          </ul>
        ),
      },
      {
        question: '아이를 추가하고 싶은데 어떻게 하나요?',
        answer: (
          <ul>
            <li>
              현재 맞춤정보에 자녀 정보가 등록된 경우, 오른쪽 '톱니 모양' 터치 &gt; '아이정보
              수정하기'를 누르시면 정보 추가 등록이 가능합니다.
            </li>
            <br />
            <li>
              또는 [마이페이지] - [설정] - [아이정보 설정]에서 아이정보를 변경하실 수 있습니다.
            </li>
            <li>아이의 이름과 생년월일을 입력 후 '저장'을 눌러 주세요.</li>
            <br />
            <li>
              만약, 두 명 이상 아이 정보를 등록하신 경우 차이의놀이앱 [맞춤정보] - '톱니 모양'을
              터치하면, 등록된 아이 정보가 모두 표시되며, 맞춤 정보를 받고 싶은 아이 정보를 바로
              선택하시면 적용됩니다.
            </li>
          </ul>
        ),
      },
      {
        question: '알림을 첫째 아이로 받고 있는데 둘째 아이로 받고 싶어요.',
        answer: (
          <ul>
            <li>
              차이의 놀이앱 [맞춤정보]에서 처음으로 보이는 자녀분 정보에 맞게 앱 알림 정보를 보내
              드리고 있습니다.
            </li>
            <li>
              최신 버전 기준으로 맞춤정보 오른쪽에 있는 '톱니 모양'터치 &gt; 둘째 아이 이름을
              터치하시면, 내일부터 해당 자녀에 맞는 알림을 받아 보실 수 있습니다.
            </li>
          </ul>
        ),
      },
      {
        question: '회원 탈퇴는 어떻게 하나요?',
        answer: (
          <ul>
            <li>
              최신버전 차이의놀이앱을 사용하시는 경우 [마이페이지]-[설정] 터치하시면 '탈퇴하기'
              메뉴가 있습니다. 해당 메뉴를 통해 즉시 회원 탈퇴 가능합니다.
            </li>
            <li>
              구버전 앱을 사용하시면 회원탈퇴 메뉴가 보이지 않습니다. [마이페이지] 메뉴의 [문의 및
              의견 보내기]를 이용하시거나, ask@havitplay.com 로 회원 이메일 주소를 정확히 기재하여
              메일을 보내 주세요. 회원 탈퇴 진행 후 메일 회신으로 처리 결과를 안내해 드립니다.
            </li>
          </ul>
        ),
      },
      {
        question: '아이 발달보다 앞선 놀이나 육아팁이 보여요.',
        answer: (
          <ul>
            <li>
              차이의 놀이에서 제공해 드리는 맞춤형 정보는 해당 개월수의 평균 발달 상황에 기반하여
              제공해 드리고 있습니다.
            </li>
            <li>
              다만, 아기의 발달 상황은 모두 같지 않고, 각자만의 발달 속도가 있기에 자녀의 발달
              상황보다 빠른 듯한 놀이가 보이시더라도 전혀 염려하지 않으셔도 됩니다.
            </li>
            <li>
              예를 들어 아직 걷기 전이지만 선행 놀이 형태로 걷기 자극을 주는 놀이가 보여진다면, 이런
              자극은 아이 발달에 도움이 되는 정보로 참고해 주시면 좋습니다.
            </li>
            <li>
              자녀분의 발달 상황을 관찰하셨다가, 보시기에 적절한 상황으로 판단되실 때 놀이를 진행해
              주시면 됩니다.
            </li>
          </ul>
        ),
      },
      {
        question: '앱 알림이 갑자기 안와요.',
        answer: (
          <ul>
            <li>
              차이의 놀이에서 제공해 드리는 맞춤형 정보는 해당 개월수의 평균 발달 상황에 기반하여
              제공해 드리고 있습니다.
            </li>
            <li>
              다만, 아기의 발달 상황은 모두 같지 않고, 각자만의 발달 속도가 있기에 자녀의 발달
              상황보다 빠른 듯한 놀이가 보이시더라도 전혀 염려하지 않으셔도 됩니다.
            </li>
            <li>
              예를 들어 아직 걷기 전이지만 선행 놀이 형태로 걷기 자극을 주는 놀이가 보여진다면, 이런
              자극은 아이 발달에 도움이 되는 정보로 참고해 주시면 좋습니다.
            </li>
            <li>
              자녀분의 발달 상황을 관찰하셨다가, 보시기에 적절한 상황으로 판단되실 때 놀이를 진행해
              주시면 됩니다.
            </li>
          </ul>
        ),
      },
      {
        question: '지난 알림은 어디서 볼 수 있나요?',
        answer: (
          <ul>
            <li>
              매일 오전에 전해 드리는 오늘의 앱 알림 콘텐츠는 [맞춤정보] 맨 처음에 보이는
              콘텐츠입니다.
            </li>
            <li>
              또한, [마이페이지] 메뉴 상단에 있는 '종' 모양 [알림] 아이콘을 터치하시면 육아정보 알림
              및 광고 콘텐츠를 바로 확인하실 수 있습니다.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    category: '차이의 성장교실 문의',
    items: [
      {
        question: '구매한 콘텐츠는 어디에서 볼 수 있나요?',
        answer: (
          <ul>
            <li>
              [마이페이지] 메뉴에서 [구매한 성장교실 콘텐츠] 를 터치하시면 성장교실 콘텐츠를 바로
              확인하실 수 있습니다.
            </li>
          </ul>
        ),
      },
      {
        question: '코인이 무엇인가요?',
        answer: (
          <ul>
            <li>
              성장교실 코인은 차이의 놀이 유료 콘텐츠인 성장교실 콘텐츠를 열람하기 위해 필요한 가상
              화폐입니다.
            </li>
            <li>안드로이드앱을 사용하시면, 카드결제/구글인앱 결제를 통해 충전 할 수 있습니다.</li>
            <li>iOS 환경에서는 애플 정책상, 애플인앱 결제로 충전이 가능합니다.</li>
          </ul>
        ),
      },
      {
        question: '성장교실에도 할인혜택이 있나요?',
        answer: (
          <ul>
            <li>
              코인 충전 금액은 최소 2,000원부터 최대 30,000원까지 가능하며, 결제 금액에 따라
              10%~26.7% 에 해당하는 보너스 코인을 추가로 적립해 드리고 있습니다.
            </li>
            <li>
              다양하게 마련된 성장교실 콘텐츠 제목과 본문을 살펴 보신 후 코인 결제 금액을 선택해
              주시면 좀 더 많은 보너스 코인 혜택을 받으실 수 있어 더욱 좋습니다.
            </li>
          </ul>
        ),
      },
      {
        question: '친구 초대 이벤트 코인은 어떻게 받을 수 있나요?',
        answer: (
          <>
            <ul>
              <li>
                차이의놀이앱에서 진행하는 친구 초대 이벤트에 참여하시면 성장교실 코인을 무료로
                받으실 수 있습니다.
              </li>
              <li>
                차이의 놀이에 새롭게 가입하는 신규 회원님이 기존 차이의 놀이 회원 계정이 보유한 친구
                초대코드를 회원 가입 과정에서 정확히 입력하시면 신규회원, 기존 회원 모두에게
                성장교실 코인을 적립해 드립니다.
              </li>
              <li>
                기존에 가입된 차이의 놀이 회원님께서는 친구 초대 코드를 본인 계정에 등록할 수
                없으며, 신규 회원 초대하기만 가능합니다.
              </li>
            </ul>
            <ul>
              <li>
                친구초대 이벤트를 통한 성장교실 코인 적립 관련하여 궁금하신 점이 있으시면 카카오톡
                '해빛'을 검색하여 문의 해 주세요.
              </li>
            </ul>
          </>
        ),
      },
      {
        question: '코인을 결제했는데 충전이 안 되요.',
        answer: (
          <ul>
            <li>성장 교실 코인 충전 과정에 불편을 드려 대단히 죄송합니다.</li>
            <li>
              iOS 아이폰 인앱결제를 하신 경우, 먼저 1) 기기 앱스토어 재로그인 2) 기기 재부팅 후
              차이의놀이앱 [마이페이지] &gt; '성장교실 코인 내역'을 확인해 주세요.
            </li>
            <li>
              이 후에도 여전히 문제가 계속된다면, 이메일 ask@havitplay.com 또는 [차이의
              놀이앱]-[마이페이지]-[문의 및 의견 보내기] 로 연락 주세요.
            </li>
            <li>
              카카오톡 &gt; '해빛' 또는 앱 [마이페이지] - [카카오톡 1:1 문의하기] 을 통해서도 채팅
              상담 가능합니다.
            </li>
            <li>
              문의 남겨 주실 때 1) 회원 이메일 주소 2) 결제 완료된 문자 등의 구매 영수증 증빙 자료를
              캡처 이미지로 파일 첨부하여 보내 주시면, 업무 시간 중 최대한 신속히 확인하여 안내
              드리겠습니다.
            </li>
          </ul>
        ),
      },
      {
        question: '성장교실 코인 결제 취소는 어떻게 하나요?',
        answer: (
          <ul>
            <li>
              성장교실 코인 결제 취소는 코인 결제 및 충전 후 7일 이내, 사용하지 않은 코인에 한해서
              결제 취소가 가능합니다.
            </li>
            <li>
              단, iOS 아이폰 결제 취소는 애플 정책 상 개발사 취소가 불가능하여, 애플 고객센터로
              본인이 직접 연락해 주셔야 합니다.
            </li>
            <li>
              차이의 놀이 성장교실 콘텐츠는 청약철회가 불가한 상품으로 결제(사용)완료 된 콘텐츠에
              대한 구매 취소(청약철회)는 불가합니다.
            </li>
            <li>
              충전한 코인은 성장교실 콘텐츠 구매에 한해 사용하실 수 있습니다. 잔여 코인 환불은
              불가합니다.
            </li>
            <li>
              코인 결제 관련 문의는 카카오톡 &gt; '해빛'으로 연락 주시면 확인 후 안내 드리겠습니다.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    category: '1세 아기 부모님들이 자주 궁금해 하는 놀이법에 대한 Q&A',
    items: [
      {
        question: '아기가 놀잇감을 입에 넣는데 어떻게 놀이해야 하는 건가요?',
        answer: (
          <ul>
            <li>구강기 시기의 아기가 놀잇감을 입에 넣는 것은 당연합니다.</li>
            <li>
              아기가 입에 넣어도 안전한 놀잇감을 제시해 주는 것이 좋으며, 입에 넣으면 안 되는 놀이
              재료들은 넣어도 안전할 수 있도록 각자 가정의 상황에 따라 대처해 주시는 것이
              적절합니다.
            </li>
            <li>(예: 놀이 재료에 위생팩 씌우기 등)</li>
          </ul>
        ),
      },
      {
        question: '놀이법 방법대로 아기가 따라주지 않는데, 놀이 발달 수준이 너무 빠른 것 아닌가요?',
        answer: (
          <ul>
            <li>
              차이의 놀이에서 제공되는 놀이법은 아기의 고른 성장 발달을 위해 성장 발달 자극을 주는
              놀이법들로 구성하여 추천 제안드리고 있습니다.
            </li>
            <li>
              아기에게 제시하는 놀이법에 대한 반응은 부모님께서 기대하셨던 방식이 아닌 예상 외의
              상황이 더 많을 것입니다.
            </li>
            <li>
              그러므로 저희가 안내 드리는 해당 놀이법에서 제안한 대로 아기의 어떤 동작이
              이루어져야만 아이와의 놀이에 의미가 있는 것은 아니라는 점을 꼭 말씀 드리고 싶습니다.
            </li>
            <li>
              놀이의 의미에는 제안 드리는 놀이를 시도하고, 아기가 경험했음에 항상 의미가 있음을
              기억해 주세요.
            </li>
          </ul>
        ),
      },
      {
        question: '제시되는 놀이법들이 발달 수준에 맞는 건가요?',
        answer: (
          <ul>
            <li>
              차이의 놀이에서 제공되는 놀이법은 한국형 영유아 건강검진 (K-DIST) 문진표를 참고하여
              구성합니다.
            </li>
            <li>
              시기마다 아기가 발달해야만 하는 발달 과업이 있으며, 차이의 놀이는 놀이를 통해 아기의
              발달과업이 이루어질 수 있도록 반복적인 경험을 제공하기 위한 첫걸음이라고 생각해주세요.
            </li>
          </ul>
        ),
      },
    ],
  },
];
