import React, { useState } from 'react';
import { PackageDetailDl } from 'components/atoms';
import styled from 'styled-components';
import { ApplyButton } from 'components/modals/CouponeModal/styled';
import { useStores } from 'stores';
import { useCouponStore } from 'stores/local/Coupon';
import { observer } from 'mobx-react';
import { CouponBody } from 'stores/repository/CouponRepository';
import { MODAL_CLASSIFIER } from 'pages/PackageDetail/constants';
import PackageDetailModel from 'stores/models/PacakgeDetailModel';
import { BenefitPopup, DeliveryPopup } from 'components/molecules/Popup';

interface Props {
  detail: PackageDetailModel;
  coupon: CouponBody;
}

const CouponAndDeliveryInfo: React.FC<Props> = observer(({ coupon, detail }: any) => {
  const store = useCouponStore();
  const { auth } = useStores();
  const [downloadable, setDownloadable] = useState(coupon?.downloadable ? true : false);
  const { modal } = useStores();

  const downloadCoupon = async () => {
    if (auth.user.email) {
      const { data } = await store.download({ coupon_code: coupon.coupon_code });
      if (data.success) {
        modal.openModal(MODAL_CLASSIFIER.RESULT_MESSAGE, {
          ...data,
        });
        setDownloadable(false);
      }
    } else {
      modal.openModal(MODAL_CLASSIFIER.DOWNLOAD_COUPON, {
        message: '쿠폰 다운로드는 회원 로그인 후 가능합니다. 로그인하시겠습니까?',
      });
    }
  };

  return (
    <PackageDetailDl>
      {coupon ? (
        <div>
          <dt>쿠폰혜택</dt>
          <dd>
            {coupon.title}
            {downloadable ? (
              <DownloadButton $fill onClick={downloadCoupon}>
                다운로드
                <IconInfoButton src={'/icons/download.png'} alt='Download Icon Img' />
              </DownloadButton>
            ) : (
              <DownloadDisabledButton>
                다운완료
                <IconInfoButton src={'/icons/download.png'} alt='Download Icon Img' />
              </DownloadDisabledButton>
            )}
          </dd>
        </div>
      ) : null}
      <div>
        <dt>적립혜택</dt>
        <dd>
          회원 구매 적립 혜택 확인 <BenefitPopup />
        </dd>
      </div>
      <div>
        <dt className='top'>배송정보</dt>
        <dd>
          {detail.free_shipping_message}
          <br />
          {detail.deadline_hour_desc}
        </dd>
      </div>
      <div>
        <dt>출고예정일</dt>
        <dd>
          {detail.expected_delivery_start_date} 택배 출고 예정 <DeliveryPopup />
        </dd>
      </div>
    </PackageDetailDl>
  );
});

const DownloadButton = styled(ApplyButton)`
  width: 80px;
  height: 24px;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  ${({ theme }) => theme.tablet`
    display: block;
    margin-top: 3px;
  `}
  ${({ theme }) => theme.mobile`
    display: block;
    margin-top: 3px;
  `}
`;

const DownloadDisabledButton = styled(ApplyButton)`
  width: 80px;
  height: 24px;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  background-color: #b3b3b3;
  border: 0;
  color: white;
  ${({ theme }) => theme.tablet`
    display: block;
    margin-top: 3px;
  `}
  ${({ theme }) => theme.mobile`
    display: block;
    margin-top: 3px;
  `}
`;

const IconInfoButton = styled.img`
  display: inline-block;
  vertical-align: top;
  margin: 1px 0 0 3px;
  width: 17px;
  height: 17px;
`;

export default CouponAndDeliveryInfo;
