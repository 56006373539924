import { action, computed, extendObservable, observable } from 'mobx';
import CartRepository from 'stores/repository/CartRepository';

export default class CartOrderItemModel implements Cart.OrderItem {
  @observable id!: number;
  @observable title!: string;
  @observable package_id!: number;
  @observable square_image_url!: string;
  @observable items_count!: number;
  @observable unit_price!: number;
  @observable package_option_id?: number;
  @observable option_title!: string;
  @observable disabled!: boolean; // 재고여부
  @observable error_message?: any;
  @observable checked!: boolean;
  @observable max = 1000;

  // Ui
  index: number = -1;

  constructor(props: Cart.OrderItem, index: number) {
    extendObservable(this, { ...props });
    this.index = index;
  }

  @action
  toggleCheck(flag?: boolean, isSignIn = false): Promise<Cart.UpdateOrderItem | undefined> {
    return new Promise(resolve => {
      if (flag !== undefined) {
        this.checked = flag;
      } else {
        this.checked = !this.checked;
      }

      if (isSignIn) {
        CartRepository.updateCheck(this).then(({ data }) => resolve(data));
      } else {
        resolve(undefined);
      }
    });
  }

  @action
  updateQuantity(count: number, isSignIn = false): Promise<Cart.UpdateOrderItem | undefined> {
    return new Promise((resolve, reject) => {
      this.items_count = count;

      if (isSignIn) {
        CartRepository.updateQuantity(this)
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response: { data } }) => {
            this.items_count = data.order_item.items_count;
            reject(data);
          });
      } else {
        resolve(undefined);
      }
    });
  }

  @action
  delete(isSignIn = false): Promise<any> {
    return new Promise(resolve => {
      if (isSignIn) {
        CartRepository.delete([this]).then(res => resolve(res));
      } else {
        resolve(undefined);
      }
    });
  }

  @action
  setMax(count: number) {
    this.max = count > 1000 ? 1000 : count;
  }

  @computed
  get price() {
    return this.unit_price;
  }

  @computed
  get option() {
    return this.option_title;
  }

  @computed
  get hasOption() {
    return this.option_title;
  }
}
