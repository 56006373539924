import { StockStatus } from 'interfaces/PackageDetail';
import { extendObservable, observable, action } from 'mobx';
import PackageModel from './PackageModel';
import { commaNumber } from 'utils';

export default class PackageDetailModel implements PackageDetail {
  @observable isLoadInsta = false;

  constructor(props: PackageDetail) {
    extendObservable(this, { ...props, related_packages: props.related_packages || [] });
    this.isLoadInsta = false;
  }

  get hasStock() {
    return this.status === StockStatus.IN;
  }

  get reviews() {
    return this.content_resources.filter(
      ({ resource_type }: ContentResource) => resource_type === 'instagram',
    );
  }

  get relatedPackages() {
    return this.related_packages.map((item: any) => new PackageModel(item));
  }

  get hasOptions() {
    return this.options.length > 0;
  }

  get freeShippingPrice() {
    return commaNumber(this.free_shipping_price ?? 50000);
  }

  get shippingPrice() {
    return commaNumber(this.shipping_price ?? 3500);
  }

  cacheLayout: number = -1;
  @action
  initInsta(layout: number) {
    if (this.cacheLayout !== layout) {
      this.isLoadInsta = false;
      this.cacheLayout = layout;
      setTimeout(() => instgrm.Embeds.process(), 50);
      setTimeout(() => (this.isLoadInsta = true), (this.reviews.length + 1) * 100);
    }
  }

  id!: number;
  category_name!: string;
  title!: string;
  description!: string;
  content?: any;
  image_url!: string;
  square_image_url!: string;
  url!: string;
  share_url!: string;
  price!: number;
  display_original_price!: string;
  display_sales_price!: string;
  display_discount_rate!: string;
  ages!: string;
  display_months_or_ages!: string;
  status!: StockStatus;
  display_expected_arrival_date?: any;
  in_wishlist!: boolean;
  first_benefit_price!: any[];
  next_benefit_price!: any[];
  subtotal_price!: number;
  current_point_benefits!: string;
  deadline_hour_desc!: string;
  inventory_count!: number;
  coupon?: any;
  free_shipping_message!: string;
  expected_delivery_start_date!: string;
  rolling_images!: RollingImage[];
  banners!: Banner[];
  content_resources!: ContentResource[];
  related_packages: RelatedPackage[] = [];
  components!: Component[];
  infos!: Info[];
  plays!: Play[];
  options: Option[] = [];
  free_shipping_price!: number;
  shipping_price!: number;
}

export interface RollingImage {
  resource_type: string;
  image_url: string;
  video_url?: any;
  link_url: string;
  image_width: number;
  image_height: number;
  theme_play?: any;
  embed_code: string;
  content: string;
}

export interface Banner {
  id: number;
  text: string;
  url: string;
  image_url: string;
  image_width: number;
  image_height: number;
}

export interface ContentResource {
  resource_type: string;
  image_url: string;
  video_url?: any;
  link_url: string;
  image_width?: number;
  image_height?: number;
  theme_play?: any;
  embed_code: string;
  content: string;
}

export interface RelatedPackage {
  id: number;
  title: string;
  description: string;
  content?: any;
  image_url: string;
  url: string;
  share_url: string;
  original_price: string;
  sales_price: string;
  display_sales_price: string;
  display_discount_rate: string;
  release_date: string;
  ages: string;
  months: string;
  display_months_or_ages: string;
  show_new_label: boolean;
  status: string;
  display_expected_arrival_date?: any;
  square_image_url: string;
  show_best_label: boolean;
  show_on_sale_label: boolean;
  in_wishlist: boolean;
}

export interface Component {
  title: string;
  description: string;
  tip: string;
}

export interface Info {
  title: string;
  description: string;
}

export interface Play {
  id: number;
  name: string;
  short_effect: string;
  image_url: string;
  video_url?: any;
  ages: string;
  development_area: string;
  in_play_box: boolean;
  url: string;
  share_url: string;
  share_text: string;
  category_name: string;
  premium: boolean;
}

export interface Option {
  id: number;
  title: string;
  unit_price: number;
}

export interface PackageDetail {
  id: number;
  category_name: string;
  title: string;
  description: string;
  content?: any;
  image_url: string;
  square_image_url: string;
  url: string;
  share_url: string;
  price: number;
  display_original_price: string;
  display_sales_price: string;
  display_discount_rate: string;
  ages: string;
  display_months_or_ages: string;
  status: string;
  display_expected_arrival_date?: any;
  in_wishlist: boolean;
  first_benefit_price: any[];
  next_benefit_price: any[];
  subtotal_price: number;
  current_point_benefits: string;
  deadline_hour_desc: string;
  inventory_count: number;
  coupon?: any;
  expected_delivery_start_date: string;
  rolling_images: RollingImage[];
  banners: Banner[];
  content_resources: ContentResource[];
  related_packages: RelatedPackage[];
  components: Component[];
  infos: Info[];
  plays: Play[];
  options: Option[];
}
