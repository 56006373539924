import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useStores } from 'stores';

interface AuthRadioProps {
  name: string;
  value: string;
  text: string;
}
export const AuthRadio: React.FC<AuthRadioProps> = ({ name, value, text }) => {
  const { auth } = useStores();
  const { register } = useFormContext();
  const resetCertify = useCallback(() => {
    const isCertified = !!auth.user.phone_number_certified;

    if (isCertified) {
      auth.user.resetPhoneNumberCertified();
    }
  }, [auth.user]);

  return (
    <RadioButton>
      <input
        id={`${name}:${value}`}
        type='radio'
        name={name}
        value={value}
        onChange={resetCertify}
        ref={register}
      />
      <label htmlFor={`${name}:${value}`}>
        <div>{text}</div>
      </label>
    </RadioButton>
  );
};

export const WrapRadioButton = styled.div`
  display: inline-block;

  ${({ theme }) => theme.tablet`
    padding-left: 30px;
  `}

  ${({ theme }) => theme.mobile`
    display: flex;
    float: right;
    padding-left: 0;
    width: calc(100% - 90px);
  `};
`;

const RadioButton = styled.div`
  display: inline-block;

  ${({ theme }) => theme.mobile`
    flex: 1 1 0;
  `}

  & + & {
    padding-left: 8px;
  }

  input {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    & + label {
      display: inline-block;
      cursor: pointer;
      user-select: none;
      border-radius: 3px;
      border: solid 1px ${({ theme }) => theme.tabNonActieColor};
      text-align: center;

      ${({ theme }) => theme.desktop`
        padding: 7px 0;
        width: 126px;
        font-size: 16px;
      `};

      ${({ theme }) => theme.tablet`
        padding: 4px 0;
        width: 89px;
        font-size: 14px;
      `};

      ${({ theme }) => theme.mobile`
        width: 100%;
      `};

      > div {
        position: relative;
        display: inline-block;
        color: ${({ theme }) => theme.Dark_gray};

        ${({ theme }) => theme.desktop`
          padding-left: 28px;
          line-height: 24px;
        `};

        ${({ theme }) => theme.tablet`
          padding-left: 18px;
          line-height: 22px;
        `};

        :before {
          content: '';
          box-sizing: border-box;
          position: absolute;
          left: 0;
          border: solid 2px ${({ theme }) => theme.Gray};

          ${({ theme }) => theme.desktop`
            top: 3px;
            width: 20px;
            height: 20px;
            border-radius: 20px;
          `};

          ${({ theme }) => theme.tablet`
            top: 4px;
            width: 14px;
            height: 14px;
            border-radius: 14px;
          `};
        }
      }
    }

    &:checked + label {
      border-color: ${({ theme }) => theme.subActiveColor};
      background: ${({ theme }) => theme.subActiveColor};

      > div {
        color: #fff;

        :before {
          border-color: #fff;
        }

        :after {
          content: '';
          position: absolute;
          background-color: #fff;

          ${({ theme }) => theme.desktop`
            top: 8px;
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 10px;
          `};

          ${({ theme }) => theme.tablet`
            top: 8px;
            left: 4px;
            width: 6px;
            height: 6px;
            border-radius: 6px;
          `};
        }
      }
    }
  }
`;
