import { observable } from 'mobx';
import PackageModel, { ResPackage } from '../../../stores/models/PackageModel';

export type ResHomeRecommendeds = Home.ContainerProps<ResPackage>;

class HomeRecommendedModel implements Home.ContainerProps<PackageModel> {
  @observable id: number;
  @observable title: string;
  @observable packages: PackageModel[] = [];

  constructor({ id, title, packages }: ResHomeRecommendeds) {
    this.id = id;
    this.title = title;
    this.packages = packages.map((item: ResPackage) => new PackageModel(item));
  }
}

export default HomeRecommendedModel;
