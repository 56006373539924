import React from 'react';
import { observer } from 'mobx-react';

import { DefaultLayout } from 'layouts/Default';
import { EventListProvider, useEventListStore, useInitEventList } from './store';

import { WrapList, Title } from './styled';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import EventsContainer from 'components/organisms/Event/EventsContainer';

const EventListContainer = observer(() => {
  const { events } = useEventListStore();
  useInitEventList();

  return (
    <>
      <WrapList>
        <Title>이벤트</Title>
        <SubHeader>
          <MobileBackButton />
          <SubHeaderTitle>이벤트</SubHeaderTitle>
        </SubHeader>
        <EventsContainer store={events} />
      </WrapList>
    </>
  );
});

export default DefaultLayout(EventListContainer, { Provider: EventListProvider });
