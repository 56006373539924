import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { DefaultModalZIndex } from 'interfaces/Modal';
import { DimmedType } from 'interfaces/layout';
import { useStores } from 'stores';

export const usePostModal = (
  onCompleteLogic: daum.DaumPostcodeProps['onComplete'],
): [React.FC, () => void] => {
  const { layout } = useStores();
  const ref = useRef<HTMLDivElement>(null);
  const open = useCallback(() => {
    new daum.Postcode({
      onComplete: data => {
        onCompleteLogic(data);
        layout.CloseDimmed();
      },
      width: '100%',
    }).embed(ref.current);

    if (ref.current) {
      ref.current.style.display = 'block';
    }

    layout.OpenDimmed({
      dismissible: true,
      type: DimmedType.Normal,
      onClose: () => {
        if (ref.current) {
          ref.current.style.display = 'none';
        }
      },
    });
  }, [layout, onCompleteLogic]);

  return [() => <DaumPostcode ref={ref} />, open];
};

const DaumPostcode = styled.div`
  position: fixed;
  z-index: ${DefaultModalZIndex};
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: none;
  width: 100%;
  max-width: 500px;
  min-width: 280px;
`;
