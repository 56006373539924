import { extendObservable } from 'mobx';

export interface ResHomeBanner {
  id: number;
  text: string;
  url: string;
  image_url: string;
  image_width: number;
  image_height: number;
}

class HomeBannerModel {
  href!: string;
  src!: string;

  constructor({ id, text, url, image_url }: ResHomeBanner) {
    extendObservable(this, {
      id,
      text,
      href: url,
      src: image_url,
    });
  }
}

export default HomeBannerModel;
