import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { useStores } from 'stores';
import { PAGE_URL } from 'configs/path';
import { MypageLayout } from 'layouts/Mypage';
import { Title } from 'components/atoms/Mypage';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import { ClearA, ClearLink } from 'components/atoms/Clear';

const MypageHome = observer(() => {
  const history = useHistory();
  const {
    layout: { isDesktop },
  } = useStores();

  useEffect(() => {
    if (isDesktop) {
      history.replace(PAGE_URL.PURCHASE_LIST);
    }
  }, [history, isDesktop]);

  return (
    <>
      <SubHeader>
        <SubHeaderTitle>마이페이지</SubHeaderTitle>
      </SubHeader>
      <Wrapper>
        <Title>마이페이지</Title>
        <LinkBox>
          <H2>구매정보</H2>
          <Link to={PAGE_URL.PURCHASE_LIST}>주문배송 조회</Link>
          <br />
          <Link to={PAGE_URL.POINT_HISTORY}>포인트 조회</Link>
          <br />
          <Link to={PAGE_URL.COUPON_LIST}>쿠폰함</Link>
          <br />
          <Link to={PAGE_URL.COIN_HISTORY}>코인 충전/이용 내역</Link>
          <br />
        </LinkBox>
        <LinkBox>
          <H2>회원정보</H2>
          <Link to={PAGE_URL.INFO_ME}>개인정보 설정</Link>
          <br />
          <Link to={PAGE_URL.CHANGE_PASSWD}>비밀번호 변경</Link>
          <br />
          <Link to={PAGE_URL.INFO_CHILD}>아이정보 설정</Link>
          <br />
          <Link to={PAGE_URL.MYCOMMENTS}>나의 놀이댓글</Link>
          <br />
          <Link to={PAGE_URL.DEACTIVEATE}>탈퇴하기</Link>
          <br />
        </LinkBox>
        <LinkBox>
          <H2>고객지원</H2>
          <ExternalLink
            href={'https://www.chaisplay.com/about'}
            target='_blank'
            rel='noopener noreferrer'>
            차이의놀이 소개
          </ExternalLink>
          <br />
          <Link to={PAGE_URL.FAQ}>자주묻는 질문 FAQ</Link>
          <br />
          <ExternalLink
            href={'https://pf.kakao.com/_xdbBvxd'}
            target='_blank'
            rel='noopener noreferrer'>
            카카오톡 1:1 문의하기
          </ExternalLink>
          <br />
        </LinkBox>
      </Wrapper>
    </>
  );
});

export default MypageLayout(MypageHome);

const Wrapper = styled.div`
  line-height: 1.5;
`;

const LinkBox = styled.div`
  border-top: 10px solid #f8f8f8;
  padding: 20px;
`;

const H2 = styled.h2`
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: normal;
  color: #b3b3b3;
`;

const LinkCss = css`
  display: inline-block;
  padding: 15px 0;

  &:last-child {
    padding-bottom: 10px;
  }
`;

const Link = styled(ClearLink)`
  ${LinkCss}
`;

const ExternalLink = styled(ClearA)`
  ${LinkCss}
`;
