export interface PackagesFilter {
  page: number;
}

export interface SelectOption {
  unit: {
    id: number;
    name: string;
    price: number;
  };
  isOption: boolean;
  quantity: number;
}

export interface ICartJS {
  id: number;
  title: string;
  square_image_url: string;
  max: number;
  select: SelectOption;
  cid?: number;
}

export enum StockStatus {
  IN = 'in_stock',
  OUT = 'out_of_stock',
}
