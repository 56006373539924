import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { usePackageDetailStore } from 'pages/PackageDetail/store';

import ComponentsTable from 'components/molecules/PackagesDetail/ComponentsTable';

const ComponentTableBox: React.FC<any> = observer(() => {
  const { packageDetail } = usePackageDetailStore();

  if (packageDetail.components.length) {
    return (
      <WrapComponent>
        <ComponentsTable components={packageDetail.components} TabContents />
      </WrapComponent>
    );
  } else {
    return <WrapComponent>구성품은 패키지 설명에 기재되어 있습니다.</WrapComponent>;
  }
});
export default ComponentTableBox;

const WrapComponent = styled.div`
  position: relative;
  margin-top: 24px;
  min-height: 525px;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
    font-size: 12px;
  `}
`;
