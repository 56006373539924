import axios, { AxiosResponse } from 'axios';
import { API_URL } from 'configs/path';
import CouponModel from 'stores/models/CouponModel';

class CouponRepository {
  async fetch(): Promise<Coupon.Model[]> {
    const {
      data: { my_coupons },
    } = (await axios.get(API_URL.COUPON)) as AxiosResponse<Coupon.APIMyCoupon>;

    return my_coupons.map(data => new CouponModel(data));
  }

  download(body: CouponBody): Promise<any> {
    return axios.post(API_URL.COUPON, body);
  }
}

export default new CouponRepository();

export interface CouponBody {
  coupon_code: string;
}
