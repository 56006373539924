import axios from 'axios';
import { API_URL } from 'configs/path';
import { ResPackageCategory } from 'layouts/models/CategoriesModel';

class CategoriesRepository {
  find(): Promise<any> {
    return new Promise(resolve => {
      axios.get(API_URL.LAYOUT_CATEGORIES).then(({ data }) => {
        const parent: ResPackageCategory[] = [];
        const child: ResPackageCategory[] = [];

        // eslint-disable-next-line array-callback-return
        data.package_categories.map((cate: ResPackageCategory) => {
          if (cate.parent_id === null) {
            parent.push(cate);
          } else {
            child.push(cate);
          }
        });

        resolve([parent, child]);
      });
    });
  }
}

export interface Res {
  package_categories: ResPackageCategory[];
}

export default new CategoriesRepository();
