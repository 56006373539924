import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { action, computed, flow, observable, runInAction } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { useStores } from 'stores';
import CouponRepository, { CouponBody } from 'stores/repository/CouponRepository';
import CouponModel from 'stores/models/CouponModel';

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      coupons: [] as CouponModel[],
      /**
       * order.available_coupons을 통해서 coupons 목록 초기화하는 함수
       */
      setByOrder(items: Coupon.OrderDto[]) {
        this.coupons = items.map(item => new CouponModel(item));
        this.isInit = true;
      },
      fetchMyCoupon() {
        runInAction(async () => {
          this.coupons = await CouponRepository.fetch();
          this.isInit = true;
        });
      },
      reset() {
        this.isInit = false;
        this.coupons = [];
      },
      // 미정리
      download(body: CouponBody) {
        return flow(function* (this: any) {
          try {
            return yield CouponRepository.download(body);
          } catch (error) {
            const { data, status } = error.response;
            if (status === 422) {
              alert(data.message);
              throw data;
            } else {
              throw error;
            }
          }
        }).bind(this)();
      },
      get couponNum() {
        return this.coupons.length;
      },
    },
    {
      setByOrder: action.bound,
      reset: action.bound,
      couponNum: computed,
    },
  );

  return store;
};
type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const CouponProvider: React.FC<any> = ({ children }) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useCouponStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitMyCoupon = () => {
  const history = useHistory();
  const { auth } = useStores();
  const { fetchMyCoupon, reset } = useCouponStore();

  useEffect(() => {
    if (auth.isSignIn) fetchMyCoupon();

    return () => {
      reset();
    };
  }, [auth.isSignIn, fetchMyCoupon, history, reset]);
};
