import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import cx from 'classnames';

import { DefaultLayout } from 'layouts/Default';
import { WrapBox, AuthBox, AuthTitle, AuthForm, SmallLinkButton } from 'components/atoms';
import { Input, SubmitButton, InputErr } from 'components/atoms/Form';
import { emailReg } from 'utils';
import { useStores } from 'stores';
import { PAGE_URL } from 'configs/path';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const ResetPassword = React.memo(() => {
  const { auth } = useStores();
  const { register, errors, handleSubmit } = useForm<{
    email: string;
  }>();

  return (
    <WrapBox>
      <ResetPasswdBox>
        <AuthTitle>비밀번호 재설정</AuthTitle>
        <Guide>
          로그인 비밀번호를 잊으셨나요?
          <br />
          회원 이메일 주소를 입력해 주세요. <br />
          비밀번호 재설정 메일을 보내드립니다. <br />
        </Guide>
        <AuthForm onSubmit={handleSubmit(auth.resetPasswd)}>
          <Input
            type='text'
            id='ipt-eamil'
            name='email'
            placeholder='이메일 주소를 입력해주세요'
            className={cx({ err: errors.email })}
            ref={register({
              required: "'이메일'을 입력해주세요",
              pattern: {
                value: emailReg,
                message: "'이메일'을 입력해주세요",
              },
            })}
            autoComplete='chaisplay_signup_eamil'
          />
          {errors.email && <InputErr>{errors.email.message}</InputErr>}
          <ResetPasswdButton type='submit'>비밀번호 재설정 메일 보내기</ResetPasswdButton>
        </AuthForm>
        {useDesktopShow(
          <WrapLinkButton>
            <BottomButton to={PAGE_URL.SIGNIN}>로그인</BottomButton>
            <BottomButton to={PAGE_URL.SIGNUP}>회원가입</BottomButton>
          </WrapLinkButton>,
        )}
      </ResetPasswdBox>
    </WrapBox>
  );
});

const ResetPasswdBox = styled(AuthBox)`
  padding-left: 102px;
  padding-right: 102px;

  ${({ theme }) => theme.tablet`
    padding: 10px 0;
    width: 380px;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px 20px 30px;
    width: 100%;
  `}
`;
const ResetPasswdButton = styled(SubmitButton)`
  margin-top: 20px;

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
  `}
`;
const Guide = styled.div`
  margin-top: 24px;
  line-height: 1.71;
  font-size: 14px;
  color: ${({ theme }) => theme.TextColor};

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
    font-size: 12px;
  `}
`;
const WrapLinkButton = styled.div`
  margin-top: 16px;
  text-align: center;
`;
const BottomButton = styled(SmallLinkButton)`
  font-weight: bold;
`;

export default DefaultLayout(ResetPassword);
