export enum DISPLAY {
  MOBILE,
  TABLET,
  DESKTOP,
}

export enum DEVICE {
  ANDROID,
  APPLE,
  OTHER,
}

export const BREAK_POINT_MOBILE = 768;
export const BREAK_POINT_TABLET = 992;
export const BREAK_POINT_DESKTOP = 1280;

export interface ILayout {
  setBodyMinHeight: (val: number) => void;
  pgMinHeight: number;
}

export interface ILayoutOptions {
  Provider?: any;
  Modal?: {
    [k: number]: React.ComponentType<any>;
  };
  Option?: {
    full?: boolean;
  };
}

export interface OpenDimmedPrams {
  dismissible?: boolean;
  dimmedZIndex?: number;
  type?: DimmedType;
  onClose?: Function;
}

export enum DimmedType {
  Normal = 1,
  Modal,
}

export interface IPageLayout {
  Provider?: any;
  Title: string;
  Wrapper: React.ComponentType<any>;
  Modal?: {
    [k: number]: React.ComponentType<any>;
  };
  Option?: {
    full?: boolean;
    backUrl?: string;
  };
}
