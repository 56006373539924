import { withCommas } from 'utils';

export default class CouponModel {
  id!: number;
  code!: string;
  title!: string;
  condition!: string;
  discountPrice: number | null;
  discountRate: number | null;
  minimumPrice!: number;
  period?: string;
  downloadable: boolean;

  constructor(props: Coupon.MyCouponDto | Coupon.OrderDto) {
    this.id = props.id;
    this.code = props.coupon_code;
    this.title = props.title;
    this.condition = props.condition;
    this.discountPrice = props.discount_price;
    this.discountRate = props.discount_rate;
    this.minimumPrice = props.minimum_price;
    this.period = (props as Coupon.MyCouponDto).period;
    this.downloadable = (props as Coupon.OrderDto).downloadable ?? false;
  }

  get discountText(): string {
    if (this.discountPrice) return `${withCommas(this.discountPrice)}원`;
    else if (this.discountRate) return `${this.discountRate}%`;
    return '';
  }

  get minimumPriceText(): string {
    return `${withCommas(this.minimumPrice)}원`;
  }

  getDiscountPrice(subtotalPrice: number): number {
    let price: number = 0;
    if (this.discountPrice) price = this.discountPrice;
    else if (this.discountRate)
      price = Math.ceil((subtotalPrice * (this.discountRate / 100)) / 10) * 10;

    if (isNaN(price)) return 0;

    return price;
  }
}
