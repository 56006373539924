import { action, observable } from 'mobx';
import { RootStore } from 'stores';

import { ILayoutOptions } from 'interfaces/layout';
import { ModalProps, ModalFunctionParams } from 'interfaces/Modal';

export default class ModalStore {
  rootStore: RootStore = {} as RootStore;
  curKey: number = -1;
  @observable components: React.ComponentType<any>[] = [];
  @observable status: ModalProps[] = [];

  constructor(root: RootStore) {
    this.rootStore = root;
  }

  @action
  init(store: ILayoutOptions['Modal']) {
    this.components = Object.values(store || {});
    this.status = this.components.map(() => ({ isOpen: false, params: {} }));
  }

  @action.bound
  openModal(key: number, params?: ModalFunctionParams) {
    if (key < 0 || key >= this.status.length) return new Error(`Unable to open modal(${key}).`);
    else {
      const { onOpenStart, onOpenEnd, dismissible, dimmedZIndex } = params || {};

      if (onOpenStart) onOpenStart();

      this.rootStore.layout.OpenDimmed({ dismissible, dimmedZIndex });
      this.status[key].isOpen = true;
      this.status[key].params = params || {};
      this.curKey = key;

      if (onOpenEnd) onOpenEnd();
    }
  }

  @action.bound
  closeModal(key: number) {
    if (key < 0 || key >= this.status.length) return new Error(`Unable to close modal(${key}).`);
    else {
      const status = this.status[key];
      const { onCloseStart, onCloseEnd } = status.params;

      if (onCloseStart) onCloseStart();

      this.rootStore.layout.CloseDimmed();
      this.status[key].isOpen = false;
      this.status[key].params = {};
      this.curKey = -1;

      if (onCloseEnd) onCloseEnd();
    }
  }

  @action.bound
  closeCurrentModal() {
    if (this.curKey !== -1) this.closeModal(this.curKey);
    else {
      this.rootStore.layout.CloseDimmed();
    }
  }
}
