import React from 'react';
import styled from 'styled-components';
import { ClearButton } from '.';

export const TabWrap = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`;

export const TabButton = (props: any): any => {
  const { className, ...other } = props;
  return (
    <TabButtonWrap className={className}>
      <Tab {...other} />
    </TabButtonWrap>
  );
};

const TabButtonWrap = styled.li`
  flex: 1 1 0%;
  list-style: none;
  padding: 8px 0;
  border-bottom: 3px solid ${({ theme }) => theme.tabNonActieColor};

  &.active {
    border-color: ${({ theme }) => theme.tabActieColor};

    button {
      font-weight: bold;
      color: ${({ theme }) => theme.TextColor};
    }
  }

  ${({ theme }) => theme.tablet`
    padding: 10px 0;
    border-width: 2px;
  `}
`;

const Tab = styled(ClearButton)`
  display: block;
  width: 100%;
  height: 100%;

  line-height: 1.5;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  color: #b3b3b3;

  ${({ theme }) => theme.tablet`
    font-size: 14px;
  `}
`;
