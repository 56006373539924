import React from 'react';
import styled from 'styled-components';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { Wrapper } from '.';

export const WrapPage = styled(Wrapper)`
  position: relative;
  padding-bottom: 100px;

  ${({ theme }) => theme.tablet`
    position: static;
    padding-bottom: 20px;
  `}

  ${({ theme }) => theme.mobile`
    padding-bottom: 20px;
    min-height: 380px;
  `}
`;

const H1 = styled.h1`
  margin-top: 32px;
  padding-bottom: 16px;

  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
`;
export const Title: React.FC<any> = React.memo(props => useDesktopShow(<H1>{props.children}</H1>));
