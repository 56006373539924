import React from 'react';
import styled from 'styled-components';
import { ClearLink } from 'components/atoms/Clear';
import { PAGE_URL } from 'configs/path';

export default React.memo(function MainButton() {
  return <Link to={PAGE_URL.HOME}>메인으로</Link>;
});

const Link = styled(ClearLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 72px auto 0;
  width: 248px;
  height: 56px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  ${({ theme }) => theme.tablet`
    margin: 0 auto;
    width: 280px;
    height: 44px;
  `}
`;
