import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { generateKey } from 'utils';
import { Box, H2 } from './styled';
import DialogItem from 'components/molecules/PlaysDetail/DialogItem';

const Dialogs = observer(() => {
  const { play } = usePlayDetailStore();

  if (play.dialogs.length === 0) return null;

  return (
    <Box $withPad>
      <H2>놀이 대화팁</H2>
      <WrapDialogs>
        {play.dialogs.map((item, idx: number) => (
          <DialogItem key={generateKey(`${idx}`)} {...item} />
        ))}
      </WrapDialogs>
    </Box>
  );
});

export default Dialogs;

const WrapDialogs = styled.div`
  margin-bottom: 70px;

  ${({ theme }) => theme.tablet`
    margin-bottom: 40px;
    font-size: 12px;
  `}
`;
