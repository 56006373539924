import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { commaNumber } from 'utils';
import { useStores } from 'stores';
import { Table, Tr, Th, Td } from './styled';
import { ClearButton } from 'components/atoms/Clear';
import moment from 'moment';

export default observer(function VBank() {
  const {
    order: { isVbank, vbank_holder, vbank_name, vbank_num, vbank_date, orderPrice },
  } = useStores();
  const handleCopy = useCallback(() => alert('계좌번호가 클립보드에 복사되었습니다!'), []);

  return isVbank ? (
    <VBankBox>
      <Title>가상계좌 발급이 완료되었습니다.</Title>
      <Message>{moment(vbank_date).format('YYYY-MM-DD')}까지 입금하셔야 결제가 완료됩니다.</Message>
      <Table>
        <tbody>
          <Tr>
            <Th>
              <b>입금하실 금액</b>
            </Th>
            <Td>{commaNumber(orderPrice)}원</Td>
          </Tr>
          <Tr>
            <Th>
              <b>예금주</b>
            </Th>
            <Td>{vbank_holder}</Td>
          </Tr>
          <Tr $borderLess>
            <Th>
              <b>입금계좌</b>
            </Th>
            <Td>
              {vbank_name} {vbank_num}
              <ButtonWrapper>
                <CopyToClipboard text={`${vbank_name} ${vbank_num}`}>
                  <CopyButton onClick={handleCopy}>계좌복사</CopyButton>
                </CopyToClipboard>
              </ButtonWrapper>
            </Td>
          </Tr>
        </tbody>
      </Table>
    </VBankBox>
  ) : null;
});

const VBankBox = styled.div`
  padding: 24px;
  background-color: #fff5d5;
  ${({ theme }) => theme.desktop`
    margin-top: 16px;
  `}
  ${({ theme }) => theme.tablet`
    padding: 20px;
  `}
  ${({ theme }) => theme.mobile`
    margin: 0 -20px -10px;
    padding: 20px 40px;
  `}
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.38;
  ${({ theme }) => theme.tablet`
    font-size: 16px;
  `}
`;

const Message = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.5;
  ${({ theme }) => theme.tablet`
    font-size: 12px;
    margin-bottom: 16px;
  `}
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => theme.desktop`
    display: inline-block;
  `}
`;

const CopyButton = styled(ClearButton)`
  display: inline-block;
  margin-left: 12px;
  width: 92px;
  height: 30px;
  border-radius: 3px;
  border: solid 1px #b3b3b3;
  background-color: #fff;
  font-size: 12px;
  ${({ theme }) => theme.tablet`
    width: 68px;
    height: 25px;
    margin: 5px 0 0 0;
    display: block;
  `}
  ${({ theme }) => theme.mobile`
    width: 68px;
    height: 25px;
    margin: 5px 0 0 0;
    float: right;
  `}
`;
