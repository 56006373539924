import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { useStores } from 'stores';

import { WrapInput, Label, Input, WrapTextArea, AddrArea, AddrButton, AddrInput } from '../styled';
import { InputErr } from 'pages/Mypage/Info/styled';
import { usePostModal } from 'components/modals/DaumPostcodeModal';
import { PhoneCertify } from './PhoneCertify';

export const MemberForm: React.FC<any> = observer(() => {
  const { order, auth, layout } = useStores();
  const { register, errors, setValue } = useFormContext();

  const [DaumPostcode, handleAddress] = usePostModal(({ address, buildingName, zonecode }) => {
    setValue('user.address1', `${address} ${buildingName ? `(${buildingName})` : ''}`);
    setValue('user.zonecode', zonecode);
  });

  useEffect(() => {
    if (order.isFetched) {
      const savedUser = order.savedOrder?.user;

      setValue('user', {
        fullname: savedUser?.fullname ?? auth.user.fullname ?? order.buyer_name,
        phone_number: savedUser?.phone_number ?? auth.user.phone_number ?? order.buyer_phone_number,
        zonecode: savedUser?.zonecode ?? auth.user.zonecode,
        address1: savedUser?.address1 ?? auth.user.address1,
        address2: savedUser?.address2 ?? auth.user.address2,
      });
      setValue('_certify_methods', order.savedOrder?._certify_methods ?? 'sms');
    }
  }, [order.savedOrder, auth.user, order, order.isFetched, setValue]);

  return (
    <>
      <WrapInput $withBtn={layout.isDesktop}>
        <Label>이름</Label>
        <Input
          name='user.fullname'
          type='text'
          ref={register({
            required: '이름을 입력해 주세요',
            minLength: {
              value: 2,
              message: '이름을 입력해 주세요 (최소 2글자 이상)',
            },
          })}
          placeholder='이름을 입력해 주세요'
        />
        {errors.fullname && <InputErr>{errors.fullname.message}</InputErr>}
      </WrapInput>
      <PhoneCertify InputKey='user.phone_number' />
      <WrapInput>
        <Label>주소</Label>
        <WrapTextArea>
          <AddrArea
            name='user.address1'
            ref={register}
            placeholder='주소를 검색해주세요'
            readOnly
          />
          {errors.address1 && <InputErr>{errors.address1.message}</InputErr>}
        </WrapTextArea>
        <AddrButton type='button' onClick={handleAddress}>
          검색
        </AddrButton>
      </WrapInput>
      <WrapInput>
        <Label>상세주소</Label>
        <AddrInput
          name='user.address2'
          type='text'
          ref={register}
          placeholder='상세주소를 입력해 주세요'
        />
        {errors.address2 && <InputErr>{errors.address2.message}</InputErr>}
        <input type='hidden' name='user.zonecode' ref={register} />
      </WrapInput>
      <DaumPostcode />
    </>
  );
});
