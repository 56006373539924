import React from 'react';
import styled from 'styled-components';
import PackageItem from './PackageItem';

interface WithNumItem extends Home.Package {
  count: number;
}

const WithNumPackageItem: React.FC<WithNumItem> = ({ count, ...HomeProps }) => {
  return (
    <article>
      <Number>{count}</Number>
      <PackageItem {...HomeProps} />
    </article>
  );
};

export default WithNumPackageItem;

const Number = styled.article`
  margin-bottom: 8px;
  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
  color: #262626;

  ${({ theme }) => theme.tablet`
    margin-bottom: 3px;
    font-size: 12px;
  `}
`;
