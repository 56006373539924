import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'stores';

import { ModalProps } from 'interfaces/Modal';
import {
  WrapModal,
  WrapButtons,
  ModalButton,
  PointBar,
  WrapBar,
  BottomTxt,
  UpperTxt,
  PointBubble,
  PointInfo,
  Progress,
} from './styled';
import { PAGE_URL } from 'configs/path';
import { commaNumber } from 'utils';

const CartModal = observer(({ isOpen, params }: ModalProps) => {
  const { auth, layout, modal } = useStores();
  const history = useHistory();
  const onClickGoCart = useCallback(() => {
    history.push(PAGE_URL.CART);
  }, [history]);

  const zIndex = layout.dimmedZIndex + 1;
  const orderItem = params.order_item || {};
  const point = orderItem.subtotal_price || 0;

  const ratio = orderItem.subtotal_price
    ? (orderItem.subtotal_price - orderItem.first_benefit_price[0]) /
      (orderItem.next_benefit_price[0] - orderItem.first_benefit_price[0])
    : 1;

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isOpen) modal.closeCurrentModal();
    }, 5000);
    return () => clearTimeout(timeout);
  }, [isOpen, modal]);

  return auth.isSignIn ? (
    <WrapModal $txtCenter $show={isOpen} $zIndex={zIndex} $isSignIn={auth.isSignIn}>
      <PointBar>
        <WrapBar>
          <BottomTxt $ratio={ratio}>
            {orderItem.first_benefit_price ? orderItem.first_benefit_price[1] : 0}
          </BottomTxt>
          <UpperTxt $ratio={ratio}>
            {orderItem.next_benefit_price ? orderItem.next_benefit_price[1] : 0}
          </UpperTxt>
          <Progress $ratio={ratio} />
        </WrapBar>
        <PointBubble $ratio={ratio}>{commaNumber(point)}</PointBubble>
      </PointBar>
      <PointInfo>
        <div dangerouslySetInnerHTML={{ __html: params.order_item?.next_point_benefits }} />
      </PointInfo>
      <WrapButtons>
        <ModalButton onClick={modal.closeCurrentModal}>계속하기</ModalButton>
        <ModalButton $fill onClick={onClickGoCart}>
          장바구니 보기
        </ModalButton>
      </WrapButtons>
    </WrapModal>
  ) : (
    <WrapModal $txtCenter $show={isOpen} $zIndex={zIndex}>
      장바구니에 패키지가 담겼습니다.
      <WrapButtons>
        <ModalButton onClick={modal.closeCurrentModal}>계속하기</ModalButton>
        <ModalButton $fill onClick={onClickGoCart}>
          장바구니 보기
        </ModalButton>
      </WrapButtons>
    </WrapModal>
  );
});

export default CartModal;
