import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { PAGE_URL } from 'configs/path';
import { ClearLink, BgImgBox, BgImg } from 'components/atoms';

const PlaysItem: React.FC<any> = ({ id, image_url, short_effect, name }) => (
  <ItemBox to={generatePath(PAGE_URL.PLAY, { id })}>
    <BgImgBox $heightPercent={100}>
      <BgImg $src={image_url} $heightPercent={100} $size='cover' />
    </BgImgBox>
    <ItemInfo>
      <Effect>{short_effect}</Effect>
      <Name>{name}</Name>
    </ItemInfo>
  </ItemBox>
);

export default PlaysItem;

const ItemBox = styled(ClearLink)`
  position: relative;
  display: inline-block;
  border-radius: 3px;
  border: solid 1px #e6e6e6;
  overflow: hidden;

  /* margin-right: 30px;
  margin-bottom: 30px;
  width: calc(25% - 22.5px); */

  /* div &:nth-child(4n) {
    margin-right: 0;
  }

  ${({ theme }) => theme.mobile`
    margin-right: 10px;
    margin-bottom: 10px;
    width: calc(50% - 5px);

    div &:nth-child(2n) {
      margin-right: 0;
    }
  `} */
`;

const ItemInfo = styled.div`
  padding: 16px;
  border-top: solid 1px #e6e6e6;
  text-align: center;

  ${({ theme }) => theme.tablet`
    padding: 15px 18px;
  `}

  ${({ theme }) => theme.mobile`
    padding: 9px 10px 12px;
  `}
`;

const Effect = styled.div`
  line-height: 1.5;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.tablet`
    margin-bottom: 1px;
    font-size: 8px;
  `}
`;

const Name = styled.h3`
  margin-top: 4px;
  line-height: 1.5;
  font-size: 16px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.tablet`
    margin-top: 1px;
    font-size: 12px;
  `}
`;
