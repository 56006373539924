import React, { useCallback, useEffect } from 'react';
import { useRouteMatch, generatePath, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { DISPLAY } from 'interfaces/layout';
import { DefaultLayout } from 'layouts/Default';
import { useStores } from 'stores';
import { PackagesProvider, useInitPacakges, usePackagesStore } from './store';

import { PackagesMatchParams, PAGE_URL } from 'configs/path';
import { WrapList, Title, WrapListItem } from 'components/styled/List';
import BreadCrumb from 'components/organisms/Packages/BreadCrumb';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import SubCategoryFilter from 'components/organisms/Packages/SubCategoryFilter';
import PackagesFilter from 'components/organisms/Packages/ListFilter';
import PackagesContainer from 'components/organisms/PackagesContainer';
import Pagenation from 'components/molecules/Pagenation';

const PackagesList: React.FC<any> = observer(() => {
  const match = useRouteMatch<PackagesMatchParams>();
  const history = useHistory();
  const { layout } = useStores();
  const { pagenation, filter, isInit, packages, setFilter } = usePackagesStore();
  const onClickPage = useCallback((page: number) => () => setFilter({ page }), [setFilter]);

  useInitPacakges();

  useEffect(() => {
    if (!layout.isValidMatch(match.params)) history.replace(PAGE_URL.ERROR);
  }, [history, layout, match.params]);

  const { main, sub } = match.params;
  const showSubCategoryFilter = layout.displayType === DISPLAY.DESKTOP || !sub;
  const category = layout.getCateogryModel(decodeURIComponent(main));

  if (!category) {
    return null;
  } else {
    return (
      <WrapList>
        <BreadCrumb />
        <Title>{category.me.categoryName}</Title>
        <SubHeader>
          {!showSubCategoryFilter ? (
            <MobileBackButton url={generatePath(PAGE_URL.PACKAGES_MAIN, { main })} />
          ) : null}
          <SubHeaderTitle>{category.me.categoryName}</SubHeaderTitle>
        </SubHeader>
        {showSubCategoryFilter ? (
          <SubCategoryFilter value={category.me.id} category={category.children} />
        ) : null}
        <WrapListItem>
          <PackagesFilter />
          {isInit ? <PackagesContainer store={packages} /> : null}
        </WrapListItem>
        <Pagenation
          curPage={filter.page}
          pageInfo={pagenation}
          pageUnit={layout.displayType === DISPLAY.DESKTOP ? 10 : 5}
          onClickPage={onClickPage}
        />
      </WrapList>
    );
  }
});

export default DefaultLayout(PackagesList, { Provider: PackagesProvider });
