import React from 'react';
import styled from 'styled-components';

import ListFrame from 'components/atoms/ListFrame';
import { EventItem } from 'components/molecules/Event';

const EventsContainer: React.FC<{ store: any[] }> = ({ store }) => {
  return (
    <ContainerWrap>
      <ListFrame ListWrap={WrapList} ListItem={EventItem} store={store} />
    </ContainerWrap>
  );
};

export default EventsContainer;

const ContainerWrap = styled.section`
  ${({ theme }) => theme.desktop`
    margin-bottom: 100px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 20px 20px 10px;
    background-color: #f8f8f8;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px 0 0;
  `}
`;

const WrapList = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.desktop`
    & > * {
      width: calc(50% - 15px);
      margin-left: 30px;
    }

    & > *:nth-child(n - 3) {
      margin-top: 0;
    }

    & > *:nth-child(n + 3) {
      margin-top: 40px;
    }

    & > *:nth-child(2n + 1) {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.tablet`
    & > * {
      width: calc(50% - 10px);
      margin-left: 20px;
    }

    & > *:nth-child(n - 3) {
      margin-top: 0;
    }

    & > *:nth-child(n + 3) {
      margin-top: 20px;
    }

    & > *:nth-child(2n + 1) {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.mobile`
    & > * {
      width: 100%;
      margin-left: 0;
    }

    & > *:nth-child(n - 3) {
      margin-top: 0;
    }

    & > *:nth-child(n + 3) {
      margin-top: 0;
    }

    & > *:nth-child(2n + 1) {
      margin-left: 0;
    }
  `}
`;
