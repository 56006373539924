import React from 'react';

import { PAGE_URL } from 'configs/path';
import { generateKey } from 'utils';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

import { BreadCrumbLink } from 'components/atoms';
import BreadCrumbFrame from 'components/atoms/BreadCrumbFrame';

const BestBreadCrumb = React.memo(() => {
  const breadCrumbItems = [];

  breadCrumbItems.push(<BreadCrumbLink to={PAGE_URL.HOME}>홈</BreadCrumbLink>);
  breadCrumbItems.push(<BreadCrumbLink to={PAGE_URL.PACKAGES_BEST}>BEST</BreadCrumbLink>);

  return useDesktopShow(
    <BreadCrumbFrame items={breadCrumbItems} prefixKey={generateKey('PackagesBest')} />,
  );
});

export default BestBreadCrumb;
