import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import Dropdown from 'react-dropdown';
import cx from 'classnames';
import { commaNumber } from 'utils';

import { usePackageDetailStore } from 'pages/PackageDetail/store';
import { Option } from 'stores/models/PacakgeDetailModel';

import useScrollEvt from 'components/hooks/useScrollEvt';
import { ClearButton, DropDownCloseIcon, RelativeDiv } from 'components/atoms';
import QuantityBox from 'components/atoms/QuantityBox';
import { ErrBubble } from 'components/atoms/Form';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { BasketButton } from './BasketButton';

interface Props {
  isSticky: boolean;
  kakaoRef: React.RefObject<HTMLDivElement>;
  wrapperRef: React.RefObject<HTMLDivElement>;
}

const SideBasket: React.FC<Props> = observer(({ isSticky, kakaoRef, wrapperRef }) => {
  const { packageDetail, onClickCart } = usePackageDetailStore();
  const BasketRef = useRef<HTMLDivElement>(null);
  const [isBottom, setIsBottom] = useState(false);
  const [BottomPos, setBottomPos] = useState('');
  const onScroll = useCallback(() => {
    if (kakaoRef.current && wrapperRef.current && BasketRef.current) {
      const ref = kakaoRef.current.offsetTop !== 0 ? kakaoRef.current : wrapperRef.current;
      const { height } = BasketRef.current.getBoundingClientRect();
      const condition = ref.getBoundingClientRect().bottom < height + 81; // 81: top, height: 옵션 유무에 따라 변동

      const pos =
        ref === kakaoRef.current
          ? `top: ${ref.offsetTop + ref.offsetHeight - height}px` // 배너 바닥 위치 - 사이브 장바구니 높이
          : `bottom: 0`;

      if (condition) setBottomPos(pos);
      setIsBottom(condition);
    }
  }, [kakaoRef, wrapperRef]);

  useScrollEvt(onScroll);

  useEffect(
    () => () => {
      setIsBottom(false);
      setBottomPos('');
    },
    [],
  );

  return useDesktopShow(
    <BasketBox
      ref={BasketRef}
      className={cx({ sticky: isSticky && !isBottom, bottom: isBottom })}
      $pos={BottomPos}>
      <Title>{packageDetail.title}</Title>
      <OptionsBoxContainer />
      <QuantityBoxContainer />
      <TotalPriceContainer />
      <BasketButton Render={DesignedBasketButton} onClick={onClickCart} model={packageDetail} />
    </BasketBox>,
  );
});

export default SideBasket;

const OptionsBoxContainer: React.FC<any> = observer(() => {
  const { select, err, UIoptions, onChangeOption } = usePackageDetailStore();

  return UIoptions.length ? (
    <WrapOption>
      <OptionDropdown
        className={cx({ invalid: err.option })}
        options={UIoptions}
        onChange={onChangeOption}
        value={UIoptions.find((a: Option) => a.id === select.unit.id)}
        placeholder={'옵션을 선택해주세요.'}
        arrowOpen={<DropDownCloseIcon $css={DropDownIcon} />}
        arrowClosed={<DropDownCloseIcon $css={DropDownIcon} />}
      />
      {err.option ? <ErrBubble>옵션을 선택해주세요.</ErrBubble> : null}
    </WrapOption>
  ) : null;
});

const QuantityBoxContainer: React.FC<any> = observer(() => {
  const {
    select,
    onChangeQuantity,
    packageDetail: { inventory_count },
  } = usePackageDetailStore();

  return (
    <WrapQuantityBox>
      <QuantityBox
        value={select.quantity}
        max={inventory_count ?? 1000}
        maxGuideMessage={`패키지 구매 안내 / 현재 선택해 주신 상품의 구매 가능한 수량은 ${inventory_count}개 입니다. 상품 재고 관련 문의는 카카오톡 '해빛' 상담채널로 문의 주세요.`}
        onChange={onChangeQuantity}
        angled
      />
    </WrapQuantityBox>
  );
});

const TotalPriceContainer: React.FC<any> = observer(() => {
  const { totalPrice } = usePackageDetailStore();

  return (
    <WrapTotalPrice>
      <div>총 금액</div>
      <TotalPrice>{commaNumber(totalPrice)}원</TotalPrice>
    </WrapTotalPrice>
  );
});

const BasketBox = styled.div<{ $pos: string }>`
  position: absolute;
  right: 0;
  margin: 0 20px;
  width: 300px;
  min-height: 256px;
  line-height: 1.5;

  &.sticky {
    position: fixed;
    z-index: 0;
    top: 81px;
    right: initial;
    margin-left: 874px; // 814 + 20 + 40
  }

  &.bottom {
    ${({ $pos }) => $pos};
  }
`;

const Title = styled.h2`
  font-size: 16px;
  font-weight: normal;
`;

const WrapOption = styled(RelativeDiv)`
  margin: 16px 0 168px;
`;

const OptionDropdown = styled(Dropdown)`
  width: 300px;

  .Dropdown-control {
    box-sizing: content-box;
    padding: 8px 43px 8px 16px;
    height: auto;
    max-height: 700px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;

    line-height: 1.5;
    color: ${({ theme }) => theme.TextColor};
  }

  .Dropdown-option {
    padding: 8px 16px;
  }

  &.invalid {
    .Dropdown-control,
    .Dropdown-placeholder {
      border-color: ${({ theme }) => theme.mainActive};
      color: ${({ theme }) => theme.mainActive};
    }
  }
`;

const DropDownIcon = css`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 11px;
  height: 6px;
`;

const DesignedBasketButton = styled(ClearButton)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 44px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-weight: bold;
  text-align: center;
  color: #fff;

  &:disabled {
    background-color: ${({ theme }) => theme.tabNonActieColor};
    cursor: not-allowed;
  }
`;

const WrapQuantityBox = styled.div`
  position: absolute;
  bottom: 60px;
`;

const WrapTotalPrice = styled.div`
  position: absolute;
  right: 0;
  bottom: 60px;
  text-align: right;
`;

const TotalPrice = styled.div`
  font-size: 24px;
  font-weight: bold;
`;
