import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'stores';

import styled from 'styled-components';
import { PAGE_URL } from '../../../configs/path';
import { DISPLAY } from '../../../interfaces/layout';
import { Wrapper, ClearButton } from '../../atoms';

const TopBarContainer: React.FC<any> = observer(() => {
  const { layout, auth, cart } = useStores();
  const history = useHistory();
  const Logout = useCallback(() => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      auth.Logout(() => history.push('/'));
      // cart.deleteAll();
      layout.CloseSideBar();
    }
  }, [auth, history, layout]);

  return (
    <WrapTopBar>
      {DomTopBar(layout.displayType, auth.isSignIn, Logout, cart.cartItemLen)}
    </WrapTopBar>
  );
});

export default TopBarContainer;

const DomTopBar = (type: DISPLAY, isSignIn: boolean, handleClickLogout: any, cartLen: number) => {
  const history = useHistory();
  const dom = {
    [DISPLAY.DESKTOP]: (
      <WrapTopBarLink>
        {isSignIn ? (
          <>
            <TopBarButton onClick={() => handleClickLogout(() => history.push('/'))}>
              로그아웃
            </TopBarButton>
            <TopBarLink to={PAGE_URL.PURCHASE_LIST}>마이페이지</TopBarLink>
            <TopBarLink to={PAGE_URL.PURCHASE_LIST}>주문배송조회</TopBarLink>
          </>
        ) : (
          <>
            <TopBarLink to={PAGE_URL.SIGNIN}>로그인</TopBarLink>
            <TopBarLink to={PAGE_URL.SIGNUP}>회원가입</TopBarLink>
            <TopBarLink to={PAGE_URL.NONUSER_LOOKUP}>비회원 주문조회</TopBarLink>
          </>
        )}
        <TopBarLink to={PAGE_URL.CART}>장바구니 {cartLen ? `(${cartLen})` : ''}</TopBarLink>
      </WrapTopBarLink>
    ),
    [DISPLAY.TABLET]: (
      <WrapTopBarLink>
        {isSignIn ? (
          <>
            <TopBarButton onClick={() => handleClickLogout(() => history.push('/'))}>
              로그아웃
            </TopBarButton>
            <TopBarLink to={PAGE_URL.MYPAGE}>마이페이지</TopBarLink>
            <TopBarLink to={PAGE_URL.PURCHASE_LIST}>주문배송조회</TopBarLink>
          </>
        ) : (
          <>
            <TopBarLink to={PAGE_URL.SIGNIN}>로그인</TopBarLink>
            <TopBarLink to={PAGE_URL.SIGNUP}>회원가입</TopBarLink>
            <TopBarLink to={PAGE_URL.NONUSER_LOOKUP}>비회원 주문조회</TopBarLink>
          </>
        )}
      </WrapTopBarLink>
    ),
    [DISPLAY.MOBILE]: (
      <WrapTopBarLink>
        {isSignIn ? (
          <>
            <TopBarButton onClick={() => handleClickLogout(() => history.push('/'))}>
              로그아웃
            </TopBarButton>
            <TopBarLink to={PAGE_URL.MYPAGE}>마이페이지</TopBarLink>
            <TopBarLink to={PAGE_URL.PURCHASE_LIST}>주문배송조회</TopBarLink>
          </>
        ) : (
          <>
            <TopBarLink to={PAGE_URL.SIGNIN}>로그인</TopBarLink>
            <TopBarLink to={PAGE_URL.SIGNUP}>회원가입</TopBarLink>
            <TopBarLink to={PAGE_URL.NONUSER_LOOKUP}>비회원 주문조회</TopBarLink>
          </>
        )}
      </WrapTopBarLink>
    ),
  };

  return dom[type];
};

const WrapTopBar = styled.nav`
  width: 100%;
  background-color: #f8f8f8;
`;
const WrapTopBarLink = styled(Wrapper)`
  text-align: right;

  ${({ theme }) => theme.tablet`
    width: 100%;
  `}
`;

const TopBarLink = styled(Link)`
  display: inline-block;
  line-height: 40px;

  font-size: 16px;
  color: #666;
  text-decoration: none;

  ${({ theme }) => theme.desktop`
    margin-left: 48px;
  `}

  ${({ theme }) => theme.tablet`
    margin-right: 20px;
    line-height: 38px;
    font-size: 12px;
  `}
`;

const TopBarButton = styled(ClearButton)`
  display: inline-block;
  line-height: 40px;

  font-size: 16px;
  color: #666;
  text-decoration: none;

  ${({ theme }) => theme.desktop`
    margin-left: 48px;
  `}

  ${({ theme }) => theme.tablet`
    margin-right: 20px;
    line-height: 38px;
    font-size: 12px;
  `}
`;
