import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { generateKey } from 'utils';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import PremiumPackageItem from 'components/molecules/PlaysDetail/PremiumPackageItem';
import { Clear } from 'components/atoms/Clear';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const PremiumPackages = observer(() => {
  const { play } = usePlayDetailStore();

  if (play.premium_packages.length === 0) return null;

  return (
    <Wrap>
      <WrapTitle>
        <H2>
          이 패키지만 있으면 {useDesktopShow(<br />)}
          놀이 준비 끝!
        </H2>
        {useDesktopShow(<Clear />)}
        <Script>패키지 안에 놀이 재료가 포함되어 있어요</Script>
      </WrapTitle>
      <WrapPackages>
        {play.premium_packages.map((item, idx: number) => (
          <PremiumPackageItem key={generateKey(`${idx}`)} {...item} />
        ))}
      </WrapPackages>
      <Clear />
    </Wrap>
  );
});

export default PremiumPackages;

const Wrap = styled.div`
  margin-bottom: 70px;
  padding: 32px;
  background-color: #fff5d5;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin-bottom: 40px;
    padding: 20px;
  `}
`;

const H2 = styled.h2`
  float: left;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    margin-bottom: 0px;
    font-size: 16px;
  `}
`;

const Script = styled.div`
  ${({ theme }) => theme.tablet`
    display: inline-block;
    vertical-align: text-bottom;
    margin-left: 10px;
    font-size: 12px;
  `}

  ${({ theme }) => theme.mobile`
    margin-left: 0;
  `}
`;

const WrapTitle = styled.div`
  ${({ theme }) => theme.desktop`
    float: left;
  `}
`;

const WrapPackages = styled.div`
  ${({ theme }) => theme.desktop`
    float: right;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 20px
  `}
`;
