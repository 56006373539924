import { generatePath } from 'react-router-dom';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { API_URL } from 'configs/path';
import qs from 'query-string';
import OrderModel from 'stores/models/OrderModel';

class OrderRepository {
  fetch(id: number): AxiosPromise<Order.ResFetch> {
    return axios.get(generatePath(API_URL.ORDER, { id }));
  }

  fetchNonMember(
    email: string,
    phone: string,
  ): AxiosPromise<{ success: boolean; order_id: string; token: string }> {
    return axios.post(generatePath(API_URL.ORDER_NON_MEMBER), {
      orderer_email: email,
      orderer_phone_number: phone,
    });
  }

  delete(id: number): AxiosPromise<any> {
    return axios.delete(generatePath(API_URL.ORDER, { id }));
  }

  validate(isSignIn: boolean, id: number, req: Order.ReqValidate): AxiosPromise<Order.ResValidate> {
    return axios.post(
      generatePath(isSignIn ? API_URL.ORDER_VALIDATE : API_URL.ORDER_VALIDATE_NON_MEMBER, { id }),
      req,
    );
  }

  pay(reqImp: Order.ReqImp, { status }: Order.ResValidate['order']): Promise<Order.ResImp> {
    return new Promise((resolve, reject) => {
      if (status === 'verified') {
        if (reqImp.amount !== 0) {
          const IMP = global.IMP;

          IMP.init('imp80106218');
          IMP.request_pay(reqImp, (rsp: Order.ResImp) => {
            if (rsp.success) {
              resolve(rsp);
            } else {
              reject(rsp);
            }
          });
        } else {
          resolve({ success: true, imp_uid: '', error_msg: '' });
        }
      }
    });
  }

  complete(id: number, req: Order.ReqComplete): AxiosPromise<Order.ResFetch> {
    return axios.post(generatePath(API_URL.ORDER_COMPLETE, { id }), req);
  }

  async history(params: object): Promise<any> {
    const query = qs.stringify(params);

    try {
      const { data, headers } = (await axios.get(
        `${API_URL.ORDER_HISTORY}?${query}`,
      )) as AxiosResponse<{
        orders: Order.Order[];
      }>;

      return {
        data: { orders: data.orders.map(order => new OrderModel(order)) },
        headers,
      };
    } catch ({ response }) {
      console.log(response);
    }
  }

  addToCart(id: number): Promise<any> {
    return axios.post(generatePath(API_URL.CART_ADD_ALL, { id }));
  }

  deliveryCompleted(id: number): Promise<any> {
    return axios.put(generatePath(API_URL.DELIVERY_COMPLETE, { id }));
  }

  updateAddress1(id: number, address1: string, zonecode: string): Promise<any> {
    return axios.post(generatePath(API_URL.ORDER_ADDRESS1, { id }), {
      order: { address1, zonecode },
    });
  }
}

export default new OrderRepository();
