import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { Thead, Tr, Th, CheckBox } from 'pages/Cart/styled';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const CartTableHead = observer(() => {
  const { cart } = useStores();

  return (
    <Thead>
      <Tr>
        <Th className='checkbox'>
          <CheckBox id={'all'} checked={cart.allChecked} onChange={cart.toggleAllCheck} />
        </Th>
        <Th className='info'>상품 정보</Th>
        <Th className='quantity'>수량</Th>
        {useDesktopShow(<Th className='price'>금액</Th>)}
      </Tr>
    </Thead>
  );
});

export default CartTableHead;
