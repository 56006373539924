import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useCouponStore } from 'stores/local/Coupon';
import { useStores } from 'stores';
import { DISPLAY } from 'interfaces/layout';
import { withCommas } from 'utils';
import { PAGE_URL } from 'configs/path';
import { CouponTop } from 'components/atoms/Mypage';

const CouponTopBox: React.FC<any> = observer(() => {
  const { layout } = useStores();
  const { coupons } = useCouponStore();
  const { Wrapper, Value, Button } = CouponTop;
  const [prefix, setPrefix] = useState('사용 가능 쿠폰');

  useEffect(() => {
    if (layout.displayType === DISPLAY.DESKTOP) {
      setPrefix('사용 가능 쿠폰');
    } else {
      setPrefix('보유 쿠폰 ');
    }
  }, [layout.displayType]);

  return (
    <Wrapper>
      <Value $prefix={prefix} $suffix={'장'}>
        {withCommas(coupons.length)}
      </Value>
      <Button to={PAGE_URL.COUPON_DOWNLOAD}>쿠폰 등록하기</Button>
    </Wrapper>
  );
});

export default CouponTopBox;
