import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'configs/axios';
import 'configs/sdk-setup';

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { AuthRoute } from 'AuthRoute';
import 'mobx-react-lite/batchingForReactDom';

import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';
import { GlobalStyle } from 'styles/global-styles';
// import * as serviceWorker from './serviceWorker';
import { PAGE_URL } from 'configs/path';
import * as PAGE from 'pages';

import ScrollToTop from 'components/atoms/ScrollToTop';
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import SideMenu from 'components/molecules/SideMenu';
import Dimmed from 'components/molecules/Dimmed';
import { Alert } from 'components/Alert';

class Root extends Component {
  render(): React.ReactNode {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <ScrollToTop />
          <Header />
          <Switch>
            <Route exact path={PAGE_URL.HOME} component={PAGE.Home} />

            <Route path={[PAGE_URL.SIGNIN, PAGE_URL.NONUSER_LOOKUP]} component={PAGE.SignIn} />
            <Route path={PAGE_URL.SIGNUP} component={PAGE.SignUp} />
            <Route path={PAGE_URL.RESETPASSWD} component={PAGE.ResetPassword} />
            <Route path={PAGE_URL.POLICY} component={PAGE.Policy} />

            {/* 패키지 목록 */}
            <Route exact path={PAGE_URL.PACKAGE_SEARCH} component={PAGE.PackageSearch} />
            <Route exact path={PAGE_URL.PACKAGE} component={PAGE.PackageDetail} />
            <Route exact path={PAGE_URL.PACKAGES_BEST} component={PAGE.Best} />
            <Route
              exact
              path={[PAGE_URL.PACKAGES_MAIN, PAGE_URL.PACKAGES_SUB]}
              component={PAGE.Packages}
            />

            <Route path={PAGE_URL.PLAY} component={PAGE.PlaysDetail} />
            <Route exact path={PAGE_URL.PLAYS} component={PAGE.PlaysList} />
            <Route path={PAGE_URL.EVENT} component={PAGE.EventDetail} />
            <Route exact path={PAGE_URL.EVENTS} component={PAGE.EventList} />

            {/* 마이페이지(tablet, mobile) */}
            <AuthRoute exact path={PAGE_URL.MYPAGE} component={PAGE.MypageHome} />
            {/* 마이페이지 > 구매정보 */}
            <AuthRoute exact path={PAGE_URL.PURCHASE_LIST} component={PAGE.PurchaseList} />
            <AuthRoute
              exact
              path={PAGE_URL.USER_PURCHASE_DETAIL}
              component={PAGE.UserPurchaseDetail}
            />
            <AuthRoute path={PAGE_URL.POINT_HISTORY} component={PAGE.PointHistory} />
            <AuthRoute exact path={PAGE_URL.COUPON_LIST} component={PAGE.CouponList} />
            <AuthRoute path={PAGE_URL.COUPON_DOWNLOAD} component={PAGE.CouponDownload} />
            <AuthRoute path={PAGE_URL.COIN_HISTORY} component={PAGE.CoinHistory} />
            {/* 마이페이지 > 회원정보 */}
            <AuthRoute path={PAGE_URL.INFO_ME} component={PAGE.Info.MyInfo} />
            <AuthRoute path={PAGE_URL.CHANGE_PASSWD} component={PAGE.ChangePassword} />
            <AuthRoute path={PAGE_URL.INFO_CHILD} component={PAGE.Info.ChildInfo} />
            <AuthRoute path={PAGE_URL.MYCOMMENTS} component={PAGE.MyComments} />
            <AuthRoute path={PAGE_URL.DEACTIVEATE} component={PAGE.Deactivate} />
            {/* 마이페이지 > 고객지원 */}
            <AuthRoute path={PAGE_URL.FAQ} component={PAGE.FAQ} />

            {/* 구매 */}
            <Route path={PAGE_URL.CART} component={PAGE.Cart} />
            <Route exact path={PAGE_URL.COMPLETE} component={PAGE.PurchaseComplete} />
            <Route exact path={PAGE_URL.PURCHASE} component={PAGE.PurchaseOrder} />
            <Route
              exact
              path={PAGE_URL.NON_USER_PURCHASE_DETAIL}
              component={PAGE.NonUserPurchaseDetail}
            />

            <Route path={PAGE_URL.ERROR} component={PAGE.ErrorPage} />
            <Redirect path='*' to={PAGE_URL.ERROR} />
          </Switch>
          <Alert />
          <SideMenu />
          <Footer />
          <Dimmed />
        </Router>
      </ThemeProvider>
    );
  }
}

const rootElement = document.getElementById('root');

if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Root />, rootElement);
} else {
  ReactDOM.render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
