import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { withCommas } from 'utils';
import { HistoryTop } from 'components/atoms/Mypage';

const PointTopBox: React.FC<any> = observer(() => {
  const { auth } = useStores();
  const { Wrapper, Value, Desc } = HistoryTop;

  return (
    <Wrapper>
      <Value $prefix={'보유 포인트'} $suffix={'p'}>
        {withCommas(auth.user?.point || 0)}
      </Value>
      <Desc>보유 포인트로 차이의 놀이 패키지 구매 시 결제 가능해요 :)</Desc>
    </Wrapper>
  );
});

export default PointTopBox;
