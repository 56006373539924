import React from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PAGE_URL } from 'configs/path';
import { useStores } from 'stores';

interface Props extends Omit<RouteProps, 'component'> {
  component: React.FC<any>;
  redirectPath?: string;
}

export const AuthRoute: React.FC<Props> = observer(
  ({ component: Component, redirectPath = PAGE_URL.HOME, ...rest }) => {
    const { replace } = useHistory();
    const { auth } = useStores();

    if (!auth.isSignIn) {
      replace(redirectPath);
    }

    return <Route {...rest} render={routeProps => <Component {...routeProps} />} />;
  },
);
