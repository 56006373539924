import React, { useRef, useEffect, RefObject } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores';
import { useToggle } from 'components/hooks/useToggle';
import { useOnClickOutside } from 'components/hooks/useOnClickOutside';
import { ClearButton } from 'components/atoms/Clear';
import { PopupIcon, PopupWrapper, PopupHeader, PopupBody, PopupTail } from 'components/atoms/Popup';

export const DeliveryPopup: React.FC = React.memo(() => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, handleToggle] = useToggle(false);

  useOnClickOutside(wrapperRef, () => {
    if (wrapperRef) handleToggle(false);
  });

  return (
    <Wrapper ref={wrapperRef}>
      <ClearButton ref={buttonRef} type={'button'} onClick={handleToggle}>
        <PopupIcon />
      </ClearButton>
      {isOpen ? <InnerPopup buttonRef={buttonRef} /> : null}
    </Wrapper>
  );
});

const InnerPopup: React.FC<{ buttonRef: RefObject<HTMLButtonElement> }> = observer(
  ({ buttonRef }) => {
    const {
      layout: { isMobile },
    } = useStores();
    const popupRef = useRef<HTMLDivElement>(null);
    const tailRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const popupEl = popupRef.current;
      const buttonEl = buttonRef.current;
      const tailEl = tailRef.current;

      if (isMobile && popupEl && buttonEl && tailEl) {
        const { width: popupWidth } = popupEl.getBoundingClientRect();
        const { left, width: buttonWidth } = buttonEl.getBoundingClientRect();

        popupEl.style.cssText = 'top: unset; left: 50%;';
        tailEl.style.cssText = `left: ${
          left - (document.body.clientWidth - popupWidth) / 2 + buttonWidth / 2
        }px`;
      }
    }, [buttonRef, isMobile]);

    return (
      <Popup ref={popupRef}>
        <PopupHeader>차이의 놀이 택배 출고안내</PopupHeader>
        <PopupBody>
          놀이 패키지 구매 순서대로 준비하여 발송해 드리고 있습니다. <br />
          택배 출고 후 택배사 배송량 증가 및 날씨 등의 사정에 따라 배송이 지연될 수 있습니다.
        </PopupBody>
        <PopupTail ref={tailRef} />
      </Popup>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: sub;

  ${({ theme }) => theme.mobile`
    vertical-align: text-bottom;
    position: unset;
  `}
`;

const Popup = styled(PopupWrapper)`
  width: 455px;

  ${({ theme }) => theme.tablet`
    width: 255px;
  `}

  ${({ theme }) => theme.mobile`
    margin-top: -35px;
  `}
`;
