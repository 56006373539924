import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import cx from 'classnames';

import { DefaultLayout } from 'layouts/Default';
import { WrapBox, AuthBox, AuthTitle, AuthForm, LinkButton } from 'components/atoms';
import { InputWrap, InputLabel, Input, SubmitButton, InputErr } from 'components/atoms/Form';
import { emailReg, passwdReg } from 'utils';
import { useStores } from 'stores';
import useCacheImages from 'components/hooks/useCacheImages';
import { Link } from 'react-router-dom';
import { PAGE_URL } from 'configs/path';

interface IFormInput {
  email: string;
  password: string;
  passwordConfirm: string;
  nickname: string;
}

const SignUpOkImg = '/images/celebration.png';

const SignUp = React.memo(() => {
  const { auth } = useStores();
  const [isSignUp, setisSignUp] = useState(false);
  useCacheImages([SignUpOkImg]);

  const { register, errors, setError, watch, handleSubmit } = useForm<IFormInput>();
  const onSubmit = async (data: any) => {
    try {
      await auth.signUp(data);
      setisSignUp(true);
    } catch (error) {
      if (error.length) {
        error.map(({ field, message }: { field: keyof IFormInput; message: string }) =>
          setError(field, {
            type: 'manual',
            message,
          }),
        );
      }
    }
  };

  if (!isSignUp) {
    return (
      <WrapBox>
        <SignUpBox>
          <AuthTitle>차이의 놀이 회원 가입하기</AuthTitle>
          <AuthForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrap>
              <InputLabel htmlFor='ipt-eamil'>이메일</InputLabel>
              <Input
                type='text'
                id='ipt-eamil'
                name='email'
                placeholder='이메일 입력하기'
                className={cx({ err: errors.email })}
                ref={register({
                  required: "'이메일'을 입력해주세요",
                  pattern: {
                    value: emailReg,
                    message: "'이메일'을 입력해주세요",
                  },
                })}
                autoComplete='chaisplay_signup_eamil'
              />
              {errors.email && <InputErr>{errors.email.message}</InputErr>}
            </InputWrap>
            <InputWrap>
              <InputLabel htmlFor='ipt-pass'>비밀번호</InputLabel>
              <Input
                type='password'
                id='ipt-pass'
                name='password'
                placeholder='비밀번호 8자리 이상 입력하기'
                className={cx({ err: errors.password })}
                ref={register({
                  required: "'비밀번호'를 입력해주세요",
                  pattern: {
                    value: passwdReg,
                    message: '비밀번호는 알파벳, 숫자, 특수기호(!@#$%&*?) 조합 8자리 이상입니다.',
                  },
                })}
                autoComplete='chaisplay_signup_passwd'
              />
              {errors.password && <InputErr>{errors.password.message}</InputErr>}
            </InputWrap>
            <InputWrap>
              <InputLabel htmlFor='ipt-pass_confirm'>비밀번호 확인</InputLabel>
              <Input
                type='password'
                id='ipt-pass_confirm'
                name='passwordConfirm'
                placeholder='동일한 비밀번호 입력하기'
                className={cx({ err: errors.passwordConfirm })}
                ref={register({
                  required: true,
                  validate: value => value === watch('password'),
                })}
                autoComplete='chaisplay_signup_passwd'
              />
              {errors.passwordConfirm && <InputErr>동일한 '비밀번호'를 입력해주세요</InputErr>}
            </InputWrap>
            <InputWrap>
              <InputLabel htmlFor='ipt-nick'>닉네임</InputLabel>
              <Input
                type='text'
                id='ipt-nick'
                name='nickname'
                placeholder='닉네임 입력하기'
                className={cx({ err: errors.nickname })}
                ref={register({
                  required: "'닉네임'을 입력해주세요",
                })}
                autoComplete='chaisplay_signup_nick'
              />
              {errors.nickname && <InputErr>{errors.nickname.message}</InputErr>}
            </InputWrap>
            <SignUpButton type='submit'>회원가입</SignUpButton>
          </AuthForm>
          <SignUpInfo>
            <Guide>
              ※ 회원가입을 하시면 차이의 놀이{' '}
              <Link to={PAGE_URL.POLICY_SERVICE}>서비스 이용약관</Link>/
              <Link to={PAGE_URL.POLICY_PRIVACY}>개인정보처리방침</Link>에 동의함을 의미합니다.
            </Guide>
            <Guide>
              ※ 교육기관 회원 가입은 기관 대표 이메일 주소와 닉네임에 기관명을 입력하여 바로
              가입하실 수 있습니다.
            </Guide>
          </SignUpInfo>
        </SignUpBox>
      </WrapBox>
    );
  } else {
    return (
      <WrapBox $background='#fff'>
        <SignUpPassBox>
          차이의 놀이 가입이 완료되었습니다!
          <CelebrationImg src={SignUpOkImg} alt='SignUp Congratulations Image' />
          <GoMainButton to='/'>메인으로 가기</GoMainButton>
        </SignUpPassBox>
      </WrapBox>
    );
  }
});

const SignUpBox = styled(AuthBox)`
  padding-left: 90px;
  padding-right: 90px;

  ${({ theme }) => theme.tablet`
    padding: 10px 0;
    width: 380px;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px 20px;
    width: 100%;
  `}
`;
const SignUpButton = styled(SubmitButton)`
  margin-top: 20px;

  ${({ theme }) => theme.tablet`
    margin-top: 15px;
  `}
`;
const SignUpInfo = styled.div`
  margin-top: 24px;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
    margin-bottom: 10px;
  `};
`;
const Guide = styled.div`
  margin-bottom: 16px;
  line-height: 1.67;

  font-size: 12px;
  color: ${({ theme }) => theme.TextColor};

  a {
    color: ${({ theme }) => theme.TextColor};
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.tablet`
    margin-bottom: 10px;
  `}
`;

const SignUpPassBox = styled(AuthBox)`
  padding: 0 30px;

  font-size: 16px;
  text-align: center;

  ${({ theme }) => theme.tablet`
    width: 320px;
    padding: 20px 0;
    font-size: 14px;
  `}
`;
const CelebrationImg = styled.img`
  width: 100%;

  ${({ theme }) => theme.tablet`
    margin-top: 11px;
  `}
`;
const GoMainButton = styled(LinkButton)`
  margin-top: 25px;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
  `}
`;

export default DefaultLayout(SignUp);
