import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

import { generateKey } from 'utils';
import { PAGE_URL } from 'configs/path';

import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { ClearUl, ClearLink } from 'components/atoms/Clear';
import { ConditionalWrapper } from 'components/atoms/ConditionalWrapper';
import { ArrowRight } from 'components/atoms';

export enum ORDER_STEP {
  CART = 0,
  ORDER = 1,
  COMPL = 2,
}
const ORDER_STEP_TXT = ['장바구니', '주문/결제', '주문완료'];
const ORDER_STEP_URL = [PAGE_URL.CART, PAGE_URL.HOME, PAGE_URL.HOME];

interface Props {
  step: ORDER_STEP;
}

const OrderStep: React.FC<Props> = React.memo(({ step }) =>
  useDesktopShow(
    <WrapStep>
      {ORDER_STEP_TXT.map((txt, idx) => (
        <li className={cx({ active: step === idx })} key={generateKey(`order_step_${idx}`)}>
          <ConditionalWrapper
            condition={step === idx}
            wrapper={(children: React.ReactNode) => (
              <ClearLink to={ORDER_STEP_URL[step]}>{children}</ClearLink>
            )}>
            {txt}
          </ConditionalWrapper>
        </li>
      ))}
    </WrapStep>,
  ),
);

export default OrderStep;

const WrapStep = styled(ClearUl)`
  position: absolute;
  top: 12px;
  right: 0;

  li {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    padding-right: 15px;
    font-weight: bold;
    color: #b3b3b3; /* rgba(0, 0, 0, 0.3) */

    &.active {
      color: ${({ theme }) => theme.TextColor};
    }

    &:after {
      content: '';
      position: absolute;
      ${ArrowRight}
      top: 7px;
      right: 0;
      width: 8px;
      height: 8px;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }
`;
