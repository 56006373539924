import React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import cx from 'classnames';
import { Input, SubmitButton, InputErr } from 'components/atoms/Form';
import { AuthForm } from 'components/atoms';
import OrderRepository from 'stores/repository/OrderRepository';
import { setOrderToken } from 'configs/axios';
import { useHistory, generatePath } from 'react-router-dom';
import { PAGE_URL } from 'configs/path';

interface IFormInput {
  email: string;
  phone: string;
}

const SearchNonMembersBox: React.FC<any> = () => {
  const { register, errors, handleSubmit } = useForm<IFormInput>();
  const history = useHistory();
  const onSubmit = async (data: any) => {
    try {
      const {
        data: { success, order_id, token },
      } = await OrderRepository.fetchNonMember(data.email, data.phone);
      if (success) {
        setOrderToken(token);
        history.push(generatePath(PAGE_URL.NON_USER_PURCHASE_DETAIL, { id: order_id }));
      }
    } catch (error) {
      const { status } = error.response;
      if (status === 422) {
        alert(error.response.data.error);
        // throw data;
      } else {
        // throw error;
      }
    }
  };

  return (
    <Wrap>
      <AuthForm onSubmit={handleSubmit(onSubmit)}>
        <Input
          type='text'
          name='email'
          placeholder='주문시 사용한 이메일 주소'
          className={cx({ err: errors.email })}
          autoComplete='chaisplay-nl-email'
          ref={register({
            required: '이메일 주소를 다시 확인해주세요.',
          })}
        />
        {errors.email && <InputErr>{errors.email.message}</InputErr>}
        <Input
          type='text'
          name='phone'
          placeholder='주문시 사용한 휴대번호'
          className={cx({ err: errors.phone })}
          autoComplete='chaisplay-nl-phone'
          ref={register({
            required: '휴대폰 번호를 다시 확인해주세요.',
          })}
        />
        {errors.phone && <InputErr>{errors.phone.message}</InputErr>}
        <LookUpButton>조회하기</LookUpButton>
      </AuthForm>
    </Wrap>
  );
};

const Wrap = styled.div`
  margin-top: 20px;

  ${({ theme }) => theme.tablet`
    margin-top: 12px;
  `}
`;

const LookUpButton = styled(SubmitButton)`
  margin-top: 12px;

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
  `}
`;

export default SearchNonMembersBox;
