import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { DefaultModalZIndex, ModalProps } from 'interfaces/Modal';
import { useStores } from 'stores';
import * as atoms from './styled';
import { ClearButton } from 'components/atoms/Clear';
import { PAGE_URL } from 'configs/path';

const LoginModal = observer(({ isOpen, params }: ModalProps) => {
  const history = useHistory();
  const { modal } = useStores();
  const { message, redirectUrl } = params;
  const onClickClose = useCallback(() => {
    if (redirectUrl) history.push(redirectUrl);
    else modal.closeCurrentModal();
  }, [history, modal, redirectUrl]);

  const onClickLogin = useCallback(() => {
    history.push(`${PAGE_URL.SIGNIN}?redirectUrl=${encodeURIComponent(redirectUrl)}`);
  }, [history, redirectUrl]);

  return (
    <WrapModal $show={isOpen} $zIndex={DefaultModalZIndex}>
      <Message>{message}</Message>
      <WrapButton>
        <CloseButton onClick={onClickClose}>닫기</CloseButton>
        <LoginButton onClick={onClickLogin}>로그인 하기</LoginButton>
      </WrapButton>
    </WrapModal>
  );
});

export default LoginModal;

const WrapModal = styled(atoms.WrapModal)`
  padding: 0;
  width: 305px;

  ${({ theme }) => theme.tablet`
    width: 280px;
  `};
`;

const Message = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #d9d9d9;

  ${({ theme }) => theme.tablet`
    padding: 20px;
    font-size: 14px;
  `};
`;

const WrapButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled(ClearButton)`
  margin: 16px 0 16px auto;
  width: 60px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #b3b3b3;

  ${({ theme }) => theme.tablet`
    margin: 10px 0 10px 10px;
    height: 30px;
  `};
`;

const LoginButton = styled(ClearButton)`
  margin: 16px 14px 16px 10px;
  width: 138px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.subActiveColor};
  color: white;
  background-color: ${({ theme }) => theme.subActiveColor};

  ${({ theme }) => theme.tablet`
    width: 90px;
    margin: 10px 14px 10px 10px;
    height: 30px;
  `};
`;
