import React from 'react';
import styled from 'styled-components';

import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { WrapInput, Input, InputErr, Label } from '../../styled';
import { ChildButton } from '..';
import { GenderRadio } from '../atoms/GenderRadio';
import { Minus } from '../atoms/Icon';

export const FormRow: React.FC<any> = ({ index, err, register, remove, fields }) => {
  const errors = err.kids ? err.kids[index] || {} : {};
  const id = fields[index].user_id ? fields[index].id : null;

  return (
    <>
      <InputRow>
        <Label>아이 이름</Label>
        <Input
          type='text'
          name={`kids[${index}].fullname`}
          ref={register({ required: '아이 이름을 입력해주세요.' })}
          placeholder='아이 이름을 입력해 주세요'
          defaultValue={fields[index].fullname}
        />
        {errors.fullname && <InputErr>{errors.fullname.message}</InputErr>}
      </InputRow>
      <InputRow>
        <Label>생년월일</Label>
        <Input
          type='text'
          name={`kids[${index}].birthday`}
          ref={register({ required: '아이 생년월일을 다시 확인해주세요.' })}
          placeholder='아이 생일 입력하기'
          defaultValue={fields[index].birthday}
        />
        {errors.birthday && <InputErr>{errors.birthday.message}</InputErr>}
      </InputRow>
      <InputRow>
        {useDesktopShow(<Label>아이 성별</Label>)}
        <GenderRadio
          text='남자 아이'
          name={`kids[${index}].gender`}
          ref={register({ required: '성별을 입력해주세요.' })}
          value='boy'
          defaultChecked={fields[index].gender === 'boy'}
        />
        <GenderRadio
          text='여자 아이'
          name={`kids[${index}].gender`}
          ref={register({ required: '성별을 입력해주세요.' })}
          value='girl'
          defaultChecked={fields[index].gender === 'girl'}
        />
        {errors.gender && <InputErr>{errors.gender.message}</InputErr>}
      </InputRow>
      <input type='hidden' name={`kids[${index}].id`} ref={register()} defaultValue={id} />
      <input
        type='hidden'
        name={`kids[${index}].user_id`}
        ref={register()}
        defaultValue={fields[index].user_id}
      />
      {fields.length > 1 ? (
        <ChildButton type='button' onClick={remove}>
          <Minus /> 아이정보 지우기
        </ChildButton>
      ) : null}
      <Hr />
    </>
  );
};

const InputRow = styled(WrapInput)`
  ${({ theme }) => theme.tablet`
    margin: 20px auto;
    line-height: 1.43;

    ${Input} {
      width: 208px;
    }

    ${Label} {
      display: inline-block;
      vertical-align: unset;
      margin-right: 10px;
      width: 62px;
      font-size: 14px;
    }
  `}

  ${({ theme }) => theme.mobile`
    margin: 20px auto;

    ${Input} {
      width: calc(100% - 72px);
    }
  `}
`;

const Hr = styled.hr`
  margin: 16px 0;
  width: 100%;
  border: solid 1px #eaeaea;

  & + ${WrapInput} {
    margin-top: 24px;
  }
`;
