import styled from 'styled-components';

export const Wrapper = styled.div<{ $white?: boolean; $last?: boolean }>`
  background: ${({ $white }) => ($white ? '#fff' : '#f8f8f8')};

  ${({ theme, $last }) => theme.desktop`
    ${$last ? 'margin-bottom: 72px' : 'padding-bottom: 70px'};
  `}

  ${({ theme, $last }) => theme.tablet`
    ${$last ? null : 'padding-top: 10px'};
  `}
`;

export const Contents = styled.div`
  margin: auto;
  background: #fff;

  ${({ theme }) => theme.desktop`
    width: 960px;
  `}

  ${({ theme }) => theme.tablet`
    width: 100%;
  `}
`;

export const WrapEventPackage = styled.div`
  padding: 10px 20px 20px;
`;

export const WrapEventResource = styled.div`
  overflow: hidden;
`;
