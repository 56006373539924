import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { PlaysContainer, PackagesContainer } from 'components/molecules/PlaysDetail';
import { Box, H2, Wrapper } from './styled';

const RelatedContents = observer(() => {
  const { play } = usePlayDetailStore();
  const { plays, packages } = play.relatedContents;

  if (packages.length === 0 && plays.length === 0) return null;

  return (
    <Box $withPad>
      <H2>놀이법과 함께 보세요</H2>
      {packages.length ? (
        <Wrapper>
          <H3>함께 보면 좋은 패키지</H3>
          <PackagesContainer store={packages} />
        </Wrapper>
      ) : null}
      {plays.length ? (
        <Wrapper>
          {packages.length ? <Hr /> : null}
          <H3>함께 보면 좋은 놀이법</H3>
          <PlaysContainer store={plays} />
        </Wrapper>
      ) : null}
    </Box>
  );
});

export default RelatedContents;

const H3 = styled.h3`
  margin: 16px 0;
  line-height: 1.5;
  font-size: 16px;
  font-weight: normal;

  ${({ theme }) => theme.tablet`
    margin: 0;
  `}
`;

const Hr = styled.hr`
  border: solid 1px #d5d5d5;
`;
