import React from 'react';
import { observer } from 'mobx-react';
import { COMMENTES_TYPE } from 'interfaces/Commetes';
import { CommentsProvider, useCommentsStore, useInitComments } from 'stores/local/Comments';
import { CommentInput, CommentList } from 'components/molecules/Comment';
import { H2, Wrapper } from './styled';
import { useEventDetailStore } from 'pages/Event/Detail/store';
import { withCommas } from 'utils';

const Container: React.FC<any> = () => (
  <CommentsProvider type={COMMENTES_TYPE.EVENT}>
    <EventComments />
  </CommentsProvider>
);

const EventComments: React.FC<any> = observer(() => {
  const { event } = useEventDetailStore();
  const { isInit } = useCommentsStore();
  useInitComments();

  if (!isInit) return null;

  return (
    <Wrapper>
      <H2>댓글 {withCommas(event.comments_count)}</H2>
      <CommentInput />
      <CommentList />
    </Wrapper>
  );
});

export default Container;
