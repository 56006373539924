import { observable } from 'mobx';

export default class PointModel {
  @observable history: any[];

  constructor(props: Point.RootObject) {
    this.history = props.points.map(item => {
      return {
        id: item.id,
        value: item.point,
        created_at: item.created_at,
        type: item.point < 0 ? '사용' : '적립',
        title: item.title,
      };
    });
  }
}
