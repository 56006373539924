import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { StockStatus } from 'interfaces/PackageDetail';
import { ClearLink } from 'components/atoms/Clear';

const PremiumPackageItem: React.FC<any> = props => {
  const {
    status,
    id,
    square_image_url,
    ages,
    title,
    original_price,
    display_discount_rate,
    sales_price,
  } = props;
  const href = generatePath(PAGE_URL.PACKAGE, { id });

  if (status === StockStatus.OUT) return null;

  return (
    <WrapItem to={href}>
      <Img src={square_image_url} alt='premium package' />
      <WrapContent>
        <Age>{ages}</Age>
        <Title>{title}</Title>
        <OriginPrice>{original_price}</OriginPrice>
        <DiscountPercent>{display_discount_rate}</DiscountPercent>
        <Price>{sales_price}</Price>
      </WrapContent>
    </WrapItem>
  );
};

export default PremiumPackageItem;

const WrapItem = styled(ClearLink)`
  display: block;
  overflow: hidden;
  width: 770px;
  height: 210px;
  background: #fff;
  line-height: 1.5;
  text-decoration: none;

  & + & {
    margin-top: 24px;
  }

  ${({ theme }) => theme.tablet`
    display: inline-block;
    margin-right: 20px;
    width: calc(50% - 10px);
    height: 80px;

    &:nth-child(2n) {
      margin-right: 0;
    }

    &:nth-child(n + 2){
      margin-top: 10px;
    }
  `}

  ${({ theme }) => theme.mobile`
    margin-right: 0;
    width: 100%;

    & + & {
      margin-top: 10px;
    }
  `}
`;

const Img = styled.img`
  float: left;
  width: 210px;
  height: 210px;

  ${({ theme }) => theme.tablet`
    width: 80px;
    height: 80px;
  `}
`;

const WrapContent = styled.div`
  float: left;
  padding: 24px;
  width: calc(100% - 210px);
  height: 210px;

  ${({ theme }) => theme.tablet`
    padding: 10px;
    width: calc(100% - 80px);
    height: 80px;
  `}
`;

const Age = styled.div`
  font-size: 12px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    display: none;
  `}
`;

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.desktop`
    margin: 9px 0 26px;
    height: 48px;
    font-size: 16px;
  `}

  ${({ theme }) => theme.tablet`
    height: 38px;
    font-size: 12px;
  `}
`;

const OriginPrice = styled.div`
  float: left;
  margin-right: 8px;
  color: #b3b3b3;
  text-decoration: line-through;

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
    margin-right: 3px;
    font-size: 10px;
  `}
`;

const DiscountPercent = styled.div`
  float: left;
  font-weight: bold;
  color: #4696d7;

  ${({ theme }) => theme.tablet`
    display: none;
  `}
`;

const Price = styled.div`
  clear: left;
  margin-top: 4px;

  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.tablet`
    float: left;
    clear: none;
    margin-top: 0;
    font-size: 14px;
  `}
`;
