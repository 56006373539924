import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { ClearLink } from 'components/atoms/Clear';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const MypageSideLinks: React.FC<any> = observer(() =>
  useDesktopShow(
    <WrapLinkBox>
      <H1>마이페이지</H1>
      <H2>구매정보</H2>
      <Link to={PAGE_URL.PURCHASE_LIST}>주문배송 조회</Link>
      <Link to={PAGE_URL.POINT_HISTORY}>포인트 조회</Link>
      <Link to={PAGE_URL.COUPON_LIST}>쿠폰함</Link>
      <Link to={PAGE_URL.COIN_HISTORY}>코인 충전/이용 내역</Link>
      <H2>회원정보</H2>
      <Link to={PAGE_URL.INFO_ME}>개인정보 설정</Link>
      <Link to={PAGE_URL.CHANGE_PASSWD}>비밀번호 변경</Link>
      <Link to={PAGE_URL.INFO_CHILD}>아이정보 설정</Link>
      <Link to={PAGE_URL.MYCOMMENTS}>나의 놀이댓글</Link>
      <Link to={PAGE_URL.DEACTIVEATE}>탈퇴하기</Link>
      <H2>고객지원</H2>
      <ExternalLink
        href={'https://www.chaisplay.com/about'}
        target='_blank'
        rel='noopener noreferrer'>
        차이의놀이 소개
      </ExternalLink>
      <Link to={PAGE_URL.FAQ}>자주묻는 질문 FAQ</Link>
      <ExternalLink
        href={'https://pf.kakao.com/_xdbBvxd'}
        target='_blank'
        rel='noopener noreferrer'>
        카카오톡 1:1 문의하기
      </ExternalLink>
    </WrapLinkBox>,
  ),
);

export default MypageSideLinks;

const WrapLinkBox = styled.div`
  display: inline-block;
  margin-bottom: 100px;
  padding: 24px;
  width: 199px;
  border: solid 1px #d5d5d5;
  line-height: 1.5;
`;
const H1 = styled.h1`
  margin: 0;
  font-size: 32px;
  text-align: center;
`;
const H2 = styled.h2`
  margin-top: 24px;
  font-size: 16px;
`;
const Link = styled(ClearLink)`
  display: block;
  margin: 8px 0;

  &:last-child {
    margin: 0;
  }

  & > ${H2} {
    margin-top: 32px;
  }
`;
const ExternalLink = styled.a`
  display: block;
  margin: 8px 0;
  text-decoration: none;
`;
