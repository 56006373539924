import axios from 'axios';
import { API_URL } from 'configs/path';
import { generatePath } from 'react-router-dom';

class EventRepository {
  fetchList(): Promise<{ events: any[] }> {
    return axios.get(API_URL.EVENTS);
  }

  fetchDetail(id: string): Promise<{ event: any[] }> {
    return axios.get(generatePath(API_URL.EVENT, { id }));
  }
}

export default new EventRepository();
