import React from 'react';
import styled from 'styled-components';
import { Box, H2 } from './styled';
import { ClearLink } from 'components/atoms';
import { PAGE_URL } from 'configs/path';

const Notice: React.FC<any> = React.memo(({ ages }) => (
  <Box $withPad>
    <H2>꼭 참고해 주세요</H2>
    <WrapNotice>
      1. 차이의 놀이의 모든 콘텐츠는 아이를 돌보고 기르는 모든 양육자 분들을 대상으로 한 콘텐츠
      입니다. 아이를 기르는 주 양육자는 아빠, 엄마, 조부모님, 돌봄 선생님 등 각 가정의 상황에 따라
      다를 수 있습니다. 다만, 매 콘텐츠마다 각 양육 상황을 고려하여 모두 기재하기에는 어려움이 있어
      '엄마'로 표기하여 설명드리는 점이 있습니다. 차이의 놀이의 콘텐츠는 엄마가 주로 양육을 해야
      한다는 의미로써 엄마를 주로 언급하여 표기하는 것은 아닌 점 꼭 참고해 주시기 바랍니다.
      <br />
      <br />
      2. 아기의 성장발달 속도는 개개인 상황에 따라 차이가 있을 수 있습니다. 지금 바로 우리 아이와
      제안 드리는 놀이를 해 주기 어려운 경우가 있을 수 있으며, 선행 발달 자극으로 참고하실 수 있도록
      놀이팁을 제공해 드리고 있습니다.
      <br />
      <br />
      {(ages.includes(1) || ages.includes(2)) && (
        <ClearLink to={PAGE_URL.FAQ}>
          [1세 아기 부모님들이 자주 궁금해 하는 놀이법에 대한 Q&A]
        </ClearLink>
      )}
    </WrapNotice>
  </Box>
));

export default Notice;

const WrapNotice = styled.div`
  margin: 16px 0 70px;
  line-height: 1.8;

  ${({ theme }) => theme.tablet`
    margin: 10px 0 40px;
    font-size: 12px;
  `}
`;
