import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 16px 0 70px;

  ${({ theme }) => theme.tablet`
    margin: 10px 0 40px;
  `}
`;

export const Box = styled.div<{ $withPad?: boolean }>`
  ${({ theme, $withPad }) => theme.tablet`
    ${$withPad ? 'padding: 0 20px' : null};
  `}
`;

export const H2 = styled.h2`
  margin: 0 0 16px;
  padding-bottom: 16px;
  border-bottom: solid 2px #262626;
  line-height: 32px;
  font-size: 24px;

  ${({ theme }) => theme.tablet`
    margin: 0 0 10px;
    padding-bottom: 10px;
    line-height: 1.5;
    font-size: 16px;
  `}
`;

export const Subscript = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 16px;
  line-height: 32px;
  font-size: 16px;
  font-weight: normal;
  color: #b3b3b3;
`;
