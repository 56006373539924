import React from 'react';
import { Helmet } from 'react-helmet';

enum Loc {
  en,
  ko,
}
const locales = {
  [Loc.en]: 'en_US',
  [Loc.ko]: 'ko_KR',
};
const siteName = {
  [Loc.en]: "Chai's Play",
  [Loc.ko]: '차이의 놀이',
};

interface Props {
  data: {
    locale?: Loc;
    title?: string;
    description?: string;
    image?: string;
    url?: string;
    type?: string;
    androidAppUrl?: string;
    iosAppUrl?: string;
  };
}

const ReactHelmet = ({ data }: Props) => {
  const loc = data.locale || Loc.ko;
  const lang = data.locale ? locales[data.locale] : locales[Loc.ko];
  const title = data.title ? `${data.title} | ${siteName[loc]}` : siteName[loc];
  const description = data.description;
  const image = data.image ? data.image : `/icons/${lang}/chai_logo.png`;
  const canonical = data.url || window.location.href;
  const type = data.type || 'website';
  const androidAppUrl = data.androidAppUrl || '';
  const iosAppUrl = data.iosAppUrl || '';

  return (
    <Helmet titleTemplate='%s'>
      <html lang={lang} />
      <title>{title}</title>
      <meta name='description' content={description} />
      {canonical ? <link rel='canonical' href={canonical} /> : null}
      {image ? <link rel='image_src' href={image} /> : null}

      <meta property='og:site_name' content={siteName[loc]} />
      <meta property='og:title' content={title} />
      {description ? <meta property='og:description' content={description} /> : null}
      {canonical ? <meta property='og:url' content={canonical} /> : null}
      <meta property='og:locale' content={lang} />
      <meta property='og:type' content={type} />
      {image ? <meta property='og:image' content={image} /> : null}
      <meta property='fb:pages' content={canonical} />
      <meta property='al:android:url' content={androidAppUrl} />
      <meta property='al:android:package' content='com.havit.android' />
      <meta property='al:android:app_name' content='차이의 놀이' />
      <meta property='al:ios:url' content={iosAppUrl} />
      <meta property='al:ios:app_store_id' content='1134836561' />
      <meta property='al:ios:app_name' content='차이의 놀이' />
    </Helmet>
  );
};
export default ReactHelmet;
