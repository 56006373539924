import { useState } from 'react';
import styled from 'styled-components';

export interface Tab {
  buttonText: string;
  content?: any;
}

const useTabs = (idx: number, Tabs: Tab[]) => {
  const [currentIdx, setCurrentIdx] = useState(idx);

  return {
    curIndex: currentIdx,
    setTab: setCurrentIdx,
    curTab: Tabs[currentIdx] || null,
  };
};

export const TabContent = styled.div<{ $active?: boolean }>`
  ${props => (props.$active ? '' : 'display:none')};

  ${({ theme }) => theme.tablet`
    padding: 0 20px;
  `}

  &.no-padding {
    padding: 0;
  }
`;

export default useTabs;
