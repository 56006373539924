import React from 'react';

import { PAGE_URL } from 'configs/path';
import { generateKey } from 'utils';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

import { BreadCrumbLink } from 'components/atoms';
import BreadCrumbFrame from 'components/atoms/BreadCrumbFrame';

const PlaysBreadCrumb = React.memo(() => {
  const breadCrumbItems = [];

  breadCrumbItems.push(<BreadCrumbLink to={PAGE_URL.HOME}>홈</BreadCrumbLink>);
  breadCrumbItems.push(<BreadCrumbLink to={PAGE_URL.PLAYS}>활동지 다운로드</BreadCrumbLink>);

  return useDesktopShow(
    <BreadCrumbFrame items={breadCrumbItems} prefixKey={generateKey('PlaysList')} />,
  );
});

export default PlaysBreadCrumb;
