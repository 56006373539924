import React from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores';
import { commaNumber, generateKey } from 'utils';
import { PAGE_URL } from 'configs/path';
import { FormSection, H2, Hr } from './styled';
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  PackageImg,
  WrapCartInfo,
  PackageTitleLink,
  OptionName,
} from 'pages/Cart/styled';
import { ClearLink } from 'components/atoms/Clear';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';
import CartOrderItemModel from 'stores/models/CartOrderItemModel';

const PurchaseTable: React.FC<any> = observer(() => {
  const { order } = useStores();

  return (
    <FormSection>
      <H2>구매 내역</H2>
      <Hr />
      <Table>
        <Thead>
          <Tr>
            <Th className='info'>상품 정보</Th>
            <Th className='quantity'>수량</Th>
            <Th className='price'>금액</Th>
          </Tr>
        </Thead>
        <Tbody>
          {order.order_items.map(item => (
            <CartRow item={item} key={generateKey(`cart_tableRow_${item.id}`)} />
          ))}
        </Tbody>
      </Table>
    </FormSection>
  );
});
export default PurchaseTable;

const CartRow: React.FC<{ item: CartOrderItemModel }> = observer(({ item }) => {
  const { package_id, square_image_url, title, hasOption, option, items_count, price } = item;

  return (
    <Row>
      <Td className='info'>
        <ClearLink to={generatePath(PAGE_URL.PACKAGE, { id: package_id })}>
          <PackageImg src={square_image_url} alt='images' />
          <WrapCartInfo>
            <PackageTitleLink to={generatePath(PAGE_URL.PACKAGE, { id: package_id })}>
              {title}
            </PackageTitleLink>
            {hasOption ? <OptionName>{`[옵션] ${option}`}</OptionName> : null}
            {useTabletShow(
              <PriceText>
                {commaNumber(price)}원 / {items_count}개
              </PriceText>,
            )}
          </WrapCartInfo>
        </ClearLink>
      </Td>
      {useDesktopShow(
        <>
          <Td className='vtop'>{items_count}개</Td>
          <Td className='vtop'>{commaNumber(price)}원</Td>
        </>,
      )}
    </Row>
  );
});

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  ${({ theme }) => theme.desktop`
    margin-bottom: 16px;  
    border-top: 1px solid #262626;
  `}
`;

export const Row = styled(Tr)`
  ${({ theme }) => theme.tablet`
    padding: 0;
    border: 0;
  `}
  ${({ theme }) => theme.mobile`
    padding: 0;
    border: 0;
  `}
`;

export const PriceText = styled.div`
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.TextColor};
`;

export const OrderStatus = styled.div`
  min-width: 120px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};
`;
