import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { action, flow, observable } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { DetailMatchParams } from 'configs/path';
import { COMMENTES_TYPE, conv_commentable_type } from 'interfaces/Commetes';
import CommentsRepository from 'stores/repository/CommentsRepository';
import CommentsModel from 'stores/models/CommentsModel';

const createStore = (type: COMMENTES_TYPE) => {
  const store = observable(
    {
      isInit: false,
      type,
      id: 0,
      comments: {} as CommentsModel,
      fetch(id: string) {
        flow(function* (this: any) {
          try {
            this.id = id;

            const { data } = yield CommentsRepository.fetchType(this.type, +id);
            this.comments = new CommentsModel(data);

            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
      post(params: { body: string }) {
        flow(function* (this: any) {
          try {
            const { data } = yield CommentsRepository.post({
              commentable_type: conv_commentable_type(this.type),
              commentable_id: this.id,
              ...params,
            });

            this.comments.push(data.comment);
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
      delete(id: number) {
        flow(function* (this: any) {
          try {
            const { data } = yield CommentsRepository.delete(id);

            if (data.success) {
              const refresh = this.comments.delete(id);
              if (refresh) this.fetch(this.id);
            }
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
      more() {
        flow(function* (this: any) {
          try {
            const {
              id,
              type,
              comments: { comments },
            } = this;
            const { id: lastCommentId } = comments[comments.length - 1];
            const { data } = yield CommentsRepository.more(
              lastCommentId,
              conv_commentable_type(type),
              id,
            );
            this.comments.more(data);
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
    },
    {
      fetch: action,
    },
  );

  return store;
};
type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const CommentsProvider: React.FC<{ type: COMMENTES_TYPE }> = ({ type, children }) => {
  const store = useLocalStore(() => createStore(type));

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useCommentsStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitComments = () => {
  const { fetch } = useCommentsStore();
  const { id } = useRouteMatch<DetailMatchParams>().params;

  useEffect(() => {
    fetch(id);
  }, [fetch, id]);
};
