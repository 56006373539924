import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { useCouponStore } from 'stores/local/Coupon';
import { CouponCard } from 'components/atoms/CouponCard';

const CouponCards: React.FC<any> = observer(() => {
  const { coupons } = useCouponStore();

  return (
    <WrapCards>
      {coupons.map(item => (
        <CouponCard key={item.id} item={item} />
      ))}
    </WrapCards>
  );
});

export default CouponCards;

const WrapCards = styled.div`
  ${({ theme }) => theme.desktop`
    & > div {
      display: inline-block;
      vertical-align: top;
      margin-top: 30px;
    }

    & > div:nth-child(2n) {
      margin-left: 28px;
    }
  `};

  ${({ theme }) => theme.tablet`
    margin: 0 auto;
    max-width: 280px;
  `};

  ${({ theme }) => theme.mobile`
    padding: 0 20px;
    max-width: none;
  `};
`;
