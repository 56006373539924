import React from 'react';
import { observer } from 'mobx-react';
import { Element } from 'react-scroll';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { COMMENTES_TYPE } from 'interfaces/Commetes';

import { CommentsProvider, useCommentsStore, useInitComments } from 'stores/local/Comments';
import { CommentInput, CommentList } from 'components/molecules/Comment';
import { Box, H2, Wrapper } from './styled';
import { withCommas } from 'utils';

const Container: React.FC<any> = () => (
  <CommentsProvider type={COMMENTES_TYPE.PLAY}>
    <PlaysComments />
  </CommentsProvider>
);

const PlaysComments: React.FC<any> = observer(() => {
  const { play } = usePlayDetailStore();
  const { isInit } = useCommentsStore();

  useInitComments();

  if (!isInit) return null;

  return (
    <Element name='PlayComments'>
      <Box $withPad>
        <Wrapper>
          <H2>댓글 {withCommas(play.comments_count)}</H2>
          <CommentInput />
          <CommentList />
        </Wrapper>
      </Box>
    </Element>
  );
});

export default Container;
