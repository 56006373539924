import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { phoneFomatter } from 'utils';
import { Section, SectionTitle, Table, Tr, Th, Td, MobileTableWrapper } from './styled';

export default observer(function ShippingTable() {
  const {
    order: { name, phone_number, address1, address2, shipping_request },
  } = useStores();

  return (
    <Section>
      <SectionTitle>배송 정보</SectionTitle>
      <MobileTableWrapper>
        <Table>
          <tbody>
            <Tr>
              <Th>이름</Th>
              <Td>{name}</Td>
            </Tr>
            <Tr>
              <Th>휴대폰번호</Th>
              <Td>{phoneFomatter(phone_number)}</Td>
            </Tr>
            <Tr>
              <Th>주소</Th>
              <Td>{address1}</Td>
            </Tr>
            <Tr>
              <Th>상세주소</Th>
              <Td>{address2}</Td>
            </Tr>
            <Tr>
              <Th>배송 요청사항</Th>
              <Td>{shipping_request}</Td>
            </Tr>
          </tbody>
        </Table>
      </MobileTableWrapper>
    </Section>
  );
});
