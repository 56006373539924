import { observable } from 'mobx';

type searchWordsType = {
  [category: string]: {
    [age: string]: string[];
  };
};
export default class SearchKeywordModel {
  @observable searchWords: searchWordsType = {};

  constructor(props: SearchWords.RootObject) {
    props.search_words.forEach(({ category, age, words }) => {
      if (category in this.searchWords) {
        this.searchWords[category][`${age}`] = words;
      } else {
        this.searchWords[category] = {
          [`${age}`]: words,
        };
      }
    });
  }

  // TODO: 카테고리 임시로 패키지
  getKeywords(age: string, category: string = 'package') {
    return this.searchWords[category][age];
  }
}
