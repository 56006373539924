import React from 'react';
import { observer } from 'mobx-react';
import { MypageLayout } from 'layouts/Mypage';
import { CouponProvider } from 'stores/local/Coupon';
import { MODAL_CLASSIFIER } from './constants';

import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { Title, TitleBackButton, Wrapper } from 'components/atoms/Mypage';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import CouponForm from 'components/organisms/Mypage/Coupon/CouponForm';
import MessageModal from 'components/modals/MessageModal';
import { useHistory } from 'react-router-dom';

const PageCouponDownload: React.FC<any> = observer(({ pgMinHeight }) => {
  const history = useHistory();

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>쿠폰 등록하기</SubHeaderTitle>
      </SubHeader>
      <Wrapper $pgMinHeight={pgMinHeight}>
        <Title>
          쿠폰 등록하기
          {useDesktopShow(<TitleBackButton onClick={history.goBack}>뒤로</TitleBackButton>)}
        </Title>
        <CouponForm />
      </Wrapper>
    </>
  );
});

export default MypageLayout(PageCouponDownload, {
  Provider: CouponProvider,
  Option: { full: true },
  Modal: {
    [MODAL_CLASSIFIER.RESULT_MESSAGE]: MessageModal,
  },
});
