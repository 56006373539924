import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { commaNumber } from 'utils';
import { useStores } from 'stores';
import { FormSection, FormSubSection, H2, H2Help, Hr } from './styled';
import { BenefitPopup, DeliveryPopup } from 'components/molecules/Popup';
import { useFormContext, useWatch } from 'react-hook-form';

const PurchaseInfo: React.FC<any> = observer(() => {
  const { order } = useStores();

  return (
    <FormSection $noMargin>
      <FormSubSection>
        <H2>출고예정일</H2>
        <Hr />
        <Guide>
          지금 결제완료시 <Strong>{order.expected_delivery_start_date}</Strong> 택배출고예정{' '}
          <DeliveryPopup />
        </Guide>
      </FormSubSection>
      <FormSubSection>
        <H2>적립 예정 포인트</H2>
        <H2Help>택배 수령확인 후 포인트가 적립됩니다.</H2Help>
        <Hr />
        <PredictionPoint />
      </FormSubSection>
    </FormSection>
  );
});

const PredictionPoint: React.FC = () => {
  const { control } = useFormContext();
  const price = useWatch({
    control,
    name: 'order.price',
    defaultValue: 0,
  });
  const point =
    price >= 1000000 // 1,000,000 -> 70,000
      ? 70000
      : price >= 900000 // 900,000 -> 62,000
      ? 62000
      : price >= 700000 // 700,000 -> 48,000
      ? 48000
      : price >= 500000 // 500,000 -> 33,500
      ? 33500
      : price >= 300000 // 300,000 -> 20,000
      ? 20000
      : price >= 200000 // 200,000 -> 12,000
      ? 12000
      : price >= 150000 // 150,000 -> 7,500
      ? 7500
      : price >= 100000 // 100,000 -> 5,000
      ? 5000
      : price >= 70000 // 70,000 -> 3,000
      ? 3000
      : price >= 50000 // 50,000 -> 2,000
      ? 2000
      : price >= 30000 // 30,000 -> 600
      ? 600
      : 0;

  return (
    <Guide>
      <Strong>{commaNumber(point)}</Strong> 포인트 적립 예정 <BenefitPopup />
    </Guide>
  );
};

export default PurchaseInfo;

const Guide = styled.div`
  margin-top: 16px;
`;

const Strong = styled.strong`
  color: ${({ theme }) => theme.subActiveColor};
`;
