import axios from 'axios';
import { API_URL } from 'configs/path';

class SearchRepository {
  fetchKeyword(): Promise<any> {
    return axios.get(API_URL.SEARCH_KEYWORD);
  }
}

export default new SearchRepository();
