import React from 'react';
import styled from 'styled-components';

interface IProps {
  prefixKey: string;
  items: any[];
}

const BreadCrumbFrame = (props: IProps) => {
  const { prefixKey, items } = props;

  return (
    <WrapBreadCrumb>
      {items.map((item, idx) => (
        <li key={`${prefixKey}${idx}`}>{item}</li>
      ))}
    </WrapBreadCrumb>
  );
};

const WrapBreadCrumb = styled.ul`
  li {
    display: inline-block;
    vertical-align: text-top;
    list-style: none;

    &::after {
      content: '';
      display: inline-block;
      margin-left: 4px;
      margin-right: 8px;
      width: 8px;
      height: 8px;
      border-top: 1px solid #d5d5d5;
      border-right: 1px solid #d5d5d5;

      transform: rotate(45deg);
    }

    &:last-child::after {
      content: none;
    }
  }
`;

export default BreadCrumbFrame;
