import React from 'react';
import { observer } from 'mobx-react';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { Box, H2, Subscript } from './styled';
import StepContainer from 'components/molecules/PlaysDetail/StepContainer';

const MakingStep = observer(() => {
  const { play } = usePlayDetailStore();

  if (play.making_steps.length) {
    return (
      <Box $withPad>
        <H2>
          만들기 방법<Subscript>놀이 전, 준비해주세요</Subscript>
        </H2>
        <StepContainer store={play.making_steps} />
      </Box>
    );
  } else return null;
});

export default MakingStep;
