import React from 'react';
import styled from 'styled-components';

export default React.memo(function FailedBody() {
  return (
    <>
      <FailedImage />
      <AskKakaoDesc>
        결제 관련하여 문의가 있으시다면,
        <br />
        카카오톡 1:1 문의하기를 이용해주세요!
      </AskKakaoDesc>
    </>
  );
});

const FailedImage = styled.div`
  margin: 34px auto 24px;
  width: 181px;
  height: 109px;
  background-image: url('/images/purchase-failed.svg');
`;

const AskKakaoDesc = styled.div`
  font-size: 12px;
  text-align: center;
  line-height: 2;
`;
