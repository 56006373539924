import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { useRouteMatch } from 'react-router';
import { DetailMatchParams } from 'configs/path';
import styled from 'styled-components';
import {
  FormSection,
  H2,
  Hr,
  WrapInput,
  Input,
  Label,
  WrapTextArea,
  AddrArea,
  AddrButton,
  AddrInput,
} from './styled';
import { InputErr } from 'pages/Mypage/Info/styled';
import { useStores } from 'stores';
import { usePostModal } from 'components/modals/DaumPostcodeModal';
import { phoneFomatter } from 'utils';

const ShippingForm: React.FC<any> = observer(() => {
  const { auth, order: orderStore } = useStores();
  const { register, errors, setValue, getValues } = useFormContext();
  const { id } = useRouteMatch<DetailMatchParams>().params;
  const [DaumPostcode, handleAddress] = usePostModal(({ address, buildingName, zonecode }) => {
    setValue('order.address1', `${address} ${buildingName ? `(${buildingName})` : ''}`);
    orderStore.updateAddress1(+id, address, zonecode);
  });

  const resetData = useCallback(() => {
    setValue('order', {
      name: '',
      phone_number: '',
      address1: '',
      address2: '',
      shipping_request: '',
    });
  }, [setValue]);

  const handleSame = useCallback(
    ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => {
      if (checked) {
        const { user, order } = getValues();

        if (auth.isSignIn) {
          setValue('order', {
            ...order,
            name: user.fullname,
            phone_number: user.phone_number,
            address1: user.address1,
            address2: user.address2,
          });
          orderStore.updateAddress1(+id, auth.user.address1, auth.user.zonecode || '');
        } else {
          setValue('order', {
            ...order,
            name: order.orderer_name,
            phone_number: order.orderer_phone_number,
          });
        }
      } else {
        resetData();
      }
    },
    [auth.isSignIn, auth.user, getValues, id, orderStore, resetData, setValue],
  );

  const resetPhoneNumber = useCallback(
    (event: any) => {
      setValue('order.phone_number', phoneFomatter(event.target.value));
    },
    [setValue],
  );

  useEffect(() => {
    // https://github.com/havitplay/chaisplay-front/issues/14
    if (orderStore.isFetched) {
      const _order = orderStore.savedOrder?.order;

      setValue('order', {
        name: _order?.name,
        phone_number: _order?.phone_number,
        address1: _order?.address1,
        address2: _order?.address2,
        shipping_request: _order?.shipping_request,
      });
    }

    return () => resetData();
  }, [resetData, orderStore, orderStore.isFetched, setValue]);

  return (
    <>
      <FormSection>
        <H2>배송 정보</H2>
        <WrapSameButton>
          <input type='checkbox' id='chk-same' onChange={handleSame} />
          <label htmlFor='chk-same'>주문자 정보와 동일</label>
        </WrapSameButton>
        <Hr />
        <WrapInput>
          <Label>이름</Label>
          <Input
            name='order.name'
            type='text'
            ref={register({
              required: '이름을 입력해 주세요',
              minLength: {
                value: 2,
                message: '이름을 입력해 주세요 (최소 2글자 이상)',
              },
            })}
            placeholder='이름을 입력해 주세요'
          />
          {errors.order?.name && <InputErr>{errors.order?.name.message}</InputErr>}
        </WrapInput>
        <WrapInput>
          <Label>휴대폰번호</Label>
          <Input
            name='order.phone_number'
            type='text'
            ref={register({ required: '휴대폰 번호를 입력해주세요' })}
            placeholder='휴대폰 번호를 입력해주세요'
            onBlur={resetPhoneNumber}
            maxLength={15}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          {errors.order?.phone_number && <InputErr>{errors.order?.phone_number.message}</InputErr>}
        </WrapInput>
        <WrapInput>
          <Label>주소</Label>
          <WrapTextArea>
            <AddrArea
              name='order.address1'
              ref={register({ required: '주소를 검색해주세요' })}
              placeholder='주소를 검색해주세요'
              readOnly
            />
          </WrapTextArea>
          <AddrButton type='button' onClick={handleAddress}>
            검색
          </AddrButton>
          {errors.order?.address1 && <InputErr>{errors.order?.address1.message}</InputErr>}
        </WrapInput>
        <WrapInput>
          <Label>상세주소</Label>
          <AddrInput
            name='order.address2'
            type='text'
            ref={register}
            placeholder='상세주소를 입력해 주세요'
          />
          {errors.address2 && <InputErr>{errors.address2.message}</InputErr>}
        </WrapInput>
        <WrapInput>
          <Label>배송요청사항</Label>
          <AddrInput
            name='order.shipping_request'
            type='text'
            ref={register}
            placeholder='예시) 경비실에 맡겨주세요'
          />
        </WrapInput>
      </FormSection>
      <DaumPostcode />
    </>
  );
});
export default ShippingForm;

const WrapSameButton = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 16px;
  padding-right: 22px;
  font-size: 12px;
  font-weight: normal;

  input {
    position: absolute;
    right: 0;
  }
`;
