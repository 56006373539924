import React from 'react';
import styled from 'styled-components';

import StepItem from './StepItem';
import { generateKey } from 'utils';

const StepContainer: React.FC<{ store: any[] }> = ({ store }) => {
  return (
    <ContainerWrap>
      <WrapList $len={store.length}>
        {store.map((result: any, index: number) => {
          if (index && index % 4 === 3 && store.length > 4) {
            return (
              <React.Fragment key={generateKey()}>
                <StepItem {...result} count={index + 1} />
                <Break />
              </React.Fragment>
            );
          } else {
            return <StepItem key={generateKey()} {...result} count={index + 1} />;
          }
        })}
      </WrapList>
    </ContainerWrap>
  );
};

export default StepContainer;

const ContainerWrap = styled.div`
  margin-bottom: 70px;

  ${({ theme }) => theme.tablet`
    margin-bottom: 40px;
  `}
`;

const Break = styled.hr`
  flex-basis: 100%;
  margin: 56px 0 0;
  height: 0;
  border: 1px solid #eaeaea;

  ${({ theme }) => theme.tablet`
    margin-top: 30px!important;

    & + div {
      margin-left: 0!important;
    }
  `}

  ${({ theme }) => theme.mobile`
    display: none;
  `}
`;

const WrapList = styled.div<{ $len: number }>`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.desktop`
    & > div {
      width: calc(25% - 22.5px);
      margin-left: 30px;
    }

    & > div:nth-of-type(4n + 1) {
      margin-left: 0;
    }

    & > div:nth-of-type(n + 5) {
      margin-top: 16px;
    }

    & hr+div {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.tablet`
    & > * {
      width: calc(25% - 15px);
      margin-left: 20px;
    }

    & > *:nth-of-type(4n + 1) {
      margin-left: 0;
    }

    & > *:nth-of-type(n - 5) {
      margin-top: 0;
    }

    & > *:nth-of-type(n + 5) {
      margin-top: 10px;
    }
  `}

  ${({ theme }) => theme.mobile`
    & > * {
      width: calc(50% - 5px);
      margin-left: 10px;
    }

    & > *:nth-of-type(2n + 1) {
      margin-left: 0;
    }

    & > *:nth-of-type(n - 3) {
      margin-top: 0;
    }

    & > *:nth-of-type(n + 3) {
      margin-top: 10px;
    }
  `}
`;
