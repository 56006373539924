import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import 'react-dropdown/style.css';
import 'fontsource-noto-sans-kr';

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html,
  body {
    margin: 0;
    font-family: "Noto Sans KR";
    line-height: 1;

    &.no-scroll {
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    color: ${({ theme }) => theme.TextColor};

  &::-webkit-scrollbar {
    position: absolute;
    width: 13px;
  }
  &::-webkit-scrollbar-thumb {
    border-width: 4px 5px;
    border-style: solid;
    border-color: transparent;
    background-clip: padding-box;
    background-color: #b2b2b2;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-button { display: none; }
 }

 path {
   color: inherit;
 }

  ul {
    margin: 0;
    padding: 0;
  }

  .swiper-pagination-bullet {
    background: #000;
    opacity: 0.3;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  @media (min-width: 1280px) {
    .swiper-pagination-bullet {
      margin: 0 7px!important;
      width: 10px;
      height: 10px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 32px;
    }
  }

  @media (max-width: 1280px) {
    .swiper-pagination-bullet {
      margin: 0 5px!important;
      width: 6px;
      height: 6px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 16px;
    }
  }

  @media (max-width: 760px) {
    .swiper-pagination-bullet {
      margin: 0 4px!important;
      width: 6px;
      height: 6px;
    }

    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 12px;
    }
  }

  /* DropDown  */
  .Dropdown-control:hover {
    box-shadow: none;
  }

  .Dropdown-menu {
    left: 0;
    max-height: none;
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  }

  .Dropdown-option {
    line-height: 1.5;
    color: #262626;

    &:hover {
      background-color: #fff;
      color: ${({ theme }) => theme.subActiveColor};
    }

    &.is-selected {
      display: none;
    }
  }

  /* Page Transition */
  .page-fade-appear,
  .page-fade-enter {
    opacity: 0;
  }
  .page-fade-enter-done {
    opacity: 1;
    transition: opacity 200ms;
  }
`;
