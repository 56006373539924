import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores';
import { Section, SectionTitle, Hr, Flex, FlexBox } from './styled';
import { ATag } from 'components/atoms';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

export default observer(function Receipt() {
  const {
    order: { receipt_url, trading_statement_url, cash_receipt_url, status },
  } = useStores();

  const DesktopHr = useDesktopShow(<Hr />);

  return status === 'success' || status === 'completed' ? (
    <Section>
      <SectionTitle>결제 영수증</SectionTitle>
      {DesktopHr}
      <Flex>
        <FlexBox>
          구매전표 확인하기
          <ReceiptLink href={receipt_url} target='_blank'>
            구매영수증
          </ReceiptLink>
        </FlexBox>
        <FlexBox>
          {trading_statement_url ? (
            <>
              거래명세서 확인하기
              <ReceiptLink href={trading_statement_url} target='_blank'>
                거래명세서
              </ReceiptLink>
            </>
          ) : null}
        </FlexBox>
        <FlexBox>
          {cash_receipt_url ? (
            <>
              현금영수증 확인하기
              <ReceiptLink href={cash_receipt_url} target='_blank'>
                현금영수증
              </ReceiptLink>
            </>
          ) : null}
        </FlexBox>
      </Flex>
    </Section>
  ) : null;
});

const ReceiptLink = styled(ATag)`
  display: inline-block;
  margin: 16px 0 16px 16px;
  width: 106px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  color: #fff;
  ${({ theme }) => theme.tablet`
    width: 90px;
    height: 30px;
    line-height: 28px;
    margin: 5px 10px;
    background-color: inherit;
    border: 1px solid ${theme.subActiveColor};
    color: ${theme.subActiveColor};
  `}
`;
