import React from 'react';
import { useStores } from 'stores';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

SwiperCore.use([Pagination, Autoplay]);

const HomeBannerSlider: React.FC = observer(() => {
  const { home } = useStores();
  const options: Swiper = {
    autoplay: true,
    pagination: { clickable: true },
  };
  if (home.banners.length === 1) delete options.pagination;

  return home.banners.length ? (
    <Swiper {...options}>
      {home.banners.map((item, index: number) => (
        <SwiperSlide key={`__homeAdItem_${index}`}>
          <SlideWrap href={item.href}>
            <ImgWrap>
              <BannerImg src={item.src} heightPercent={(100 / 16) * 9} />
            </ImgWrap>
          </SlideWrap>
        </SwiperSlide>
      ))}
    </Swiper>
  ) : null;
});

const SlideWrap = styled.a`
  display: block;
  max-height: 497px;
  overflow: hidden;

  ${({ theme }) => theme.tablet`
    max-height: 305px;
  `}
`;

const ImgWrap = styled.div`
  padding-bottom: ${() => (100 / 16) * 9}%;

  ${({ theme }) => theme.tablet`
    padding-bottom: 0;
    height: 305px;
  `}

  ${({ theme }) => theme.mobile`
    padding-bottom: ${() => (100 / 16) * 9}%;
    height: 0;
  `}
`;

interface BannerImgProps {
  src: string;
  heightPercent: number;
}

export const BannerImg = styled.div<BannerImgProps>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width: 100%;
  height: auto;
  padding-bottom: ${({ heightPercent }) => heightPercent}%;

  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto;

  @media screen and (min-width: 1920px) {
    background-size: contain;
  }

  ${({ theme }) => theme.tablet`
    background-size: cover;
    padding-bottom: 306px;
  `}

  ${({ theme, heightPercent }) => theme.mobile`
    padding-bottom: ${heightPercent}%;
  `}
`;

export default HomeBannerSlider;
