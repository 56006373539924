import React from 'react';
import { AccordionItemButton, AccordionItemState } from 'react-accessible-accordion';
import {
  Item,
  Heading,
  CloseButton,
  OpenButton,
  ItemPanel,
} from 'components/atoms/AccordionStyled';

const ReturnGuideAccordion = () => (
  <Item>
    <Heading>
      <AccordionItemButton>
        반품/교환 안내
        <AccordionItemState>
          {({ expanded }) => (expanded ? <CloseButton /> : <OpenButton />)}
        </AccordionItemState>
      </AccordionItemButton>
    </Heading>
    <ItemPanel>
      <dl>
        <div>
          <dt>차이의 놀이 반품/교환 안내</dt>
          <dd>
            <p>- 카톡 ‘해빛’ 채널을 통한 상담 후 저희 쪽에서 반품 신청해 드리고 있습니다.</p>
            <p>- 판매자 지정 택배사 : CJ 대한통운</p>
            <p>
              - 반품배송비 : 편도 3,500원 (최초 배송비 무료인 경우 7,000원 부과) / 제품의 사이즈가
              가로 50, 세로 30을 넘는 경우, 편도 4,000원 (최초 배송비 무료인 경우 8,000원 부과) /
              도서 산간 지역 3,000~5,000원 왕복 추가 과금
            </p>
            <p>
              - 교환배송비 : 7,000원 (제품의 사이즈가 가로 50, 세로 30을 넘는 경우, 8,000원 부과)
            </p>
            <p>- 보내실 곳 : (우: 12269) 경기도 남양주시 와부읍 궁촌로 78, 1동 해빛/차이의 놀이</p>
          </dd>
        </div>
        <div>
          <dt>반품 교환 사유에 따른 요청 가능 기간</dt>
          <dd>
            <p>- 카톡 ‘해빛’ 채널을 통한 상담 후 저희 쪽에서 반품 신청해 드리고 있습니다.</p>
            <p>- 구매자 단순변심 : 상품 수령 후 7일 이내 (구매자 반품배송비 부담)</p>
            <p>
              - 표시/광고와 상이, 상품하자 : 상품 수령 후 3개월 이내 혹은 표시/광고와 다른 사실을 안
              날로부터 30일 이내 (판매자 반품배송비 부담) 둘 중 하나 경과 시 반품/교환 불가
            </p>
          </dd>
        </div>
        <div>
          <dt>반품/교환 불가능 사유</dt>
          <dd>
            <p>1. 반품요청기간이 지난 경우</p>
            <p>
              2. 구매자의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우 (단, 상품의 내용을
              확인하기 위하여 포장 등을 훼손한 경우는 제외)
            </p>
            <p>
              3. 포장을 개봉하였으나 포장이 훼손되어 상품가치가 현저히 상실된 경우 (예, 식품 등)
            </p>
            <p>4. 구매자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우</p>
            <p>
              5. 시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우
            </p>
            <p>
              6. 고객주문 확인 후 상품제작에 들어가는 주문제작상품 (청약철회권 행사가 불가하다는
              사실을 서면 동의 받은 경우)
            </p>
          </dd>
        </div>
      </dl>
    </ItemPanel>
  </Item>
);

export default ReturnGuideAccordion;
