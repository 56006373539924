import { extendObservable } from 'mobx';

export default class CommentsModel implements Commenet.RootObject {
  constructor(props: Commenet.RootObject) {
    extendObservable(this, { ...props });
  }

  push(data: Commenet.Commenet) {
    this.comments.unshift(data);
  }

  delete(id: number) {
    // 추가 댓글이 있는 경우 새로고침
    if (!this.show_load_more) this.comments = this.comments.filter(item => item.id !== id);
    return this.show_load_more;
  }

  more({ comments, show_load_more }: Commenet.RootObject) {
    this.comments = [...this.comments, ...comments];
    this.show_load_more = show_load_more;
  }

  comments: Commenet.Commenet[] = [] as Commenet.Commenet[];
  show_load_more: boolean = false;
}
