export const PAGE_URL = {
  ERROR: '/error',
  HOME: '/',

  // Users
  SIGNIN: '/users/signin',
  NONUSER_LOOKUP: '/nonuser/lookup',
  SIGNUP: '/users/signup',
  RESETPASSWD: '/users/password/reset',

  // Policy
  POLICY: '/policy/:type',
  POLICY_SERVICE: '/policy/service',
  POLICY_PRIVACY: '/policy/privacy',

  PACKAGES_BEST: '/packages/best',
  PACKAGES_MAIN: '/packages/:main',
  PACKAGES_SUB: '/packages/:main/:sub',
  PACKAGE: '/packages/detail/:id',
  PACKAGE_SEARCH: '/packages',
  PACKAGE_GROWTHKIT: '/packages/growth/growthkit', // 성장놀이터
  PACKAGE_WORKBOOK: '/packages/playbook/workbook', // 워크북

  PLAYS: '/plays',
  PLAY: '/plays/:id',

  EVENTS: '/event',
  EVENT: '/event/:id',

  MYPAGE: '/mypage',
  // 마이페이지 > 구매정보
  PURCHASE_LIST: '/mypage/purchase',
  USER_PURCHASE_DETAIL: '/mypage/purchase/:id',
  POINT_HISTORY: '/mypage/point',
  COUPON_LIST: '/mypage/coupon',
  COUPON_DOWNLOAD: '/mypage/coupon/download',
  COIN_HISTORY: '/mypage/coin',
  // 마이페이지 > 회원정보
  INFO_ME: '/mypage/info/me',
  CHANGE_PASSWD: '/users/password/change',
  INFO_CHILD: '/mypage/info/child',
  MYCOMMENTS: '/mypage/comments',
  DEACTIVEATE: '/users/deactivate',
  // 마이페이지 > 고객지원
  FAQ: '/cs/faq',

  // 구매
  CART: '/cart',
  PURCHASE: '/purchase/:id',
  COMPLETE: '/purchase/:id/complete',
  NON_USER_PURCHASE_DETAIL: '/purchase/:id/detail',

  // 기관구매 안내
  COMPANY_PURCHASE: '/event/129',
};

export const API_URL = {
  // Layout
  LAYOUT_CATEGORIES: '/packages/categories?category=all',

  // Home
  HOME_BANNERS: '/packages/banners',
  HOME_RECOMMEND: '/recommended_packages?target=web',
  HOME_BEST: '/packages/landing?best=true&per_page=25',
  HOME_NEW: '/packages/landing?sort=by_release_date&per_page=50',
  HOME_BY_TRANSACTION: '/packages/landing?sort=by_transaction&per_page=25',
  HOME_BY_POPULARITY: '/packages/landing?sort=by_popularity&per_page=25',
  HOME_BY_INSTAGRAM: '/packages/landing?sort=by_instagram&per_page=25',

  // Best
  BEST_DEFAULT: '/packages/landing?per_page=25',

  // Search
  SEARCH_KEYWORD: '/search_words',
  SEARCH_PACKAGES: '/search/packages',

  // Auth
  AUTH_SIGNUP: '/registrations',
  AUTH_SIGNIN: '/sessions',
  AUTH_USER: '/users', // GET,PUT
  AUTH_SEND_CODE: '/users/send_sms_code', // POST
  AUTH_CHECK_CODE: '/users/confirm_sms_code', // POST
  AUTH_DEACTIATE: '/users/:id', // DELETE
  AUTH_KIDS: '/kids/update_kids', // PUT
  AUTH_CHANGE_PASSWD: '/users/update_password', // PUT
  AUTH_RESET_PASSWD: '/users/send_reset_password_instructions', // POST

  PACKAGES: '/packages/landing', // Packages List
  PACKAGE: '/packages/:id/?target=web', // Package Item
  PLAYS: '/plays', // Plays List (활동지 다운로드)
  PLAY: '/plays/:id', // Play item

  EVENTS: '/events',
  EVENT: '/events/:id',

  COMMENTES: '/:type/:id/comments',
  COMMENTES_POST: '/comments',
  COMMENTES_DELETE: '/comments/:id',
  COMMENTES_MORE: '/comments/:lastCommentId/load_more', // GET
  MY_COMMENTS: '/my_pages/comments', // GET

  CART: '/cart', // GET
  CART_ADD: '/order_items', // POST
  CART_LOCAL: '/order_items/add_items_to_cart', // POST
  CART_EDIT: '/order_items/:id', // PUT,
  CART_ADD_ALL: '/orders/:id/add_to_cart', // POST

  ORDER: '/orders/:id', // GET, DELETE
  ORDER_NON_MEMBER: '/orders/check_for_non_members', //POST
  ORDER_VALIDATE: '/orders/:id/validate_order', // POST
  ORDER_VALIDATE_NON_MEMBER: '/orders/:id/validate_order_for_non_members', // POST
  ORDER_COMPLETE: '/orders/:id/completed', // POST
  ORDER_HISTORY: '/my_pages/orders', // GET
  DELIVERY_COMPLETE: '/orders/:id/delivery_completed', // PUT
  ORDER_ADDRESS1: '/api/orders/:id/update_address1', // POST

  COIN: '/coin_histories', // GET
  POINT: '/my_pages/points', // GET
  COUPON: '/my_coupons', // GET, POST

  SHIPPING_PRICES: 'shipping_prices', // GET
};

export interface PackagesMatchParams {
  main: string;
  sub?: string;
}
export interface DetailMatchParams {
  id: string;
}

export interface IdMatchParams {
  id: string;
}
