import React from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'stores';
import * as Styled from '../styled';
import { MemberForm } from './MemberForm';
import { NonMemberForm } from './NonMemberForm';

const UserForm: React.FC<any> = observer(() => {
  const { auth } = useStores();

  return (
    <Styled.FormSection>
      <Styled.H2>{auth.isSignIn ? '회원정보' : '주문자 정보'}</Styled.H2>
      <Styled.Hr />
      {auth.isSignIn ? <MemberForm /> : <NonMemberForm />}
    </Styled.FormSection>
  );
});

export default UserForm;
