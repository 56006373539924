import React, { useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { MypageLayout } from 'layouts/Mypage';
import { useHistory } from 'react-router-dom';

import { Wrapper, Title } from 'components/atoms/Mypage';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { useStores } from 'stores';
import { OrderTableHead, OrderTableBody } from 'components/molecules/Mypage/OrderHistory';
import Pagenation from 'components/molecules/Pagenation';
import { DISPLAY } from 'interfaces/layout';
import Banner from '../Complete/components/Banner';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const PurchaseList: React.FC<any> = observer(({ pgMinHeight }) => {
  const { order, layout, setHistory } = useStores();
  const history = useHistory();

  const onClickPage = useCallback((page: number) => () => order.setFilter({ page }), [order]);

  useEffect(() => {
    setHistory(history);

    async function purchaseHistory() {
      order.fetchFilter(document.location.search);
      await order.history();
    }
    purchaseHistory();
  }, [history, order, setHistory, history.location]);

  return (
    <>
      {useDesktopShow(
        <BannerWrapper>
          <Banner />
        </BannerWrapper>,
      )}
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>주문배송 조회</SubHeaderTitle>
      </SubHeader>
      <PurchaseListWrapper>
        <Title>주문배송 조회</Title>
        <Table>
          <OrderTableHead />
          <OrderTableBody />
        </Table>
        <Pagenation
          curPage={order.filter.page}
          pageInfo={order.pagenation}
          pageUnit={layout.displayType === DISPLAY.DESKTOP ? 10 : 5}
          onClickPage={onClickPage}
        />
      </PurchaseListWrapper>
    </>
  );
});

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  ${({ theme }) => theme.desktop`
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 2px solid #262626;
  `}
`;

export const BannerWrapper = styled.div`
  margin: -49px 0 0 37px;
`;
export const PurchaseListWrapper = styled(Wrapper)`
  margin-bottom: 70px;
`;

export default MypageLayout(PurchaseList);
