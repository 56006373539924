import React from 'react';
import { DefaultLayout } from 'layouts/Default';
import { MypageLayout } from 'layouts/Mypage';
import { WrapPage } from 'components/atoms/PurchasePage';
import { PurchaseDetail } from './PurchaseDetail';

export const UserPurchaseDetail: React.FC<any> = MypageLayout(PurchaseDetail);
export const NonUserPurchaseDetail: React.FC<any> = DefaultLayout(
  React.memo(() => (
    <WrapPage>
      <PurchaseDetail />
    </WrapPage>
  )),
);
