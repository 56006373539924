import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { commaNumber } from 'utils';
import { usePackageDetailStore } from 'pages/PackageDetail/store';

export const TotalPriceContainer: React.FC<any> = observer(() => {
  const { totalPrice } = usePackageDetailStore();

  return (
    <WrapTotalPrice>
      <PriceLabel>총 금액</PriceLabel>
      <TotalPrice>{commaNumber(totalPrice)}</TotalPrice>
    </WrapTotalPrice>
  );
});

const WrapTotalPrice = styled.div`
  float: right;
  vertical-align: top;
  padding: 13px 0;
  line-height: 1;

  ${({ theme }) => theme.tablet`
    float: left;
    padding: 0;
  `}
`;

const PriceLabel = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-top: 7px;
  margin-left: 24px;

  ${({ theme }) => theme.tablet`
    display: none;
  `}
`;
const TotalPrice = styled.div`
  font-weight: bold;

  &:after {
    content: '원';
    font-size: 22px;
  }

  ${({ theme }) => theme.desktop`
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;
    font-size: 27px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 16px;
    font-size: 14px;
    color: ${theme.mainActive};

    &:after {
      font-size: 14px;
    }
  `}
`;
