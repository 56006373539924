import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { BgImg, ClearLink } from 'components/atoms';
import { PAGE_URL } from 'configs/path';

const PackageItem: React.FC<Home.Package> = React.memo(
  ({ id, tag, img, age, name, discount, price, origin, txtCenter }) => (
    <ItemWrap to={generatePath(PAGE_URL.PACKAGE, { id })} $txtCenter={txtCenter}>
      <ImgWrap>
        {tag ? <TagBest>{tag}</TagBest> : null}
        <BgImg $src={img} $heightPercent={100} />
      </ImgWrap>
      <ContentWrap>
        <Age>{age}</Age>
        <Name>{name}</Name>
        <OriginPrice>{origin}</OriginPrice>
        <DiscountPercent>{discount}</DiscountPercent>
        <Price>{price}</Price>
      </ContentWrap>
    </ItemWrap>
  ),
);

export default PackageItem;

const ItemWrap = styled(ClearLink)<{ $txtCenter?: boolean }>`
  display: block;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  line-height: 1.5;
  text-align: ${({ $txtCenter }) => ($txtCenter ? 'center' : 'left')};
  text-decoration: none;
`;
const ImgWrap = styled.div`
  position: relative;
  padding-bottom: 100%;
  width: 100%;
  height: 0;
`;
const Tag = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1;
  padding: 8px 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.tablet`
    padding: 3px 7px;
    font-size: 8px;
  `}
`;
const TagBest = styled(Tag)`
  background-color: ${({ theme }) => theme.mainActive};
`;
const ContentWrap = styled.div`
  padding: 16px;
  border-top: 1px solid #e6e6e6;
  overflow: hidden;
  text-align: center;

  ${({ theme }) => theme.tablet`
    padding: 11px 11px 12px;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px 10px 12px;
  `}
`;
const Age = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: #262626;

  ${({ theme }) => theme.tablet`
    font-size: 10px;
  `}
`;
const Name = styled.div`
  margin: 8px 0 16px;
  height: 48px;
  color: #262626;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.tablet`
    margin: 4px 0 10px;
    font-size: 12px;
    height: 36px;
  `}

  ${({ theme }) => theme.mobile`
    margin: 6px 0;
  `}
`;

const OriginPrice = styled.div`
  display: inline;
  margin-right: 8px;
  color: #b3b3b3;
  text-decoration: line-through;

  ${({ theme }) => theme.tablet`
    margin-top: 4px;
    margin-right: 3px;
    font-size: 10px;
  `}
`;

const DiscountPercent = styled.div`
  display: inline;
  font-weight: bold;
  color: #4696d7;

  ${({ theme }) => theme.tablet`
    display: none;
  `}
`;

const Price = styled.div`
  margin-top: 4px;
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.tablet`
    display: inline;
    margin-top: 0;
    font-size: 14px;
  `}
`;
