import React from 'react';
import { ModalProps } from 'interfaces/Modal';
import { CouponProvider } from 'stores/local/Coupon';
import { CouponeModalContainer } from './CouponeModal';

const CouponeModal = React.memo((props: ModalProps) => {
  return (
    <CouponProvider>
      <CouponeModalContainer {...props} />
    </CouponProvider>
  );
});

export default CouponeModal;
