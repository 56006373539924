import React from 'react';
import styled from 'styled-components';
import { Clear } from 'components/atoms/Clear';

const StepItem: React.FC<any> = props => {
  const { count, content, image_url, tip, link_url } = props;

  return (
    <ItemWrap>
      <Number>{count}</Number>
      {image_url ? (
        <>
          <a href={link_url} target='_blank'>
            <Img src={image_url} alt={`${count} image`} />
          </a>
          <Clear />
        </>
      ) : null}
      <Content>{content}</Content>
      <Tip>{tip}</Tip>
    </ItemWrap>
  );
};

export default StepItem;

const ItemWrap = styled.div`
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    font-size: 12px;
  `}
`;

const Number = styled.div`
  font-weight: bold;
`;

const Img = styled.img`
  float: left;
  margin-top: 8px;
  width: 100%;
`;

const Content = styled.div`
  margin-top: 16px;
  line-height: 1.8;

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
  `}
`;

const Tip = styled.div`
  margin-top: 16px;
  line-height: 1.8;
  color: #b3b3b3;
`;
