import React from 'react';
import styled from 'styled-components';

import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { Wrapper } from 'components/atoms';

export const WrapList = styled(Wrapper)`
  ${({ theme }) => theme.desktop`
    padding: 36px 0 100px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 0 0 40px;
  `}

  ${({ theme }) => theme.mobile`
    padding: 0 0 30px;
  `}
`;

export const WrapListItem = styled.div`
  ${({ theme }) => theme.tablet`
    padding: 0 20px;
    border-top: 10px solid #f2f2f2;
  `}

  ${({ theme }) => theme.mobile`
    padding: 0 10px;
  `}
`;

const H1 = styled.h1`
  margin-top: 36px;
  padding-bottom: 16px;
  border-bottom: 2px solid #000;

  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
`;

export const Title: React.FC<any> = React.memo(props => useDesktopShow(<H1>{props.children}</H1>));

export const SearchInfo = styled.div`
  margin-bottom: 32px;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin: 15px 0;
    font-size: 12px;
  `}

  ${({ theme }) => theme.mobile`
    margin: 12px 0;
  `}
`;

export const WrapEmptyList = styled(Wrapper)`
  line-height: 1.5;
  text-align: center;

  strong {
    display: block;
  }

  ${({ theme }) => theme.desktop`
    margin: 40px auto 250px;

    strong {
      margin: 24px 0 8px;
      line-height: 1;
      font-size: 24px;
    }
  `}

  ${({ theme }) => theme.tablet`
    font-size: 12px;

    strong {
      margin: 12px 0 3px;
    }
  `}

  ${({ theme }) => theme.mobile`
    padding: 0 0 30px;
  `}
`;

export const EmptyImage = styled.img.attrs({
  src: '/images/empty.svg',
  alt: '검색 빈몬록 이미지',
})`
  ${({ theme }) => theme.tablet`
    margin-top: 12px;
    width: 142px;
    height: 126px;
  `}
`;
