import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { action, flow, observable } from 'mobx';
import { useLocalStore } from 'mobx-react';
import qs from 'query-string';
import { Location, History } from 'history';

import PackagesRepository from '../repository/PackagesRepository';
import PackageModel, { ResPackage } from 'stores/models/PackageModel';
import { parseLinkHeader } from 'utils';

interface PackagesFilter {
  q: string;
  age: string;
  page: number;
  per_page: number;
}

interface storeProps {
  history: History;
  location: Location;
}

const createStore = ({ location, history }: storeProps) => {
  const store = observable(
    {
      isInit: false,
      history,
      location,
      keyword: '',
      totalPackages: 0,
      filter: {
        q: '',
        age: '-1',
        page: 1,
        per_page: 20,
      } as PackagesFilter,
      pagenation: {},
      packages: [] as PackageModel[],

      fetch() {
        flow(function* (this: any) {
          try {
            this.isInit = false;

            const query = qs.stringify(this.filter);
            const { data, headers } = yield PackagesRepository.fetchSearch(query);

            this.totalPackages = data.count;
            this.packages = data.packages.map((item: ResPackage) => new PackageModel(item));
            this.pagenation = parseLinkHeader(headers.link);
            this.isInit = true;
          } catch (error) {
            this.totalPackages = 0;
            this.isInit = true;
          }
        }).bind(this)();
      },
      setFilter(query: any) {
        const newQuery = { ...qs.parse(this.location.search), ...query };

        this.history.push({
          pathname: this.location.pathname,
          search: qs.stringify(newQuery),
        });
      },
      setProps({ history, location }: storeProps) {
        this.history = history;
        this.location = location;

        const { q, page, ...other } = qs.parse(location.search);
        this.keyword = (q as string) || '';
        this.filter = {
          ...this.filter,
          q: this.keyword,
          page: parseInt(page as string) || 1,
          ...other,
        };
      },
    },
    {
      fetch: action,
    },
  );

  return store;
};

type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const SearchPackagesProvider = ({ children }: any) => {
  const location = useLocation();
  const history = useHistory();
  const store = useLocalStore(() => createStore({ location, history }));

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useSearchPackagesStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitSearchPackages = () => {
  const location = useLocation();
  const history = useHistory();
  const { fetch, setProps } = useSearchPackagesStore();

  useEffect(() => {
    setProps({ location, history });
    fetch();
  }, [fetch, history, location, setProps]);
};
