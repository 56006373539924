import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { BottomBar, BarRightLink, RightLinkWrap } from '../molecules/BottomBar';
import { Wrapper } from '../atoms';
import { DISPLAY } from '../../interfaces/layout';

import IconButton from '../atoms/IconButton';
import { useStores } from 'stores';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { PAGE_URL } from 'configs/path';

const Footer: React.FC<any> = observer(() => {
  const { layout } = useStores();

  return (
    <FooterWrap ref={layout.footerRef}>
      <Wrapper>
        <BottomBar type={layout.displayType} />
      </Wrapper>
      {useDesktopShow(<Hr />)}
      <InfoWrap>
        {layout.displayType !== DISPLAY.DESKTOP ? (
          <ul>
            <InfoLinkWrap>
              <InfoLink to={PAGE_URL.POLICY_PRIVACY}>개인정보처리방침</InfoLink>
            </InfoLinkWrap>
            <InfoLinkWrap>
              <InfoLink to={PAGE_URL.POLICY_SERVICE}>서비스 이용약관</InfoLink>
            </InfoLinkWrap>
          </ul>
        ) : null}
        <CompanyInfo>
          (주)해빛 | 대표: 서숙연 | 사업자번호: 425-81-00173
          <br />
          본사 주소: 서울특별시 중구 회현동 2 위워크 서울스퀘어 13층
          <br />
          물류센터 주소: 경기도 남양주시 와부읍 궁촌로 78, 1동
          <br />
          연락처: 070-5138-0722
          <br />
          통신판매업 신고번호: 2023-와부조안-0007
        </CompanyInfo>
        <Copywriter>Copyright HAVIT. Inc. All Rights Reserved.</Copywriter>

        <IconWrapper>
          <IconButton
            IconWrap={IconWrap}
            src={'/icons/naver.png'}
            href='https://post.naver.com/my.nhn?memberNo=22348821'
            isTargetBlank={true}
          />
          <IconButton
            IconWrap={IconWrap}
            src={'/icons/insta.png'}
            href='https://www.instagram.com/havitplay'
            isTargetBlank={true}
          />
        </IconWrapper>
      </InfoWrap>
    </FooterWrap>
  );
});

export default Footer;

const FooterWrap = styled.footer`
  background-color: #fff;

  ${({ theme }) => theme.desktop`
    border-top: 1px solid #cbcbcb;
  `}

  ${({ theme }) => theme.tablet`
    border-top: 10px solid #f8f8f8;
  `}
`;

const Hr = styled.hr`
  margin: 0;
  border: 0;
  border-bottom: 1px solid #cbcbcb;
`;

const InfoLinkWrap = styled(RightLinkWrap)`
  float: none;
  display: inline-block;

  ${({ theme }) => theme.tablet`
    margin: 0 0 10px;
    padding: 0 6px;

    font-size: 10px;
  `}

  &:first-child {
    padding-left: 0;

    &:after {
      content: none;
    }
  }
`;
const InfoLink = styled(BarRightLink)`
  line-height: 15px;

  ${({ theme }) => theme.tablet`
    margin: 0;
    padding: 0;
    font-weight: bold;
  `}
`;

const InfoWrap = styled(Wrapper)`
  position: relative;
  padding: 17px 0 24px;

  ${({ theme }) => theme.tablet`
    margin-top: 32px;
    padding: 20px 0 30px;
    text-align: center;
  `}
`;
const CompanyInfo = styled.div`
  font-size: 12px;
  line-height: 2;
  color: #262626;

  ${({ theme }) => theme.tablet`
    font-size: 10px;
    line-height: 1.6;
  `}
`;
const Copywriter = styled.div`
  margin-top: 16px;
  font-size: 11px;
  line-height: 1.82;
  color: #262626;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
  `}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 17px;
  right: 0;

  ${({ theme }) => theme.tablet`
    top: -32px;
    left: 0;
    right: 0;
  `}
`;
const IconWrap = styled.a`
  display: inline-block;
  margin-left: 16px;

  ${({ theme }) => theme.tablet`
    margin: 0 5px;
    width: 32px;
    height: 32px;
  `}
`;
