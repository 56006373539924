import styled, { css } from 'styled-components';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { DownButton, UpButton } from '.';
import { FiMinus, FiPlus } from 'react-icons/fi';

export const Item = styled(AccordionItem)`
  ${({ theme }) => theme.tablet`
    margin-left: -20px;
    width: calc(100% + 40px);
    border-width: 1px 0 1px 0;
  `}
`;

export const Heading = styled(AccordionItemHeading)`
  position: relative;
  padding: 24px 0 16px 4px;
  border-bottom: solid 2px #262626;
  font-size: 24px;
  font-weight: bold;

  > div {
    outline: none;
  }

  ${({ theme }) => theme.tablet`
    margin: 0 20px;
    padding: 15px 0;
    font-size: 16px;
  `}
`;

export const ItemPanel = styled(AccordionItemPanel)`
  ${({ theme }) => theme.tablet`
    padding: 15px 20px 0;
    background-color: #fff;
    font-size: 12px;
  `}

  dl {
    margin: 0;

    div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    dt {
      flex-basis: 276px;
      flex-grow: 0;
      flex-shrink: 0;

      ${({ theme }) => theme.desktop`
        padding: 12px 24px;
        background-color: #f8f8f8;
      `}

      ${({ theme }) => theme.tablet`
        flex-basis: 100%;
        font-weight: bold;
      `}
    }

    dd {
      margin: 0;
      padding: 16px 0 8px 29px;
      flex-basis: calc(100% - 276px);
      flex-grow: 0;
      flex-shrink: 0;

      ${({ theme }) => theme.tablet`
        flex-basis: 100%;
        margin-left: 0;
        padding: 10px 0 20px;
      `}

      p {
        margin: 0 0 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    div:last-child {
      dt,
      dd {
        padding-bottom: 18px;

        ${({ theme }) => theme.tablet`
            padding-bottom: 10px;
        `}
      }
    }
  }
`;

const ButtonCSS = css`
  position: absolute;
  top: 24px;
  right: 0;
  width: 35px;
  height: 35px;

  ${({ theme }) => theme.tablet`
    width: 20px;
    height: 20px;
  `}
`;
export const OpenButton = styled(FiPlus)`
  ${ButtonCSS}
`;
export const CloseButton = styled(FiMinus)`
  ${ButtonCSS}
`;

// legacy design -----------------------------------
export const ArrowButtonCSS = css`
  position: absolute;
  right: 32px;
  color: #b3b3b3;

  ${({ theme }) => theme.tablet`
    right: 22px;
  `}
`;
export const CloseArrowButton = styled(UpButton)`
  ${ArrowButtonCSS}
  top: 36px;

  ${({ theme }) => theme.tablet`
    top: 23px;
  `}
`;
export const OpenArrowButton = styled(DownButton)`
  ${ArrowButtonCSS}
  top: 31px;

  ${({ theme }) => theme.tablet`
    top: 20px;
  `}
`;
// -------------------------------------------------
