import React from 'react';
import styled from 'styled-components';
import { ClearLink } from 'components/atoms/Clear';
import { PAGE_URL } from 'configs/path';
import { generatePath } from 'react-router-dom';
import { useStores } from 'stores';
import { observer } from 'mobx-react';

export default observer(function PurchaseAgainButton() {
  const {
    order: { orderId },
  } = useStores();

  if (orderId) {
    return <Link to={generatePath(PAGE_URL.PURCHASE, { id: orderId })}>다시 결제하기</Link>;
  } else {
    return <></>;
  }
});

const Link = styled(ClearLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  width: 280px;
  height: 44px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  ${({ theme }) => theme.tablet`
    margin: 24px auto 0;
  `}
`;
