import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { PAGE_URL } from 'configs/path';
import { ClearLink } from 'components/atoms/Clear';

const MyCommentCard: React.FC<any> = ({ commentable_id, created_at, title, body }: any) => {
  return (
    <WrapLink to={generatePath(PAGE_URL.PLAY, { id: commentable_id })}>
      <Date>{moment(created_at).format('YYYY-MM-DD')}</Date>
      <Title>{title}</Title>
      <Contents>{body}</Contents>
    </WrapLink>
  );
};

export default MyCommentCard;

export const WrapLink = styled(ClearLink)`
  padding: 24px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  background-color: #fff;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    padding: 15px 20px;
    border: none;
  `}
`;

const Date = styled.div`
  font-size: 12px;

  ${({ theme }) => theme.tablet`
    color: #b3b3b3;
  `}
`;

const Title = styled.h2`
  margin-top: 8px;
  font-size: 24px;
  font-weight: normal;

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
    font-size: 16px;
    font-weight: bold;
  `}
`;

const Contents = styled.div`
  display: -webkit-box;
  margin-top: 8px;
  height: 48px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
    height: 41px;
    font-size: 14px;
  `}
`;
