import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { MypageLayout } from 'layouts/Mypage';
import { useStores } from 'stores';

import { WrapInfo, SumbitButton } from '../Info/styled';
import { Input, Label, WrapInput, InputErr, Form } from './styled';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { Title } from 'components/atoms/Mypage';

type Form = APIBodyForm.ChangePasswdForm['user'];

const ChangePassword: React.FC<any> = observer(() => {
  const { auth } = useStores();
  const { handleSubmit, register, errors, setError } = useForm<Form>();

  const onSubmit = async (user: Form) => {
    try {
      await auth.changePasswd({ user });
    } catch (error) {
      error.forEach(({ field, message }: any) =>
        setError(field, {
          type: 'manual',
          message,
        }),
      );
    }
  };

  if (!auth.user) return null;
  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>비밀번호 변경</SubHeaderTitle>
      </SubHeader>
      <WrapInfo>
        <Title>비밀번호 변경</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <WrapInput>
            <Label>현재 비밀번호</Label>
            <Input
              name='current_password'
              type='password'
              ref={register({ required: "'현재 비밀번호' 공백일 수 없습니다." })}
              placeholder='현재 비밀번호를 입력해주세요'
            />
            {errors.current_password && <InputErr>{errors.current_password.message}</InputErr>}
          </WrapInput>
          <WrapInput>
            <Label>새 비밀번호(최소 6글자)</Label>
            <Input
              name='password'
              type='password'
              ref={register({
                required: "'새 비밀번호' 공백일 수 없습니다.",
                minLength: {
                  value: 6,
                  message: "'비밀번호'는 최소 6자 이상이어야 합니다.",
                },
              })}
              placeholder='새 비밀번호를 입력해주세요'
            />
            {errors.password && <InputErr>{errors.password.message}</InputErr>}
          </WrapInput>
          <WrapInput>
            <Label>새 비밀번호 다시 입력</Label>
            <Input
              name='password_confirmation'
              type='password'
              ref={register({ required: "'비밀번호 확인'이 비밀번호와 일치하지 않습니다." })}
              placeholder='새 비밀번호를 다시 입력해주세요'
            />
            {errors.password_confirmation && (
              <InputErr>{errors.password_confirmation.message}</InputErr>
            )}
          </WrapInput>
          <SumbitButton type='submit'>저장</SumbitButton>
        </Form>
      </WrapInfo>
    </>
  );
});

export default MypageLayout(ChangePassword);
