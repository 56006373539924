import React, { useEffect } from 'react';
import { action, flow, observable } from 'mobx';
import { useLocalStore } from 'mobx-react';
import CommentsRepository from 'stores/repository/CommentsRepository';
import CommentsModel from 'stores/models/CommentsModel';

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      comments: {} as CommentsModel,
      fetch() {
        flow(function* (this: any) {
          try {
            const { data } = yield CommentsRepository.fetchMine();
            this.comments = new CommentsModel(data);
            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
    },
    {
      fetch: action,
    },
  );

  return store;
};
type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const MyCommentsProvider: React.FC<any> = ({ children }) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useMyCommentsStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitMyComments = () => {
  const { fetch } = useMyCommentsStore();

  useEffect(() => {
    fetch();
  }, [fetch]);
};
