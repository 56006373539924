import axios from 'axios';
import qs from 'query-string';

import { API_URL } from 'configs/path';
import { ResPackage } from 'stores/models/PackageModel';

interface Res {
  packages: ResPackage[];
}

class BestRepository {
  URL = API_URL.BEST_DEFAULT;

  fetch(params: object): Promise<Res> {
    const query = qs.stringify(params);
    return axios.get(`${this.URL}&${query}`);
  }
}

export default new BestRepository();
