import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { PAGE_URL } from 'configs/path';
import { IEvent } from 'stores/models/EventDetailModel';
import { ClearLink } from 'components/atoms/Clear';

const EventPackage: React.FC<IEvent.RelatedPackage> = props => {
  const { id, image_url, display_months_or_ages, title, original_price, sales_price } = props;
  const href = generatePath(PAGE_URL.PACKAGE, { id });

  return (
    <WrapPackage>
      <Img src={image_url} alt='package' />
      <Contents>
        <Age>{display_months_or_ages}</Age>
        <Title>{title}</Title>
        <OriginPrice>{original_price}</OriginPrice>
        <Price>{sales_price}</Price>
        <LinkButton to={href}>구매하기</LinkButton>
      </Contents>
    </WrapPackage>
  );
};

export default EventPackage;

const WrapPackage = styled.div`
  display: inline-block;
  border-radius: 3px;
  border: solid 1px #e6e6e6;
  overflow: hidden;
  line-height: 1.5;

  ${({ theme }) => theme.desktop`
    margin-top: 20px;
    width: calc(50% - 10px);

    &:nth-child(-n + 2) {
      margin-top: 0px;
    }

    &:nth-child(2n) {
      margin-left: 20px;
    }
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
    margin-right: 12px;
    width: calc(25% - 9px);

    &:nth-child(-n + 4) {
      margin-top: 0;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  `}

  ${({ theme }) => theme.mobile`
    margin-top: 20px;
    margin-right: 10px;
    width: calc(50% - 5px);

    &:nth-child(-n + 2) {
      margin-top: 0;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }
  `}
`;

const Img = styled.img`
  float: left;
  width: 200px;
  height: 200px;

  ${({ theme }) => theme.tablet`
    width: 100%;
    height: 100%;
  `}
`;

const Contents = styled.div`
  ${({ theme }) => theme.desktop`
    padding: 16px 16px 16px 216px;
    height: 200px;
  `}

  ${({ theme }) => theme.tablet`
    float: left;
    padding: 15px;
    width: 100%;
    height: 100%;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px;
  `}
`;

const Age = styled.div`
  font-size: 12px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    font-size: 10px;
  `}

  ${({ theme }) => theme.mobile`
    font-weight: normal;
    color: #666;
  `}
`;

const Title = styled.div`
  margin: 8px 0;
  height: 48px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ theme }) => theme.tablet`
    margin: 6px 0 5px;
    font-size: 12px;
    height: 36px;
  `}

  ${({ theme }) => theme.mobile`
    margin: 4px 0 0;
    font-weight: bold;
  `}
`;

const OriginPrice = styled.div`
  float: left;
  margin: 2px 8px 0 0;
  line-height: 36px;
  color: #b3b3b3;
  text-decoration: line-through;

  ${({ theme }) => theme.tablet`
    margin-top: 2px;
    margin-right: 3px;
    line-height: 20px;
    font-size: 10px;
  `}

  ${({ theme }) => theme.mobile`
    margin-top: 1px;
    margin-right: 3px;
    line-height: 20px;
    font-size: 10px;
  `}
`;

const Price = styled.div`
  float: left;
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.tablet`
    float: left;
    clear: none;
    margin-top: 0;
    font-size: 14px;
  `}
`;

const LinkButton = styled(ClearLink)`
  display: inline-block;
  margin-top: 11px;
  width: 100%;
  line-height: 40px;
  border-radius: 3px;
  background-color: #6ec8b4;
  font-weight: bold;
  text-align: center;
  color: #fff;

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
    line-height: 28px;
    font-size: 12px;
    font-weight: normal;
  `}
`;
