import { action, extendObservable, observable, toJS } from 'mobx';

export enum CertifyStep {
  INIT,
  EDIT,
  SEND,
}
class UserModel implements ResUser {
  @observable certifyStep = CertifyStep.INIT;

  constructor(props: ResUser | any) {
    extendObservable(this, props);
  }

  get kids2JS() {
    return toJS(this.kids);
  }

  @action.bound
  setCertifyStep(step: CertifyStep) {
    this.certifyStep = step;
  }

  @action.bound
  setPhoneNumberCertified(flag: boolean) {
    this.phone_number_certified = flag;
  }

  @action.bound
  resetPhoneNumberCertified() {
    this.phone_number_certified = false;
  }

  id!: number;
  email!: any;
  nickname!: string;
  fullname?: any;
  phone_number?: any;
  phone_number_certified!: boolean;
  address1?: any;
  address2?: any;
  admin?: any;
  authentication_token!: string;
  profile_image_url!: string;
  play_boxes_count!: number;
  my_story_boxes_count!: number;
  my_package_play_boxes_count!: number;
  my_growth_class_play_boxes_count!: number;
  package_orders_count!: number;
  show_feed!: boolean;
  feed_scheduled_at!: number;
  feed_kid_age?: any;
  cart_items_count!: number;
  first_language!: string;
  coin!: number;
  point!: number;
  wishlists_count!: number;
  uid!: string;
  coupon_count!: number;
  kids!: Kid[];
  mobile_keys!: any[];
  zonecode?: string;
}

export default UserModel;
export interface ResUser {
  id: number;
  email: string;
  nickname: string;
  fullname?: any;
  phone_number?: any;
  phone_number_certified: boolean;
  address1?: any;
  address2?: any;
  admin?: any;
  authentication_token: string;
  profile_image_url: string;
  play_boxes_count: number;
  my_story_boxes_count: number;
  my_package_play_boxes_count: number;
  my_growth_class_play_boxes_count: number;
  package_orders_count: number;
  show_feed: boolean;
  feed_scheduled_at: number;
  feed_kid_age?: any;
  cart_items_count: number;
  first_language: string;
  coin: number;
  point: number;
  wishlists_count: number;
  uid: string;
  coupon_count: number;
  kids: Kid[];
  mobile_keys: any[];
  zonecode?: string;
}

export interface Kid {
  id: number | null;
  user_id: number | null;
  gender: 'boy' | 'girl' | null;
  birthday: string | null;
  fullname: string | null;
}

export interface KidForm {
  kids: Kid[];
}
