import React, { useState } from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PAGE_URL } from 'configs/path';
import { generateKey, commaNumber } from 'utils';
import { useStores } from 'stores';
import moment from 'moment';
import styled from 'styled-components';
import { ClearButton, ClearLink } from 'components/atoms';

import {
  Tbody,
  Tr,
  Td,
  PackageImg,
  WrapCartInfo,
  PackageTitleLink,
  Price,
  OptionName,
} from 'pages/Cart/styled';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';
import OrderModel from 'stores/models/OrderModel';

const OrderTableBody = observer(() => {
  const { order } = useStores();

  return (
    <Tbody>
      {order.orders.map((item, index) => (
        <OrderRow item={item} index={index} key={generateKey(`order_tableRow_${item.id}`)} />
      ))}
    </Tbody>
  );
});

export default OrderTableBody;

const OrderRow: React.FC<{ item: OrderModel; index: number }> = observer(({ item, index }) => {
  const { order } = useStores();
  const [displayStatus, setDisplayStatus] = useState(item.display_status);
  const [canCancelOrder, setCanCancelOrder] = useState(item.can_cancel_order);
  const [displayTrackingNumbers] = useState(item.display_tracking_numbers);
  const firstOrderItem = item.order_items[0];

  const deleteOrder = async (id: number) => {
    if (window.confirm('구매를 취소하시겠습니까?')) {
      const { data } = await order.delete(id);
      if (data.success) {
        setDisplayStatus('구매 취소');
        setCanCancelOrder(false);
        alert(data.message);
      } else {
        alert(data.message);
      }
    }
  };

  const addToCart = async (id: number) => {
    const { data } = await order.addToCart(id);
    if (data.success) {
      alert(data.message);
    } else {
      alert(data.message);
    }
  };

  const deliveryCompleted = async (id: number) => {
    if (window.confirm('제품을 안전하게 받으셨나요? 수령확인을 하시면 즉시 포인트가 지급됩니다.')) {
      const { data } = await order.deliveryCompleted(id);
      if (data.success) {
        setDisplayStatus('배송 완료');
        alert(data.message);
      } else {
        alert(data.message);
      }
    }
  };

  return (
    <Tr style={{ backgroundColor: item.status === 'canceled' ? '#f8f8f8' : '#ffffff' }}>
      {useTabletShow(<Td style={{ fontWeight: 'bold' }}>{item.display_status}</Td>)}
      <Td className='vtop'>
        {item.paid_at
          ? moment(item.paid_at).format('YYYY.MM.DD')
          : moment(item.created_at).format('YYYY.MM.DD')}
        {useTabletShow(
          <OrderGreenButtonLink
            style={{ border: 0, fontWeight: 'bold', width: 'auto', float: 'right' }}
            onClick={() => addToCart(item.id)}>
            장바구니 담기
          </OrderGreenButtonLink>,
        )}
      </Td>
      <Td className='info'>
        <ItemWrap to={generatePath(PAGE_URL.USER_PURCHASE_DETAIL, { id: item.id })}>
          <PackageImg src={firstOrderItem.square_image_url} alt='images' />
        </ItemWrap>
        <WrapCartInfo>
          <PackageTitleLink to={generatePath(PAGE_URL.USER_PURCHASE_DETAIL, { id: item.id })}>
            {item.title}
          </PackageTitleLink>
          {firstOrderItem.option_title ? (
            <OptionName>{`[옵션] ${firstOrderItem.option_title}`}</OptionName>
          ) : null}
          {useTabletShow(<Price>{commaNumber(item.orderPrice)}원</Price>)}
        </WrapCartInfo>
      </Td>
      {useTabletShow(
        <Td>
          {canCancelOrder && (
            <OrderGreenButtonLink onClick={() => deleteOrder(item.id)}>
              주문 취소
            </OrderGreenButtonLink>
          )}
          {displayTrackingNumbers.length > 0 &&
            item.status === 'success' &&
            !item.already_earned_points && (
              <OrderGreenButtonLink onClick={() => deliveryCompleted(item.id)}>
                수령확인하기
              </OrderGreenButtonLink>
            )}
          <OrderDetailLink to={generatePath(PAGE_URL.USER_PURCHASE_DETAIL, { id: item.id })}>
            상세보기
          </OrderDetailLink>
        </Td>,
      )}
      <Td className='vtop'>
        <Price>{commaNumber(item.orderPrice)}원</Price>
      </Td>
      {useDesktopShow(
        <Td>
          {displayStatus}
          {canCancelOrder && (
            <OrderGreenButtonLink onClick={() => deleteOrder(item.id)}>
              주문 취소
            </OrderGreenButtonLink>
          )}
          {displayTrackingNumbers.length > 0 &&
            item.status === 'success' &&
            !item.already_earned_points && (
              <OrderGreenButtonLink onClick={() => deliveryCompleted(item.id)}>
                수령확인하기
              </OrderGreenButtonLink>
            )}
          <OrderGreyButtonLink onClick={() => addToCart(item.id)}>
            장바구니 담기
          </OrderGreyButtonLink>
        </Td>,
      )}
    </Tr>
  );
});

const OrderGreenButtonLink = styled(ClearButton)`
  vertical-align: bottom;
  margin: 5px auto;
  width: 92px;
  border-radius: 3px;
  border: solid 1px #6ec8b4;
  color: #6ec8b4;
  line-height: 30px;
  font-size: 12px;
  text-align: center;

  ${({ theme }) => theme.tablet`
    margin: 0;
    display: inline-block;
    font-weight: normal !important;
  `};
`;

const OrderGreyButtonLink = styled(ClearButton)`
  vertical-align: bottom;
  margin: 5px auto;
  width: 92px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  color: #262626;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  ${({ theme }) => theme.tablet`
    margin: 0;
    float: right;
  `};
`;

const OrderDetailLink = styled(ClearLink)`
  width: 92px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  color: #262626;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  ${({ theme }) => theme.tablet`
    margin: 0;
    float: right;
  `};
`;

const ItemWrap = styled(ClearLink)`
  display: block;
  text-decoration: none;
`;
