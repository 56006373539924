import React, { useEffect, useRef, useState, useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

import { useStores } from 'stores';
import { IPageLayout } from 'interfaces/layout';

import * as Atoms from 'components/atoms/Page';
import { ConditionalWrapper } from 'components/atoms/ConditionalWrapper';
import ModalLayer from './ModalLayer';

export const PageLayout = (
  ChildComponent: React.ComponentType<any>,
  { Provider, Title, Wrapper, Modal, Option } = {} as IPageLayout,
) =>
  function WrapperFn(props: RouteComponentProps) {
    const history = useHistory();
    const { layout, setHistory } = useStores();

    const PageRef = useRef<HTMLDivElement>(null);
    const [minHeight, setminHeight] = useState(window.innerHeight);

    const onEndTransition = useCallback(() => {
      setTimeout(() => {
        if (Option?.full) {
          setminHeight(window.innerHeight - layout.headerHeight - layout.footerHeight - 1);
        } else {
          const height = PageRef.current?.clientHeight || window.innerHeight;
          if (height < window.innerHeight) setminHeight(0);
        }
      }, 200);
    }, [layout.footerHeight, layout.headerHeight]);

    useEffect(() => {
      layout.CloseSideBar();
      layout.CloseDimmed();
      setHistory(history);
    }, [props, layout, setHistory, history]);

    useEffect(() => {
      onEndTransition();
    }, [layout.displayType, onEndTransition]);

    return useObserver(() => {
      return (
        <Atoms.Page $minHeight={minHeight}>
          <CSSTransition
            in={layout.isInit}
            appear={true}
            timeout={200}
            classNames='pg-animation page-fade'
            unmountOnExit
            addEndListener={onEndTransition}>
            <div ref={PageRef}>
              <ConditionalWrapper
                condition={Provider}
                wrapper={(children: React.ReactNode) => <Provider>{children}</Provider>}>
                <Wrapper>
                  {Title ? <Atoms.Title>{Title}</Atoms.Title> : null}

                  <Atoms.MobileHeader>
                    <Atoms.MobileBackButton url={Option?.backUrl} />
                    {Title ? <Atoms.MobileTitle>{Title}</Atoms.MobileTitle> : null}
                  </Atoms.MobileHeader>

                  <ChildComponent setBodyMinHeight={setminHeight} {...props} />
                </Wrapper>
              </ConditionalWrapper>
            </div>
          </CSSTransition>
          <ModalLayer store={Modal} />
        </Atoms.Page>
      );
    });
  };
