import React from 'react';
import styled from 'styled-components';
import { generateKey } from 'utils';

interface Props<T> {
  store: Array<T>;
  ListWrap: React.ComponentType<any>;
  ListItem: React.ComponentType<any>;
  ListItemProps?: any;
}

const ListFrame = <T extends any>({ ListWrap, ListItem, store, ListItemProps }: Props<T>): any => {
  const listItems = store.map((result: T, index: number) => {
    return (
      <ListItem
        key={generateKey(`result-div-${index}`)}
        {...result}
        count={index + 1}
        {...ListItemProps}
      />
    );
  });

  return <ListWrap>{listItems}</ListWrap>;
};

ListFrame.defaultProps = {
  ListWrap: styled.div``,
  ListItem: () => styled.div``,
  items: [],
};

export default ListFrame;
