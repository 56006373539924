import React from 'react';
import styled, { css } from 'styled-components';

// refactoring
export const RadioButtonCss = css`
  input {
    position: absolute;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;

    & + label {
      position: relative;
      display: inline-block;
      padding-left: 28px;
      line-height: 24px;
      cursor: pointer;
      user-select: none;
    }

    & + label:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 4px;
      left: 0;
      width: 20px;
      height: 20px;
      border: solid 2px #d5d5d5;
      border-radius: 20px;
    }

    &:checked + label:before {
      border-color: ${({ theme }) => theme.subActiveColor};
    }

    &:checked + label:after {
      content: '';
      position: absolute;
      top: 9px;
      left: 5px;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${({ theme }) => theme.subActiveColor};
    }
  }
`;

const InputLabelWidth = '120px';
export const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & input {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - ${InputLabelWidth});

    ${({ theme }) => theme.tablet`
      flex-basis: calc( 100% - 90px );
    `}
  }

  & input + div {
    margin: 4px 0 4px ${InputLabelWidth};

    ${({ theme }) => theme.tablet`
      margin: -1px 0 5px 90px;
   `}
  }
`;

export const InputLabel = styled.label`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${InputLabelWidth};
  padding: 18px 0;

  font-size: 16px;
  color: ${({ theme }) => theme.TextColor};

  ${({ theme }) => theme.tablet`
    flex-basis: 90px;
    font-size: 12px;
  `}
`;
export const InputErr = styled.div`
  margin: 4px 0;
  font-size: 12px;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.tablet`
    font-size: 10px;
  `}
`;

export const Input = styled.input`
  margin: 4px 0;
  padding: 10px 16px;
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;

  line-height: 24px;
  font-size: 16px;
  text-align: left;

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.subActiveColor};
  }

  &.err {
    border-color: ${({ theme }) => theme.FormErrColor};
  }

  ${({ theme }) => theme.tablet`
    margin: 5px 0;
    padding: 10px;
    font-size: 14px;
  `}
`;

export const CheckButton = (props: any) => {
  const { id, children, injectRef, ...other } = props;

  return (
    <CheckInputWrap>
      <CheckInput type='checkbox' id={id} ref={injectRef} {...other} />
      <CheckLabel htmlFor={id}>{children}</CheckLabel>
    </CheckInputWrap>
  );
};
const CheckInputWrap = styled.div`
  position: relative;
  margin: 4px 0;

  ${({ theme }) => theme.mobile`
    margin: 5px 0;
  `}
`;
const CheckInput = styled.input`
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  & + label {
    position: relative;
    display: inline-block;
    padding-left: 21px;
    cursor: pointer;
    user-select: none;
  }

  & + label:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 1px;
    width: 12px;
    height: 12px;
    border: solid 1px #7f7f7f;
    background-color: #fff;
    border-radius: 3px;
    transition: all 0.12s, border-color 0.08s;
  }

  &:checked + label:before {
    position: absolute;
    top: 0;
    left: 7px;
    width: 6px;
    height: 10px;
    border-color: ${({ theme }) => theme.subActiveColor};
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
    transform: rotate(40deg);
  }
`;
const CheckLabel = styled.label`
  line-height: 1.5;
  font-size: 12px;
  color: #262626;
`;

export const SubmitButton = styled.button`
  width: 100%;
  height: 44px;
  border: 0;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};

  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;

  cursor: pointer;
  overflow: visible;
  white-space: nowrap;

  ${({ theme }) => theme.tablet`
    font-size: 14px;
  `}
`;

export const ClearSelect = styled.select`
  appearance: none;
  &::-ms-expand {
    display: none;
  }
`;

export const ErrBubble = styled.div`
  position: absolute;
  margin-top: 14px;
  padding: 16px 24px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  background-color: ${({ theme }) => theme.mainActive};
  line-height: 1.5;
  color: #fff;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: -10px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: inherit;
  }
`;
