import React from 'react';
import styled from 'styled-components';

const Privacy = () => (
  <PrivacyContents>
    <ul>
      <li>
        제 1 조 (목적)
        <br />
        &nbsp;· &nbsp; 본 약관은 이용자가 (주)해빛(이하 "회사"라 합니다.)이 제공하는 해빛과 차이의
        놀이 서비스를 이용함에 있어 회사와 이용자간의 권리와 의무 및 책임사항을 규정함을 목적으로
        합니다.
      </li>
      <li>
        제 2 조 (정의)
        <br />
        &nbsp;· &nbsp; 본 약관에서 사용하는 주요 용어는 다음과 같습니다.
        <ol>
          <li>
            "서비스"라 함은, 구현되는 단말기(PC, 태블릿, 휴대용 단말기 등의 각 종 유무선 장치)와
            상관없이 해빛 혹 차이의 놀이 브랜드명을 가지고 "회사"가 제공하는 제반서비스를 말합니다.
          </li>
          <li>
            "회원" 이라 함은, 회사의 "서비스"에 접속하여 본 약관에 동의하고, 회원 등록을 완료한
            이용자를 말합니다.
          </li>
          <li>
            “콘텐츠”라고 함은, (주)해빛이 제작하여 “서비스상”에 게시한
            부호ᆞ문자ᆞ음성ᆞ음향ᆞ화상ᆞ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크
            등을 의미합니다.
          </li>
          <li>
            "게시물"이라 함은, "회원"이 "서비스"를 이용함에 있어 "서비스상"에 게시한
            부호ᆞ문자ᆞ음성ᆞ음향ᆞ화상ᆞ동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크
            등을 의미합니다.
          </li>
          <li>
            "이벤트"라 함은 회사가 직접 혹은 제3의 회사를 통해 제휴하여 고객에게 제공하는 서비스를
            말합니다.
          </li>
        </ol>
      </li>
      <li>
        제 3조 (약관의 게시, 효력 및 개정)
        <br />
        <ol>
          <li>
            회사는 본 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 접속 초기 화면에 게시합니다.
          </li>
          <li>
            회사는 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제에 관한
            법률, 소비자기본법, 정보통신망이용촉진 및 정보보호 등에 관련법을 위배하지 않는 범위에서
            본 약관을 개정할 수 있습니다.
          </li>
          <li>
            회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를
            명시하여 현행약관과 함께 그 적용일자를 삼십(30)일 전부터 일정기간 서비스내 공지하고,
            SMS, 로그인시 동의창 등의 전자적 수단을 통해 고지합니다.
          </li>
          <li>
            회사가 전항에 따라 회원에게 통지하면서 공지 하고, 고지일로부터 개정약관 시행일 14일
            후까지 거부의사를 표시하지 않는 경우 개정 약관에 동의하신 것으로 봅니다.
          </li>
          <li>
            회원이 개정에 동의하지 않는 경우 회사는 개정된 약관을 적용할 수 없으며, 회원은 개정된
            약관의 효력 발생일로부터 서비스를 이용하실 수 없습니다.
          </li>
        </ol>
      </li>
      <li>
        제 4 조(약관의 해석)
        <br />
        <ol>
          <li>
            이 약관에서 정하지 않는 사항이나 해석에 대하여는 관련 법령 또는 상관례에 따릅니다.
          </li>
          <li>
            회사는 (주)해빛 내부의 개별 서비스에 대해 별도의 이용약관 또는 정책(이하'별도의 약관')을
            둘 수도 있으며, 그내용이 이 약관과 상충할 경우에는 별도의 약관이 우선하여 적용됩니다.
          </li>
        </ol>
      </li>
      <li>
        제 5조 (이용계약의 체결)
        <br />
        <ol>
          <li>
            서비스 이용계약은 회원이 되고자 하는분(이하'가입신청자')이 약관에 동의하고 가입을
            신청하므로써 체결됩니다.
          </li>
          <li>
            가입신청자는 가입신청시 진실한 정보를 기재하셔야 하며, 허위의 정보를 기재함으로 인한
            불이익 및 법적 책임은 가입신청자에게 있습니다.
          </li>
          <li>
            가입신청자가 이전에 회원자격을 상실한 적이 있는경우, 기타 승낙이 불가능한 사유가 있는
            경우 승낙이 거부될 수 있습니다.
          </li>
          <li>
            회사는 서비스 관련설비의 여유 기타 기술상 업무상의 이유로 승낙을 유보할 수 있으며, 유보
            될 경우 가입신청자에게 별도로 알려드립니다.
          </li>
        </ol>
      </li>
      <li>
        제 6조 (회원 정보의 변경)
        <br />
        <ol>
          <li>
            회원은 서비스 내 나의정보 화면을 통하여 본인의 개인정보를 열람하고 수정할 수 있습니다.
          </li>
          <li>
            가입신청시 기재한 회원정보에 병동이 생긴 경우 이를 수정하거나 회사에 알려주셔야 하며
            미수정 또는 알리지 않음으로 인한 불이익에 대하여 회사는 책임지지 않습니다.
          </li>
        </ol>
      </li>
      <li>
        제 8조 (권리의 귀속 및 저작물의 이용)
        <br />
        <ol>
          <li>
            회사가 회원에게 제공하는 각종 서비스에 대한 저작권을 포함한 일체의 권리는 회사에
            귀속되며 회원이 서비스를 이용하는 과정에서 작성한 게시물에 대한 저작권을 포함한 일체에
            관한 권리는 별도의 의사표시가 없는 한 해당 회원에게 귀속됩니다.
          </li>
        </ol>
      </li>
      <li>
        제 9조 (서비스의 변경)
        <br />
        <ol>
          <li>
            회사는 운영상, 기술상의 필요에 따라 제공하는 서비스의 전부 또는 일부를 변경할 수 있으며,
            이에 대하여 약관 상에 다른 규정이 없는 한 회원에게 별도의 보상을 하지는 않습니다.
          </li>
          <li>
            서비스를 변경하는 경우 변경사유 및 일자, 변경내용을 변경 7일전에 공지사항 게시판에
            공지합니다. 단, 변경사유 또는 내용을 구체적으로 공지하기 어려운 경우에는 그 이유를
            밝힙니다.
          </li>
        </ol>
      </li>
      <li>
        제 10조 (서비스의 일시 중단)
        <br />
        <ol>
          <li>
            회사는 서비스관련 설비 보수점검, 교체 및 고장, 통신두절 등 기술상 업무상의 이유로
            서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 사전에 통지함을 원칙으로 하지만,
            부득이한 사유가 발생할 경우 사후에 통지할 수 있으며 이에대한 보상이 필요한 경우
            통상적으로 예상 가능한 범위 내에서 보상을 원칙으로 하며, 불가항력에 의한 경우에는 보상해
            드리지 않습니다.
          </li>
          <li>
            서비스의 일시 중단은 이메일 또는 서비스 내 공지사항, PUSH, SMS, 서비스 웹사이트에
            게시하는 방법 등으로 통지합니다.
          </li>
        </ol>
      </li>
      <li>
        제 11조 (회원의 의무)
        <br />
        <ol>
          <li>회원은 다음 행위를 하여서는 안 됩니다.</li>
          <li>
            - 회원정보에 허위 내용을 등록하는 행위
            <br />
            - 회사의 서비스에 게시된 정보를 변경하거나 서비스를 이용하여 얻은 정보를 회사의 사전
            승낙 없이 영리 또는 비영리 목적으로 복제, 출판, 방송 등에 사용하거나 제 3자에게 제공하는
            행위
            <br />
            - 회사 기타 제 3자의 명예를 훼손하거나 지적재산권을 침해하는 등 회사나 제3자의 권리를
            침해하는 행위
            <br />
            - 다른 회원의 ID 및 비밀번호를 도용하여 부당하게 서비스를 이용한 경우
            <br />
            - 정크메일(junk mail), 스팸메일(spam mail), 행운의 편지(chain letters), 피라미드 조직에
            가입할 것을 권유하는 메일, 외설 또는 폭력적인 메시지·화상·음성 등 정보통신망 이용촉진 및
            정보보호등에 관한 법률 등 관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터
            프로그램 등)를 전송하거나 게시하는 행위
            <br />
            - 회사의 직원이나 서비스의 관리자를 가장하거나 사칭하거나 타인의 명의를 도용하여 글을
            게시하거나 메시지를 발송하는 행위
            <br />
            - 스토킹(stalking) 등 다른 회원의 서비스 이용을 방해하는 행위
            <br />
            - 다른 회원의 개인정보를 그 동이 없이 수집, 저장, 공개하는 행위
            <br />- 불특정 다수의 회원을 대상으로 하여 광고 또는 선전을 게시하거나 스팸메일을
            전송하는 등 현행법령, 회사가 제공하는 서비스에 대한 약관 및 기타 서비스 이용에 관한
            규정을 위반하는 행위회사는 회원이 위 항의 행위를 하는 경우 서비스의 이용을 제한하거나
            일방적으로 본 계약을 해지할 수 있습니다.
          </li>
        </ol>
      </li>
      <li>
        제 12조 (양도금지)
        <br />
        <ol>
          <li>
            회원의 서비스 받을 권리에 대하여 이를 양도 내지 증여하거나 질권의 목적으로 할 수
            없습니다
          </li>
        </ol>
      </li>
      <li>
        제 13조 (이용계약의 해지)
        <br />
        <ol>
          <li>
            회원은 언제든지 회사가 정하는 절차에 따라 이용계약 해지 신청을 할 수 있으며, 회사는
            관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
          </li>
          <li>
            회원이 계약을 해지할 경우, 관련법 및 회사가 별도로 정하는 “개인정보취급방침”에 따라
            회사가 회원의 개인정보 등을 보유하는 경우를 제외하고는 해지 즉시처리합니다.
          </li>
          <li>
            회원이 계약을 해지하는 경우, 회원이 작성한 게시물 중 프로필 등과 같이 본인 계정에 등록된
            게시물 일체는 삭제됩니다. 다만, 다른 회원에 의해 담기, 스크랩 된 자료는 유지됩니다.
          </li>
          <li>
            "회원"이 제10조의 규정을 위반한 경우 회사는 사전통보 없이 일방적으로 본 계약을 해지할 수
            있고, 이로 인하여 서비스 운영에 손해가 발생한 경우 이에 대한 민, 형사상 책임도 물을 수
            있습니다.
          </li>
        </ol>
      </li>
      <li>
        제 14조 (면책)
        <br />
        <ol>
          <li>
            회사는 다음 사유로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에
            대해서는 책임을 부담하지 않습니다.
          </li>
          <li>
            - 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
            <br />
            - 서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가
            있는 경우
            <br />
            - 회원의 귀책사유로 서비스 이용에 장애가 있는 경우
            <br />
            - 기타 회사의 고의과실이 없는 사유로 인한 경우회사는 CP가 제공하거나 회원이 작성하는
            등의 방법으로 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을
            하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 않습니다.
            <br />
          </li>
        </ol>
      </li>
      <li>
        제 15조 (이벤트의 이용)
        <br />
        <ol>
          <li>회원은 서비스내 제휴된 다양한 이벤트를 유료·무료로 이용하실 수 있습니다.</li>
          <li>제휴된 이벤트의 내용은 다른 회사와의 협의 상황에 따라 변경될 수 있습니다.</li>
          <li>
            제휴 이벤트 이용과 관련한 분쟁에서는 명시적인 책임소재가 제휴된 회사측에 있을 경우,
            회사는 책임을 지지 않습니다. 단, 회사에서 직접 진행한 경우 원만한 해결을 위해 최대한
            노력합니다.
          </li>
        </ol>
      </li>
      <li>
        제 16조 (회사의 의무)
        <br />
        <ol>
          <li>
            회사는 관련 법령 또는 이 약관을 위반하지 않으며, 계속적이고 안정적으로 서비스를 제공하기
            위하여 최선을 다합니다.
          </li>
          <li>
            회사는 신용정보를 포함한 회원의 개인정보 보호를 위하여 보안시스템을 갖추어야 하며
            개인정보 취급방침을 공시하고 준수하겠습니다.
          </li>
          <li>
            회사는 서비스 이용과 관련한 회원의 의견이나 불만사항 등이 정당하다고 인정될 경우 이를
            처리하여야 하며, 처리된 결과는 게시판 또는 이메일을 통해 회원에게 통보합니다.
          </li>
        </ol>
      </li>
      <li>
        제 17조 (서비스의 종료)
        <br />
        <ol>
          <li>회사는 서비스를 종료하고자 하는 날로부터 3개월 이전에 회원에게 알립니다.</li>
          <li>종료 통지일로 부터 서비스 종료일까지 일부 서비스이용이 제한 될 수 있습니다.</li>
        </ol>
      </li>
      <li>
        제 18조 (준거법 및 합의 관할)
        <br />
        <ol>
          <li>회사와 회원간에 제기된 법적 분쟁은 대한민국법을 준거법으로 합니다.</li>
          <li>
            회사와 회원간의 분쟁에 관한 소송은 민사소송법상의 주소지를 관할하는 관할법원에
            제소합니다.
          </li>
        </ol>
      </li>
      <li>
        제 19조 (개인정보보호의무)
        <br />
        <ol>
          <li>
            회사는 관련 법령이 정하는 바에 따라서 회원 등록정보를 포함한 개인정보를 보호하기 위하여
            노력합니다. 이에 관해서는 관련 법령 및 회사의 "개인정보 취급방침"에 의하며, 회원이 늘
            확인하실수 있도록 웹사이트를 통해 공시합니다.
          </li>
        </ol>
      </li>
      <li>
        제 20조 (위치 정보의 이용)
        <br />
        &nbsp;· &nbsp; '회사'는 '회원'이 모바일 단말기에서 본 서비스에 접속하여 회원 가입, 혹은 계정
        정보 수정시 거주지 정보를 GPS로 입력할 경우에만 해당 '회원'의 위치정보를 이용합니다.
        <ol>
          <li>회사명: (주)해빛</li>
          <li>메일: ask@havitplay.com</li>
        </ol>
      </li>
      <li>
        위치정보이용 안내
        <br />
        <ol>
          <li>
            GPS가 탑재된 모바일 단말기에서 본 서비스에 접속하여 회원 가입, 혹은 계정 정보 수정시
            거주지 정보를 GPS로 입력할 경우에만 해당 '회원'의 위치정보를 이용합니다.
          </li>
          <li>
            모바일 단말기로 무선 인터넷을 통해 회원 가입, 혹은 계정 정보 수정시 거주지 정보를 GPS로
            입력할 경우에만 WPS(WiFi Positioning System) 기술을 통해 수집된 위치정보를 이용합니다.
          </li>
          <li>
            '회사'는 이용자가 회원 가입, 혹은 계정 정보 수정시 거주지 정보를 GPS로 입력한 경우
            이외에는 별도로 이용자의 위치정보를 이용하지 않습니다.
          </li>
        </ol>
      </li>
      <li>
        <b>부칙본 약관은 2015년 10월 01일 부터 적용됩니다.</b>
      </li>
    </ul>
  </PrivacyContents>
);

export const PrivacyContents = styled.div`
  line-height: 2;
  font-size: 12px;
  color: ${({ theme }) => theme.TextColor};

  ul > li {
    list-style: none;
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    ol {
      padding-left: 2rem;
    }
  }
`;

export default Privacy;
