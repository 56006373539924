import styled, { css } from 'styled-components';

const Circle = css`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: solid 1px #b3b3b3;
  background-color: #fff;

  ${({ theme }) => theme.tablet`
    width: 32px;
    height: 32px;
    border-radius: 32px;
  `}
`;

export const Minus = styled.div`
  ${Circle}

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    border-top: 1px solid #b3b3b3;

    ${({ theme }) => theme.tablet`
      width: 16px;
    `}
  }
`;

export const Plus = styled(Minus)`
  ${Circle}

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    width: 20px;
    border-top: 1px solid #b3b3b3;

    ${({ theme }) => theme.tablet`
      width: 16px;
    `}
  }
`;
