import axios from 'axios';

// axios.defaults.headers.common['Pragma'] = 'no-cache';
// axios.defaults.baseURL = process.env.NODE_ENV === 'development'
//   ? '/'
//   : process.env.API_DOMAIN

export const defaultURL = 'https://api.chaisplay.com/';
export const stagingURL = 'https://api.havitmom.com/';

axios.defaults.baseURL = defaultURL;
export const setAuthToken = (token: string, email: string) => {
  axios.defaults.headers.common['Authorization'] = token;
  axios.defaults.headers.common['X-User-Token'] = token;
  axios.defaults.headers.common['X-User-Email'] = email;
};

export const removeAuthToken = () => {
  delete axios.defaults.headers.common['Authorization'];
  delete axios.defaults.headers.common['X-User-Token'];
  delete axios.defaults.headers.common['X-User-Email'];
};

export const getOrderToken = () => {
  return sessionStorage.getItem('X-Order-Token') ?? axios.defaults.headers.common['X-User-Token'];
};

export const setOrderToken = (token?: string) => {
  let orderToken = token || sessionStorage.getItem('X-Order-Token');

  if (orderToken) {
    sessionStorage.setItem('X-Order-Token', orderToken);
    axios.defaults.headers.common['X-Order-Token'] = orderToken;
  }
};

export const removeOrderToken = () => {
  delete axios.defaults.headers.common['X-Order-Token'];
};
