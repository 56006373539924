import React, { useCallback } from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';

import { ClearButton } from 'components/atoms';
import { H2, Wrapper } from './styled';

const EventAddressCopy: React.FC<any> = () => {
  const { href } = global.location;
  const onCopy = useCallback(() => alert('주소가 복사 되었습니다.'), []);

  return (
    <Wrapper>
      <H2>소중한 사람들과 공유해 보세요.</H2>
      <CopyInput type='text' value={href} readOnly />
      <CopyToClipboard text={href} onCopy={onCopy}>
        <CopyButton>주소 복사</CopyButton>
      </CopyToClipboard>
    </Wrapper>
  );
};

export default EventAddressCopy;

const CopyInput = styled.input`
  margin-right: 8px;
  padding: 11px 12px;
  width: 733px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  outline: none;
  font-size: 12px;

  ${({ theme }) => theme.tablet`
    margin-right: 10px;
    padding: 6px 8px;
    width: calc(100% - 80px);
    height: 28px;
    font-size: 10px;
  `}
`;

const CopyButton = styled(ClearButton)`
  display: inline-block;
  vertical-align: top;
  width: 216px;
  height: 40px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.tablet`
    width: 70px;
    height: 28px;
    font-size: 12px;
  `}
`;
