import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { usePackageDetailStore } from 'pages/PackageDetail/store';
import QuantityBox from 'components/atoms/QuantityBox';

export const QuantityBoxContainer: React.FC<any> = observer(() => {
  const {
    select,
    onChangeQuantity,
    packageDetail: { inventory_count },
  } = usePackageDetailStore();

  return (
    <WrapQuantityBox>
      <QuantityBox
        value={select.quantity}
        max={inventory_count ?? 1000}
        maxGuideMessage={`패키지 구매 안내 / 현재 선택해 주신 상품의 구매 가능한 수량은 ${inventory_count}개 입니다. 상품 재고 관련 문의는 카카오톡 '해빛' 상담채널로 문의 주세요.`}
        onChange={onChangeQuantity}
      />
    </WrapQuantityBox>
  );
});

const WrapQuantityBox = styled.div`
  float: right;
  vertical-align: top;
  padding: 6px 0;

  ${({ theme }) => theme.tablet`
    float: left;
    padding: 0;
    margin-left: 16px;
  `}

  ${({ theme }) => theme.mobile`
    float: right;
    padding: 0;
    margin-left: 16px;
  `}
`;
