import React from 'react';
import { observer } from 'mobx-react';
import { MypageLayout } from 'layouts/Mypage';
import { CouponProvider, useInitMyCoupon } from 'stores/local/Coupon';

import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { Title, WrapperHistory } from 'components/atoms/Mypage';
import { CouponTopBox, CouponCards } from 'components/organisms/Mypage/Coupon';

const PageCouponList: React.FC<any> = observer(({ pgMinHeight }) => {
  useInitMyCoupon();

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>쿠폰함</SubHeaderTitle>
      </SubHeader>
      <WrapperHistory $pgMinHeight={pgMinHeight}>
        <Title>쿠폰함</Title>
        <CouponTopBox />
        <CouponCards />
      </WrapperHistory>
    </>
  );
});

export default MypageLayout(PageCouponList, { Provider: CouponProvider, Option: { full: true } });
