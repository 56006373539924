import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const HistoryCard: React.FC<any> = React.memo(({ value, created_at, type, title, unit }) => {
  return (
    <Card>
      <Info>
        [{type}] {value}
        {unit}
      </Info>
      <Desc>{title}</Desc>
      <Time>{moment(created_at).format('YYYY.MM.DD hh:mm:ss')}</Time>
    </Card>
  );
});

export default HistoryCard;

const Card = styled.div`
  margin-top: 10px;
  padding: 10px;
  background-color: #fff;

  &:first-child {
    margin-top: 15px;
  }
`;

const Info = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const Desc = styled.div`
  margin-top: 5px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Time = styled.div`
  margin-top: 5px;
  font-size: 12px;
  color: #b3b3b3;
`;
