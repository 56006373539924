import React from 'react';
import styled from 'styled-components';
import { ClearA } from 'components/atoms/Clear';

export default React.memo(function Banner() {
  return (
    <Link href='https://pf.kakao.com/_xdbBvxd' target='_blank'>
      <img src='/images/complete-kakaotalk-banner.svg' alt='주문 완료 카카오톡 배너' />
    </Link>
  );
});

const Link = styled(ClearA)`
  display: block;
  margin-top: 72px;
  img {
    width: 100%;
  }
`;
