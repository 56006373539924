import React from 'react';
import moment, { Moment } from 'moment';
import { observable, action, flow, extendObservable, toJS, reaction, computed } from 'mobx';

import {
  DISPLAY,
  BREAK_POINT_MOBILE,
  BREAK_POINT_DESKTOP,
  DEVICE,
  OpenDimmedPrams,
  DimmedType,
} from 'interfaces/layout';
import { DefaultDimmedZIndex } from 'interfaces/Modal';
import CategoriesModel, { ResPackageCategory } from 'layouts/models/CategoriesModel';
import CategoriesRepository from 'layouts/repository/CategoriesRepository';
import { layoutStoreKey } from 'configs/localStorage';
import { PackagesMatchParams } from 'configs/path';

export default class LayoutStore {
  @observable isInit = false;
  @observable isLoading = true;
  @observable displayType!: DISPLAY;
  @observable deviceType!: DEVICE;
  @observable packageCategory: CategoriesModel[] = [];

  @observable sideMenuOpen = false;
  matchMediaListener: any = {};

  headerRef: React.RefObject<HTMLTableRowElement> = React.createRef();
  footerRef: React.RefObject<HTMLTableRowElement> = React.createRef();

  constructor() {
    const existingStore = localStorage.getItem(layoutStoreKey);
    if (existingStore) extendObservable(this, JSON.parse(existingStore));

    this.setDisplayType(window.innerWidth);
    this.setDeviceType();
    this.handleWindowMatchMedia();
    this.fetch();

    if (moment().isSameOrAfter(this.topAppBanner.expiryDate)) {
      this.topAppBanner.show = true;
    }
    reaction(
      () => toJS(this.topAppBanner),
      () => {
        localStorage.setItem(layoutStoreKey, JSON.stringify(this.toJS));
      },
    );
  }

  @action
  fetch = flow(function* (this: LayoutStore) {
    try {
      const [parent, children] = yield CategoriesRepository.find();

      const unSorted: { [key: number]: CategoriesModel } = {};
      parent.forEach((p: ResPackageCategory) => {
        const list = children.filter((a: ResPackageCategory) => a.parent_id === p.id);

        unSorted[p.id] = new CategoriesModel(p, list);
      });

      this.packageCategory = [20, 17, 14, 26, 11, 2, 15, 27, 1, 23, 12, 10, 22, 9].map(
        (id: number) => {
          return unSorted[id];
        },
      );
      this.isInit = true;
    } catch (error) {
      throw error;
    }
  });

  @action
  setDisplayType(width: number) {
    let type: DISPLAY;

    if (width <= BREAK_POINT_MOBILE) type = DISPLAY.MOBILE;
    else if (width <= BREAK_POINT_DESKTOP) type = DISPLAY.TABLET;
    else type = DISPLAY.DESKTOP;

    this.displayType = type;
  }

  @action
  setDeviceType() {
    if (navigator.userAgent.match(/Android/i) !== null) this.deviceType = DEVICE.ANDROID;
    else if (navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null)
      this.deviceType = DEVICE.APPLE;
    else this.deviceType = DEVICE.OTHER;
  }

  @action.bound
  private handleWindowMatchMedia() {
    const handleWindowResize = () => this.setDisplayType(window.innerWidth);

    this.matchMediaListener[DISPLAY.MOBILE] = window
      .matchMedia(`screen and (max-width: ${BREAK_POINT_MOBILE}px)`)
      .addListener(handleWindowResize);
    this.matchMediaListener[DISPLAY.MOBILE] = window
      .matchMedia(`screen and (max-width: ${BREAK_POINT_DESKTOP}px)`)
      .addListener(handleWindowResize);
    this.matchMediaListener[DISPLAY.MOBILE] = window
      .matchMedia(`screen and (min-width: ${BREAK_POINT_DESKTOP}px)`)
      .addListener(handleWindowResize);
  }

  @action.bound
  public OpenSideBar() {
    this.OpenDimmed();
    this.sideMenuOpen = true;
  }
  @action.bound
  public CloseSideBar() {
    this.CloseDimmed();
    this.sideMenuOpen = false;
  }

  /* Dimmed 속성*/
  @observable dimmedOpen = false;
  @observable dismissible = false;
  @observable dimmedType = DimmedType.Modal;
  @observable dimmedZIndex: number = DefaultDimmedZIndex;
  @observable dimmedOnClose?: Function;

  @action.bound
  public OpenDimmed({ dismissible, dimmedZIndex, type, onClose }: OpenDimmedPrams = {}) {
    this.dimmedZIndex = dimmedZIndex || DefaultDimmedZIndex;
    this.dimmedOpen = true;
    this.dismissible = dismissible || false;
    this.dimmedType = type || DimmedType.Modal;
    this.dimmedOnClose = onClose;

    document.body.className = 'no-scroll';
  }
  @action.bound
  public CloseDimmed() {
    if (this.dimmedType === DimmedType.Normal && typeof this.dimmedOnClose === 'function') {
      this.dimmedOnClose();
    }

    this.dimmedOpen = false;
    this.dimmedZIndex = DefaultDimmedZIndex;
    this.dismissible = false;
    this.dimmedType = DimmedType.Modal;

    document.body.className = '';
  }
  @action.bound
  public ToggleDimmed() {
    this.dimmedOpen = !this.dimmedOpen;

    if (!this.dimmedOpen) document.body.className = '';
    else document.body.className = 'no-scroll';
  }

  get headerHeight() {
    return (this.headerRef.current?.getBoundingClientRect().height as number) || 0;
  }

  get footerHeight() {
    return (this.footerRef.current?.getBoundingClientRect().height as number) || 0;
  }

  // TopAppBanner
  @observable topAppBanner: { show: boolean; expiryDate?: Moment } = {
    show: true,
    expiryDate: undefined,
  };
  @action.bound
  public CloseTopAppBanner() {
    this.topAppBanner.show = false;
    this.topAppBanner.expiryDate = moment().add(1, 'd');
  }

  get AppDownloadLink() {
    return this.deviceType === DEVICE.ANDROID
      ? 'market://details?id=com.havit.android'
      : this.deviceType === DEVICE.APPLE
      ? 'itms-apps://itunes.apple.com/kr/app/apple-store/id1134836561'
      : 'https://www.chaisplay.com/downloads/app?referrer=utm_source%3Dweb_footer%26utm_medium%3Dweb%26utm_term%3Dapp_download%26utm_campaign%3Dapp_download_landing';
  }

  get toJS() {
    return toJS({ topAppBanner: this.topAppBanner });
  }

  getCateogryModel(category: string) {
    const rtn = this.packageCategory.filter(({ me }) => me.category === category);
    return rtn.length ? rtn[0] : undefined;
  }

  isValidMatch(params: PackagesMatchParams) {
    const main = decodeURIComponent(params.main);
    const mainCategory = this.getCateogryModel(main);

    if (mainCategory) {
      if (!params.sub) return true;
      else {
        const sub = decodeURIComponent(params.sub);
        const subCategory = mainCategory.children.filter(({ category }) => category === sub);

        return subCategory.length ? true : false;
      }
    }

    return false;
  }

  @computed
  get isDesktop() {
    return this.displayType === DISPLAY.DESKTOP;
  }

  @computed
  get isTablet() {
    return this.displayType === DISPLAY.TABLET;
  }

  @computed
  get isMobile() {
    return this.displayType === DISPLAY.MOBILE;
  }

  // Alert UI ________________________________________________
  @observable alertState = {
    visible: false,
    message: '',
  };

  @action.bound
  alert(message = '') {
    if (message.length) {
      this.alertState = {
        visible: true,
        message,
      };
    }
  }
  // _________________________________________________________
}
