import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { useStores } from 'stores';
import { CertifyStep } from 'stores/models/UserModel';
import { phoneFomatter } from 'utils';

import { WrapInput, Label, Input } from '../styled';
import {
  PhoneInputErr,
  SendButton,
  WrapPhoneInput,
  CertifyButton,
  PhoneInput,
  Success,
  WrapCertifyInput,
} from './styled';
import { AuthRadio, WrapRadioButton } from './AuthRadio';

export const PhoneCertify: React.FC<{ InputKey: string }> = observer(({ InputKey }) => {
  const {
    auth,
    layout: { isMobile },
  } = useStores();
  const { register, errors, getValues, setValue, setError } = useFormContext();

  const handleSendCode = useCallback(async () => {
    const phone = getValues(InputKey);
    const methods = getValues('_certify_methods');

    try {
      auth.user?.setCertifyStep(CertifyStep.EDIT);
      await auth.sendCode(phone, methods);
      auth.user?.setCertifyStep(CertifyStep.SEND);
    } catch ({ message }) {
      setError('phone_number', {
        type: 'manual',
        message,
      });
    }
  }, [InputKey, auth, getValues, setError]);

  const handleCertifyCode = useCallback(async () => {
    const phone = getValues(InputKey);
    const code = getValues('code');

    try {
      await auth.certifyCode(phone, code);
      setValue('_certify_phone_number', phone);
    } catch ({ message }) {
      setError('code', {
        type: 'manual',
        message,
      });
    }
  }, [InputKey, auth, getValues, setError, setValue]);

  const resetPhoneNumber = useCallback(
    (event: any) => {
      setValue(InputKey, phoneFomatter(event.target.value));
    },
    [InputKey, setValue],
  );

  const reCertifyCheck = useCallback(() => {
    const isCertified = !!auth.user.phone_number_certified;
    const origin = getValues('_certify_phone_number');
    const phone = getValues(InputKey);

    if (isCertified && origin !== phone) {
      auth.user.resetPhoneNumberCertified();
    }
  }, [InputKey, auth.user, getValues]);

  const certifyStep = auth.user.certifyStep;
  const isCertified = !!auth.user.phone_number_certified;

  return (
    <>
      <WrapPhoneInput>
        <Label>휴대폰번호</Label>
        <PhoneInput $isCertified={isCertified}>
          <Input
            name={InputKey}
            type='text'
            placeholder='휴대폰 번호를 입력해주세요'
            onBlur={resetPhoneNumber}
            onChange={reCertifyCheck}
            maxLength={15}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            ref={register({
              required: true,
              validate: value => isCertified,
            })}
          />
          {isCertified && <Success>인증완료</Success>}
        </PhoneInput>
        {certifyStep === CertifyStep.INIT && !isCertified && (
          <PhoneInputErr>
            {isMobile
              ? '정확한 주문 배송을 위해 휴대폰을 인증하세요.'
              : '정확한 주문 배송을 위해 휴대폰 인증을 진행해 주세요.'}
          </PhoneInputErr>
        )}
        {certifyStep === CertifyStep.EDIT && errors.phone_number && (
          <PhoneInputErr>{errors.phone_number.message}</PhoneInputErr>
        )}
        {certifyStep === CertifyStep.SEND && errors.code && (
          <PhoneInputErr>{errors.code.message}</PhoneInputErr>
        )}
      </WrapPhoneInput>
      <WrapInput>
        <Label>인증 방법</Label>
        <WrapRadioButton>
          <AuthRadio name='_certify_methods' value='sms' text='SMS 문자' />
          <AuthRadio name='_certify_methods' value='kakao' text='카카오톡' />
        </WrapRadioButton>
        {!isCertified && (
          <SendButton type='button' onClick={handleSendCode}>
            {certifyStep === CertifyStep.INIT ? '인증번호 발송' : '재발송'}
          </SendButton>
        )}
        {certifyStep === CertifyStep.SEND ? (
          <WrapCertifyInput>
            <Label></Label>
            <Input name='code' type='text' ref={register} placeholder='인증번호 6자리 입력' />
            <CertifyButton type='button' onClick={handleCertifyCode}>
              확인
            </CertifyButton>
          </WrapCertifyInput>
        ) : null}
      </WrapInput>
    </>
  );
});
