import { PAGE_URL } from 'configs/path';
import { generatePath } from 'react-router-dom';
import { action, observable, computed, runInAction } from 'mobx';
import { RootStore } from 'stores';
import { parseLinkHeader } from 'utils';
import OrderRepository from './repository/OrderRepository';
import CartOrderItemModel from './models/CartOrderItemModel';
import qs from 'query-string';
import { getOrderToken } from 'configs/axios';
import CryptoJS from 'crypto-js';
import CouponModel from './models/CouponModel';

enum ORDER_STEP {
  READY = 'ready',
  VALDATE = 0,
  PAY,
  COMPLETE = 'success',
  DELIVERED = 'completed',
  FAILED = 'failed',
  WAITING = 'waiting',
}

export default class OrderStore {
  constructor(root: RootStore) {
    this.rootStore = root;
  }

  @action
  async fetch(orderId: number, isOrder = false) {
    const {
      data: { order },
    } = await OrderRepository.fetch(orderId);

    // FIXME: OrderModel 클래스 개발 필요함...
    runInAction(() => {
      this.isFetched = true;
      this.orderId = order.id;
      this.status = order.status as ORDER_STEP;
      this.buyer_name = order.buyer_name;
      this.buyer_phone_number = order.buyer_phone_number;
      this.address1 = order.address1;
      this.address2 = order.address2;
      this.buyer_email = order.buyer_email;
      this.subtotal_price = order.subtotal_price;
      this.shipping_price = order.shipping_price;
      // https://github.com/havitplay/chaisplay-front/issues/14
      this.extra_shipping_price = isOrder ? 0 : order.extra_shipping_price;
      this.available_coupons = order.available_coupons;
      this.price = order.price;
      this.expected_delivery_start_date = order.expected_delivery_start_date;
      this.earned_point = order.earned_point;
      this.earning_point = order.earning_point;
      this.title = order.title;
      this.order_items = order.order_items.map(
        (item, index) => new CartOrderItemModel(item, index),
      );
      this.name = order.name;
      this.phone_number = order.phone_number;
      this.shipping_request = order.shipping_request;
      this.created_at = order.created_at;
      this.paid_at = order.paid_at;
      this.pay_method = order.pay_method;
      this.vbank_holder = order.vbank_holder;
      this.vbank_name = order.vbank_name;
      this.vbank_num = order.vbank_num;
      this.vbank_date = order.vbank_date;
      this.discount_price = order.discount_price;
      this.used_point = order.used_point;
      this.coupon_price = order.coupon_price;
      this.merchant_uid = order.merchant_uid;
      this.display_status = order.display_status;
      this.display_tracking_numbers = order.display_tracking_numbers;
      this.receipt_url = order.receipt_url;
      this.trading_statement_url = order.trading_statement_url;
      this.cash_receipt_url = order.cash_receipt_url;
      this.error_msg = order.error_msg;
      this.loadOrderFromSessionStrage();
    });
  }

  @action.bound
  async purchase(order: Order.ReqValidate) {
    if (this.orderId) {
      try {
        let {
          data: { order: validate },
        } = await OrderRepository.validate(this.isSignIn, this.orderId, order);
        this.status = ORDER_STEP.VALDATE;

        this.rootStore.layout.OpenDimmed();
        const reqImp = this.getReqImpPrams(order, validate);
        const { success, imp_uid, error_msg } = await OrderRepository.pay(reqImp, validate);
        this.status = ORDER_STEP.PAY;

        const { data: o } = await OrderRepository.complete(this.orderId, {
          imp_success: success,
          imp_uid,
          error_msg,
        });

        this.status = o.order.status === 'waiting' ? ORDER_STEP.WAITING : ORDER_STEP.COMPLETE;
        this.rootStore.layout.CloseDimmed();

        this.goCompletePage();
      } catch (err) {
        switch (this.status) {
          case ORDER_STEP.READY:
            alert(err.response.data.error || '정보를 모두 입력해 주세요.');
            break;
          case ORDER_STEP.VALDATE:
            this.rootStore.layout.CloseDimmed();
            //결제 취소 후 order 새로 고침을 위해 Refresh
            this.rootStore.history.go(0);
            setTimeout(() => alert('결제가 취소되었습니다.'), 0);
            break;
          case ORDER_STEP.PAY:
            alert('고객센터에 문의바랍니다.');
            break;
        }
      }
    }
  }

  @action
  async completed(orderId: number, o: Order.ReqComplete) {
    const {
      data: { order },
    } = await OrderRepository.complete(orderId, {
      imp_uid: o.imp_uid,
      imp_success: o.imp_success,
      error_msg: o.error_msg,
    });

    runInAction(() => {
      this.status = order.status === 'waiting' ? ORDER_STEP.WAITING : ORDER_STEP.COMPLETE;
      this.isFetched = true;
      this.orderId = order.id;
      this.status = order.status as ORDER_STEP;
      this.buyer_name = order.buyer_name;
      this.buyer_phone_number = order.buyer_phone_number;
      this.address1 = order.address1;
      this.address2 = order.address2;
      this.buyer_email = order.buyer_email;
      this.subtotal_price = order.subtotal_price;
      this.shipping_price = order.shipping_price;
      this.extra_shipping_price = order.extra_shipping_price;
      this.available_coupons = order.available_coupons;
      this.price = order.price;
      this.expected_delivery_start_date = order.expected_delivery_start_date;
      this.earned_point = order.earned_point;
      this.earning_point = order.earning_point;
      this.title = order.title;
      this.order_items = order.order_items.map(
        (item, index) => new CartOrderItemModel(item, index),
      );
      this.name = order.name;
      this.phone_number = order.phone_number;
      this.shipping_request = order.shipping_request;
      this.created_at = order.created_at;
      this.paid_at = order.paid_at;
      this.pay_method = order.pay_method;
      this.vbank_holder = order.vbank_holder;
      this.vbank_name = order.vbank_name;
      this.vbank_num = order.vbank_num;
      this.vbank_date = order.vbank_date;
      this.discount_price = order.discount_price;
      this.used_point = order.used_point;
      this.coupon_price = order.coupon_price;
      this.merchant_uid = order.merchant_uid;
      this.display_status = order.display_status;
      this.display_tracking_numbers = order.display_tracking_numbers;
      this.receipt_url = order.receipt_url;
      this.trading_statement_url = order.trading_statement_url;
      this.cash_receipt_url = order.cash_receipt_url;
      this.error_msg = order.error_msg;
    });
  }

  async history() {
    const { data, headers } = await OrderRepository.history(this.filter);

    this.orders = data.orders;
    this.pagenation = parseLinkHeader(headers.link);
  }

  async delete(orderId: number) {
    return await OrderRepository.delete(orderId);
  }

  async addToCart(orderId: number) {
    return await OrderRepository.addToCart(orderId);
  }

  async deliveryCompleted(orderId: number) {
    return await OrderRepository.deliveryCompleted(orderId);
  }

  async updateAddress1(orderId: number, address1: string, zonecode: string) {
    const {
      data: { order },
    } = await OrderRepository.updateAddress1(orderId, address1, zonecode);

    runInAction(() => {
      this.extra_shipping_price = order.extra_shipping_price;
      this.price = order.price;
    });
  }

  async fetchNonMember(email: string, phoneNumber: string) {
    return await OrderRepository.fetchNonMember(email, phoneNumber);
  }

  fetchFilter(search: string) {
    const { page } = qs.parse(search);
    this.filter.page = parseInt(page as string) || 1;
  }

  setFilter(query: any) {
    const newQuery = { ...qs.parse(document.location.search), ...query };

    this.rootStore.history.push({
      pathname: document.location.pathname,
      search: qs.stringify(newQuery),
    });
  }

  get completePagePath() {
    return generatePath(PAGE_URL.COMPLETE, { id: this.orderId });
  }

  goCompletePage() {
    this.rootStore.history.push(this.completePagePath);
  }

  getReqImpPrams(
    { user, order }: Order.ReqValidate,
    { merchant_uid }: Order.ResValidate['order'],
  ): Order.ReqImp {
    const { title } = this;
    const { email, phone_number } = this.rootStore.auth.user;
    const isApp = false; // TODO
    let vbank_due = '';

    if (order.pay_method === 'vbank') {
      const threeDayLater = new Date(new Date().getTime() + 60 * 60 * 24 * 3 * 1000);
      vbank_due =
        threeDayLater.getFullYear() +
        ('0' + (threeDayLater.getMonth() + 1)).slice(-2) +
        ('0' + threeDayLater.getDate()).slice(-2);
    }

    return {
      pg: 'nice',
      merchant_uid,
      name: title,
      amount: +order.price,
      buyer_email: email || order.orderer_email,
      buyer_name: user?.fullname || order.orderer_name,
      buyer_tel: phone_number || order.orderer_phone_number,
      buyer_addr: `${order.address1} ${order.address2}`,
      buyer_postcode: null,
      app_scheme: isApp ? 'havit' : null,
      niceMobileV2: true,
      pay_method: order.pay_method,
      m_redirect_url: global.location.origin + this.completePagePath,
      vbank_due,
    };
  }

  @computed
  get maxPoint() {
    const price = this.price - this.coupon_price;
    const point = price > this.userPoint ? this.userPoint : price;

    return point > 0 ? point : 0;
  }

  get isSignIn() {
    return this.rootStore.auth.isSignIn;
  }

  get userPoint() {
    return this.rootStore.auth.user.point;
  }

  @computed
  get displayPayMethod() {
    if (this.pay_method === 'card') return '카드결제';
    else if (this.pay_method === 'vbank') return '가상계좌';
    else if (this.pay_method === 'trans') return '계좌이체';

    return '';
  }

  @computed
  get isVbank() {
    return this.pay_method === 'vbank';
  }

  @computed
  get totalPrice() {
    return this.subtotal_price + this.shipping_price + this.extra_shipping_price;
  }

  rootStore: RootStore = {} as RootStore;
  status: ORDER_STEP = ORDER_STEP.READY;

  @observable isFetched: boolean = false;
  @observable orderId!: number;
  @observable buyer_name: string = '';
  @observable buyer_phone_number: string = '';
  @observable address1: string = '';
  @observable address2: string = '';
  @observable buyer_email: string = '';
  @observable subtotal_price: number = 0;
  @observable shipping_price: number = 0;
  @observable extra_shipping_price: number = 0;
  @observable available_coupons: any[] = [];
  @observable price: number = 0;
  @observable expected_delivery_start_date: string = '';
  @observable earned_point: number = 0;
  @observable earning_point: number = 0;
  title: string = '';

  // Complete
  @observable order_items: CartOrderItemModel[] = [];
  @observable name: string = '';
  @observable phone_number: string = '';
  @observable shipping_request: string = '';
  @observable paid_at?: string;
  @observable created_at?: string;
  @observable pay_method: string = 'card';
  @observable vbank_holder?: string;
  @observable vbank_name?: string;
  @observable vbank_num?: string;
  @observable vbank_date?: string;
  @observable discount_price: number = 0;
  @observable used_point: number = 0;
  @observable coupon_price: number = 0;
  @observable merchant_uid: string = '';
  @observable display_status: string = '';
  @observable display_tracking_numbers: string[] = [];
  @observable receipt_url: string = '';
  @observable trading_statement_url: string = '';
  @observable cash_receipt_url: string = '';
  @observable error_msg: string = '';

  @observable orders: any[] = [];
  @observable pagenation: any = {};
  @observable filter: any = { per_page: 10, page: 1 };

  // 정리
  findCoupon(coupon_id: number, coupon_price: number): Coupon.Model | undefined {
    let coupon = this.available_coupons.find(({ id }) => id === coupon_id);

    if (coupon) {
      coupon = new CouponModel(coupon);
      if (coupon.getDiscountPrice(this.subtotal_price) === coupon_price) return coupon;
    }
  }

  @computed
  get orderPrice() {
    return this.price;
  }

  @action
  reset() {
    this.isFetched = false;
    this.savedOrder = null;
    this.resetOrderInSessionStorage();
  }

  @observable savedOrder: Order.ReqValidate | null = null;

  getSavedOrderKey() {
    return getOrderToken() + this.orderId;
  }

  saveOrderToSessionStorage(order: Order.ReqValidate) {
    if (!!sessionStorage) {
      const {
        auth: { user },
      } = this.rootStore;
      const key = this.getSavedOrderKey();

      /* 핸드폰 인증 여부, 추가 배송비의 경우 별도처리 */
      order._phone_number_certified = user.phone_number_certified;
      order._extra_shipping_price = this.extra_shipping_price;

      sessionStorage.setItem(
        key,
        CryptoJS.AES.encrypt(JSON.stringify(order), key) as unknown as string,
      );
    }
  }

  resetOrderInSessionStorage() {
    const key = this.getSavedOrderKey();
    sessionStorage.removeItem(key);
  }

  @action.bound
  loadOrderFromSessionStrage() {
    const checkNonMemberForm = ({ order: _order, _certify_methods }: Order.ReqValidate) =>
      _order.orderer_name !== '' ||
      _order.orderer_phone_number !== '' ||
      _order.orderer_email !== '' ||
      _certify_methods !== 'sms';
    const checkMemberForm = ({ user: _user, _certify_methods }: Order.ReqValidate) => {
      const {
        auth: { user },
      } = this.rootStore;

      return (
        _user.fullname !== (user?.fullname ?? this.buyer_name) ||
        _user.phone_number !== (user?.phone_number ?? this.buyer_phone_number) ||
        _user.zonecode !== user?.zonecode ||
        _user.address1 !== user?.address1 ||
        _user.address2 !== user?.address2 ||
        _certify_methods !== 'sms'
      );
    };
    const checkShippingForm = ({ order: _order }: Order.ReqValidate) =>
      _order.name !== '' ||
      _order.phone_number !== '' ||
      _order.address1 !== '' ||
      _order.address2 !== '' ||
      _order.shipping_request !== '';
    const checkPaymentMethods = ({ order: _order }: Order.ReqValidate) =>
      _order.pay_method !== 'card';
    const checkPaymentForm = ({ order: _order }: Order.ReqValidate) =>
      _order.used_point !== '' || _order.my_coupon_id !== '' || _order.my_coupon_price !== '';

    if (!!sessionStorage) {
      const key = this.getSavedOrderKey();
      const ciphertext = sessionStorage.getItem(key);

      if (ciphertext) {
        const {
          auth: { user, isSignIn },
        } = this.rootStore;
        const bytes = CryptoJS.AES.decrypt(ciphertext, key);
        const savedOrder = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const message =
          '주문서가 새로고침 되었습니다. \n이전에 입력하신 정보(' +
          (isSignIn ? '회원, 배송, 결제 방법, 쿠폰, 포인트' : '주문자, 배송, 결제 방법') +
          ')를 그대로 유지하시겠습니까?';

        if (
          savedOrder &&
          ((isSignIn ? checkMemberForm(savedOrder) : checkNonMemberForm(savedOrder)) ||
            checkShippingForm(savedOrder) ||
            checkPaymentMethods(savedOrder) ||
            (isSignIn && checkPaymentForm(savedOrder))) &&
          window.confirm(message)
        ) {
          this.savedOrder = savedOrder;
          user.setPhoneNumberCertified(!!this.savedOrder?._phone_number_certified);
          this.extra_shipping_price = savedOrder._extra_shipping_price;
        } else {
          this.resetOrderInSessionStorage();
        }
      }
    }
  }
}
