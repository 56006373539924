import styled from 'styled-components';

export const PopupWrapper = styled.div`
  position: absolute;
  z-index: 11;
  top: 0;
  left: 50%;
  margin-top: -20px;
  transform: translateX(-50%) translateY(-100%);
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const PopupHeader = styled.div`
  padding: 16px 24px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  background-color: #f8f8f8;
  overflow: hidden;

  ${({ theme }) => theme.tablet`
    padding: 10px 20px;
    font-size: 14px;
  `}
`;

export const PopupBody = styled.div`
  position: relative;
  z-index: 1;
  padding: 16px 24px;
  border-radius: 0 0 5px 5px;
  background-color: #fff;
  line-height: 1.5;
  overflow: hidden;

  ${({ theme }) => theme.tablet`
    padding: 10px 20px;
    font-size: 12px;
  `}
`;

export const PopupTail = styled.div`
  position: absolute;
  z-index: 0;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #fff;
  transform: translateX(-50%) translateY(-50%) rotate3d(0, 0, 1, 45deg) skew(5deg, 5deg);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

export const PopupIcon = styled.i`
  display: block;
  width: 18px;
  height: 18px;
  background: url('/icons/exclamation-mark.svg') no-repeat;
  background-size: 100%;
`;
