import React from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import cx from 'classnames';

import { PAGE_URL } from 'configs/path';
import { WrapFilter, WrapFilterLink, FilterLink } from 'components/styled/ListFilter';
import { playsAge } from 'configs/filterType';

const AgeFilter = React.memo(() => {
  const location = useLocation();
  const { age } = qs.parse(location.search);

  return (
    <WrapFilter>
      <FilterItem isActive={age === undefined} to={PAGE_URL.PLAYS} text={'전체'} lastRowItems={3} />
      {playsAge.map(({ label, value }) => (
        <FilterItem
          key={`Plays_AgeFtiler_${value}`}
          isActive={age === value}
          to={`${PAGE_URL.PLAYS}?age=${value}`}
          text={label}
          lastRowItems={2}
        />
      ))}
    </WrapFilter>
  );
});

export default AgeFilter;

interface FilterItemProps {
  isActive: boolean;
  to: string;
  text: string;
  lastRowItems: number;
}

const FilterItem: React.FC<FilterItemProps> = React.memo(({ isActive, to, text, lastRowItems }) => (
  <WrapFilterLink $lastRowItems={lastRowItems}>
    <FilterLink className={cx({ active: isActive })} to={to}>
      {text}
    </FilterLink>
  </WrapFilterLink>
));
