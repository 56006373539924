import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { searchAge } from 'configs/filterType';
import { useSearchStore } from 'stores/local/Search';

export default observer(function KeywardFilterTab() {
  const { age, setAge } = useSearchStore();

  return (
    <WrapRadioButton>
      {searchAge.map(({ label, value }, idx) => (
        <RadioButton key={`SearchAge${value}`}>
          <Radio
            type='radio'
            id={`search-age-${idx}`}
            name='age'
            value={value}
            checked={age === value}
            onChange={setAge}
          />
          <RadioLabel htmlFor={`search-age-${idx}`}>{label}</RadioLabel>
        </RadioButton>
      ))}
    </WrapRadioButton>
  );
});

const WrapRadioButton = styled.div`
  overflow: hidden;

  ${({ theme }) => theme.desktop`
    margin: 0 16px;
  `}

  ${({ theme }) => theme.tablet`
    margin-bottom: 16px;
  `};
`;

const RadioButton = styled.div`
  float: left;
`;
const RadioLabel = styled.label`
  display: block;
  width: 80px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  ${({ theme }) => theme.desktop`
    padding: 8px 0;
    font-weight: bold;
    color: #b3b3b3;
  `}

  ${({ theme }) => theme.tablet`
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 8px 0;
    border-radius: 3px;
    background-color: #fff;
    color: ${theme.TextColor};
    font-size: 12px;
  `}
`;
const Radio = styled.input`
  display: none;

  &:checked + ${RadioLabel} {
    ${({ theme }) => theme.desktop`
      border-bottom: 2px solid ${theme.subActiveColor};
      color: ${theme.subActiveColor};
    `}

    ${({ theme }) => theme.tablet`
      background-color: ${theme.subActiveColor};
      color: #fff;
    `}
  }
`;
