import { generatePath } from 'react-router-dom';
import axios, { AxiosPromise } from 'axios';
import { API_URL } from 'configs/path';
import { ICartJS } from 'interfaces/PackageDetail';
import CartOrderItemModel from 'stores/models/CartOrderItemModel';

class CartRepository {
  fetch(): AxiosPromise<Cart.RootObject> {
    return axios.get(API_URL.CART);
  }

  getShippingPrices = async (): Promise<Cart.ShippingPrices> => {
    try {
      const { data } = await axios.get<Cart.ShippingPrices>(API_URL.SHIPPING_PRICES);
      return data;
    } catch (error) {
      return { free_shipping_minimum: 50000 };
    }
  };

  add({ id, select }: ICartJS): AxiosPromise<any> {
    return axios.post(API_URL.CART_ADD, {
      order_item: {
        package_id: id,
        items_count: select.quantity,
        unit_price: select.unit.price,
        package_option_id: select.isOption ? select.unit.id : undefined,
      },
    });
  }

  async delete(items: CartOrderItemModel[]): Promise<any> {
    return Promise.all(
      items.map(({ id }) => {
        return axios.delete(generatePath(API_URL.CART_EDIT, { id }));
      }),
    ).then(res => res.pop()?.data);
  }

  updateCheck({ id, checked }: CartOrderItemModel) {
    return axios.put(generatePath(API_URL.CART_EDIT, { id }), {
      order_item: {
        checked,
      },
    });
  }

  updateQuantity({ id, package_id, items_count }: CartOrderItemModel) {
    return axios.put(generatePath(API_URL.CART_EDIT, { id }), {
      order_item: {
        package_id,
        items_count,
      },
    });
  }

  updateLocal(items: CartOrderItemModel[]): AxiosPromise<Cart.AddItems2Cart> {
    return axios.post(API_URL.CART_LOCAL, {
      order_items: items.map(({ package_id, items_count, unit_price, package_option_id }) => ({
        package_id,
        items_count,
        unit_price,
        package_option_id,
      })),
    });
  }
}

export default new CartRepository();
