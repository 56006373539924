import React from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { useStores } from 'stores';
import { BgImg } from 'components/atoms';
import { ClearLink } from 'components/atoms/Clear';
import PackageItem from '../Share/PackageItem';

const SlideItem: React.FC<Home.Package> = observer(props => {
  const { id, tag, img, age, name, origin, price } = props;
  const {
    layout: { isDesktop },
  } = useStores();

  if (isDesktop) {
    return <PackageItem txtCenter {...props} />;
  }

  return (
    <ItemWrap to={generatePath(PAGE_URL.PACKAGE, { id })}>
      <ImgWrap>
        {tag ? <TagBest>{tag}</TagBest> : null}
        <Img $src={img} $heightPercent={100} />
      </ImgWrap>
      <ContentWrap>
        <Age>{age}</Age>
        <Name>{name}</Name>
        <OriginPrice>{origin}</OriginPrice>
        <Price>{price}</Price>
      </ContentWrap>
    </ItemWrap>
  );
});

export default SlideItem;

const ItemWrap = styled(ClearLink)`
  display: flex;
  padding: 8px 0;
  border-top: 2px solid #eaeaea;
`;
const ImgWrap = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
`;
const Img = styled(BgImg)`
  border-radius: 3px;
`;

const Tag = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 3px 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 3px;

  line-height: 12px;
  font-size: 8px;
  font-weight: bold;
  color: #fff;
`;
const TagBest = styled(Tag)`
  background-color: ${({ theme }) => theme.mainActive};
`;

const ContentWrap = styled.div`
  margin-left: 11px;
  padding-right: 6px;
  width: calc(100% - 96px);
  overflow: hidden;
`;
const Name = styled.div`
  margin-bottom: 5px;
  height: 36px;
  line-height: 1.5;
  font-size: 12px;
  color: #262626;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Age = styled.div`
  margin-bottom: 5px;
  line-height: 15px;
  font-size: 10px;
  font-weight: bold;
  color: #262626;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const OriginPrice = styled.div`
  float: left;
  margin-right: 8px;
  line-height: 1.5;
  font-size: 16px;
  color: #b3b3b3;
  text-decoration: line-through;

  ${({ theme }) => theme.tablet`
    margin-top: 3px;
    margin-right: 3px;
    font-size: 10px;
  `}
`;

const Price = styled.span`
  float: left;

  line-height: 18px;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};
`;
