import React from 'react';
import { observer } from 'mobx-react';

import { DefaultLayout } from 'layouts/Default';
import { PlayDetailProvider, useInitPlayDetail, usePlayDetailStore } from './store';

import { WrapDetail } from './styled';
import ReactHelmet from 'components/atoms/Helmet';
import AppAdBanner from 'components/molecules/AppAdBanner';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { MobileShareButton } from 'components/molecules/ShareButton';
import * as CP from 'components/organisms/PlayDetail';

const PlayDetailContainer = observer(() => {
  const { isInit, play } = usePlayDetailStore();
  useInitPlayDetail();

  if (!isInit) return null;

  const meta = {
    title: play.name,
    description: play.effect,
    image: play.image_url,
  };

  return (
    <>
      <ReactHelmet data={meta} />
      <WrapDetail>
        <SubHeader>
          <MobileBackButton />
          <SubHeaderTitle $withLeftButton $withRightButton>
            {play.name}
          </SubHeaderTitle>
          <MobileShareButton {...meta} />
        </SubHeader>
        <CP.PlayInfo />
        <CP.PlayPdf />
        <CP.MakingStep />
        <CP.PlayStep />
        <CP.PremiumPackages />
        <CP.Dialogs />
        <CP.TipScript />
        <CP.Notice ages={play.ages} />
        <CP.Tags />
        <CP.Comments />
        <CP.RelatedContents />
        <CP.RandomPlays />
        <CP.Copyright />
      </WrapDetail>
      <AppAdBanner />
    </>
  );
});

export default DefaultLayout(PlayDetailContainer, { Provider: PlayDetailProvider });
