import { observable, flow } from 'mobx';
import HomeRepository from 'pages/Home/repository/HomeRepository';
import HomeBannerModel, { ResHomeBanner } from 'pages/Home/models/BannerModel';
import HomeRecommendedModel, { ResHomeRecommendeds } from 'pages/Home/models/RecommendedModel';
import PackageModel, { ResPackage } from 'stores/models/PackageModel';
import { BEST_TYPE } from 'interfaces/Home';

class HomeStore {
  isFecth = false;
  @observable banners: HomeBannerModel[] = [];
  @observable recommendeds: HomeRecommendedModel[] = [];
  @observable new: PackageModel[] = [];
  @observable bestType?: BEST_TYPE;
  @observable best: PackageModel[] = [];

  fetchBanners = flow(function* (this: HomeStore) {
    try {
      const { data } = yield HomeRepository.fetchBanners();
      this.banners = data.banners.map((banner: ResHomeBanner) => new HomeBannerModel(banner));
    } catch (error) {
      throw error;
    }
  });

  fetchRecommendeds = flow(function* (this: HomeStore) {
    try {
      const { data } = yield HomeRepository.fetchRecommendeds();
      this.recommendeds = data.recommended_packages.map(
        (rpackage: ResHomeRecommendeds) => new HomeRecommendedModel(rpackage),
      );
    } catch (error) {
      throw error;
    }
  });

  fetchNew = flow(function* (this: HomeStore) {
    try {
      const { data } = yield HomeRepository.fetchNew();
      this.new = data.packages.map((npackage: ResPackage) => new PackageModel(npackage));
    } catch (error) {
      throw error;
    }
  });

  fetchBest = (type: BEST_TYPE) =>
    flow(function* (store: HomeStore) {
      store.bestType = type;

      try {
        let packages;

        switch (type) {
          case BEST_TYPE.TRANSACTION: {
            const { data } = yield HomeRepository.fetchByTransaction();
            packages = data.packages;
            break;
          }
          case BEST_TYPE.POPULARITY: {
            const { data } = yield HomeRepository.fetchByPopularity();
            packages = data.packages;
            break;
          }
          case BEST_TYPE.INSTAGRAM: {
            const { data } = yield HomeRepository.fetchByInstagram();
            packages = data.packages;
            break;
          }
          case BEST_TYPE.BEST: {
            const { data } = yield HomeRepository.fetchBest();
            packages = data.packages;
            break;
          }
        }

        store.best = packages.map((bpackage: ResPackage) => new PackageModel(bpackage));
      } catch (error) {
        throw error;
      }
    })(this);

  async fecth(): Promise<any> {
    await Promise.all([
      this.fetchBanners(),
      this.fetchRecommendeds(),
      this.fetchBest(BEST_TYPE.TRANSACTION),
      this.fetchNew(),
    ]);
    this.isFecth = true;
  }
}

export default HomeStore;
