import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { observable, flow, action } from 'mobx';
import { useLocalStore } from 'mobx-react';

import EventRepository from '../repository/EventRepository';

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      events: [] as any[],
      fetch() {
        flow(function* (this: any) {
          try {
            this.isInit = false;

            const { data } = yield EventRepository.fetchList();
            this.events = data.events;

            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
    },
    {
      fetch: action,
    },
  );

  return store;
};

type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const EventListProvider = ({ children }: any) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useEventListStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitEventList = () => {
  const { fetch } = useEventListStore();
  const location = useLocation();

  useEffect(() => {
    fetch();
  }, [fetch, location]);
};
