const useCacheImages = (srcArray: any[], setIsLoading?: any) => {
  srcArray.forEach(src => {
    if (src) {
      new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = ev => resolve(ev);
        img.onerror = ev => reject(ev);
      });
    }
  });

  if (setIsLoading) setIsLoading(false);
};

export default useCacheImages;
