import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { phoneFomatter } from 'utils';
import { Section, SectionTitle, Table, Tr, Th, Td, MobileTableWrapper } from './styled';

export default observer(function BuyerTable() {
  const {
    order: { buyer_name, buyer_phone_number, buyer_email },
  } = useStores();

  return (
    <Section>
      <SectionTitle>구매자 정보</SectionTitle>
      <MobileTableWrapper>
        <Table>
          <tbody>
            <Tr>
              <Th>이름</Th>
              <Td>{buyer_name}</Td>
            </Tr>
            <Tr>
              <Th>휴대폰번호</Th>
              <Td>{phoneFomatter(buyer_phone_number)}</Td>
            </Tr>
            <Tr>
              <Th>이메일주소</Th>
              <Td>{buyer_email}</Td>
            </Tr>
          </tbody>
        </Table>
      </MobileTableWrapper>
    </Section>
  );
});
