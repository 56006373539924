import styled from 'styled-components';

export const PackagesTitle = styled.h1`
  display: inline-block;
  line-height: 1.5;

  ${({ theme }) => theme.desktop`
    margin-top: 48px;
    padding-bottom: 6px;
    border-bottom: solid 3px #262626;
    font-size: 32px;
  `}

  ${({ theme }) => theme.tablet`
    margin: 20px 0 10px;
    font-size: 14px;
  `}
`;
