import React, { useEffect, Fragment, useRef, useState, useCallback } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

import { useStores } from 'stores';
import { ILayoutOptions } from 'interfaces/layout';

import { Page } from 'components/atoms';
import { ConditionalWrapper } from 'components/atoms/ConditionalWrapper';
import ModalLayer from './ModalLayer';

export const DefaultLayout = (
  ChildComponent: React.ComponentType<any>,
  params = {} as ILayoutOptions,
) =>
  function WrapperFn(props: RouteComponentProps) {
    const history = useHistory();
    const { layout, setHistory } = useStores();
    const { Provider, Modal, Option } = params;

    const PageRef = useRef<HTMLDivElement>(null);
    const [minHeight, setminHeight] = useState(window.innerHeight);
    const onEndTransition = useCallback(() => {
      setTimeout(() => {
        if (Option?.full) {
          setminHeight(window.innerHeight - layout.headerHeight - layout.footerHeight - 1);
        } else {
          const height = PageRef.current?.clientHeight || window.innerHeight;
          if (height < window.innerHeight) setminHeight(0);
        }
      }, 200);
    }, [Option, layout.footerHeight, layout.headerHeight]);

    useEffect(() => {
      layout.CloseSideBar();
      layout.CloseDimmed();
      setHistory(history);
    }, [props, layout, setHistory, history]);

    useEffect(() => {
      onEndTransition();
    }, [layout.displayType, onEndTransition]);

    return useObserver(() => {
      return (
        <Page $minHeight={minHeight}>
          <CSSTransition
            in={layout.isInit}
            appear={true}
            timeout={200}
            classNames='pg-animation page-fade'
            unmountOnExit
            addEndListener={onEndTransition}>
            <div ref={PageRef}>
              <ConditionalWrapper
                condition={Provider}
                wrapper={(children: React.ReactNode) => <Provider>{children}</Provider>}>
                <Fragment>
                  <ChildComponent setBodyMinHeight={setminHeight} {...props} />
                </Fragment>
              </ConditionalWrapper>
            </div>
          </CSSTransition>
          <ModalLayer store={Modal} />
        </Page>
      );
    });
  };
