import React from 'react';
import { observer } from 'mobx-react';
import { AccordionItemButton, AccordionItemState } from 'react-accessible-accordion';
import { useStore } from 'pages/PackageDetail/store';
import {
  Item,
  Heading,
  CloseButton,
  OpenButton,
  ItemPanel,
} from 'components/atoms/AccordionStyled';
import { DesktopBr } from 'components/atoms';

const DeliveryGuideAccordion: React.FC = observer(() => {
  const {
    packageDetail: { freeShippingPrice, shippingPrice },
  } = useStore();

  return (
    <Item>
      <Heading>
        <AccordionItemButton>
          재화등의 배송방법에 관한 정보
          <AccordionItemState>
            {({ expanded }) => (expanded ? <CloseButton /> : <OpenButton />)}
          </AccordionItemState>
        </AccordionItemButton>
      </Heading>
      <ItemPanel>
        <dl>
          <div>
            <dt>재화등의 배송방법, 배송비에 관한 정보</dt>
            <dd>
              <p>
                택배, 편도 {shippingPrice}원 (제품 최종 구매액 {freeShippingPrice}원 이상 시 편도
                비용 {shippingPrice}원 무료, 도서 산간 지역 3,000~5,000원 비용 추가 과금)
              </p>
            </dd>
          </div>
          <div>
            <dt>주문 이후 예상되는 배송기간</dt>
            <dd>
              <p>대금 지급일로부터 3일 이내에 발송</p>
            </dd>
          </div>
          <div>
            <dt>
              제품하자, 오배송 등에 따른 청약철회 등의 경우 청약철회 등을 할 수 있는 기간 및
              통신판매업자가 부담하는 반품비용 등에 관한 정보
            </dt>
            <dd>
              <p>
                전자상거래등에서의소비자보호관한법률 등에 의한 제품의 하자 또는 오배송 등으로 인한
                청약철회의 경우에는 상품 수령 후 3개월 이내, 그 사실을 안 날 또는 알 수 있었던
                날로부터 30일 이내에 청약철회를 할 수 있으며, 반품비용은 통신판매업자가 부담합니다.
              </p>
            </dd>
          </div>
          <div>
            <dt>
              제품하자가 아닌 소비자의 단순변심, 착오구매에 따른 청약철회 시 소비자가 부담하는
              반품비용 등에 관한 정보
            </dt>
            <dd>
              <p>
                전자상거래등에서의소비자보호관한법률 편도 3,500원 (최초 배송비 무료인 경우 7,000원
                부과), 제품의 사이즈가 가로 50, 세로 30을 넘는 경우, 편도 4,000원 (최초 배송비
                무료인 경우 8,000원 부과), 도서 산간 지역 3,000~5,000원 비용 추가 과금
              </p>
            </dd>
          </div>
          <div>
            <dt>재화등의 교환/반품/보증 조건 및 품질보증기준</dt>
            <dd>
              <p>소비자분쟁해결기준(공정거래위원회 고시) 및 관계법령에 따릅니다.</p>
            </dd>
          </div>
          <div>
            <dt>재화등의 A/S 관련 전화번호</dt>
            <dd>
              <p>
                카카오톡 &gt; '해빛' 으로 문의 주시면 가장 신속한 상담이 가능합니다. / 07051380722
              </p>
            </dd>
          </div>
          <div>
            <dt>
              대금을 환불받기 위한 방법과 환불이 지연될 경우 지연에 따른 배상금을 지급받을 수 있다는
              사실 및 배상금 지급의 구체적 조건 및 절차
            </dt>
            <dd>
              <p>
                주문취소 및 대금의 환불은 차이의 놀이앱 '마이페이지' 혹은 카카오톡 '해빛' 고객상담
                채널을 통해 신청할 수 있으며, 전자상거래 등에서의 소비자보호에 관한 법률에 따라
                소비자의 청약철회 후 판매자가 재화 등을 반환 받은 날로부터 3영업일 이내에 지급받은
                대금의 환급을 정당한 사유 없이 지연하는 때에는 소비자는 지연기간에 대해서 연 30%
                지연배상금을 판매자에게 청구할 수 있습니다.
              </p>
            </dd>
          </div>
          <div>
            <dt>
              소비자피해보상의 처리, 재화등에 대한 불만 처리 및 소비자와 사업자 사이의 분쟁처리에
              관한 사항
            </dt>
            <dd>
              <p>
                소비자분쟁해결기준(공정거래위원회 고시) 및 관계법령에 따릅니다.
                <br />
                <DesktopBr />
              </p>
            </dd>
          </div>
          <div>
            <dt>거래에 관한 약관의 내용 또는 확인할 수 있는 방법</dt>
            <dd>
              <p>사이트 하단의 이용 약관 링크 참조</p>
            </dd>
          </div>
        </dl>
      </ItemPanel>
    </Item>
  );
});

export default DeliveryGuideAccordion;
