import React, { RefObject, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores';
import { useToggle } from 'components/hooks/useToggle';
import { useOnClickOutside } from 'components/hooks/useOnClickOutside';
import { ClearButton } from 'components/atoms/Clear';
import { PopupIcon, PopupWrapper, PopupHeader, PopupBody, PopupTail } from 'components/atoms/Popup';

export const BenefitPopup: React.FC = React.memo(() => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, handleToggle] = useToggle(false);

  useOnClickOutside(wrapperRef, () => {
    if (wrapperRef) handleToggle(false);
  });

  return (
    <Wrapper ref={wrapperRef}>
      <ClearButton ref={buttonRef} type={'button'} onClick={handleToggle}>
        <PopupIcon />
      </ClearButton>
      {isOpen ? <InnerPopup buttonRef={buttonRef} /> : null}
    </Wrapper>
  );
});

const InnerPopup: React.FC<{ buttonRef: RefObject<HTMLButtonElement> }> = observer(
  ({ buttonRef }) => {
    const {
      layout: { isMobile },
    } = useStores();
    const popupRef = useRef<HTMLDivElement>(null);
    const tailRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const popupEl = popupRef.current;
      const buttonEl = buttonRef.current;
      const tailEl = tailRef.current;

      if (isMobile && popupEl && buttonEl && tailEl) {
        const { width: popupWidth } = popupEl.getBoundingClientRect();
        const { left, width: buttonWidth } = buttonEl.getBoundingClientRect();

        popupEl.style.cssText = 'top: unset; left: 50%;';
        tailEl.style.cssText = `left: ${
          left - (document.body.clientWidth - popupWidth) / 2 + buttonWidth / 2
        }px`;
      }
    }, [buttonRef, isMobile]);

    return (
      <Popup ref={popupRef}>
        <PopupHeader>차이의 놀이 적립 혜택</PopupHeader>
        <PopupBody>
          <>
            <span>구매 적립 포인트는 실 결제 금액 기준으로 적립됩니다. (포인트 사용금액 제외)</span>
            <br />
          </>
          <BoxContainer>
            <Box>
              <dt>3만원 이상 구매시</dt>
              <dd>600포인트 적립</dd>
              <dt>5만원 이상 구매시</dt>
              <dd>2,000포인트 적립</dd>
              <dt>7만원 이상 구매시</dt>
              <dd>3,000포인트 적립</dd>
              <dt>10만원 이상 구매시</dt>
              <dd>5,000포인트 적립</dd>
              <dt>15만원 이상 구매시</dt>
              <dd>7,500포인트 적립</dd>
            </Box>
            <Box>
              <dt>20만원 이상 구매시</dt>
              <dd>12,000포인트 적립</dd>
              <dt>30만원 이상 구매시</dt>
              <dd>20,000포인트 적립</dd>
              <dt>50만원 이상 구매시</dt>
              <dd>33,500포인트 적립</dd>
              <dt>70만원 이상 구매시</dt>
              <dd>48,000포인트 적립</dd>
              <dt>90만원 이상 구매시</dt>
              <dd>62,000포인트 적립</dd>
              <dt>100만원 이상 구매시</dt>
              <dd>70,000포인트 적립</dd>
            </Box>
          </BoxContainer>
        </PopupBody>
        <PopupTail ref={tailRef} />
      </Popup>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: sub;

  ${({ theme }) => theme.tablet`
    vertical-align: text-bottom;
  `}

  ${({ theme }) => theme.mobile`
    position: unset;
  `}
`;

const Popup = styled(PopupWrapper)`
  width: 620px;

  ${({ theme }) => theme.tablet`
    width: 255px;
  `}

  ${({ theme }) => theme.mobile`
    margin-top: -35px;
  `}
`;

const BoxContainer = styled.div`
  ${({ theme }) => theme.desktop`
    display: flex;
  `}
`;

const Box = styled.dl`
  margin: 16px 0 0px;

  dt,
  dd {
    float: left;
    margin: 0;
    width: 50%;
  }

  dd {
    color: ${({ theme }) => theme.mainActive};
  }

  ${({ theme }) => theme.desktop`
    flex: 50%;

    dt,
    dd {
      margin-bottom: 8px;
      padding-right: 8px;
    }

    dt {
      text-align: right;
    }

    dd {
      width: 130px;
    }

    &:first-child {
      dt {
        width: 140px;
      }
    }

    &:last-child {
      dt {
        padding-left: 8px;
        width: 155px;
      }
      dd {
        padding-right: 0;
      }
    }
  `}

  ${({ theme }) => theme.tablet`
    overflow: hidden;

    & + & {
      margin-top: 0;
    }

    dt,
    dd {
      margin-bottom: 6px;
    }

    dd {
      padding-left: 8px;
    }
  `}
`;
