import { useState, useCallback } from 'react';

const useToggle = (initialState = false) => {
  const [isToggled, setIsToggled] = useState(initialState);
  const toggle = useCallback(
    (prop?: any) => {
      setIsToggled(state => (typeof prop === 'boolean' ? prop : !state));
    },
    [setIsToggled],
  );

  return [isToggled, toggle] as const;
};

const useToggleList = (initialState: any[]) => {
  const [isToggled, setIsToggled] = useState(initialState);
  const setToggle = useCallback(
    (key: any) => () => {
      setIsToggled(state => {
        return state.findIndex(a => a === key) !== -1
          ? [...state.filter(a => a !== key)]
          : [...state, key];
      });
    },
    [setIsToggled],
  );

  return [isToggled, setToggle] as const;
};

export { useToggle, useToggleList };
