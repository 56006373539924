import styled from 'styled-components';
import * as atoms from '../styled';
import { ClearButton, ClearLink } from 'components/atoms/Clear';
import { RadioButtonCss } from 'components/atoms/Form';

export const WrapModal = styled(atoms.WrapModal)`
  padding: 0;
  border-radius: 0;

  ${({ theme }) => theme.desktop`
    width: 582px;
    height: 752px;
  `}

  ${({ theme }) => theme.tablet`
    width: 60vw;
    min-width: 280px;
    height: 432px;
  `}
`;

export const ModalHeader = styled.div`
  position: relative;
  padding: 24px;

  ${({ theme }) => theme.tablet`
    padding: 15px 20px;
    font-size: 14px;
  `}
`;

export const HeaderTitle = styled.div`
  font-size: 24px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    margin-bottom: 8px;
    font-size: 16px;
  `}
`;

export const HeaderButton = styled(ClearLink)`
  ${atoms.ModalButtonCss}

  ${({ theme }) => theme.desktop`
    position: absolute;
    top: 22px;
    right: 24px;
    width: 158px;
    font-weight: normal;
  `}

  ${({ theme }) => theme.tablet`
    margin-left: 7px;
    padding: 0;
    width: 71px;
    background-color: ${theme.subActiveColor};
    line-hieght: 24px;
    font-size: 11px;
    color: #fff;
  `}
`;

export const ModalBody = styled.div`
  padding: 24px;
  height: 580px;
  background-color: #f8f8f8;
  overflow-y: auto;

  ${({ theme }) => theme.tablet`
    padding: 0;
    height: 301px;
  `}
`;

export const RadioLabel = styled.label`
  ${({ theme }) => theme.tablet`
    line-height: 20px!important;
    font-size: 13px;
    font-weight: bold;
  `}
`;

export const RadioButton = styled.div`
  ${RadioButtonCss}

  input + label {
    padding-left: 36px;

    ${({ theme }) => theme.tablet`
      padding-left: 32px;
      width: 100%;
    `}

    &:before {
      top: 1px;
      width: 24px;
      height: 24px;
      border-radius: 24px;

      ${({ theme }) => theme.tablet`
        width: 20px;
        height: 20px;
        border-radius: 20px;
      `}
    }
  }

  input:checked + label {
    &:after {
      top: 7px;
      left: 6px;
      width: 12px;
      height: 12px;
      border-radius: 12px;

      ${({ theme }) => theme.tablet`
        top: 6px;
        left: 5px;
        width: 10px;
        height: 10px;
        border-radius: 10px;
      `}
    }
  }

  ${({ theme }) => theme.desktop`
    & + & {
      margin-top: 20px;
    }
  `}

  ${({ theme }) => theme.tablet`
    padding: 15px;
    border-bottom: 2px solid #e6e6e6;
  `}
`;

export const ModalFooter = styled.div`
  position: relative;
  padding: 24px;
  text-align: right;

  ${({ theme }) => theme.tablet`
    padding: 15px 20px;
  `}
`;
export const CloseButton = styled(ClearButton)`
  ${atoms.ModalButtonCss}
  margin-right: 16px;
  width: 60px;

  ${({ theme }) => theme.tablet`
    margin-right: 30px;
    padding: 0;
    width: 26px;
    border: 0;
    background-color: #fff;
    line-height: 20px;
    color: ${theme.subActiveColor};
    font-size: 14px;
  `}
`;
export const ApplyButton = styled(ClearButton)<atoms.ButtonProps>`
  ${atoms.ModalButtonCss}
  width: 158px;

  ${({ theme }) => theme.tablet`
    padding: 0;
    width: 80px;
    border: 0;
    background-color: #fff;
    line-height: 20px;
    font-size: 14px;
    color: ${theme.subActiveColor};
  `}
`;
