import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Clear = styled.div`
  clear: both;
`;

export const ClearLink = styled(Link)`
  color: ${({ theme }) => theme.TextColor};
  text-decoration: none;
`;

export const ClearA = styled.a`
  color: ${({ theme }) => theme.TextColor};
  text-decoration: none;
`;

export const ClearUl = styled.ul`
  li {
    list-style: none;
    line-height: 1.5;
  }
`;

export const ClearButton = styled.button<{ $inline?: boolean }>`
  ${({ $inline }) => (!$inline ? 'display: block' : null)};
  padding: 0;
  border: 0;
  background: transparent;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
`;
