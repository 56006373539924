/* eslint-disable no-useless-escape */
import { PAGE_URL } from 'configs/path';
import moment from 'moment';
import { generatePath } from 'react-router-dom';

export const withCommas = (x: number) => {
  if (typeof x === 'number') return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  else return x;
};

export const emailReg =
  /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/i;
export const passwdReg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i; //
export const parseLinkHeader = (s: string) => {
  const output: any = {};
  const regex = /<([^>]+)>; rel="([^"]+)"/g;

  let m;
  while ((m = regex.exec(s))) {
    const [, v, k] = m;
    const page = /page=.*?([^page=]\d*)/g.exec(v);
    output[k] = page?.length === 2 ? parseInt(page[1]) : 1;
  }

  return output;
};

export const commaNumber = (n: number) => {
  try {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch (error) {
    return '0';
  }
};
export const removeComma = (n: string) => {
  try {
    return Number(n.replace(/\,/g, ''));
  } catch (error) {
    return 0;
  }
};

let key_count = 0;
export const generateKey = (s?: string, inc: boolean = true) =>
  `__chai_${s || ''}_${inc ? key_count++ : ''}`;
export const getYoutubeVid = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]{11,11}).*/;
  const match = url.match(regExp);

  if (match && match.length >= 2) return match[2];
  else return '';
};

export const getPlaysId = (url: string) => {
  if (url) {
    const regExp = /(\d+)/;
    const match = url.match(regExp);

    if (url.indexOf('https://www.chaisplay.com/plays/') === 0) {
      if (match && match.length) return match[0];
      else return '';
    } else {
      return '';
    }
  } else return '';
};

export const replaceMallUrl = (originUrl: string) => {
  const url = originUrl ?? '';
  const match = url.match(/(\d+)/);
  const id = match && match.length ? match[0] : null;

  if (id) {
    if (url.indexOf('https://www.chaisplay.com/plays/') === 0)
      return generatePath(PAGE_URL.PLAY, { id });
    else if (url.indexOf('https://www.chaisplay.com/packages/') === 0)
      return generatePath(PAGE_URL.PACKAGE, { id });
  }

  return '';
};

export function toFit(
  cb: () => void,
  { dismissCondition = () => false, triggerCondition = () => true }: any,
) {
  if (!cb) {
    throw Error('Invalid required arguments');
  }
  let tick = false;

  return function () {
    if (tick) {
      return;
    }

    tick = true;
    return requestAnimationFrame(() => {
      if (dismissCondition()) {
        tick = false;
        return;
      }

      if (triggerCondition()) {
        tick = false;
        return cb();
      }
    });
  };
}

export const getTodayDiff = (date: string) => {
  const diff_h = moment().diff(date, 'hours');
  const diff_d = moment().diff(date, 'days');
  const diff_y = moment().diff(date, 'years');
  let rtn = '';

  if (diff_y >= 1) rtn = `약 ${diff_y} 년 전`;
  else if (diff_h === 0) rtn = `방금 전`;
  else if (diff_d < 1) rtn = `${diff_h} 시간 전`;
  else if (diff_d < 31) rtn = `${diff_d} 일 전`;
  else rtn = `약 ${Math.floor(diff_d / 30)} 달 전`;

  return rtn;
};

export const phoneFomatter = (str: string, hide = false) => {
  if (!str) return;
  let num = str.replace(/-/g, '');
  let formatNum = '';

  if (num.length === 11) {
    if (hide) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (num.indexOf('02') === 0) {
      if (hide) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      if (hide) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  }

  return formatNum;
};
