import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import cx from 'classnames';
import range from 'lodash.range';

import { ClearButton } from 'components/atoms';
import {
  BsChevronDoubleLeft,
  BsChevronLeft,
  BsChevronRight,
  BsChevronDoubleRight,
} from 'react-icons/bs';

const Pagenation = ({ curPage, pageInfo, pageUnit, onClickPage }: any) => {
  const [pages, setPages] = useState([] as number[]);

  useEffect(() => {
    const last = pageInfo.last || curPage;
    const lowerBound = Math.floor((curPage - 1) / pageUnit) * pageUnit + 1;
    const upperBound = lowerBound + pageUnit <= last ? lowerBound + pageUnit : last + 1;

    setPages(range(lowerBound, upperBound));
  }, [curPage, pageInfo.last, pageUnit]);

  return pages.length ? (
    <WrapPageLink>
      {pageInfo.first ? (
        <PageLinkIcon
          number={pageInfo.first}
          onClickPage={onClickPage}
          Icon={BsChevronDoubleLeft}
        />
      ) : null}
      {pageInfo.prev ? (
        <PageLinkIcon number={pageInfo.prev} onClickPage={onClickPage} Icon={BsChevronLeft} />
      ) : null}
      <span>
        {pages.map(p => (
          <PageLink
            key={`__packages_pagenation_${p}`}
            number={p}
            isActive={p === curPage}
            onClickPage={onClickPage}
          />
        ))}

        {pageInfo.last && pages[pages.length - 1] !== pageInfo.last ? (
          <>
            <PageLinkButton onClick={onClickPage(pages[pageUnit - 1] + 1)}>...</PageLinkButton>
            <PageLink number={pageInfo.last} onClickPage={onClickPage} />
          </>
        ) : null}
      </span>
      {pageInfo.next ? (
        <PageLinkIcon number={pageInfo.next} onClickPage={onClickPage} Icon={BsChevronRight} />
      ) : null}
      {pageInfo.last ? (
        <PageLinkIcon
          number={pageInfo.last}
          onClickPage={onClickPage}
          Icon={BsChevronDoubleRight}
        />
      ) : null}
    </WrapPageLink>
  ) : null;
};

const PageLink = React.memo(({ number, onClickPage, isActive }: any) => (
  <PageLinkButton onClick={onClickPage(number)} className={cx({ active: isActive })}>
    {number}
  </PageLinkButton>
));

const PageLinkIcon = React.memo(({ number, onClickPage, Icon }: any) => {
  return (
    <PageLinkIconButton onClick={onClickPage(number)}>
      <Icon />
    </PageLinkIconButton>
  );
});

const WrapPageLink = styled.div`
  margin-top: 72px;
  text-align: center;

  ${({ theme }) => theme.tablet`
    margin-top: 40px;
  `}
`;

const PageLinkButton = styled(ClearButton)`
  display: inline-block;
  margin: 0 8px;
  padding: 0 8px;

  line-height: 1.5;
  font-size: 20px;
  color: #b3b3b3;

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 16px;
  }

  &.active {
    font-weight: bold;
    color: #262626;
  }

  ${({ theme }) => theme.tablet`
    font-size: 12px;
  `}

  ${({ theme }) => theme.mobile`
    margin: 0 4px;
    padding: 0 4px;

    &:first-child {
      margin-left: 12px;
    }

    &:last-child {
      margin-right: 12px;
    }
  `}
`;

const PageLinkIconButton = styled(ClearButton)`
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  font-size: 18px;
  & svg {
    color: #b3b3b3;
  }

  ${({ theme }) => theme.tablet`
    font-size: 16px;
    line-height: 18px;
  `}
`;
export default Pagenation;
