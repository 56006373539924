import React from 'react';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { ClearButton } from 'components/atoms/Clear';
import { useStores } from 'stores';
import { useLocation } from 'react-router-dom';
import { PAGE_URL } from 'configs/path';
import { InfoBubble } from './InfoBubble';
import { RelativeDiv } from 'components/atoms';

const PlayPdf: React.FC<any> = observer(() => {
  const { play } = usePlayDetailStore();
  const { auth } = useStores();
  const location = useLocation();

  if (!play.pdf_image_url) return null;

  return (
    <InfoBox>
      <WrapImg>
        <Img src={play.pdf_image_url} />
      </WrapImg>
      <PdfDownloadBox>
        <PdfDownloadWrap>
          <DownloadText>활동지 다운로드</DownloadText>
          {auth.isSignIn && auth.user ? (
            <RelativeDiv>
              <DownloadButton>
                <ExternalLink href={play.pdf_download_url} target='_blank'>
                  <InfoButton>
                    <IconInfoButton src={'/icons/download.png'} alt='Download Icon Img' />
                  </InfoButton>
                  활동지 다운로드
                </ExternalLink>
                <InfoBubble />
              </DownloadButton>
            </RelativeDiv>
          ) : (
            <RelativeDiv>
              <DownloadButton>
                <ExternalLink
                  href={`${PAGE_URL.SIGNIN}?redirectUrl=${encodeURIComponent(location.pathname)}`}>
                  <InfoButton>
                    <IconInfoButton src={'/icons/download.png'} alt='Download Icon Img' />
                  </InfoButton>
                  로그인 후 다운로드
                </ExternalLink>
              </DownloadButton>

              <InfoBubble />
            </RelativeDiv>
          )}
        </PdfDownloadWrap>
      </PdfDownloadBox>
    </InfoBox>
  );
});

const InfoBox = styled.div`
  display: flex;
  line-height: 0;
  border: 1px solid #d5d5d5;

  ${({ theme }) => theme.desktop`
    margin: 40px 0 70px;
    overflow: hidden;
  `}

  ${({ theme }) => theme.tablet`
    margin: 20px 0 50px;
    font-size: 12px;
    display: block;
    position: relative;
    border: 0;
    background-color: #f8f8f8;
    padding: 54px 0 20px;
  `}
  ${({ theme }) => theme.mobile`
    margin: 20px 0 50px;
    background-color: #f8f8f8;
    padding: 54px 0 20px;
  `}
`;

const WrapImg = styled.div`
  ${({ theme }) => theme.desktop`
    flex: 50%;
  `}

  ${({ theme }) => theme.tablet`
    text-align: center;
  `}
`;

const PdfDownloadBox = styled.div`
  display: flex;
  flex: 50%;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #d5d5d5;
  ${({ theme }) => theme.tablet`
    display: initial;
    border: 0;
  `}
`;

const PdfDownloadWrap = styled.div`
  text-align: center;
`;

const Img = styled.img`
  ${({ theme }) => theme.desktop`
    width: 100%;
  `}
  ${({ theme }) => theme.tablet`
    width: 206px;
  `}
  ${({ theme }) => theme.mobile`
    width: 206px;
  `}
`;

const DownloadText = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;

  ${({ theme }) => theme.tablet`
    position: absolute;
    left: calc(50% - 47px);
    top: -20px;
    font-size: 16px;
    top: 32px;
  `}
  ${({ theme }) => theme.mobile`
    font-size: 16px;
    top: 32px;
  `};
`;

const DownloadButton = styled(ClearButton)`
  position: relative;
  display: inline-block;
  margin-top: 16px;
  width: 240px;
  height: 52px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-weight: bold;
  color: #fff;
  ${({ theme }) => theme.tablet`
    width: 206px;
    height: 34px;
    margin-top: 10px;
  `}
  ${({ theme }) => theme.mobile`
    width: 206px;
    height: 34px;
    margin-top: 10px;
  `}
`;

const ExternalLink = styled.a`
  display: block;
  margin: 8px 0;
  text-decoration: none;
  color: white;
  ${({ theme }) => theme.tablet`
    font-size: 14px;
  `}
`;

const InfoButton = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-right: 3px;
`;

const IconInfoButton = styled.img`
  display: block;
  margin: auto;
  width: 22px;
  height: 22px;
`;

export default PlayPdf;
