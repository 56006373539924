import { observable, set } from 'mobx';

export interface ResPackageCategory {
  id: number;
  parent_id?: number;
  category: string;
  category_name: string;
  image_url: string;
}

class CategoriesModel {
  @observable me: PackageCategory = {} as PackageCategory;
  @observable children: PackageCategory[] = [];

  constructor(parent: ResPackageCategory, children: ResPackageCategory[]) {
    set(this.me, {
      id: parent.id,
      parentId: parent.parent_id,
      category: parent.category,
      categoryName: parent.category_name,
      imageUrl: parent.image_url,
    });

    this.children = children.map(c => ({
      id: c.id,
      parentId: c.parent_id,
      category: c.category,
      categoryName: c.category_name,
    }));
  }
}

export interface PackageCategory {
  id: number;
  parentId?: number;
  category: string;
  categoryName: string;
  imageUrl?: string;
}

export default CategoriesModel;
