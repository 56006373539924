import { css } from 'styled-components';
import { BREAK_POINT_DESKTOP, BREAK_POINT_MOBILE } from '../interfaces/layout';

const sizes = {
  desktop: BREAK_POINT_DESKTOP,
  tablet: BREAK_POINT_DESKTOP,
  mobile: BREAK_POINT_MOBILE,
};

export default Object.keys(sizes).reduce((acc, label) => {
  if (label === 'desktop') {
    acc[label] = (...args) => css`
      @media (min-width: ${sizes[label] + 1}px) {
        ${css(...args)};
      }
    `;
  } else {
    acc[label] = (...args) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(...args)};
      }
    `;
  }

  return acc;
}, {});
