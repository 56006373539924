import { generatePath } from 'react-router-dom';
import { action, extendObservable, observable, computed, toJS, reaction, runInAction } from 'mobx';
import { PAGE_URL } from 'configs/path';
import { cartStoreKey } from 'configs/localStorage';
import { removeComma } from 'utils';
import { RootStore } from 'stores';
import { ICartJS } from 'interfaces/PackageDetail';
import CartRepository from './repository/CartRepository';
import CartOrderItemModel from './models/CartOrderItemModel';
import { setOrderToken } from 'configs/axios';

export interface CartItem extends ICartJS {
  isChecked: boolean;
}
export default class CartStore {
  rootStore: RootStore = {} as RootStore;

  // Data
  @observable isfetched = false;
  @observable orderId?: number;
  items = observable<CartOrderItemModel>([]);
  // response: feat API
  @observable shipping_price: number = 0;
  @observable subtotal: number = 0;
  // response: update API (원본 장바구니는 변경 X)
  @observable localized_shipping_price?: number;
  @observable localized_subtotal_price?: number;
  @observable order_estimate_url: string = '';

  // Ui
  @observable allChecked = true;
  @observable free_shipping_minimum = 50000;

  constructor(root: RootStore) {
    this.rootStore = root;

    const existingStore = localStorage.getItem(cartStoreKey);
    if (existingStore) {
      const jsStore = JSON.parse(existingStore);
      extendObservable(this, {
        ...jsStore,
        items: jsStore.items.map(
          (item: Cart.OrderItem, index: number) => new CartOrderItemModel(item, index),
        ),
      });
    }

    reaction(
      () => this.isSignIn,
      async isSignIn => {
        localStorage.removeItem(cartStoreKey);

        if (isSignIn) {
          if (this.items.length) {
            await CartRepository.updateLocal(this.items);
          }
          await this.fetch();
        } else {
          this.clear();
        }
      },
    );

    reaction(
      () => toJS(this.items),
      () => {
        if (!this.isSignIn) {
          const jsStore = toJS(this) as any;
          delete jsStore.rootStore;
          delete jsStore.order_estimate_url;
          localStorage.setItem(cartStoreKey, JSON.stringify(jsStore));
        }
      },
      { delay: 100 },
    );

    setOrderToken();
  }

  @action
  clear() {
    this.orderId = undefined;
    this.items.replace([]);
    this.shipping_price = 0;
    this.subtotal = 0;
    this.localized_shipping_price = undefined;
    this.localized_subtotal_price = undefined;
    this.order_estimate_url = '';
  }

  @action
  async fetch() {
    try {
      if (this.isSignIn) {
        const { data } = await CartRepository.fetch();

        runInAction(() => {
          this.isfetched = true;
          this.orderId = data.cart.order_id;
          this.items.replace(
            data.cart.order_items.map((item, index) => new CartOrderItemModel(item, index)),
          );
          this.shipping_price = data.cart.shipping_price;
          this.subtotal = data.cart.subtotal;
          this.order_estimate_url = data.cart.order_estimate_url;
          this.localized_shipping_price = undefined;
          this.localized_subtotal_price = undefined;
          this.updateAllCheck();
        });
      } else {
        const { free_shipping_minimum } = await CartRepository.getShippingPrices();
        this.free_shipping_minimum = free_shipping_minimum;
      }
    } catch (error) {
      throw error;
    }
  }

  @action.bound
  async insert(item: ICartJS) {
    if (!this.isfetched) {
      await this.fetch();
    }

    const oldItemIndex = this.items.findIndex(({ package_id, package_option_id }) => {
      const isSamePackage = package_id === item.id;

      if (item.select.isOption) {
        return package_option_id === item.select.unit.id;
      } else {
        return isSamePackage;
      }
    });
    let res: Cart.UpdateOrderItem | undefined;
    let newItem: CartOrderItemModel;

    if (this.isSignIn) {
      const { data } = await CartRepository.add(item);

      res = data;
      newItem = new CartOrderItemModel(data.order_item, this.items.length);
    } else {
      const props = {
        checked: true,
        id: new Date().getTime(),
        title: item.title,
        package_id: item.id,
        square_image_url: item.square_image_url,
        items_count: item.select.quantity,
        unit_price: item.select.unit.price,
        max: item.max,
      } as Cart.OrderItem;

      if (item.select.isOption) {
        props.option_title = item.select.unit.name;
        props.package_option_id = item.select.unit.id;
      }

      newItem = new CartOrderItemModel(props, this.items.length);
    }

    runInAction(() => {
      if (oldItemIndex !== -1) {
        this.items[oldItemIndex] = newItem;
      } else {
        this.items.push(newItem);
      }
      this.updateLocalData(res);
    });

    return newItem;
  }

  @action.bound
  toggleCheck(item: CartOrderItemModel, flag?: boolean) {
    return async () => {
      const res = await item.toggleCheck(flag, this.isSignIn);

      this.updateLocalData(res);
      this.updateAllCheck();
    };
  }

  @action.bound
  changeQuantity(item: CartOrderItemModel) {
    return async (count: number, target?: HTMLInputElement | null) => {
      let res;
      try {
        res = await item.updateQuantity(count, this.isSignIn);
      } catch (resData) {
        res = resData;
        item.setMax(res.order_item.items_count);
        target?.blur();
        this.rootStore.layout.alert(resData.order_item.errors);
      }

      this.updateLocalData(res);
    };
  }

  @action.bound
  delete(item: CartOrderItemModel) {
    return async () => {
      const res = await item.delete(this.isSignIn);

      this.items.remove(item);
      this.updateLocalData(res);
    };
  }

  @action.bound
  async deleteChecked() {
    let res;

    if (this.checkedItems.length === 0) {
      alert('삭제할 패키지를 선택해 주세요.');
      return;
    }

    if (this.isSignIn) {
      res = await CartRepository.delete(this.checkedItems);
    }

    this.items.replace(this.items.filter(item => !item.checked));
    this.updateLocalData(res);
  }

  @action
  updateLocalData(res?: Cart.UpdateOrderItem) {
    if (this.isSignIn && res) {
      const textPrice2Number = (text: string) => removeComma(text.replace('원', ''));
      const { order_item } = res;

      this.localized_shipping_price = textPrice2Number(order_item.localized_shipping_price);
      this.localized_subtotal_price = textPrice2Number(order_item.localized_subtotal_price);
    } else {
      this.localized_subtotal_price = 0;
      this.items.forEach(({ checked, price }) => {
        if (checked) {
          this.localized_subtotal_price = (this.localized_subtotal_price || 0) + price;
        }
      });
      this.localized_shipping_price =
        this.localized_subtotal_price < this.free_shipping_minimum ? 3500 : 0;
    }
  }

  @action
  updateAllCheck() {
    const checkedNum = this.items.reduce((acc, item) => {
      if (item.checked) acc += 1;
      return acc;
    }, 0);
    this.allChecked = checkedNum === this.items.length;
  }

  @action.bound
  toggleAllCheck(ev: React.FormEvent<HTMLInputElement>) {
    const checked = !!ev.currentTarget.checked;

    this.allChecked = checked;
    this.items.forEach(item => this.toggleCheck(item, checked)());
  }

  @computed
  get fee() {
    if (this.totalPrice) {
      if (this.localized_shipping_price !== undefined) {
        return this.localized_shipping_price;
      } else {
        return this.shipping_price;
      }
    } else {
      return 0;
    }
  }

  @computed
  get totalPrice() {
    if (this.localized_subtotal_price !== undefined) {
      return this.localized_subtotal_price;
    } else {
      return this.subtotal;
    }
  }

  @computed
  get totalPriceWithFee() {
    return this.fee + this.totalPrice;
  }

  get isSignIn() {
    return this.rootStore.auth.isSignIn;
  }

  @computed
  get isEmpty() {
    return this.items.length === 0;
  }

  @computed
  get orderUrl() {
    if (this.orderId) {
      return generatePath(PAGE_URL.PURCHASE, { id: this.orderId });
    } else {
      return PAGE_URL.ERROR;
    }
  }

  @computed
  get cartItemLen() {
    if (this.isSignIn && !this.isfetched) {
      return this.rootStore.auth.user?.cart_items_count;
    }

    return this.items.length;
  }

  @computed
  get checkedItems() {
    return this.items.filter(item => item.checked);
  }

  @computed
  get hasDiabledPackage() {
    return !this.items.every(({ disabled }) => !disabled);
  }

  // 비회원 구매
  @action
  async ticketing() {
    const {
      data: { success, order_id, token },
    } = await CartRepository.updateLocal(this.checkedItems);

    if (success && token) {
      setOrderToken(token);

      runInAction(() => {
        this.orderId = order_id;
      });
    }
  }
}
