import React from 'react';
import styled from 'styled-components';

import { getYoutubeVid } from 'utils';
import { IEvent } from 'stores/models/EventDetailModel';
import * as atoms from 'components/atoms';
import { ConditionalWrapper } from 'components/atoms/ConditionalWrapper';

const EventResource: React.FC<IEvent.Resource> = props => {
  const { resource_type, link_url, image_url, video_url } = props;

  switch (resource_type) {
    case 'image':
      return (
        <ConditionalWrapper
          condition={link_url}
          wrapper={(children: React.ReactNode) => (
            <Link href={link_url} target='black'>
              {children}
            </Link>
          )}>
          <Img src={image_url} alt={resource_type} />
        </ConditionalWrapper>
      );

    case 'image_with_margin':
      return (
        <ConditionalWrapper
          condition={link_url}
          wrapper={(children: React.ReactNode) => (
            <Link href={link_url} target='black'>
              {children}
            </Link>
          )}>
          <MarginImg src={image_url} alt={resource_type} />
        </ConditionalWrapper>
      );

    case 'video':
      const vid = getYoutubeVid(video_url);

      return vid ? (
        <WrapVideo>
          <VideoContainer>
            <iframe
              title={vid}
              src={video_url}
              frameBorder='0'
              allow='accelerometer;  encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </VideoContainer>
        </WrapVideo>
      ) : null;
    default:
      return null;
  }
};

export default EventResource;

const Img = styled.img`
  float: left;
  width: 100%;
`;

const MarginImg = styled(Img)`
  padding: 10px 20px;
`;

const Link = styled(atoms.ATag)`
  float: left;
  display: block;
  width: 100%;
`;

const WrapVideo = styled.div`
  float: left;
  padding: 10px 20px;
  width: 100%;
`;
const VideoContainer = styled(atoms.VideoContainer)`
  margin: 0;
`;
