import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { PAGE_URL } from 'configs/path';
import { useStores } from 'stores';
import { SubButton, WrapSignInTxt } from 'pages/Cart/styled';

export const SiginInGuideBox = observer(({ text = '로그인하시고 혜택 받아가세요.' }) => {
  const { push } = useHistory();
  const { auth } = useStores();
  const go = useCallback(
    (url: string, state = {}) =>
      () =>
        push(url, state),
    [push],
  );

  return !auth.isSignIn ? (
    <WrapSignInTxt>
      <div className='txt'>{text}</div>
      <SubButton
        onClick={go(`${PAGE_URL.SIGNIN}?redirectUrl=${encodeURIComponent(PAGE_URL.CART)}`)}>
        로그인
      </SubButton>
      <SubButton onClick={go(PAGE_URL.SIGNUP)}>회원가입</SubButton>
    </WrapSignInTxt>
  ) : null;
});
