import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { DefaultModalZIndex, ModalProps } from 'interfaces/Modal';
import { useStores } from 'stores';
import * as atoms from './styled';
import { ClearButton } from 'components/atoms/Clear';

const MessageModal = observer(({ isOpen, params }: ModalProps) => {
  const history = useHistory();
  const { modal } = useStores();
  const { message, redirectUrl } = params;
  const onClickClose = useCallback(() => {
    if (redirectUrl) history.push(redirectUrl);
    else modal.closeCurrentModal();
  }, [history, modal, redirectUrl]);

  return (
    <WrapModal $show={isOpen} $zIndex={DefaultModalZIndex}>
      <Message>{message}</Message>
      <CloseButton onClick={onClickClose}>닫기</CloseButton>
    </WrapModal>
  );
});

export default MessageModal;

const WrapModal = styled(atoms.WrapModal)`
  padding: 0;
  width: 305px;

  ${({ theme }) => theme.tablet`
    width: 280px;
  `};
`;

const Message = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid #d9d9d9;

  ${({ theme }) => theme.tablet`
    padding: 20px;
    font-size: 14px;
  `};
`;

const CloseButton = styled(ClearButton)`
  margin: 16px 24px 16px auto;
  width: 60px;
  height: 40px;
  border-radius: 3px;
  border: solid 1px #b3b3b3;

  ${({ theme }) => theme.tablet`
    margin: 10px 20px 10px auto;
    height: 30px;
  `};
`;
