import styled, { css } from 'styled-components';

export const Section = styled.section<{ $first?: boolean }>`
  margin-top: ${({ $first }) => ($first ? '24px' : '72px')};
  ${({ theme }) => theme.tablet`
    margin-top: 10px;
    background-color: white;
    padding: 20px 0;
  `}
  ${({ theme }) => theme.mobile`
    padding: 20px 0;
  `}
`;

export const SectionTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.5;
  ${({ theme }) => theme.tablet`
    padding: 0 20px;
    font-size: 16px;
  `}
`;

export const SectionSubTitle = styled.div`
  display: inline-block;
  font-size: 16px;
  line-height: 2;
  margin: 10px 32px 0 0;
`;

export const MobileTableWrapper = styled.div`
  ${({ theme }) => theme.tablet`
    padding: 20px;
    background-color: white;
  `}
  ${({ theme }) => theme.mobile`
    padding: 10px 20px;
  `}
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  ${({ theme }) => theme.desktop`
    margin: 16px 0;
    border-top: 2px solid #262626;
  `}
`;

export const Tr = styled.tr<{ $borderLess?: boolean }>`
  border-bottom: ${({ $borderLess }) => (!$borderLess ? 'solid 1px #d5d5d5' : null)};
  ${({ theme }) => theme.tablet`
    border: 0;
  `}
`;

export const Th = styled.th<{ $center?: boolean }>`
  font-weight: normal;
  line-height: 1.5;
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  vertical-align: top;

  ${({ theme }) => theme.desktop`
    padding: 16px 24px;
    width: 246px;
    background-color: #f8f8f8;
    font-size: 16px;
  `}
  ${({ theme }) => theme.tablet`
    padding: 5px 0;
    font-size: 14px;
    width: 88px;
  `}
`;

export const Td = styled.td<{ $center?: boolean }>`
  position: relative;
  background-color: #fff;
  font-weight: normal;
  line-height: 1.5;
  text-align: ${({ $center }) => ($center ? 'center' : 'left')};
  vertical-align: text-top;

  ${({ theme }) => theme.desktop`
    padding: 16px 32px;
    font-size: 16px;
  `}
  ${({ theme }) => theme.tablet`
    padding: 5px 10px;
    font-size: 14px;
    background-color: inherit;
  `}
  ${({ theme }) => theme.mobile`
    text-align: right;
  `}
`;

export const Price = styled.div<{ $bold?: boolean; $active?: boolean }>`
  font-size: 24px;
  line-height: 1;
  font-weight: ${({ $bold }) => ($bold ? 'bold' : null)};
  color: ${({ $active, theme }) => ($active ? theme.mainActive : null)};
`;

export const SubInfo = styled.div<{ $prefix?: string; $active?: boolean }>`
  display: inline-block;
  margin-top: 4px;
  min-width: 115px;
  font-size: 12px;
  line-height: 1.5;
  text-align: ${({ $prefix }) => ($prefix ? 'right' : 'center')};

  ${Price} + & {
    margin-top: 10px;
  }

  &::before {
    content: '${({ $prefix }) => $prefix || ''}';
    float: left;
    font-weight: bold;
  }
  ${({ theme, $active }) => theme.tablet`
    text-align: left;
    color: ${$active ? theme.mainActive : '#b3b3b3'};
  `}
  ${({ theme }) => theme.mobile`
    text-align: right;
  `}
`;

export const Em = styled.em`
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.subActiveColor};
`;

const MathIcon = css`
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
`;

export const Minus = styled.div`
  &::before {
    ${MathIcon}
    content: '-';
  }
`;
export const Plus = styled.div`
  &::before {
    ${MathIcon}
    content: '+';
  }
`;
export const Equal = styled.div`
  &::before {
    ${MathIcon}
    content: '=';
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.tablet`
    flex-direction: column;
    margin: 10px 20px 0 20px
  `}
`;

export const FlexBox = styled.div<{ $txtAlign?: string }>`
  flex: 1 0 0;
  text-align: ${({ $txtAlign }) => $txtAlign || 'left'};
  ${({ theme }) => theme.tablet`
    font-size: 14px;
  `}
`;

export const Hr = styled.hr`
  margin: 16px 0 0;
  border: solid 1px #262626;
`;
