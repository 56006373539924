import axios from 'axios';
import { API_URL } from 'configs/path';
import UserModel from 'stores/models/UserModel';

class AuthRepository {
  async fetch(): Promise<UserModel> {
    const {
      data: { user },
    } = await axios.get(API_URL.AUTH_USER);

    return new UserModel(user);
  }

  async updateInfo(body: any) {
    const { data } = await axios.put(API_URL.AUTH_USER, body);

    return data;
  }

  async sendCode(phone_number: string, isKakao: boolean) {
    const url = `${API_URL.AUTH_SEND_CODE}${isKakao ? '?target=kakao' : ''}`;

    const {
      data: { success, message },
    } = await axios.post(url, {
      phone_number,
    });

    return { success: success ?? false, message };
  }

  async certifyCode(phone_number: string, phone_sms_code: string) {
    const {
      data: { success, message },
    } = await axios.post(API_URL.AUTH_CHECK_CODE, {
      phone_number,
      phone_sms_code,
    });

    return { success: success ?? false, message };
  }
}

export default new AuthRepository();
