import React from 'react';
import styled from 'styled-components';

import { observer } from 'mobx-react';
import { useStores } from 'stores';

export default observer(function FailedHeader() {
  const {
    order: { error_msg },
  } = useStores();

  return (
    <>
      <FailedBox>
        <Title>결제가 실패하였습니다.</Title>
        <Message>{error_msg}</Message>
      </FailedBox>
    </>
  );
});

const FailedBox = styled.div`
  margin-top: 32px;
  padding: 24px;
  background-color: #fff5d5;
  ${({ theme }) => theme.tablet`
    margin: -5px 0 -10px;
    padding: 20px;
  `}
  ${({ theme }) => theme.mobile`
    margin: -5px -20px -10px;
    padding: 20px 40px;
  `}
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.38;
  ${({ theme }) => theme.tablet`
    font-size: 16px;
  `}
`;

const Message = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 1.5;
  ${({ theme }) => theme.tablet`
    font-size: 12px;
    margin-bottom: 16px;
  `}
`;
