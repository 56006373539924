import React from 'react';
import styled from 'styled-components';
import { Box } from './styled';

const Copyright: React.FC<any> = () => (
  <Box $withPad>
    <Hr />
    <P>
      차이의 놀이 콘텐츠는, 교육학을 전공하고 아이 성장발달 놀이를 연구하는 선생님이 직접
      작성하였습니다.&nbsp;
      <b>
        차이의 놀이의 모든 콘텐츠는 저작권의 보호를 받으며, 일부 혹은 전체를 무단 전재할 수
        없습니다.
      </b>
    </P>
  </Box>
);

export default Copyright;

const P = styled.p`
  margin: 16px 0;
  font-size: 12px;
  line-height: 1.5;

  b {
    font-size: 14px;
  }

  ${({ theme }) => theme.tablet`
    margin: 10px 0;
    font-size: 10px;

    b {
      font-size: 10px;
    }
  `}
`;

const Hr = styled.hr`
  border: solid 1px #eaeaea;
`;
