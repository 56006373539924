import React from 'react';
import styled, { css } from 'styled-components';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { ClearLink, ClearButton } from './Clear';

const H1 = styled.h1`
  line-height: 1.38;
  font-size: 24px;
`;

export const Title: React.FC<any> = React.memo(props => useDesktopShow(<H1>{props.children}</H1>));

const TitleBackButtonCss = css`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 10px;
  width: 54px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
`;

export const TitleBackLink = styled(ClearLink)`
  ${TitleBackButtonCss}
`;

export const TitleBackButton = styled(ClearButton)`
  ${TitleBackButtonCss}
`;

export const Wrapper = styled.div<{ $pgMinHeight?: number }>`
  padding: 24px 0 0 37px;
  ${({ $pgMinHeight }) => ($pgMinHeight ? `min-height: ${$pgMinHeight}px;` : null)}
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    padding: 0 0 20px;
  `};
`;

// History top box
export const WrapperHistory = styled(Wrapper)`
  ${({ theme }) => theme.tablet`
    padding: 16px 0 0;
    background-color: #f8f8f8;
  `};
`;

export const HistoryTop = {
  Wrapper: styled.div`
    ${({ theme }) => theme.desktop`
      margin-top: 16px;
      padding: 24px;
      background-color: #f8f8f8;
    `};

    ${({ theme }) => theme.tablet`
      margin: 0 auto;
      max-width: 280px;
    `};

    ${({ theme }) => theme.mobile`
      padding: 0 20px;
      max-width: none;
    `};
  `,
  Value: styled.div<{ $prefix: string; $suffix: string }>`
    font-size: 3rem;
    font-weight: bold;

    ${({ theme }) => theme.tablet`
      font-size: 14px;
      text-align: right;
    `};

    &:before {
      content: ${({ $prefix }) => `'${$prefix}'`};

      ${({ theme }) => theme.desktop`
        display: block;
        line-height: 1;
        font-size: 16px;
      `};

      ${({ theme }) => theme.tablet`
        float: left;
        font-size: 14px;
        font-weight: normal;
      `};
    }

    &:after {
      content: ${({ $suffix }) => `'${$suffix}'`};
      margin-left: 4px;

      ${({ theme }) => theme.desktop`
        line-height: 1;
        font-size: 24px;
        font-weight: bold;
      `};

      ${({ theme }) => theme.tablet`
        font-size: 10px;
        font-weight: normal;
      `};
    }
  `,
  Desc: styled.div`
    margin-top: 8px;
    font-size: 16px;

    ${({ theme }) => theme.tablet`
      margin-top: 5px;
      font-size: 12px;
      color: #b3b3b3;
    `};
  `,
};

export const CouponTop = {
  Wrapper: styled(HistoryTop.Wrapper)`
    ${({ theme }) => theme.tablet`
      overflow: hidden;
    `};
  `,
  Value: styled(HistoryTop.Value)`
    ${({ theme }) => theme.tablet`
      float: left;
      margin: 4px 0;
      font-size: 14px;
      text-align: left;
    `};

    &:before,
    &:after {
      ${({ theme }) => theme.tablet`
        float: none;
        margin-left: 0px;
        font-size: 14px;
        font-weight: bold;
      `};
    }
  `,
  Button: styled(ClearLink)`
    display: inline-block;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.subActiveColor};
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: center;

    ${({ theme }) => theme.desktop`
      margin-top: 16px;
      width: 177px;
      line-height: 40px;
    `};

    ${({ theme }) => theme.tablet`
      float: right;
      width: 105px;
      line-height: 30px;
    `};
  `,
};
