import axios from 'axios';
import { API_URL } from 'configs/path';
import { generatePath } from 'react-router-dom';

class PlaysRepository {
  fetch(id: number): Promise<any> {
    return axios.get(generatePath(API_URL.PLAY, { id }));
  }

  fetchPapers(query: string): Promise<{ plays: any[] }> {
    // TODO: Promise type
    return axios.get(`${API_URL.PLAYS}?category=paper&${query}`);
  }
}

export default new PlaysRepository();
