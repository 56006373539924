import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import qs from 'query-string';
import { Element, scroller } from 'react-scroll';

import { useStores } from 'stores';
import { MODAL_CLASSIFIER } from './constants';
import { DefaultLayout } from 'layouts/Default';
import { DISPLAY, ILayout } from 'interfaces/layout';
import { PackageDetailProvider, useInitPacakgeDetail, useStore } from './store';
import CategoriesModel from 'layouts/models/CategoriesModel';
import { PackagesMatchParams } from 'configs/path';

import useTabs from 'components/hooks/useTabs';
import useScrollEvt from 'components/hooks/useScrollEvt';

import { Wrapper, WrapInfo, TabContent, RelatedPackages } from './styled';
import { ShowDiv } from 'components/atoms';
import BreadCrumb from 'components/organisms/Packages/BreadCrumb';
import ImageSlider from 'components/organisms/PackageDetail/ImageSlider';
import PackageInfo from 'components/organisms/PackageDetail/PackageInfo';
import PackageBanners from 'components/organisms/PackageDetail/PackageBanners';
import ExplanationBox from 'components/organisms/PackageDetail/ExplanationBox';
import PlaysBox from 'components/organisms/PackageDetail/PlaysBox';
import ReviewBox from 'components/organisms/PackageDetail/ReviewBox';
import ReactHelmet from 'components/atoms/Helmet';
import StickeyTabs from 'components/molecules/PackagesDetail/StickeyTabs';
import { StickeyBasket } from 'components/molecules/PackagesDetail/StickeyBasket';
import KakaotalkBanners from 'components/molecules/PackagesDetail/KakaotalkBanners';
import PackagesContainer from 'components/organisms/PackagesContainer';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import { MobileShareButton } from 'components/molecules/ShareButton';
import MobileBackButton from 'components/atoms/MobileBackButton';
import ScrollTopButton from 'components/molecules/ScrollTopButton';
import AppAdBanner from 'components/molecules/AppAdBanner';
import CartModal from 'components/modals/CartModal';
import LoginModal from 'components/modals/LoginModal';
import MessageModal from 'components/modals/MessageModal';
import { DesktopTab, MobileTab } from 'components/organisms/PackageDetail/Tabs';
import SideBasket from 'components/molecules/PackagesDetail/SideBasket';
import ComponentTableBox from 'components/organisms/PackageDetail/ComponentTableBox';
import { AppDonwloadButton } from './components/AppDonwloadButton';

const Tabs = [
  { buttonText: '설명' },
  { buttonText: '놀이법' },
  { buttonText: '구성품' },
  { buttonText: '후기' },
];

const PackageDetail: React.FC<ILayout> = observer(({ setBodyMinHeight }) => {
  const { pathname, search } = useLocation();
  const { layout } = useStores();
  const store = useStore();
  const { curIndex, setTab } = useTabs(+(qs.parse(search)?.tab || 0), Tabs);
  const TabRef = useRef<HTMLDivElement>(null);
  const KakaoRef = useRef<HTMLDivElement>(null);
  const WrapperRef = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);

  const onScroll = useCallback(() => {
    if (TabRef.current) {
      const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
      const condition = TabRef.current.getBoundingClientRect().top <= 0;
      setSticky(!isBottom && condition);
    }
  }, []);
  const onClickTab = useCallback(
    (idx: number) => () => {
      const target = layout.displayType !== DISPLAY.DESKTOP ? 'SubHeader' : `TabContents`;
      const offset = layout.displayType !== DISPLAY.DESKTOP ? -100 : -85;

      window.history.replaceState({}, '', pathname + `?tab=${idx}`);
      setTab(idx);
      scroller.scrollTo(target, {
        offset,
        duration: 350,
        smooth: true,
      });
    },
    [layout.displayType, pathname, setTab],
  );

  useInitPacakgeDetail();
  useScrollEvt(onScroll);

  useEffect(
    () => () => {
      setSticky(false);
    },
    [],
  );

  useEffect(() => {
    if (!store.isInit) setSticky(false);
  }, [store.isInit]);

  useEffect(() => {
    if (layout.displayType !== DISPLAY.DESKTOP) setBodyMinHeight(0);
    if (store.isInit && curIndex === 3) store.packageDetail.initInsta(layout.displayType);
  }, [curIndex, layout.displayType, setBodyMinHeight, store.isInit, store.packageDetail]);

  const { isInit, packageDetail } = store;

  if (!isInit) return null;
  else {
    const { category_name, rolling_images, banners } = packageDetail;
    let main = {} as CategoriesModel;
    let matchParams = {} as PackagesMatchParams;

    layout.packageCategory.forEach(item => {
      const child = item.children.find(({ categoryName }) => categoryName === category_name);
      if (item.me.categoryName === category_name || child) {
        main = item;
        matchParams = { main: item.me.category, sub: child?.category };
      }
    });

    Tabs[1].buttonText = `놀이법(${packageDetail.plays.length})`;
    Tabs[3].buttonText = `후기(${packageDetail.reviews.length})`;

    const meta = {
      title: packageDetail.title,
      description: packageDetail.description,
      image: packageDetail.square_image_url,
      androidAppUrl: `havit://packages/${packageDetail.id}`,
      iosAppUrl: `havit://www.chaisplay.com/packages/${packageDetail.id}`,
    };

    return (
      <>
        <ReactHelmet data={meta} />

        <Element name='SubHeader'>
          <SubHeader>
            <MobileBackButton />
            <SubHeaderTitle>제품상세</SubHeaderTitle>
            <MobileShareButton {...meta} />
            <MobileTab store={Tabs} curIndex={curIndex} onClick={onClickTab} />
          </SubHeader>
        </Element>

        <Wrapper $first>
          <ShowDiv $show={curIndex === 0 || layout.displayType === DISPLAY.DESKTOP}>
            <BreadCrumb
              category={layout.packageCategory}
              subCategory={main.children}
              params={matchParams}
            />
            <WrapInfo>
              <ImageSlider images={rolling_images} />
              <PackageInfo detail={packageDetail} />
            </WrapInfo>
            <PackageBanners banners={banners} />
          </ShowDiv>
        </Wrapper>

        <DesktopTab store={Tabs} childRef={TabRef} curIndex={curIndex} onClick={onClickTab} />

        <Element name='TabContents'>
          <Wrapper $last ref={WrapperRef}>
            <SideBasket isSticky={isSticky} kakaoRef={KakaoRef} wrapperRef={WrapperRef} />
            <TabContent $active={curIndex === 0}>
              <ExplanationBox store={packageDetail} />
              <KakaotalkBanners childRef={KakaoRef} />
              <RelatedPackages>
                {packageDetail.relatedPackages.length ? (
                  <>
                    <h2>같이보면 더 좋아요!</h2>
                    <PackagesContainer store={packageDetail.relatedPackages} />
                  </>
                ) : null}
              </RelatedPackages>
            </TabContent>

            <TabContent $active={curIndex === 1}>
              <PlaysBox />
            </TabContent>

            <TabContent $active={curIndex === 2}>
              <ComponentTableBox />
            </TabContent>

            <TabContent $active={curIndex === 3} className='no-padding'>
              <ReviewBox />
            </TabContent>
          </Wrapper>
        </Element>

        <AppAdBanner />

        <StickeyTabs show={isSticky} tabs={Tabs} curIndex={curIndex} onClick={onClickTab} />
        <StickeyBasket show={layout.displayType !== DISPLAY.DESKTOP} />
        <ScrollTopButton />
        <AppDonwloadButton />
      </>
    );
  }
});

export default DefaultLayout(PackageDetail, {
  Provider: PackageDetailProvider,
  Modal: {
    [MODAL_CLASSIFIER.ADD_PACKAGE]: CartModal,
    [MODAL_CLASSIFIER.RESULT_MESSAGE]: MessageModal,
    [MODAL_CLASSIFIER.DOWNLOAD_COUPON]: LoginModal,
  },
});
