import React from 'react';
import styled from 'styled-components';
import { DefaultModalZIndex } from 'interfaces/Modal';
import { ClearButton } from 'components/atoms/Clear';

interface Prop {
  message: string;
  handleOkClick: React.MouseEventHandler<any>;
}

export const AlertView: React.FC<Prop> = React.memo(({ message, handleOkClick }) => {
  return (
    <AlertBox>
      <AlertBody dangerouslySetInnerHTML={{ __html: message }} />
      <AlertFooter>
        {handleOkClick ? <AlertOkButton onClick={handleOkClick}>확인</AlertOkButton> : null}
      </AlertFooter>
    </AlertBox>
  );
});

const AlertBox = styled.div`
  position: fixed;
  z-index: ${DefaultModalZIndex};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 335px;
  border-radius: 3px;
  background-color: #fff;

  ${({ theme }) => theme.tablet`
    width: 280px;
    border-radius: 5px;
    font-size: 12px;
  `}
`;

const AlertBody = styled.div`
  padding: 16px 24px;
  line-height: 1.5;
  word-break: keep-all;

  ${({ theme }) => theme.tablet`
    padding: 16px 16px 8px;
    text-align: center;
  `}
`;

const AlertFooter = styled.div`
  display: flex;

  ${({ theme }) => theme.desktop`
    flex-direction: row-reverse;
    padding: 16px 24px;
    border-top: 2px solid #b3b3b3;
  `}

  ${({ theme }) => theme.tablet`
    padding: 8px 16px 16px;
    justify-content: center;
  `}
`;

const AlertOkButton = styled(ClearButton)`
  width: 90px;
  height: 40px;
  border-radius: 3px;
  background: ${({ theme }) => theme.subActiveColor};
  font-size: 14px;
  color: #fff;

  ${({ theme }) => theme.tablet`
    width: 122px;
    height: 28px;
    font-size: 12px;
  `}
`;
