import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { useStores } from 'stores';
import { DEVICE } from 'interfaces/layout';

import { ATag } from '.';

interface Props {
  Wrapper: any;
}

export const AppleDonwloadButton = observer(({ Wrapper }: Props) => {
  const { layout } = useStores();

  const href =
    layout.deviceType === DEVICE.APPLE
      ? 'itms-apps://itunes.apple.com/kr/app/apple-store/id1134836561'
      : 'https://itunes.apple.com/kr/app/apple-store/id1134836561';
  const target = layout.deviceType === DEVICE.OTHER ? '_blank' : '';

  return (
    <Wrapper>
      <ApplyButton href={href} target={target} />
    </Wrapper>
  );
});

const ApplyButton = styled(ATag)`
  display: block;
  width: 114px;
  height: 38px;
  background-image: url('/icons/apple-download.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mobile`
    width: 110px;
    height: 37px;
  `}
`;

export const GoogleDonwloadButton = observer(({ Wrapper }: Props) => {
  const { layout } = useStores();

  const href =
    layout.deviceType === DEVICE.ANDROID
      ? 'market://details?id=com.havit.android'
      : 'http://play.google.com/store/apps/details?id=com.havit.android';
  const target = layout.deviceType === DEVICE.OTHER ? '_blank' : '';

  return (
    <Wrapper>
      <GoogleButton href={href} target={target} />
    </Wrapper>
  );
});

const GoogleButton = styled(ATag)`
  display: block;
  width: 128px;
  height: 38px;
  background-image: url('/icons/google-download.svg');
  background-size: contain;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mobile`
    width: 125px;
    height: 37px;
  `}
`;

export const DownloadButton = observer(({ Wrapper }: Props) => {
  const { layout } = useStores();
  const target = layout.deviceType === DEVICE.OTHER ? '_blank' : '';

  return (
    <Wrapper>
      <Button href={layout.AppDownloadLink} target={target}>
        차이의 놀이앱 다운로드
      </Button>
    </Wrapper>
  );
});

const Button = styled(ATag)`
  display: block;
  padding: 15px 0;
  width: 319px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.mainActive};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  font-size: 20px;
  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.mobile`
    padding: 11px 0;
    width: 249px;
    font-size: 18px;
  `}
`;
