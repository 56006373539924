import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores';
import { ClearA } from 'components/atoms/Clear';
import { DEVICE } from 'interfaces/layout';

export const AppDonwloadButton: React.FC = observer(() => {
  const { layout } = useStores();
  const [appLaunchUrl, setAppLaunchUrl] = useState(
    layout.deviceType === DEVICE.ANDROID
      ? 'market://details?id=com.havit.android'
      : layout.deviceType === DEVICE.APPLE
      ? 'itms-apps://itunes.apple.com/kr/app/apple-store/id1134836561'
      : 'https://www.chaisplay.com/downloads/app?referrer=utm_source%3Dweb_footer%26utm_medium%3Dweb%26utm_term%3Dapp_download%26utm_campaign%3Dapp_download_landing',
  );

  useEffect(() => {
    setTimeout(() => {
      if (layout.deviceType === DEVICE.ANDROID) {
        const androidAppUrl = document
          ?.querySelector("meta[property='al:android:url']")
          ?.getAttribute('content');

        const androidAppPackage = document
          ?.querySelector("meta[property='al:android:package']")
          ?.getAttribute('content');

        if (androidAppUrl) {
          const url =
            'intent:/' +
            androidAppUrl.split(':/')[1] +
            '#Intent;package=' +
            androidAppPackage +
            ';scheme=' +
            androidAppUrl.split(':/')[0] +
            ';S.browser_fallback_url=' +
            encodeURIComponent('#') +
            ';launchFlags=268435456;end';

          setAppLaunchUrl(url);
        }
      } else if (layout.deviceType === DEVICE.APPLE) {
        const iosAppUrl = document
          ?.querySelector("meta[property='al:ios:url']")
          ?.getAttribute('content')
          ?.split('://')[1];

        if (iosAppUrl) {
          setAppLaunchUrl(`havit://${iosAppUrl}`);
        }
      }
    }, 500);
  });

  return !layout.isDesktop ? <Button href={appLaunchUrl}>차이의 놀이앱에서 보기</Button> : null;
});

const Button = styled(ClearA)`
  position: fixed;
  z-index: 997;
  bottom: 70px;
  left: 20px;
  padding: 8px 0;
  width: calc(100% - 87px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 50px;
  background-color: #b3b3b3;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  text-align: center;
  color: #fff;
`;
