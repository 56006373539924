import React from 'react';
import styled from 'styled-components';

import ListFrame from 'components/atoms/ListFrame';
import PackageModel from 'stores/models/PackageModel';
import WithNumPackageItem from 'components/molecules/Share/WithNumPackageItem';
import PackageItem from 'components/molecules/Share/PackageItem';

interface Props {
  store: PackageModel[];
  withNumber?: boolean;
  txtCenter?: boolean;
}

const PackagesContainer: React.FC<Props> = ({ store, withNumber, txtCenter }) => {
  return (
    <ContainerWrap $withNumber={withNumber}>
      <ListFrame
        ListWrap={WrapList}
        ListItem={withNumber ? WithNumPackageItem : PackageItem}
        ListItemProps={{ txtCenter }}
        store={store}
      />
    </ContainerWrap>
  );
};

export default PackagesContainer;

const ContainerWrap = styled.section<{ $withNumber?: boolean }>`
  margin-top: ${({ $withNumber }) => ($withNumber ? '32px' : '8px')};

  ${({ theme, $withNumber }) => theme.tablet`
    margin-top: ${$withNumber ? '15px' : '10px'};
  `}
`;

export const WrapList = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.desktop`
    & > * {
      width: calc(20% - 22.5px);
      margin-left: 28px;
    }
    
    & > *:nth-child(n + 6) {
      margin-top: 72px;
    }

    & > *:nth-child(5n + 1) {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.tablet`
    & > * {
      width: calc(25% - 15px);
      margin-left: 20px;
    }

    & > *:nth-child(n + 5) {
      margin-top: 40px;
    }

    & > *:nth-child(4n + 1) {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.mobile`
    & > * {
      width: calc(50% - 5px);
      margin-left: 10px;
    }
    
    & > *:nth-child(n + 3) {
      margin-top: 20px;
    }

    & > *:nth-child(2n + 1) {
      margin-left: 0;
    }
  `}
`;
