import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';

import { ILayoutOptions } from 'interfaces/layout';
import { generateKey } from 'utils';

interface Props {
  store: ILayoutOptions['Modal'];
}

const ModalContainer: React.FC<Props> = observer(({ store }) => {
  const { modal } = useStores();

  useEffect(() => {
    modal.init(store);
  }, [modal, store]);

  const { components, status } = modal;
  return (
    <>
      {components.map((Modal, idx) => (
        <Modal key={generateKey(`modal_${idx}`)} {...status[idx]} />
      ))}
    </>
  );
});

export default ModalContainer;
