import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { useStores } from 'stores';
import { ClearLink, ClearButton } from 'components/atoms/Clear';
import { PAGE_URL } from 'configs/path';
import { useCommentsStore } from 'stores/local/Comments';

interface IFormInput {
  body: string;
}

const CommentInput: React.FC<any> = observer(() => {
  const { auth } = useStores();
  const { register, handleSubmit, setValue } = useForm<IFormInput>();
  const { post } = useCommentsStore();
  const onSubmit = (data: IFormInput) => {
    try {
      post(data);
    } catch (error) {
      throw error;
    }
    setValue('body', '');
  };

  return (
    <Wrapper $SignIn={auth.isSignIn}>
      {auth.isSignIn && auth.user ? (
        <>
          <Nickname>{auth.user.nickname}</Nickname>
          <Email>{auth.user.email}</Email>
          <CommentForm onSubmit={handleSubmit(onSubmit)}>
            <Textarea name='body' placeholder='댓글을 입력해주세요.' ref={register} />
            <SubmitButton type='submit'>등록</SubmitButton>
          </CommentForm>
        </>
      ) : (
        <>
          로그인하시고 댓글을 남겨보세요.
          <br />
          <AuthButton to={PAGE_URL.SIGNIN}>로그인</AuthButton>
          <AuthButton to={PAGE_URL.SIGNUP}>회원가입</AuthButton>
        </>
      )}
    </Wrapper>
  );
});

export default CommentInput;

const Wrapper = styled.div<{ $SignIn?: boolean }>`
  margin-bottom: 10px;
  line-height: 1.5;
  br {
    display: none;
  }

  ${({ $SignIn }) =>
    $SignIn
      ? ``
      : `
      padding: 21px 24px;
      border-radius: 3px;
      border: solid 1px #d5d5d5;
    `};

  ${({ theme, $SignIn }) => theme.tablet`
    ${
      $SignIn
        ? ``
        : `
      margin-bottom: 0; 
      padding: 10px;`
    }
    font-size: 12px;
  `}

  ${({ theme }) => theme.mobile`
    br {
      display: block
    }
  `}
`;

const AuthButton = styled(ClearLink)`
  display: inline-block;
  margin-right: 8px;
  width: 104px;
  line-height: 30px;
  border-radius: 3px;
  background-color: #d5d5d5;
  font-size: 12px;
  text-align: center;

  &:first-of-type {
    margin-left: 24px;
  }

  ${({ theme }) => theme.tablet`
    width: 60px;
    line-height: 20px;

    &:first-of-type {
      margin-left: 8px;
    }
  `}

  ${({ theme }) => theme.mobile`
    margin-top: 6px;
    margin-right: 10px;
    line-height: 25px;

    &:first-of-type {
      margin-left: 0;
    }
  `}
`;

const Nickname = styled.div`
  display: inline-block;
  font-weight: bold;
`;

const Email = styled.div`
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 8px;
  font-size: 12px;
  color: #b3b3b3;
`;

const CommentForm = styled.form`
  margin-top: 9px;
`;

const Textarea = styled.textarea`
  display: inline-block;
  padding: 8px 12px;
  width: calc(100% - 163px);
  height: 80px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  resize: none;

  &::placeholder {
    color: #b3b3b3;
  }

  ${({ theme }) => theme.tablet`
    width: calc(100% - 156px);
    height: 62px;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
    margin-bottom: 9px;
  `}
`;

const SubmitButton = styled(ClearButton)`
  display: inline-block;
  vertical-align: top;
  margin-left: 16px;
  width: 147px;
  height: 80px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.tablet`
    margin-left: 10px;
    width: 146px;
    height: 62px;
  `}

  ${({ theme }) => theme.mobile`
    margin-left: 0px;
    width: 100%;
    height: 30px;
  `}
`;
