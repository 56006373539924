import React from 'react';
import styled, { css } from 'styled-components';
import { FaPlus, FaEquals } from 'react-icons/fa';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

import { Wrapper, RelativeDiv, ClearButton, ClearLink, ATag } from 'components/atoms';
import { ButtonProps } from 'interfaces/Styled';
import QuantityBox, { QuantityBoxProps } from 'components/atoms/QuantityBox';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  ${({ theme }) => theme.desktop`
    margin-bottom: 16px;  
    border-top: 1px solid #262626;
  `}
`;

export const Thead = styled.thead`
  ${({ theme }) => theme.tablet`
    display: none;
  `}
`;

export const Tbody = styled.tbody`
  ${({ theme }) => theme.tablet`
    display: block;
  `}
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #e3e3e3;

  ${({ theme }) => theme.desktop`
    height: 56px;
  `}

  ${({ theme }) => theme.tablet`
    display: block;
    padding: 7px 20px 20px;
  `}
`;

const rowWidthCSS = css`
  &.disabled {
    font-weight: normal;
    color: #b3b3b3;

    * {
      font-weight: normal;
      color: #b3b3b3;
    }
  }

  ${({ theme }) => theme.desktop`
    text-align: center;

    &.checkbox {
      padding-left: 16px;
      width: 100px;
      text-align: left;
    }
    &.quantity {
      width: 200px;
    }
    &.price {
      width: 150px;
    }
    &.vtop {
      vertical-align: top;
    }
    &.order-date {
      width: 144px;
    }
    &.order-info {
      width: 536px;
    }
    &.order-price {
      width: 140px;
    }
    &.order-status {
      width: 140px;
    }
  `}

  ${({ theme }) => theme.tablet`
    padding: 0;
    &.checkbox {
      overflow: hidden;
      & > div {
        float: left;
      }
      button {
        float: right;
      }
    }
  `}
`;

export const Th = styled.th`
  background-color: #f8f8f8;
  line-height: 24px;
  font-weight: normal;
  ${rowWidthCSS}
`;

export const Td = styled.td`
  ${rowWidthCSS}
  overflow: hidden;

  ${({ theme }) => theme.desktop`
    padding: 16px 0;
  `}

  ${({ theme }) => theme.tablet`
    position: relative;
    display: block;
    margin-top: 10px;
    width: 100%;

    &.info {
      border-radius: 3px;
      border: solid 1px #e6e6e6;
    }
  `}
`;

export const PackageImg = styled.img`
  float: left;
  width: 127px;
  height: 127px;
  border-radius: 5px;

  ${({ theme }) => theme.tablet`
    width: 94px;
    height: 94px;
    border-radius: 0;
  `}
`;

export const WrapCartInfo = styled.div`
  float: left;
  margin-left: 15px;
  width: calc(100% - 145px);
  line-height: 1.5;
  text-align: left;

  ${({ theme }) => theme.tablet`
    position: absolute;
    margin-left: 0;
    padding: 10px 10px 0 104px;
    width: 100%;
    height: 94px;
  `}
`;

export const PackageTitleLink = styled(ClearLink)`
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: bold;

  ${({ theme }) => theme.desktop`
    margin-bottom: 8px;
    max-height: 48px;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `}

  ${({ theme }) => theme.tablet`
    max-height: 21px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  `}
`;

export const OptionName = styled.div`
  height: 48px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.tablet`
    height: 36px;
    font-size: 12px;
  `}
`;

export const Price = styled.div`
  ${({ theme }) => theme.tablet`
    position: absolute;
    bottom: 10px;
    line-height: 1.5;
    font-size: 12px;
  `}
`;

export const CheckBox = React.memo((props: any) => {
  const { id, children, injectRef, value, ...other } = props;
  return (
    <RelativeDiv>
      <CheckInput type='checkbox' id={id} ref={injectRef} {...other} />
      <CheckLabel htmlFor={id}>{children}</CheckLabel>
    </RelativeDiv>
  );
});

const CheckInput = styled.input`
  position: absolute;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;

  & + label:before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    width: 22px;
    height: 22px;
    vertical-align: top;
    border: solid 1px #b3b3b3;
    background-color: #fff;
    border-radius: 3px;

    ${({ theme }) => theme.tablet`
      width: 18px;
      height: 18px;
      border-color: #d5d5d5;
    `}
  }
  &:checked + label:before {
    border-color: ${({ theme }) => theme.subActiveColor};
    background-color: ${({ theme }) => theme.subActiveColor};
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 8px;
    width: 4px;
    height: 12px;
    border: solid 2px #fff;
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 0;
    transform: rotate(40deg);

    ${({ theme }) => theme.tablet`
      left: 6px;
      width: 4px;
      height: 8px;
    `}
  }
`;
const CheckLabel = styled.label`
  display: inline-block;
  vertical-align: top;
  height: 24px;
  cursor: pointer;
  user-select: none;
  line-height: 1.5;
  font-size: 12px;
`;

export const Quantity = styled(QuantityBox)<QuantityBoxProps>`
  ${({ theme }) => theme.tablet`
    margin-top: 0;
  `}
`;

/*
 * CartContainer
 */

export const WrapCart = styled(Wrapper)`
  position: relative;
  padding-bottom: 100px;

  ${({ theme }) => theme.tablet`
    position: static;
    padding-bottom: 20px;
  `}

  ${({ theme }) => theme.mobile`
    padding-bottom: 0;
    min-height: 380px;
  `}
`;

const H1 = styled.h1`
  margin-top: 32px;
  padding-bottom: 16px;

  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
`;
export const Title: React.FC<any> = React.memo(props => useDesktopShow(<H1>{props.children}</H1>));

export const SubButton = styled(ClearButton)<ButtonProps>`
  display: inline-block;
  padding: 0 24px;
  height: 30px;
  border-radius: 3px;
  border: solid 1px ${({ $color, theme }) => $color || theme.subActiveColor};
  background-color: ${({ $fill, $color, theme }) =>
    $fill ? $color || theme.subActiveColor : '#fff'};
  font-size: 12px;
  color: ${({ $fill, $fontColor, theme }) => {
    const color = $fontColor || theme.subActiveColor;
    return $fill && color === theme.subActiveColor ? '#fff' : color;
  }};

  & + & {
    margin-left: 8px;
  }

  ${({ theme }) => theme.tablet`
    padding: 0 10px;
    width: auto;
    height: 24px;
  `}
`;
export const SubDeleteButton = styled(SubButton)<ButtonProps>`
  padding: 0 16px;
`;

export const WrapCartButtons = styled.div`
  text-align: center;

  ${({ theme }) => theme.desktop`
    margin-top: 32px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 0 20px 20px;
  `}
`;

export const CartButton = styled(ClearButton)<ButtonProps>`
  display: inline-block;
  width: 248px;
  height: 56px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.subActiveColor};
  background-color: ${({ $fill, theme }) => ($fill ? theme.subActiveColor : '#fff')};

  line-height: 1.5;
  font-weight: bold;
  color: ${({ $fill, theme }) => ($fill ? '#fff' : theme.subActiveColor)};

  &:disabled {
    opacity: 0.5;
  }

  ${({ theme }) => theme.desktop`
    & + & {
      margin-left: 16px;
    }
  `}

  ${({ theme }) => theme.tablet`
    width: 100%;
    height: 44px;
    font-size: 16px;

    & + & {
      margin-top: 10px;
    }
  `}
`;

export const GoPackageButton = styled(CartButton)<ButtonProps>`
  height: 44px;

  ${({ theme }) => theme.tablet`
    width: 135px;
    height: 36px;
    font-size: 12px;
  `}
`;

export const WrapSignInTxt = styled.div`
  line-height: 1.5;
  & .txt {
    display: inline-block;
  }

  ${({ theme }) => theme.desktop`
    margin-bottom: 24px;
    padding: 24px 21px;
    border: solid 1px #d5d5d5;

    & .txt {
      margin-right: 24px;
    }
  `}

  ${({ theme }) => theme.tablet`
    padding: 7px 20px;
    background-color: #f8f8f8;
    font-size: 12px;

    & .txt {
      margin-right: 10px;
    }
  `}

  ${({ theme }) => theme.mobile`
    & .txt {
      display: block;
      margin-bottom: 5px;
      margin-right: 0;
    }
  `}
`;

export const WrapSummary = styled.div`
  ${({ theme }) => theme.desktop`
    margin-top: 16px;
    padding: 32px;
    background: #fafafa;
    text-align: right;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 15px;
    padding: 0 20px;
  `}
`;

export const SummaryItem = styled.div`
  line-height: 1.5;

  ${({ theme }) => theme.desktop`
    display: inline-block;
    text-align: right;
  `}

  ${({ theme }) => theme.tablet`
    & + & {
      margin-top: 10px;
    }
  `}
`;

export const SItemInfo = styled.div`
  ${({ theme }) => theme.desktop`
    &:not(.total) {
      margin-bottom: 10px;
    }
  `}

  ${({ theme }) => theme.tablet`
    display: inline-block;
    width: 110px;
    font-size: 14px;
  `}
`;

export const Unit = styled.span`
  ${({ theme }) => theme.desktop`
    font-size: 22px;
  `}
`;

export const SItemPrice = styled.div`
  ${({ theme }) => theme.desktop`
    font-size: 24px;
  `}

  ${({ theme }) => theme.tablet`
    display: inline-block;
    padding-left: 25px;
    width: calc(100% - 110px);
    font-size: 14px;

    &.fee:before {
      content: '+';
    }
  `}

  ${({ theme }) => theme.mobile`
    text-align: right;
  `}

  &.total,
  &.total * {
    font-size: 32px;
    font-weight: bold;

    ${Unit} {
      /* vertical-align: text-bottom; */
      font-size: 28px;
    }

    ${({ theme }) => theme.tablet`
      vertical-align: top;
      font-size: 20px;

      ${Unit} {
        vertical-align: initial;
        font-size: 18px;
      }
    `}
  }
`;

export const IconPlus = styled(FaPlus)`
  margin: 0 24px;
`;

export const IconEqual = styled(FaEquals)`
  margin: 0 15px 0 24px;
`;

export const GuideTxt = styled.div`
  margin-top: 16px;
  line-height: 1.5;
  font-size: 12px;
  color: #b3b3b3;

  ${({ theme }) => theme.tablet`
    margin: 10px 0 10px 135px;
    font-size: 10px;
  `}

  ${({ theme }) => theme.mobile`
    margin: 10px 0;
    font-size: 10px;
    text-align: right;
  `}
`;

export const WrapEmptyTxt = styled.div`
  padding-top: 32px;
  border-top: 2px solid #000;
  line-height: 1.5;
  text-align: center;

  & .txt {
    margin: 16px 0;
  }

  ${({ theme }) => theme.tablet`
    position: absolute;
    top: 45px; //subHeader height
    z-index: 0;
    padding: 18px 0;
    width: 100%;
    height: 100%;
    border-top: 0;
    background-color: #f8f8f8;

    & .txt {
      margin-bottom: 12px;
      font-size: 14px;

      & + ${SubButton} {
        width: 135px;
        height: 36px;
      }
    }
  `}
`;

export const EmptyCart = styled.img`
  ${({ theme }) => theme.tablet`
    width: 115px;
  `}

  ${({ theme }) => theme.mobile`
    width: 88px;
  `}
`;

export const OrderEstimateButton = styled(ATag)`
  display: inline-block;
  margin-top: 16px;
  padding: 10px 16px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.subActiveColor};
  color: ${({ theme }) => theme.subActiveColor};
  background-color: #fff;
  font-size: 12px;

  ${({ theme }) => theme.tablet`
    margin: 0px 0 16px 132px;
    padding: 5px 10px;
    font-size: 10px;
  `}

  ${({ theme }) => theme.mobile`
    float: right;
  `}
`;
