import React, { useCallback } from 'react';
import styled from 'styled-components';
import { animateScroll as scroll } from 'react-scroll';

import { ClearButton } from 'components/atoms';

const ScrollTopButton: React.FC<any> = React.memo(() => {
  const onClick = useCallback(() => {
    scroll.scrollToTop({ duration: 350 });
  }, []);

  return <Button onClick={onClick}>Top</Button>;
});

const Button = styled(ClearButton)`
  position: fixed;
  z-index: 997;
  bottom: 70px;
  right: 20px;
  padding-top: 10px;
  width: 37px;
  height: 37px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: rgba(0, 0, 0, 0.75);
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 12px;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid #fff;
  }

  ${({ theme }) => theme.desktop`
    display: none;
  `}
`;

export default ScrollTopButton;
