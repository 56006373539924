export interface ModalProps {
  isOpen: boolean;
  params: ModalFunctionParams;
}

export interface ModalFunctionParams {
  [key: string]: any;
  onOpenStart?: Function;
  onOpenEnd?: Function;
  onCloseStart?: Function;
  onCloseEnd?: Function;
  dismissible?: boolean;
  dimmedZIndex?: number;
}

export const DefaultDimmedZIndex = 998;
export const DefaultModalZIndex = DefaultDimmedZIndex + 1;
