import axios from 'axios';
import { API_URL } from 'configs/path';

class CoinRepository {
  fetch(): Promise<any> {
    return axios.get(API_URL.COIN);
  }
}

export default new CoinRepository();
