import React from 'react';
import { observer } from 'mobx-react';

import { DefaultLayout } from 'layouts/Default';
import { EventDetailProvider, useEventDetailStore, useInitEventDetail } from './store';
import { generateKey } from 'utils';

import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { Wrapper, Contents, WrapEventPackage, WrapEventResource } from './styled';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import EventResource from 'components/molecules/Event/EventResource';
import EventPackage from 'components/molecules/Event/EventPackage';
import EventBanner from 'components/molecules/Event/EventBanner';
import AppAdBanner from 'components/molecules/AppAdBanner';
import { EventAddressCopy, EventComments } from 'components/organisms/Event';

const AppBanner = () => useDesktopShow(<AppAdBanner />);

const EventListContainer = observer(() => {
  const { isInit, event } = useEventDetailStore();

  useInitEventDetail();

  if (!isInit) return null;

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>이벤트</SubHeaderTitle>
      </SubHeader>
      <Wrapper>
        <Contents>
          {event.resources.length ? (
            <WrapEventResource>
              {event.resources.map(item => (
                <EventResource key={generateKey()} {...item} />
              ))}
            </WrapEventResource>
          ) : null}
          {event.related_packages.length ? (
            <WrapEventPackage>
              {event.related_packages.map(item => (
                <EventPackage key={generateKey()} {...item} />
              ))}
            </WrapEventPackage>
          ) : null}
        </Contents>
      </Wrapper>
      <Wrapper $white $last>
        <EventBanner />
        <EventAddressCopy />
        <EventComments />
      </Wrapper>
      <AppBanner />
    </>
  );
});

export default DefaultLayout(EventListContainer, { Provider: EventDetailProvider });
