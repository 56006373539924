import styled, { css } from 'styled-components';

import { Input } from 'pages/Mypage/Info/styled';
import { InputButton, Label, WrapInput } from '../styled';

export const WrapPhoneInput = styled(WrapInput)`
  ${Input} {
    ${({ theme }) => theme.desktop`
      width: 419px;
    `}
  }
`;

export const PhoneInput = styled.div<{ $isCertified: boolean }>`
  position: relative;
  display: inline-block;
  vertical-align: top;

  ${({ theme }) => theme.tablet`
    width: calc(100% - 90px);
  `}

  ${Input} {
    ${({ $isCertified }) =>
      $isCertified &&
      css`
        background: #daeaf7;
      `}

    ${({ theme }) => theme.tablet`
      width: 100%;
    `}
  }
`;

export const Success = styled.div`
  position: absolute;
  color: #4696d7;
  bottom: 11px;
  right: 8px;
  font-size: 12px;

  ${({ theme }) => theme.tablet`
    bottom: 8px;
  `};
`;

export const PhoneInputErr = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.desktop`  
    display: inline-block;
    margin-top: 12px;
    margin-left: 8px;
  `}

  ${({ theme }) => theme.tablet`
    position: absolute;
    top: 6px;
    right: 8px;
  `}

  ${({ theme }) => theme.mobile`
    position: relative;
    top: unset;
    right: unset;
    margin-top: 5px;
    margin-left: 95px;
  `}
`;

export const SendButton = styled(InputButton)`
  display: inline-block;

  ${({ theme }) => theme.desktop`
    margin-left: 34px;
    padding: 0 15px;
    width: 124px;
  `}

  ${({ theme }) => theme.tablet`
    position: relative;
    margin-left: 16px;
    width: 113px;
  `}

  ${({ theme }) => theme.mobile`
    position: relative;
    margin-top: 10px;
    margin-left: 90px;
    width: calc(100% - 90px);
  `}
`;

export const WrapCertifyInput = styled(WrapInput)`
  ${({ theme }) => theme.tablet`
    display: inline-block;
    vertical-align: top;
    margin-top: 0;
    width: calc(100% - 405px);

    ${Label} {
      display: none;
    }

    ${Input} {
      margin-left: 26px;
      width: calc(100% - 96px);
    }
  `}

  ${({ theme }) => theme.mobile`
    display: block;
    margin-top: 10px;
    width: 100%;

    ${Label} {
      display: inline-block;
    }

    ${Input} {
      margin-left: 0;
      width: calc(100% - 160px);
    }
  `}
`;

export const CertifyButton = styled(InputButton)`
  ${({ theme }) => theme.desktop`
    width: 125px;
  `}
`;
