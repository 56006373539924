import styled from 'styled-components';
import { ClearLink } from 'components/atoms';

export const WrapFilter = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d5d5d5;

  ${({ theme }) => theme.tablet`
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: 0;
  `}
`;

export const WrapFilterLink = styled.li<{ $lastRowItems: number }>`
  list-style: none;
  margin: 8px 0;
  width: 16.66%;

  ${({ theme, $lastRowItems }) => theme.tablet`
    margin: 0;
    padding: 14px 0;
    width: 33.33%;
    border-right: 1px solid #ececec;
    border-bottom: 1px solid #ececec;

    text-align: center;

    &:nth-child(-n+3) {
      border-top: 1px solid #ececec;
    }

    &:nth-child(3n) {
      border-right: 0;
    }

    &:nth-last-child(-n+${$lastRowItems}) {
      border-bottom: 0;
    }
  `}
`;

export const FilterLink = styled(ClearLink)`
  line-height: 1.5;
  font-size: 16px;

  &.active {
    font-weight: bold;
    color: ${({ theme }) => theme.mainActive};
  }

  ${({ theme }) => theme.tablet`
    font-size: 12px;
  `}
`;
