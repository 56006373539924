import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { ClearButton } from '.';
import { useStores } from 'stores';

export enum BoxSize {
  SMALL,
  NORMAL,
}

export interface QuantityBoxProps {
  className?: string;
  value: number;
  max: number;
  maxGuideMessage?: string;
  onChange: (value: number, target?: HTMLInputElement | null) => void;
  size?: BoxSize;
  angled?: boolean;
}

const QuantityBox: React.FC<QuantityBoxProps> = ({
  className,
  value,
  max,
  maxGuideMessage,
  onChange,
  size,
  angled,
}) => {
  const {
    layout: { alert },
  } = useStores();

  const ref = useRef<HTMLInputElement>(null);
  const [buf, setBuf] = useState<number | ''>(value);
  const onClickValue = useCallback(
    (value: number) => () => {
      if (value !== 0) {
        if (max === undefined || value <= max) {
          setBuf(value);
          onChange(value, ref.current);
        } else if (value > max) {
          alert(maxGuideMessage);
        }
      }
    },
    [alert, max, maxGuideMessage, onChange],
  );
  const onChangeValue = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      let value = parseInt(e.currentTarget.value, 10);

      if (value > max) {
        alert(maxGuideMessage);
      }

      value = value >= max ? max : value;
      setBuf(value || '');
      if (value) onChange(value, ref.current);
    },
    [alert, max, maxGuideMessage, onChange],
  );
  const onBlurValue = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const value = parseInt(e.currentTarget.value, 10);
      if (isNaN(value)) {
        setBuf(1);
        onChange(1, ref.current);
      } else if (max === undefined || value <= max) {
        setBuf(value);
        onChange(value, ref.current);
      } else {
        setBuf(max);
        onChange(max, ref.current);
      }
    },
    [max, onChange],
  );

  useEffect(() => {
    setBuf(value);
  }, [value]);

  return (
    <Wrap className={className} $angled={angled}>
      <Button onClick={onClickValue((buf as number) - 1)} $size={size as BoxSize}>
        <FiMinus />
      </Button>
      <Value
        ref={ref}
        type='number'
        value={buf}
        onChange={onChangeValue}
        onBlur={onBlurValue}
        $size={size as BoxSize}
      />
      <Button onClick={onClickValue((buf as number) + 1)} $size={size as BoxSize}>
        <FiPlus />
      </Button>
    </Wrap>
  );
};

QuantityBox.defaultProps = {
  size: BoxSize.NORMAL,
};

export default QuantityBox;

const Wrap = styled.div<{ $angled?: boolean }>`
  display: inline-block;
  ${({ $angled }) => ($angled ? null : 'border-radius:3px')};
  border: solid 1px #d5d5d5;
  background-color: #fff;

  ${({ theme }) => theme.tablet`
    margin-top: 8px;
  `}
`;

const Value = styled.input<{ $size: BoxSize }>`
  display: inline-block;
  vertical-align: top;
  padding: 0;
  width: 48px;
  border: 0;
  border-radius: 0;
  border-left: solid 1px #d5d5d5;
  border-right: solid 1px #d5d5d5;
  outline: none;
  line-height: 30px;
  font-size: 16px;
  text-align: center;

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ $size }) =>
    $size === BoxSize.SMALL
      ? `
    padding: 4px 8px; 
    width: 61px;
    line-height: 24px;`
      : null}

  ${({ theme, $size }) => theme.tablet`
    padding: 0;
    width: 50px;
    line-height: 28px;
    font-size: 16px;
    font-weight: ${$size === BoxSize.SMALL ? 'normal' : 'bold'};
  `}
`;

const Button = styled(ClearButton)<{ $size: BoxSize }>`
  display: inline-block;
  width: 30px;
  height: 30px;

  & svg {
    width: 18px;
    height: 18px;
  }

  ${({ theme }) => theme.tablet`
    width: 25px;
    height: 25px;

    & svg {
      width: 10px;
      height: 10px;
    }
  `}

  ${({ $size }) =>
    $size === BoxSize.SMALL
      ? `
    width: 31px; 
    height: 32px;

    & svg {
      stroke-width: 1px;
    }
    `
      : null}
`;
