import styled from 'styled-components';
import * as atoms from 'components/atoms';

export const Wrapper = styled(atoms.Wrapper)`
  ${({ theme }) => theme.desktop`
    width: 960px;
    margin-top: 70px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 30px 20px 10px;
  `}
`;

export const H2 = styled.h2`
  margin: 0;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: solid 2px #262626;
  line-height: 32px;
  font-size: 24px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
  margin-bottom: 10px;
  padding-bottom: 10px;
  line-height: 24px;
  font-size: 16px;
`}
`;
