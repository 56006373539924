import React from 'react';
import { generatePath } from 'react-router';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import CartOrderItemModel from 'stores/models/CartOrderItemModel';
import { PAGE_URL } from 'configs/path';
import { commaNumber } from 'utils';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';
import {
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  PackageImg,
  WrapCartInfo,
  PackageTitleLink,
  OptionName,
} from 'pages/Cart/styled';
import { PriceText, Row, OrderStatus } from 'components/organisms/Purchase/PurchaseTable';
import { RelativeDiv } from 'components/atoms';
import { Section, SectionSubTitle, SectionTitle, Table } from './styled';
import { ClearLink } from 'components/atoms/Clear';
import moment from 'moment';

export default observer(function PurchaseTable() {
  const {
    order: { merchant_uid, order_items, created_at, paid_at, display_status },
  } = useStores();

  return (
    <Section $first>
      <RelativeDiv>
        <SectionTitle>구매 내역</SectionTitle>
        {useDesktopShow(
          <>
            <SectionSubTitle>
              주문일 | {moment(paid_at || created_at).format('YYYY-MM-DD')}
            </SectionSubTitle>
            <SectionSubTitle>주문번호 | {merchant_uid}</SectionSubTitle>
          </>,
        )}
      </RelativeDiv>
      <div>
        <Table>
          <Thead>
            <Tr>
              <Th className='info'>상태</Th>
              <Th className='info'>상품 정보</Th>
              <Th className='quantity'>수량</Th>
              <Th className='price'>금액</Th>
            </Tr>
          </Thead>
          <Tbody>
            {order_items.map((item, index) => (
              <TableRow
                item={item}
                display_status={display_status}
                count={order_items.length}
                key={item.id}
                index={index}
              />
            ))}
          </Tbody>
        </Table>
      </div>
    </Section>
  );
});

const TableRow: React.FC<{
  item: CartOrderItemModel;
  display_status: string;
  count: number;
  index: number;
}> = ({ item, display_status, count, index }) => {
  const { package_id, square_image_url, title, hasOption, option, items_count, price } = item;

  const DesktopOrderStatus = useDesktopShow(
    <Td rowSpan={count} className='vtop'>
      <OrderStatus>{display_status}</OrderStatus>
    </Td>,
  );

  return (
    <Row>
      {index === 0 && DesktopOrderStatus}
      <Td className='info'>
        <ClearLink to={generatePath(PAGE_URL.PACKAGE, { id: package_id })}>
          <PackageImg src={square_image_url} alt='images' />
          <WrapCartInfo>
            <PackageTitleLink to={generatePath(PAGE_URL.PACKAGE, { id: package_id })}>
              {title}
            </PackageTitleLink>
            {hasOption ? <OptionName>{`[옵션] ${option}`}</OptionName> : null}
            {useTabletShow(
              <PriceText>
                {commaNumber(price)}원 / {items_count}개
              </PriceText>,
            )}
          </WrapCartInfo>
        </ClearLink>
      </Td>
      {useDesktopShow(
        <>
          <Td className='vtop'>{items_count}개</Td>
          <Td className='vtop'>{commaNumber(price)}원</Td>
        </>,
      )}
    </Row>
  );
};
