import React from 'react';
import styled from 'styled-components';

const Service = () => (
  <ServiceContents>
    (주)해빛(이하 “회사”)이 운영하는 통합회원 패밀리 서비스(이하 “사이트”)에서는 이용자의 개인정보를
    중요시하며, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 개인 정보와 관련된 법령 상의
    개인정보보호규정 및 방송통신위원회가 제정한 “개인정보보호지침”을 준수하고 있으며, 아래와 같이
    개인정보 취급방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.본 방침은 2015년 10월
    01일부터 시행됩니다.
    <ol>
      <li>
        개인정보의 수집 및 이용 목적
        <ul>
          <li>
            개인정보는 생존하는 개인에 관한 정보로서 이름, 이메일 등의 사항으로 당사 회원 개인을
            식별할 수 있는 정보를 말합니다. 당사가 수집한 개인정보는 다음의 목적을 위해 활용합니다.
            <ol>
              <li>
                서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 콘텐츠 제공, 물품배송
                또는 청구서 등 발송, 금융거래 본인 인증 및 금융 서비스, 구매 및 요금 결제, 요금 추심
              </li>
              <li>
                회원 관리 통합 회원제 서비스 이용에 따른 본인확인, 개인식별, 불량회원의 부정 이용
                방지와 비인가 사용 방지, 가입 의사 확인, 가입 및 가입횟수 제한, 만14세 미만 아동
                개인 정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을
                위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
              </li>
              <li>
                마케팅 및 광고에 활용 신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스
                제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의
                서비스 이용에 대한 통계
              </li>
            </ol>
          </li>
        </ul>
      </li>
      <li>
        수집하는 개인 정보 항목 및 수집방법
        <ul>
          <li>
            사이트는 회원가입, 원활한 고객상담, 각종 서비스 신청 등 기본적인 서비스 제공을 위한 필수
            정보와 고객 맞춤 서비스 제공을 위한 선택정보로 구분하여 아래와 같은 개인정보를 수집하고
            있습니다.
            <ol>
              <li>수집항목</li>
              <li>
                ◎ 필수항목 : 닉네임, 비밀번호, email 주소
                <br />
                ◎ 선택항목 : 이름, 주소, 휴대전화번호
                <br />
                ◎ 회원맞춤정보: 자녀 이름, 자녀 생일, 자녀 성별
                <br />
                ◎ 서비스 이용 과정이나 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이 자동으로
                생성되어 수집될 수 있습니다.
                <br />
                - 서비스 이용기록, 접속 로그, 쿠키, 접속 IP 정보, 불량 이용 기록
                <br />
                수집방법
                <br />
              </li>
              <li>
                ◎ 홈페이지, 애플리케이션을 통한 회원가입, 상담 게시판, 이벤트/경품행사 응모, 배송
                요청, 제휴사로부터의 제공, 생성정보 수집 툴을 통한 수집수집하는 개인정보의 보유 및
                이용기간
                <br />
                원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이
                파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동한 보존합니다.
                <br />
                <ol>
                  <li>회원탈퇴시 보존 개인정보</li>
                  <li>
                    - 보존 항목: 닉네임, 이름, email 주소, 휴대전화번호, 주소
                    <br />
                    - 보존 근거: 불량 이용자의 재가입 방지, 불법적 사용자에 대한 관련 기관 수사협조
                    <br />- 보존 기간 : 1년관련 법령에 의한 정보 보유 사유
                  </li>
                </ol>
              </li>
              <li>
                - 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여
                보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원정보를
                보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은
                아래와 같습니다.
                <br />
                - 본인 확인에 관한 기록 : 6개월 (정보통신망 이용촉진 및 정보보호 등에 관한 법률)
                <br />
                - 방문에 관한 기록 : 3개월 (통신비밀보호법)
                <br />
                - 표시/광고에 관한 기록 : 6개월 (전자상거래등에서의 소비자보호에 관한 법률)
                <br />
                - 계약 또는 청약철회 등에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한
                법률)
                <br />
                - 대금결제 및 재화 등의 공급에 관한 기록 : 5년 (전자상거래등에서의 소비자보호에 관한
                법률)
                <br />
                - 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 전자상거래등에서의 소비자보호에 관한
                법률)
                <br />
              </li>
            </ol>
          </li>
        </ul>
      </li>
      <li>
        개인 정보의 파기절차 및 방법
        <ul>
          <li>
            원칙적으로 개인정보 수집 및 이용목적이 달성되거나, 보유 및 이용기간이 경과된 후에는 해당
            정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
            <ol>
              <li>파기절차</li>
              <li>
                - 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 내부 방침 및 기타
                관련 법령에 의한 정보보호 사유에 따라 (보유 및 이용기간 참조) 일정 기간 저장된후
                파기되어 됩니다.
                <br />
                - 일정 기간 저장된 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지
                않습니다.파기방법
                <br />
              </li>
            </ol>
          </li>
        </ul>
      </li>
      <li>
        - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기하고, 전자적 파일형태로
        저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
      </li>
      <li>
        개인정보 제공 및 공유
        <ul>
          <li>
            회사는 이용자들의 개인정보를 "2. 개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서
            사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의
            개인정보를 외부에 공개하지 않습니다.
          </li>
        </ul>
      </li>
      <li>
        개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항
        <ul>
          <li>
            회원님 개개인에게 개인화되고 맞춤화된 서비스를 제공하기 위해서 회원님의 정보를 저장하고
            수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는
            서버가 이용자의 브라우저에게 보내는 조그마한 데이터 꾸러미로 회원님 컴퓨터의
            하드디스크에 저장됩니다.
            <ol>
              <li>쿠키의 사용 목적</li>
              <li>
                - 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악
                및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인
                맞춤 서비스 제공쿠키 설정 거부 방법
              </li>
            </ol>
          </li>
        </ul>
      </li>
      <li>
        - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을
        설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
        쿠키의 저장을 거부할 수도 있습니다.
        <br />
        <br />
        ◎ 설정방법 예(인터넷 익스플로러의 경우)
        <br />- 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt; 개인정보 (단, 쿠키 설치를 거부하였을
        경우 로그인이 필요한 일부 서비스의 이용이 어려울 수 있습니다.)
      </li>
      <li>
        개인정보 보호를 위한 기술적·관리적 보호 대책
        <ul>
          <li>기술적인 대책</li>
          <li>
            - 회원님의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일
            잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
            <br />
            - 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를
            취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현될 경우
            백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.관리적인
            대책
            <br />
          </li>
        </ul>
      </li>
      <li>
        - 위와 같은 노력 이외에 회원님 스스로도 제3자에게 비밀번호 등이 노출되지 않도록 주의하셔야
        합니다. 특히 비밀번호 등이 공공장소에 설치한 PC를 통해 유출되지 않도록 항상 유의하시기
        바랍니다. 회원님의 ID와 비밀번호는 반드시 본인만 사용하시고 비밀번호를 자주 바꿔주시는 것이
        좋습니다.
        <br />- 회사는 개인정보 취급직원을 개인정보 관리업무를 수행하는 자 및 업무상 개인정보의
        취급이 불가피 한 자로 엄격히 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를
        강조하고 있으며, 감사위원회의 감사를 통하여 본 정책의 이행사항 및 담당직원의 준수여부를
        확인하여 문제가 발견될 경우 바로 시정 조치하고 있습니다.
      </li>
      <li>
        이용자 및 법정대리인의 권리와 그 행사방법
        <ul>
          <li>
            이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인
            정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다.
          </li>
          <li>
            이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는
            '회원정보 수정' 등)을 클릭하여 본인 확인 절차를 거치신 후 직접 열람 또는 정정이
            가능합니다.
          </li>
          <li>
            혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
            조치하겠습니다.
          </li>
          <li>
            이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해
            개인 정보를 이용 또는 제공하지 않습니다.{' '}
          </li>
          <li>
            또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체
            없이 통지하여 정정이 이루어지도록 하겠습니다.
          </li>
          <li>
            회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의
            보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
            처리하고 있습니다.
          </li>
        </ul>
      </li>
      <li>
        개인정보 관리책임자 및 상담·신고
        <ul>
          <li>
            해빛은 회원님의 개인 정보를 보호하기 위해 최선을 다하고 있습니다. 개인정보를 보호하는데
            있어 회원님께 알려드린 사항들에 반하는 사고가 발생할 경우 개인정보관리책임자 혹은
            담당부서로 신고하실 수 있습니다. 회원님들의 신고사항에 대해 신속하고 충분한 답변을
            드리겠습니다.
          </li>
          <li>개인정보 관리책임자</li>
          <li>
            - 이 름 : 지상훈
            <br />
            - 소속/직위 : CTO
            <br />
            - e­mail : charlie@havitplay.com기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는
            아래 기관에 문의하시기 바랍니다.
            <br />
          </li>
        </ul>
      </li>
      <li>
        - 개인분쟁조정위원회 (http://www.1336.or.kr / 1336)
        <br />
        - 정보보호마크인증위원회 (http://www.eprivacy.or.kr / 02­580­0533~4)
        <br />
        - 대검찰청 첨단범죄수사과 (http://www.spo.go.kr / 02­3480­2000)
        <br />
        - 경찰청 사이버테러대응센터 (http://www.ctrc.go.kr / 02­392­0330)
        <br />
      </li>
      <li>
        부칙
        <ul>
          <li>
            법령/정책 또는 보안기술의 변경에 따라 내용의 추가/삭제 및 수정이 있을 시에는 변경사항
            시행일의 7일전부터 당사 사이트의 공지사항을 통하여 고지합니다.
          </li>
          <li>이 개인정보취급방침은 2015년 10월 01일부터 적용됩니다.</li>
          <li>개인정보취급방침 시행일자 : 2015년 10월 01일</li>
        </ul>
      </li>
    </ol>
  </ServiceContents>
);

export const ServiceContents = styled.div`
  line-height: 2;
  font-size: 12px;
  color: ${({ theme }) => theme.TextColor};

  & > ol {
    padding-left: 1rem;

    & > li {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      & > ul {
        padding-left: 1rem;

        & li > ol {
          padding-left: 1rem;
        }
      }
    }
  }
`;

export default Service;
