import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { scroller } from 'react-scroll';

import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { BgImgBox, VideoContainer as VideoContainerAtom } from 'components/atoms';
import { ClearUl, Clear, ClearButton } from 'components/atoms/Clear';
import NewLine from 'components/atoms/NewLine';
import {
  AddressCopy,
  FacebookShareButton,
  KakaoShareButton,
  WrapShareButtons,
} from 'components/molecules/ShareButton';
import { getYoutubeVid } from 'utils';

const PlayInfo: React.FC<any> = observer(() => {
  const { play } = usePlayDetailStore();
  const handleClickComment = useCallback(() => {
    scroller.scrollTo('PlayComments', {
      offset: -50,
      duration: 250,
      smooth: true,
    });
  }, []);

  return (
    <InfoBox>
      <WrapImg>
        <Img src={play.image_url} alt='play image' />
      </WrapImg>
      <WrapInfo>
        <Title>{play.name}</Title>
        <Summary>
          <ClearButton onClick={handleClickComment} $inline>
            <Comment>댓글 {play.comments_count}</Comment>
          </ClearButton>

          <SummaryItem $right>
            분류
            <SummaryValue>{play.category_name}</SummaryValue>
          </SummaryItem>
          <SummaryItem $right>
            발달 영역
            <SummaryValue>{play.development_area}</SummaryValue>
          </SummaryItem>
          <SummaryItem $right>
            연령
            <SummaryValue>{play.display_ages}</SummaryValue>
          </SummaryItem>
        </Summary>
        <NewLine text={play.effect} Wrapper={Text} />

        {play.notice ? (
          <WrapNotice>
            <NoticeTitle>
              <NoticeIcon />
              주의사항
            </NoticeTitle>
            <NewLine text={play.notice} sticky Wrapper={Text} />
          </WrapNotice>
        ) : null}
        <WrapMaterial>
          {play.tool ? (
            <div>
              <dt>
                <MaterialIcon />
                사용 교구
              </dt>
              <dd>{play.tool}</dd>
            </div>
          ) : null}
          {play.excluded_materials ? (
            <div>
              <dt>
                <EMaterialIcon />
                미리 준비하면 좋아요!
              </dt>
              <dd>{play.excluded_materials}</dd>
            </div>
          ) : null}
          {play.included_materials ? (
            <div>
              <dt>
                <IMaterialIcon />
                패키지에 포함된 재료
              </dt>
              <dd>{play.included_materials}</dd>
            </div>
          ) : null}
        </WrapMaterial>
        {useDesktopShow(
          <WrapShareButtons>
            <KakaoShareButton
              title={play.name}
              description={play.effect}
              imageUrl={play.image_url}
            />
            {/* <KakaoStoryShareButton text={`[${play.name}] ${play.effect}`} /> */}
            <FacebookShareButton />
            <AddressCopy />
          </WrapShareButtons>,
        )}
      </WrapInfo>
      <Clear />
      <Video src={play.video_url} direction={play.video_direction} />
    </InfoBox>
  );
});

export default PlayInfo;

const VideoContainer = styled(VideoContainerAtom)<{ $portrait: boolean }>`
  ${({ $portrait }) =>
    $portrait
      ? css`
          max-width: 582px;
          padding-bottom: 87.5%;
          margin: 32px auto 0;
        `
      : css`
          margin: 32px 0 0;
        `};

  ${({ theme, $portrait }) => theme.tablet`
    margin-top: 0;
    margin-bottom: 20px;

    ${
      $portrait
        ? css`
            padding-bottom: 97.5%;
          `
        : null
    }
  `}

  ${({ theme, $portrait }) => theme.mobile`
    ${
      $portrait
        ? css`
            padding-bottom: 150%;
          `
        : null
    }
  `}
`;

const VideoWrapper = styled.div`
  background-color: #ccc;

  ${({ theme }) => theme.tablet`
    margin: 0 20px;
  `}
`;

const Video: React.FC<{ src: string; direction?: 'portrait' | 'landscape' }> = ({
  src,
  direction,
}) => {
  const vid = getYoutubeVid(src ?? '');

  return vid ? (
    <VideoWrapper>
      <VideoContainer $portrait={direction === 'portrait'}>
        <iframe title={vid} src={src} />
      </VideoContainer>
    </VideoWrapper>
  ) : null;
};

const Text = styled.div`
  line-height: 1.8;
`;

const InfoBox = styled.div`
  line-height: 1.5;

  ${({ theme }) => theme.desktop`
    margin: 40px 0 70px;
    overflow: hidden;
  `}

  ${({ theme }) => theme.tablet`
    font-size: 12px;
  `}
`;
const WrapImg = styled(BgImgBox)`
  ${({ theme }) => theme.desktop`
    float: left;
    width: 582px;
    height: 582px;
  `}

  ${({ theme }) => theme.tablet`
    text-align: center;
    line-height: 1;
    background-color: #ccc;
  `}
`;
const Img = styled.img`
  width: 100%;
  max-width: 582px;

  ${({ theme }) => theme.desktop`
    position: absolute;
    height: 100%;
  `}
`;

const WrapInfo = styled.div`
  float: left;

  ${({ theme }) => theme.desktop`
    margin-left: 30px;
    width: calc(100% - 612px);
    line-height: 1.5;
  `}

  ${({ theme }) => theme.tablet`
    padding: 20px;
    width: 100%;
  `}
`;

const Title = styled.h1`
  margin-bottom: 8px;
  font-size: 32px;

  ${({ theme }) => theme.tablet`
    margin-bottom: 10px;
    font-size: 20px;
  `}
`;
const Summary = styled(ClearUl)`
  ${({ theme }) => theme.desktop`
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid;
  `}

  ${({ theme }) => theme.tablet`
    margin: 10px 0;
    padding: 10px 0;
    border-top: 2px solid;
  `}
`;
const SummaryItem = styled.li<{ $right?: boolean }>`
  display: inline-block;
  font-size: 12px;
  font-weight: bold;

  & + & {
    margin-left: 16px;
  }

  ${({ theme }) => theme.tablet`
    font-size: 10px;
  `}

  ${({ theme, $right }) => theme.mobile`
    ${$right ? 'float:right;' : null}

    & + & {
      margin-left: 10px;
    }
  `}
`;
const Comment = styled(SummaryItem)`
  ${({ theme }) => theme.desktop`
    font-size: 16px;
    margin-right: 32px;
  `}
`;
const SummaryValue = styled.span`
  font-weight: normal;

  &:before {
    content: '|';
    vertical-align: text-bottom;
    margin: 0 4px;

    ${({ theme }) => theme.tablet`
      margin: 0 3px;
    `}
  }
`;

const WrapNotice = styled.div`
  margin-top: 24px;
  span {
    display: inline-block;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.mainActive};
  }
`;
const NoticeTitle = styled.h2`
  margin-bottom: 9px;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.tablet`
    font-size: 12px;
    margin-bottom: 10px;
  `}
`;
const NoticeIcon = styled.i`
  display: inline-block;
  vertical-align: bottom;
  margin-right: 4px;
  width: 24px;
  height: 24px;
  background: url('/icons/play/notice.svg') no-repeat;

  ${({ theme }) => theme.tablet`
    margin-right: 5px;
    width: 16px;
    height: 16px;
  `}
`;

const WrapMaterial = styled.dl`
  margin: 14px 0 24px;
  line-height: 1.8;

  ${({ theme }) => theme.tablet`
    margin: 18px 0 0;
  `}

  div {
    display: flex;
    flex-direction: row;

    ${({ theme }) => theme.desktop`
      &:first-child {
        dt,
        dd {
          padding-top: 16px;
        }
      }

      &:last-child {
        dt,
        dd {
          padding-bottom: 16px;
        }
      }
    `}

    ${({ theme }) => theme.tablet`
      flex-wrap: wrap;
      font-size: 12px;
    `}
  }

  dt,
  dd {
    flex-grow: 0;
    flex-shrink: 0;

    ${({ theme }) => theme.desktop`
      padding: 8px 16px 8px;
    `}
  }

  dt {
    ${({ theme }) => theme.desktop`
      flex-basis: 230px;
      background-color: #f8f8f8;
    `}

    ${({ theme }) => theme.tablet`
      flex-basis: 170px;
      font-weight: bold;

      &.components {
        flex-basis: 80px;
        padding-right: 15px;
      }
    `}
  }

  dd {
    ${({ theme }) => theme.desktop`
      flex-basis: calc(100% - 276px);
      margin-left: 0;
    `}

    ${({ theme }) => theme.tablet`
      margin: 5px 0 20px;
      flex-basis: 100%;

      &.components {
        flex-basis: calc(100% - 95px);
      }
    `}
  }
`;
const MaterialIconCSS = css`
  display: inline-block;
  vertical-align: sub;
  margin-right: 7px;
  width: 20px;
  height: 20px;

  ${({ theme }) => theme.tablet`
    margin-right: 5px;
    width: 16px;
    height: 16px;
  `}
`;
const MaterialIcon = styled.i`
  ${MaterialIconCSS}
  background: url('/icons/play/material.svg') no-repeat;
  background-size: 100%;
`;
const EMaterialIcon = styled.i`
  ${MaterialIconCSS}
  background: url('/icons/play/check.svg') no-repeat;
  background-size: 100%;
`;
const IMaterialIcon = styled.i`
  ${MaterialIconCSS}
  background: url('/icons/play/gift.svg') no-repeat;
  background-size: 100%;
`;

// ${$portrait ? css`

// `: null};
// padding-bottom: ${ ? '178.25%' : '56.25%'};
