import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { Box, H2 } from './styled';
import NewLine from 'components/atoms/NewLine';

const TipScript = observer(() => {
  const { play } = usePlayDetailStore();

  if (!play.tip_script) return null;

  return (
    <Box $withPad>
      <H2>차이의 놀이팁</H2>
      <NewLine Wrapper={WrapTipScript} text={play.tip_script} />
    </Box>
  );
});

export default TipScript;

const WrapTipScript = styled.div`
  margin: 16px 0 70px;
  line-height: 1.8;

  ${({ theme }) => theme.tablet`
    margin: 10px 0 40px;
    font-size: 12px;
  `}
`;
