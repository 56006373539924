import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import {
  Section,
  SectionTitle,
  Table,
  Tr,
  Th,
  Td,
  MobileTableWrapper,
} from '../../Complete/components/styled';
import styled from 'styled-components';
import { ATag } from 'components/atoms';
import { DeliveryPopup } from 'components/molecules/Popup';

export default observer(function DeliveryInfoTable() {
  const {
    order: { status, expected_delivery_start_date, display_tracking_numbers },
  } = useStores();

  if (status === 'success' || status === 'completed') {
    return (
      <Section>
        <SectionTitle>택배 배송조회</SectionTitle>
        <MobileTableWrapper>
          <Table>
            <tbody>
              <Tr>
                <Th>출고예정일</Th>
                <Td>
                  {expected_delivery_start_date} <DeliveryPopup />
                </Td>
              </Tr>
              <Tr>
                <Th>택배사</Th>
                <Td>CJ대한통운</Td>
              </Tr>
              <Tr>
                <Th>운송장번호</Th>
                <Td>
                  {display_tracking_numbers.map((trackingNumber, index) => {
                    return (
                      <>
                        {index ? <span>, </span> : ''}
                        <TracknginNumberLink
                          href={`https://www.doortodoor.co.kr/parcel/doortodoor.do?fsp_action=PARC_ACT_002&fsp_cmd=retrieveInvNoACT&invc_no=${trackingNumber}`}
                          target='_blank'>
                          {trackingNumber.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3')}
                        </TracknginNumberLink>
                      </>
                    );
                  })}
                </Td>
              </Tr>
            </tbody>
          </Table>
        </MobileTableWrapper>
      </Section>
    );
  } else {
    return <></>;
  }
});

const TracknginNumberLink = styled(ATag)`
  color: ${({ theme }) => theme.blue};
  line-height: 30px;
`;
