import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { useStores } from 'stores';

import { ListMoreButton, Wrapper } from 'components/atoms';
import PackagesContainer from 'components/organisms/PackagesContainer';
import { PackagesTitle } from './styled';

const NewContainer: React.FC<any> = observer(() => {
  const { home } = useStores();
  const [MoreFlag, setMoreFlag] = useState(false);
  const handleMoreClick = useCallback(() => setMoreFlag(true), []);

  return (
    <HomeNewWrap>
      <PackagesTitle>신상품</PackagesTitle>
      <ContainerWrap>
        <PackagesContainer store={home.new.slice(0, !MoreFlag ? 25 : 50)} txtCenter />
        {!MoreFlag ? <ListMoreButton onClick={handleMoreClick}>더보기</ListMoreButton> : null}
      </ContainerWrap>
    </HomeNewWrap>
  );
});

export default NewContainer;

const HomeNewWrap = styled(Wrapper)`
  text-align: center;

  ${({ theme }) => theme.desktop`
    margin-top: 32px;
    margin-bottom: 80px;
  `}

  ${({ theme }) => theme.mobile`
    margin-bottom: 20px;
  `}
`;

const ContainerWrap = styled.section`
  text-align: left;

  ${({ theme }) => theme.desktop`
    margin-top: 40px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 0 10px;
  `}
`;
