import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useStores } from 'stores';
import { useInitSearch, useSearchStore } from 'stores/local/Search';
import { PAGE_URL } from 'configs/path';
import { ClearButton } from 'components/atoms/Clear';
import MobileBackButton from 'components/atoms/MobileBackButton';
import KeywardFilterTab from './KeywardFilterTab';
import KeywardList from './KeywardList';

interface IFormInput {
  keyword: string;
}

const SearchForm: React.FC<{}> = observer(() => {
  const { push } = useHistory();
  const {
    layout: { isDesktop },
  } = useStores();
  const { age } = useSearchStore();
  const { register, handleSubmit } = useForm<IFormInput>();
  const onSubmit = useCallback(
    ({ keyword }: IFormInput) => {
      if (keyword) {
        push(`${PAGE_URL.PACKAGE_SEARCH}?q=${keyword}&age=${age}`);
      }
    },
    [age, push],
  );

  useInitSearch();

  return (
    <Wrapper>
      <WrapInput onSubmit={handleSubmit(onSubmit)}>
        {!isDesktop ? <BackButton /> : null}
        <Input ref={register} type='text' name='keyword' placeholder='검색어를 입력해주세요.' />
        <SearchButton type='submit' />
      </WrapInput>
      <KeywardBox>
        {isDesktop ? <Header>키워드 추천</Header> : null}
        {!isDesktop ? <SubHeader>연령</SubHeader> : null}
        <KeywardFilterTab />
        {!isDesktop ? <SubHeader>키워드</SubHeader> : null}
        <KeywardList />
      </KeywardBox>
    </Wrapper>
  );
});
export default SearchForm;

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  margin-top: -1px;
  width: 100%;
  height: 610px;
  background: #fff;

  ${({ theme }) => theme.tablet`
    position:fixed;
    top: 0;
    width: 100%;
    height: 100%;
  `}
`;

const BackButton = styled(MobileBackButton)`
  ${({ theme }) => theme.mobile`
    left: 20px;
  `}
`;

const WrapInput = styled.form`
  position: relative;
  margin: auto;
  width: 360px;

  ${({ theme }) => theme.tablet`
    padding: 8px 49px 8px 54px;
    width: 100%;
  `}
`;

const Input = styled.input`
  border: 0;
  outline: none;

  ${({ theme }) => theme.desktop`
    padding: 8px 44px 8px 0;
    width: 100%;
    border-bottom: solid 2px #262626;
  `}

  ${({ theme }) => theme.tablet`
    padding: 6px 10px;
    width: 100%;
    line-height: 20px;
    border-radius: 3px;
    background-color: #f8f8f8;
    font-size: 14px;
  `}
`;

const SearchButton = styled(ClearButton)`
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;

  ${({ theme }) => theme.desktop`
    top: 0;
    right: 0;
    width: 36px;
    height: 36px;
    background-image: url('/icons/header/search.svg');
    background-size: 20px 20px;
  `}

  ${({ theme }) => theme.tablet`
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    background-image: url(/icons/search-gray.svg);
    background-size: 19px 22px;
  `}

  ${({ theme }) => theme.mobile`
    top: 12px;
    right: 20px;
    width: 19px;
    height: 22px;
  `}
`;

const KeywardBox = styled.div`
  overflow: hidden;

  ${({ theme }) => theme.desktop`
    margin: 30px auto 48px;
    width: 847px;
    border-radius: 20px;
    border: solid 1px #e6e6e6;
  `}

  ${({ theme }) => theme.tablet`
    padding: 15px 8px 15px 20px;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8;
  `};
`;

const Header = styled.div`
  margin: 24px 16px 20px 24px;
  font-size: 16px;
  font-weight: bold;
`;

const SubHeader = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  color: #b3b3b3;
`;
