import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { DetailMatchParams, PAGE_URL } from 'configs/path';

import { Wrapper } from 'components/atoms/Mypage';
import VBank from '../Complete/components/VBank';
import PurchaseTable from '../Complete/components/PurchaseTable';
import Payments from '../Complete/components/Payments';
import PaymentsMobile from '../Complete/components/PaymentsMobile';
import Receipt from '../Complete/components/Receipt';
import BuyerTable from '../Complete/components/BuyerTable';
import ShippingTable from '../Complete/components/ShippingTable';
import Banner from '../Complete/components/Banner';
import BackButton from './components/BackButton';
import DeliveryInfoTable from './components/DeliveryInfoTable';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';

export const PurchaseDetail: React.FC = observer(() => {
  const { id } = useRouteMatch<DetailMatchParams>().params;
  const history = useHistory();
  const { order, auth } = useStores();

  useEffect(() => {
    async function fetch() {
      try {
        await order.fetch(+id);
      } catch (err) {
        history.replace(PAGE_URL.ERROR);
      }
    }

    fetch();
  }, [history, id, order]);

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>주문 상세 내역</SubHeaderTitle>
      </SubHeader>
      <Wrapper>
        <VBank />
        <PurchaseTable />
        {useDesktopShow(<Payments />)}
        {useTabletShow(<PaymentsMobile />)}
        <Receipt />
        <BuyerTable />
        <ShippingTable />
        <DeliveryInfoTable />
        {useDesktopShow(<Banner />)}
        {auth.isSignIn ? <BackButton /> : null}
      </Wrapper>
    </>
  );
});
