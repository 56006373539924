import React from 'react';
import { observer } from 'mobx-react';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { Box, H2 } from './styled';
import StepContainer from 'components/molecules/PlaysDetail/StepContainer';

const PlayStep = observer(() => {
  const { play } = usePlayDetailStore();

  if (play.steps.length === 0) return null;

  return (
    <Box $withPad>
      <H2>놀이 방법</H2>
      <StepContainer store={play.steps} />
    </Box>
  );
});

export default PlayStep;
