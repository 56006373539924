import styled from 'styled-components';

export const Table = styled.table`
  table-layout: fixed;
  margin-top: 25px;
  width: 100%;
  border-top: solid 2px #262626;
  border-collapse: collapse;
  text-align: center;

  thead {
    background-color: #f8f8f8;
  }

  tr {
    border-bottom: solid 1px #d5d5d5;
  }
`;

export const Td = styled.td<{ $txtBold?: boolean }>`
  padding: 16px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${({ $txtBold }) => ($txtBold ? 'font-weight: bold;' : null)};
`;
