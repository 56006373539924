import React from 'react';
import { observer } from 'mobx-react';
import qs from 'query-string';
import Dropdown from 'react-dropdown';
import styled, { css } from 'styled-components';

import { packagesSort, packagesAge } from 'configs/filterType';
import { usePackagesStore } from 'pages/Packages/store';
import { DropDownOpenIcon, DropDownCloseIcon } from 'components/atoms';
import { Clear } from 'components/atoms/Clear';

const FilterContainer: React.FC<any> = observer(() => {
  const { filter, totalPackages, setFilter } = usePackagesStore();
  const onChangeAgeFilter = ({ value }: any) => setFilter({ page: 1, ...qs.parse(value) });
  const onChangeSortFilter = ({ value }: any) => setFilter({ page: 1, sort: value });

  return (
    <WrapListFilter>
      <Total>총 {totalPackages}개 상품</Total>
      <SortDropdown
        options={packagesSort}
        onChange={onChangeSortFilter}
        value={filter.sort}
        arrowOpen={<DropDownOpenIcon $css={DropDownIcon} />}
        arrowClosed={<DropDownCloseIcon $css={DropDownIcon} />}
      />
      <AgeDropdown
        options={packagesAge}
        onChange={onChangeAgeFilter}
        value={filter.age || packagesAge[0]}
        arrowOpen={<DropDownOpenIcon $css={DropDownIcon} />}
        arrowClosed={<DropDownCloseIcon $css={DropDownIcon} />}
      />
      <Clear />
    </WrapListFilter>
  );
});

const WrapListFilter = styled.div`
  margin-top: 24px;

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
    padding: 0 20px;
    text-align: right;
  `}

  ${({ theme }) => theme.mobile`
    margin-top: 7px;
    padding: 0 10px;
  `}
`;
const Total = styled.div`
  float: left;
  margin-top: 8px;
  line-height: 1.5;
  font-size: 16px;
  color: ${({ theme }) => theme.TextColor};

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
    font-size: 12px;
  `};
`;

const DropdownCSS = styled(Dropdown)`
  float: right;
  width: auto;

  .Dropdown-control {
    box-sizing: content-box;
    padding: 8px 20px 8px 8px;
    height: auto;
    border: 0;

    line-height: 1.5;
    font-size: 16px;
    color: ${({ theme }) => theme.TextColor};
  }

  .Dropdown-menu {
    left: auto;
    right: 0;
    padding: 10px 15px;
  }

  .Dropdown-option {
    padding: 5px 0;
    font-size: 16px;
  }

  ${({ theme }) => theme.tablet`
    .Dropdown-control {
      padding: 5px 19px 5px 5px;
      font-size: 12px;
    }

    .Dropdown-menu {
      padding: 10px 12px;
    }

    .Dropdown-option {
      padding: 2.5px 0;
      font-size: 12px;
      text-align: left;
    }
  `}
`;
const AgeDropdown = styled(DropdownCSS)`
  .Dropdown-menu {
    width: 120px;
  }

  ${({ theme }) => theme.tablet`
    .Dropdown-menu {
      width: 90px;
    }
  `}
`;
const SortDropdown = styled(DropdownCSS)`
  margin-left: 33px;

  .Dropdown-menu {
    width: 135px;
  }

  ${({ theme }) => theme.tablet`
    margin-left: 20px;

    .Dropdown-menu {
      width: 105px;
    }
  `}
`;
const DropDownIcon = css`
  position: absolute;
  top: 17px;
  right: 0;
  width: 12px;
  height: 9px;

  ${({ theme }) => theme.tablet`
    top: 11px;
  `}
`;

export default FilterContainer;
