import React, { useRef, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useTabletShow } from 'components/hooks/useLayoutShow';
import useScrollEvt from 'components/hooks/useScrollEvt';

import { SlideYTransition } from './Animation';
import { RelativeDiv } from '.';

export const SubHeader: React.FC<any> = props => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);
  const onScroll = useCallback(() => {
    if (ref.current) {
      const condition = ref.current.getBoundingClientRect().top <= -ref.current.clientHeight;
      setSticky(condition);
    }
  }, []);

  useScrollEvt(onScroll);

  return useTabletShow(
    <RelativeDiv ref={ref}>
      <SlideYTransition in={isSticky} timeout={500} classNames='slide-down' unmountOnExit>
        <WrapStikey>{props.children}</WrapStikey>
      </SlideYTransition>
      {props.children}
    </RelativeDiv>,
  );
};

export const SubHeaderTitle = styled.h1<{ $withLeftButton?: boolean; $withRightButton?: boolean }>`
  padding: 12px 0;
  border-bottom: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  line-height: 1.5;
  font-size: 14px;
  text-align: center;
  font-weight: bold;

  ${({ $withLeftButton }) =>
    $withLeftButton &&
    css`
      padding-left: 40px;
    `}

  ${({ $withRightButton }) =>
    $withRightButton &&
    css`
      padding-right: 40px;
    `}
`;

const WrapStikey = styled.div`
  position: fixed;
  z-index: 990;
  top: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
`;
