import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

import { Component } from 'stores/models/PacakgeDetailModel';
import { generateKey } from 'utils';

interface Props {
  components: Component[];
  withHeader?: boolean;
  TabContents?: boolean;
  BorderBottom?: boolean;
}

const ComponentsTable: React.FC<Props> = React.memo(
  ({ components, withHeader, TabContents, BorderBottom }) =>
    components.length ? (
      <InfoBox $TabContents={TabContents} $BorderBottom={BorderBottom}>
        {withHeader ? <h2>구성품</h2> : null}
        <dl>
          {components.map(({ title, description, tip }, idx) => (
            <div
              className={cx({ hr: title && idx !== 0 })}
              key={generateKey(`PackageDetail_Components_${idx}`)}>
              <dt className='components'>{title}</dt>
              <dd className='components'>
                {description}
                <Tip>{tip}</Tip>
              </dd>
            </div>
          ))}
        </dl>
      </InfoBox>
    ) : null,
);

export const InfoBox = styled.div<{ $TabContents?: boolean; $BorderBottom?: boolean }>`
  margin-top: ${({ $TabContents }) => ($TabContents ? '0' : '56px')};
  line-height: 1.5;
  ${({ $BorderBottom }) => ($BorderBottom ? 'border-bottom: solid 2px #d5d5d5;' : null)}

  & + & {
    margin-top: 72px;
    ${({ theme }) => theme.tablet`
      margin-top: 30px;
    `}
  }

  h2 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    ${({ theme }) => theme.tablet`
      font-size: 16px;
    `}
  }

  dl {
    margin: 0;
    border-top: ${({ $TabContents }) => ($TabContents ? '0' : '2px solid #262626')};

    ${({ theme, $TabContents }) => theme.tablet`
      margin: 10px 0;
      ${$TabContents ? `border: 0` : ''};
    `}

    div {
      display: flex;
      flex-direction: row;

      ${({ $TabContents }) =>
        $TabContents
          ? `&.hr {
              border-top: 1px solid #d5d5d5;
            }`
          : null};

      ${({ theme }) => theme.tablet`
        font-size: 12px;
      `}

      &:first-child {
        dt,
        dd {
          padding-top: ${({ $TabContents }) => ($TabContents ? 0 : 18)}px;

          ${({ theme }) => theme.tablet`
            padding-top: 10px;
          `}
        }
      }

      &:last-child {
        dt,
        dd {
          padding-bottom: 18px;

          ${({ theme }) => theme.tablet`
            padding-bottom: 10px;
          `}
        }
      }
    }

    dt,
    dd {
      ${({ theme }) => theme.desktop`
        padding: 12px 24px;
      `}

      ${({ theme }) => theme.tablet`
        padding: 5px 0;
      `}
    }

    dt {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 276px;
      background-color: ${({ $TabContents }) => ($TabContents ? '#fff' : '#f8f8f8')};
      font-weight: ${({ $TabContents }) => ($TabContents ? 'bold' : 'normal')};

      ${({ theme }) => theme.tablet`
        flex-basis: 170px;
        background-color: #fff;
        font-weight: bold;

        &.components {
          flex-basis: 80px;
          padding-right: 15px;
        }
      `}
    }

    dd {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(100% - 276px);
      margin-left: 0;

      ${({ theme }) => theme.tablet`
        flex-basis: calc(100% - 170px);
        background-color: #fff;

        &.components {
          flex-basis: calc(100% - 95px);
        }
      `}
    }
  }
`;

const Tip = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.mainActive};
`;

export default ComponentsTable;
