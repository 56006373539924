import { extendObservable } from 'mobx';

type Props = ResPackage | Play.Package;

class PackageModel {
  constructor(props: Props) {
    extendObservable(this, {
      id: props.id,
      name: props.title,
      img: props.image_url,
      age: props.display_months_or_ages,
      origin: props.original_price,
      price: props.display_sales_price,
      discount: props.display_discount_rate,
      tag: this.getTag(props),
    });
  }

  getTag(props: Props): string {
    if (props.show_new_label) return 'New';
    if (props.show_best_label) return 'Best';
    if (props.show_on_sale_label) return 'Sale';
    return '';
  }
}

export interface ResPackage {
  id: number;
  title: string;
  description: string;
  image_url: string;
  url: string;
  original_price: string; // "2,000원",
  display_sales_price: string; //"1,990원",
  display_discount_rate: string; // "1%",
  display_months_or_ages: string; // "만 3-6세",
  show_new_label: boolean; // true,
  show_best_label: boolean; // false,
  show_on_sale_label: boolean; // false,

  sales_price: string; // "1,990원",
  release_date: string; // "2020-08-12",
  content: any; // null
  age: string; // "만 3-6세",
  months: string; // "37-84개월",
  status: string; // "in_stock",
  display_expected_arrival_date: any; // null,
  square_image_url: string; // ?
  share_url: string; // ?
  in_wishlist: false; // ?
}

export default PackageModel;
