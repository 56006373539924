import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observable, flow, action } from 'mobx';
import { useLocalStore } from 'mobx-react';

import { DetailMatchParams } from 'configs/path';
import EventRepository from '../repository/EventRepository';
import { IEvent } from 'stores/models/EventDetailModel';

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      event: {} as IEvent.Event,
      fetch(id: string) {
        flow(function* (this: any) {
          try {
            this.isInit = false;

            const { data } = yield EventRepository.fetchDetail(id);
            this.event = data.event;
            console.log(data);

            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
    },
    {
      fetch: action,
    },
  );

  return store;
};

type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const EventDetailProvider = ({ children }: any) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useEventDetailStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitEventDetail = () => {
  const { fetch } = useEventDetailStore();
  const { id } = useRouteMatch<DetailMatchParams>().params;

  useEffect(() => {
    fetch(id);
  }, [fetch, id]);
};
