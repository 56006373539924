import styled, { keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

export const HeightOpen = keyframes`
  0% {
    transform-origin: top;
    transform: scaleY(0);
    max-height: 0;
  }
  100% {
    transform-origin: top;
    transform: scaleY(100%);
    max-height: 860px;
  }
`;

export const HeightClose = keyframes`
  0% {
    transform-origin: top;
    transform: scaleY(100%);
    max-height: 860px;
  }
  100% {
    transform-origin: top;
    transform: scaleY(0);
    max-height: 0;
  }
`;

export const FadeIn = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
`;

export const SlideYTransition = styled(CSSTransition)`
  &.slide-up-enter {
    opacity: 0;
    transform: translateY(100%);
  }
  &.slide-up-enter-active {
    opacity: 1;
    transform: translateY(0%);
  }
  &.slide-up-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  &.slide-up-exit-active {
    opacity: 0;
    transform: translateY(100%);
  }

  &.slide-up-enter-active,
  &.slide-up-exit-active {
    transition: opacity 250ms, transform 250ms;
  }

  &.slide-down-enter {
    opacity: 0;
    transform: translateY(-100%);
  }
  &.slide-down-enter-active {
    opacity: 1;
    transform: translateY(0%);
  }
  &.slide-down-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  &.slide-down-exit-active {
    opacity: 0;
    transform: translateY(-100%);
  }

  &.slide-down-enter-active,
  &.slide-down-exit-active {
    transition: opacity 250ms, transform 250ms;
  }
`;
