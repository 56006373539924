import React, { useCallback, useState, useEffect } from 'react';
import { useLocation, generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import qs from 'query-string';

import { PAGE_URL } from 'configs/path';
import { DefaultLayout } from 'layouts/Default';
import { DISPLAY, ILayout } from 'interfaces/layout';
import { usePlaysStore, useInitPlays, PlaysProvider } from './store';

import { WrapList, Title, WrapListItem } from 'components/styled/List';
import PlaysBreadCrumb from 'components/organisms/Plays/BreadCrumb';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import AgeFilter from 'components/organisms/Plays/AgeFilter';
import Pagenation from 'components/molecules/Pagenation';
import ListFrame from 'components/atoms/ListFrame';
import { WrapPlaysItem } from './styled';
import PlaysItem from 'components/molecules/Share/PlaysItem';
import { useStores } from 'stores';

const PlaysContainer: React.FC<ILayout> = observer(() => {
  const { layout } = useStores();
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const { isInit, filter, pagenation, setFilter, plays } = usePlaysStore();
  const onClickPage = useCallback((page: number) => () => setFilter({ page }), [setFilter]);

  useInitPlays();

  useEffect(() => {
    const { age } = qs.parse(location.search);
    if (layout.displayType === DISPLAY.DESKTOP) {
      setTitle('활동지 다운로드');
      setShowFilter(true);
    } else {
      if (age === undefined) {
        setTitle('활동지 다운로드');
        setShowFilter(true);
      } else {
        setTitle(`만 ${age}세`);
        setShowFilter(false);
      }
    }
  }, [layout, location.search]);

  return (
    <WrapList>
      <PlaysBreadCrumb />
      <Title>{title}</Title>
      <SubHeader>
        {!showFilter ? <MobileBackButton url={generatePath(PAGE_URL.PLAYS)} /> : null}
        <SubHeaderTitle>{title}</SubHeaderTitle>
      </SubHeader>
      {showFilter ? <AgeFilter /> : null}
      <WrapListItem>
        {isInit ? <ListFrame ListWrap={WrapPlaysItem} ListItem={PlaysItem} store={plays} /> : null}
      </WrapListItem>
      <Pagenation
        curPage={filter.page}
        pageInfo={pagenation}
        pageUnit={layout.displayType === DISPLAY.DESKTOP ? 10 : 5}
        onClickPage={onClickPage}
      />
    </WrapList>
  );
});

export default DefaultLayout(PlaysContainer, { Provider: PlaysProvider });
