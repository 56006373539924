import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { DISPLAY } from 'interfaces/layout';
import { usePointStore } from 'pages/Mypage/PointHistory/store';
import { HistoryCards, HistoryTable } from 'components/molecules/Mypage';

const PointHistory: React.FC<any> = observer(() => {
  const { layout } = useStores();
  const { point } = usePointStore();
  const history = point.history || [];

  return layout.displayType === DISPLAY.DESKTOP ? (
    <HistoryTable headerTxt={'포인트'} store={history} />
  ) : (
    <HistoryCards headerTxt={'포인트'} store={history} />
  );
});

export default PointHistory;
