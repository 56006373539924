import { extendObservable, observable } from 'mobx';
import PackageModel from './PackageModel';

export default class PlayModel implements Play.Play {
  constructor(props: Play.Play) {
    extendObservable(this, { ...props });
  }

  get relatedContents() {
    return {
      plays: this.related_contents.plays,
      packages: this.related_contents.packages.map(item => new PackageModel(item)),
    };
  }

  @observable comments_count: number = 0;

  id!: number;
  name!: string;
  tags!: string[];

  related_contents!: Play.RelatedContents;
  random_plays!: Play.RandomPlay[];

  development_area!: string;
  effect!: string;
  notice!: string;
  tool!: string;
  description!: string;
  tip_script!: string;
  ages!: number[];
  short_effect!: string;
  featured!: boolean;
  category!: string;
  category2!: string;
  topic!: string;
  subtopic!: string;
  learning_goal!: string;
  related_activity!: string;
  image_url!: string;
  video_url?: any;
  video_direction?: 'portrait' | 'landscape';
  category_name!: string;
  url!: string;
  share_url!: string;
  share_text!: string;
  in_play_box!: boolean;
  stars_count!: number;
  included_materials!: string;
  excluded_materials!: string;
  dialogs!: Play.Dialog[];
  steps!: Play.Step[];
  making_steps: any[] = [];
  premium_packages!: Play.Package[];
  pdf_image_url!: string;
  pdf_download_url!: string;

  get display_ages() {
    const len = this.ages.length;
    if (len === 0) return '전연령';
    else if (len === 1) return `만 ${this.ages[0] - 1}세`;
    else return `만 ${this.ages[0] - 1} - ${this.ages[len - 1] - 1}세`;
  }
}
