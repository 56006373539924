import React from 'react';
import styled from 'styled-components';
import HistoryCard from 'components/atoms/HistoryCard';

interface Props {
  headerTxt: string;
  store: Array<any>;
}

const HistoryCards: React.FC<Props> = ({ store, headerTxt }) => {
  return (
    <WrapCards>
      {store.map(item => (
        <HistoryCard key={item.id} {...item} unit={headerTxt} />
      ))}
    </WrapCards>
  );
};

export default HistoryCards;

const WrapCards = styled.div`
  margin: 0 auto;
  max-width: 280px;

  ${({ theme }) => theme.mobile`
    padding: 0 20px;
    max-width: none;
  `};
`;
