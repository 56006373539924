import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { useStores } from 'stores';
import { commaNumber } from 'utils';

import { FormSection, H2, Hr } from './styled';
import { Input, InputButton } from 'pages/Mypage/Info/styled';
import { MODAL_CLASSIFIER } from 'pages/Purchase/Order/constants';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

const PaymentForm: React.FC<any> = observer(() => {
  const { order, modal, layout } = useStores();
  const useForm = useFormContext();
  const { register, setValue, getValues } = useForm;

  const changePoint = useCallback(
    (ipt: string | number) => {
      let value = isNaN(+ipt) ? '' : +ipt;

      if (value > order.maxPoint) setValue('order.used_point', order.maxPoint);
      else if (value > 0) setValue('order.used_point', value);
      else setValue('order.used_point', '');
    },
    [order, setValue],
  );

  const handleChangePoint = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => changePoint(evt.target.value),
    [changePoint],
  );

  const handleMaxPoint = useCallback(() => {
    setValue('order.used_point', order.maxPoint);
    setValue('order.price', order.price - order.maxPoint - order.coupon_price);
  }, [order, setValue]);

  const changeCoupon = useCallback(() => {
    const {
      order: { my_coupon_price, used_point },
    } = getValues();
    const expectedPrice = order.price - my_coupon_price - used_point;

    order.coupon_price = +my_coupon_price;
    if (expectedPrice < 0) {
      changePoint(order.maxPoint);
    }
  }, [order, getValues, changePoint]);

  const handleCoupon = useCallback(() => {
    modal.openModal(MODAL_CLASSIFIER.USABLE_COUPON, {
      useForm,
      dismissible: true,
      onCloseEnd: changeCoupon,
    });
  }, [modal, useForm, changeCoupon]);

  useEffect(() => {
    setValue('order.price', order.price);
  }, [order.price, setValue]);

  useEffect(() => {
    if (order.isFetched) {
      const _order = order.savedOrder?.order;
      const coupon_id = _order?.my_coupon_id ?? '';
      const coupon_price = _order?.my_coupon_price ?? '';
      const coupon = order.findCoupon(+coupon_id, +coupon_price);

      if (coupon) {
        setValue('order.my_coupon_id', coupon_id);
        setValue('order.my_coupon_price', coupon_price);
        order.coupon_price = +coupon_price;

        /* Local에서 사용할 쿠폰 Form 초기화 */
        register('_coupon.id');
        register('_coupon.price');
        setValue('_coupon.id', coupon_id);
        setValue('_coupon.price', coupon_price);
      }

      /* 포인트는 먼저 적용된 쿠폰에 종속적이므로 쿠폰 적용후 point 적용 시도 */
      changePoint(+(_order?.used_point ?? ''));
    }
  }, [order, order.isFetched, register, setValue, changePoint]);

  return (
    <FormSection $noMargin={!layout.isDesktop}>
      {useDesktopShow(<H2>결제 정보</H2>)}
      <Hr />
      <Row>
        <Label>총 상품 금액</Label>
        <Text>{commaNumber(order.subtotal_price)}원</Text>
      </Row>
      <Row>
        <Label>배송비</Label>
        <Text>{commaNumber(order.shipping_price)}원</Text>
      </Row>
      {order.extra_shipping_price > 0 && (
        <Row>
          <Label>추가 배송비(도서산간지역)</Label>
          <Text>{commaNumber(order.extra_shipping_price)}원</Text>
        </Row>
      )}
      {order.isSignIn ? (
        <>
          <Row>
            <Label>할인 쿠폰</Label>
            <SmallInput
              name='order.my_coupon_price'
              type='text'
              ref={register}
              placeholder='0원'
              readOnly
            />
            <Button type='button' onClick={handleCoupon}>
              쿠폰 조회
            </Button>
            <Input name='order.my_coupon_id' type='hidden' ref={register} />
          </Row>
          <Row>
            <Label>사용 적립금</Label>
            <SmallInput
              name='order.used_point'
              type='number'
              ref={register}
              placeholder='0포인트'
              min='0'
              max={order.maxPoint}
              inputMode='numeric'
              pattern='[0-9]*'
              onChange={handleChangePoint}
            />
            <Button type='button' onClick={handleMaxPoint}>
              전액 사용
            </Button>
            <Point>잔여 포인트 : {commaNumber(order.userPoint)}</Point>
          </Row>
        </>
      ) : null}
      <Row>
        <PriceLabel>결제 금액</PriceLabel>
        <PriceText />
        <Input name='order.price' type='hidden' ref={register} />
      </Row>
    </FormSection>
  );
});
export default PaymentForm;

// 결제금액 re-render 격리
const PriceText: React.FC<any> = () => {
  const { order } = useStores();
  const { control, setValue } = useFormContext();
  const watchUsedPoint = useWatch({
    control,
    name: 'order.used_point',
    defaultValue: 0,
  });
  const watchUsedCoupon = useWatch({
    control,
    name: 'order.my_coupon_price',
    defaultValue: 0,
  });

  let price = order.price - watchUsedPoint - watchUsedCoupon;
  price = price > 0 ? price : 0;

  useEffect(() => {
    setValue('order.price', price);
  }, [price, setValue]);

  return <Text $txtEmph>{commaNumber(price > 0 ? price : 0)}원</Text>;
};

const Row = styled.div`
  margin: 8px 0 5px;
  ${({ theme }) => theme.tablet`
    position: relative;
    height: 30px;
    line-height: 30px;
    clear: both;
  `}
`;

const Label = styled.label`
  vertical-align: top;

  ${({ theme }) => theme.desktop`
    display: inline-block;
    padding: 8px 0;
    width: 197px;
  `}

  ${({ theme }) => theme.tablet`
  font-size: 14px;
`}
`;

const Text = styled.div<{ $txtEmph?: boolean; $large?: boolean }>`
  display: inline-block;
  margin-left: 15px;
  padding: 8px 0;
  width: 135px;

  ${({ $txtEmph, theme }) =>
    $txtEmph
      ? `
        color: ${theme.mainActive};
        font-size: 24px;
        font-weight: bold;
      `
      : null}

  ${({ $txtEmph, theme }) => theme.tablet`
    float: right;
    padding: 0;
    text-align: right;
    ${$txtEmph ? `font-size: 20px` : null}
  `}
`;

const SmallInput = styled(Input)`
  margin-left: 15px;
  width: 116px;

  // Remove Arrows/Spinners
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ${({ theme }) => theme.tablet`
    float: right;
    padding: 3px 10px;
    border: 1px solid #d5d5d5;
    border-radius: 3px;
    text-align: right;
  `}
`;

const Button = styled(InputButton)`
  ${({ theme }) => theme.desktop`
    width: 94px;
  `}
  ${({ theme }) => theme.tablet`
    width: 70px;
    top: 5px;
    left: 75px;
    font-size: 12px;
    background-color: ${theme.subActiveColor};
    color: white;
    padding: 5px 7px;
  `}
`;

const Point = styled.div`
  margin: 8px 0 24px 212px;
  ${({ theme }) => theme.tablet`
    clear: both;
    float: right;
    font-size: 12px;
    margin-bottom: 0;
  `}
`;

const PriceLabel = styled(Label)`
  ${({ theme }) => theme.tablet`
    font-size: 16px;
  `}
`;
