import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';

import { useStores } from 'stores';
import { useCouponStore } from 'stores/local/Coupon';
import { PAGE_URL } from 'configs/path';
import { DefaultModalZIndex, ModalProps } from 'interfaces/Modal';
import { useTabletShow } from 'components/hooks/useLayoutShow';
import {
  ApplyButton,
  CloseButton,
  HeaderButton,
  HeaderTitle,
  ModalBody,
  ModalFooter,
  ModalHeader,
  WrapModal,
} from './styled';
import CouponList from './CouponList';

export const CouponeModalContainer = observer(({ isOpen, params: { useForm } }: ModalProps) => {
  const { modal, order } = useStores();
  const { setByOrder, couponNum } = useCouponStore();

  const handleClose = useCallback(() => {
    const {
      order: { my_coupon_id: id, my_coupon_price: price },
    } = useForm.getValues();

    useForm.setValue('_coupon.id', `${id}`);
    useForm.setValue('_coupon.price', `${price}`);

    modal.closeCurrentModal();
  }, [modal, useForm]);

  const handleApply = useCallback(() => {
    const {
      _coupon: { id, price },
    } = useForm.getValues();

    useForm.setValue('order.my_coupon_id', `${id}`);
    useForm.setValue('order.my_coupon_price', `${price}`);

    modal.closeCurrentModal();
  }, [modal, useForm]);

  useEffect(() => {
    if (useForm && !useForm.getValues()._coupon) {
      useForm.register('_coupon.id');
      useForm.register('_coupon.price');
      useForm.setValue('_coupon.id', '');
      useForm.setValue('_coupon.price', '');
    }
  }, [useForm]);

  useEffect(() => {
    if (order.isFetched) {
      /* 해당 주문에서 사용가능한 쿠폰 목록 설정 */
      setByOrder(order.available_coupons);
    }
  }, [order, order.isFetched, setByOrder]);

  return isOpen ? (
    <WrapModal $show={isOpen} $zIndex={DefaultModalZIndex}>
      <ModalHeader>
        <HeaderTitle>사용가능 쿠폰</HeaderTitle>
        {useTabletShow(<>보유 쿠폰 {couponNum}장</>)}
        <HeaderButton to={PAGE_URL.COUPON_DOWNLOAD}>쿠폰등록하기</HeaderButton>
      </ModalHeader>
      <ModalBody>
        <CouponList useForm={useForm} />
      </ModalBody>
      <ModalFooter>
        <CloseButton onClick={handleClose}>취소</CloseButton>
        <ApplyButton $fill onClick={handleApply}>
          쿠폰 적용하기
        </ApplyButton>
      </ModalFooter>
    </WrapModal>
  ) : null;
});
