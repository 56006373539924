import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { generateKey } from 'utils';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { Box, H2 } from './styled';

const Tags = observer(() => {
  const { play } = usePlayDetailStore();

  if (play.tags.length) {
    return (
      <Box $withPad>
        <H2>관련 태그</H2>
        <WrapTags>
          {play.tags.map(text => (
            <Tag key={generateKey()}>#{text}</Tag>
          ))}
        </WrapTags>
      </Box>
    );
  } else return null;
});

export default Tags;

const WrapTags = styled.div`
  margin: 16px 0 70px;

  ${({ theme }) => theme.tablet`
    margin: 10px 0 40px;
    font-size: 12px;
  `}
`;

const Tag = styled.div`
  display: inline-block;
  margin-right: 8px;
  padding: 8px 16px;
  border-radius: 10px;
  border: solid 1px ${({ theme }) => theme.subActiveColor};
  background-color: #fff;
  line-height: 1.5;
  color: ${({ theme }) => theme.subActiveColor};

  ${({ theme }) => theme.tablet`
    margin-right: 5px;
    padding: 6px 20px;
    font-size: 12px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
    margin-right: 10px;
  `}
`;
