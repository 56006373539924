import styled from 'styled-components';
import { ClearButton } from 'components/atoms/Clear';

export const OrderButton = styled(ClearButton)`
  margin: auto;
  width: 248px;
  height: 56px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  ${({ theme }) => theme.tablet`
    margin: 0 auto;
    width: 288px;
    height: 44px;
  `}
`;
