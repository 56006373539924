import React from 'react';
import styled, { AnyStyledComponent } from 'styled-components';

interface IProps {
  IconWrap: AnyStyledComponent;
  src: string;
  alt?: string;
  href?: string;
  isTargetBlank?: boolean;
  handleClick?: any;
}

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;

const IconButton: React.FC<IProps> = ({ IconWrap, href, isTargetBlank, src, alt }) => {
  return (
    <IconWrap href={href} target={isTargetBlank ? '_blank' : ''}>
      <Icon src={src} alt={alt} />
    </IconWrap>
  );
};

IconButton.defaultProps = {
  href: '#',
  isTargetBlank: false,
  alt: 'Icon button',
};

export default IconButton;
