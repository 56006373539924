import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { DimmedType } from 'interfaces/layout';
import { useStores } from 'stores';
import { AlertView } from './AlertView';

export const AlertContainer: React.FC<any> = observer(() => {
  const {
    layout: {
      alertState,
      alertState: { visible, message },
      OpenDimmed,
      CloseDimmed,
    },
  } = useStores();
  const handleOkClick = useCallback(() => (alertState.visible = false), [alertState]);

  useEffect(() => {
    if (visible) {
      OpenDimmed({
        dismissible: true,
        type: DimmedType.Normal,
        onClose: handleOkClick,
      });
    } else {
      CloseDimmed();
    }
  }, [CloseDimmed, OpenDimmed, handleOkClick, visible]);

  return visible ? <AlertView message={message} handleOkClick={handleOkClick} /> : null;
});
