import React from 'react';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { ClearLink } from 'components/atoms/Clear';
import { useNotMobileShow, useMobileShow } from 'components/hooks/useLayoutShow';

const EventBanner: React.FC<any> = () => {
  return (
    <>
      {useNotMobileShow(
        <Banner>
          <Logo src='/images/event-banner.png' alt='banner background' />
          <Text>차이의 놀이엔 어떤 이벤트가 진행중일까?</Text>

          <LinkButton to={PAGE_URL.EVENTS}>더보기</LinkButton>
        </Banner>,
      )}
      {useMobileShow(
        <BannerLink to={PAGE_URL.EVENTS}>
          <img src='/images/event-banner-mobile.png' alt='' />
        </BannerLink>,
      )}
    </>
  );
};

export default EventBanner;

const Banner = styled.div`
  position: relative;
  margin: 70px auto;
  width: 960px;
  height: 88px;
  background-color: #a2caeb;

  ${({ theme }) => theme.tablet`
    margin: 0;
    width: 100%;
    height: 80px;
  `}

  ${({ theme }) => theme.mobile`
    height: 40px;
  `}
`;

const Logo = styled.img`
  float: left;
  margin: 0 20px;
  height: 88px;

  ${({ theme }) => theme.tablet`
    height: 80px;
  `}

  ${({ theme }) => theme.mobile`
    position: absolute;
    margin: 0 10px;
    height: 40px;
  `}
`;

const Text = styled.div`
  float: left;
  line-height: 88px;
  font-size: 24px;
  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.tablet`
    line-height: 80px;
    font-size: 20px;
  `}

  ${({ theme }) => theme.mobile`
    position: absolute;
    z-index: 1;
    padding-right: 70px;
    width: 100%;
    line-height: 40px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  `}
`;

const LinkButton = styled(ClearLink)`
  float: right;
  display: block;
  margin: 22px 20px;
  width: 200px;
  line-height: 44px;
  border-radius: 20px;
  background-color: #fff;
  font-weight: bold;
  color: #a2caeb;
  text-align: center;

  ${({ theme }) => theme.tablet`
    margin: 20px 20px 20px 0;
    width: 130px;
    line-height: 40px;
    border-radius: 20px;
    font-size: 20px;
  `}

  ${({ theme }) => theme.mobile`
    position: absolute;
    z-index: 2;
    right: 0;
    margin: 10px 10px 10px 0;
    width: 60px;
    line-height: 20px;
    font-size: 10px;
    font-weight: bold;
  `}
`;

const BannerLink = styled(ClearLink)`
  display: block;
  width: 100%;
  height: 41px;
  text-align: center;
  background-color: #a2caeb;
`;
