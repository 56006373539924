import React from 'react';
import { observer } from 'mobx-react';
import { DISPLAY } from 'interfaces/layout';
import { useStores } from 'stores';
import { useCoinStore } from 'pages/Mypage/CoinHistory/store';

import { HistoryCards, HistoryTable } from 'components/molecules/Mypage';

const CoinHistory: React.FC<any> = observer(() => {
  const { layout } = useStores();
  const { coin } = useCoinStore();
  const history = coin.history || [];

  return layout.displayType === DISPLAY.DESKTOP ? (
    <HistoryTable headerTxt={'코인'} store={history} />
  ) : (
    <HistoryCards headerTxt={'코인'} store={history} />
  );
});

export default CoinHistory;
