/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observable, flow, action, toJS } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { DetailMatchParams } from 'configs/path';
import { PackagesFilter, SelectOption, ICartJS } from 'interfaces/PackageDetail';
import PackageRepository from './repository/PackageRepository';
import PackageDetailModel, { Option } from 'stores/models/PacakgeDetailModel';
import { RootStore, useStores } from 'stores';
import { MODAL_CLASSIFIER } from './constants';
import { ModalFunctionParams } from 'interfaces/Modal';
import { AxiosError } from 'axios';

const createStore = (rootStore: RootStore) => {
  const initSelect = (model?: PackageDetailModel): SelectOption => ({
    unit:
      !model || model.hasOptions
        ? /* 패키지 정보가 fetch 되지 않았거나, 옵션이 있는 경우 */
          {
            id: -1,
            name: '',
            price: 0,
          }
        : /* 패키지 옵션이 없는 경우 */
          {
            id: model.id,
            name: model.title,
            price: model.price,
          },
    isOption: false,
    quantity: 1,
  });

  const store = observable(
    {
      rootStore: rootStore,
      isInit: false,
      filter: {
        page: 1,
      } as PackagesFilter,
      packageDetail: {} as PackageDetailModel,
      select: initSelect(),
      err: {
        option: false,
      },

      fetch(id: number) {
        flow(function* (this: any) {
          try {
            this.isInit = false;
            const { data } = yield PackageRepository.fetch(id);

            this.packageDetail = new PackageDetailModel(data.package);
            this.select = initSelect(this.packageDetail);

            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },

      get totalPrice() {
        const { select } = this;
        return select.quantity * select.unit.price;
      },

      get UIoptions() {
        return this.packageDetail.options.map((item: Option, idx: number) => ({
          id: item.id,
          label: item.title,
          value: idx,
          unit_price: item.unit_price,
        }));
      },

      get cartJS(): ICartJS {
        const { id, square_image_url, title, inventory_count } = this.packageDetail;

        return {
          id,
          title,
          square_image_url,
          select: toJS(this.select),
          max: inventory_count,
        };
      },

      onChangeOption(opt: { value: string }) {
        const option = this.UIoptions[opt.value];

        this.err.option = false;
        this.select.unit = {
          id: option.id,
          name: option.label,
          price: option.unit_price,
        };
        this.select.isOption = this.packageDetail.id !== option.id;
        this.select.quantity = 1;
      },

      async onClickCart(modalOption: ModalFunctionParams) {
        const { modal, cart, layout } = this.rootStore;

        if (this.select.unit.id === -1) {
          this.err.option = true;
        } else {
          try {
            const order_item = await cart.insert(this.cartJS);
            modal.openModal(MODAL_CLASSIFIER.ADD_PACKAGE, {
              order_item,
              dismissible: true,
              ...modalOption,
            });
          } catch (e) {
            const errors = (e as AxiosError).response?.data.errors;
            if (errors) {
              layout.alert(errors);
            }
          }
        }
      },

      onChangeQuantity(value: number) {
        this.select.quantity = value;
      },
    },
    {
      fetch: action,
      onClickCart: action,
      onChangeOption: action,
      onChangeQuantity: action,
    },
  );
  return store;
};

export type PackageDetailTStore = ReturnType<typeof createStore>;

const storeContext = React.createContext<PackageDetailTStore | null>(null);

export const PackageDetailProvider = ({ children }: any) => {
  const rootStore = useStores();
  const store = useLocalStore(() => createStore(rootStore));

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};
export const usePackageDetailStore = useStore;

export const useInitPacakgeDetail = () => {
  const { id } = useRouteMatch<DetailMatchParams>().params;
  const store = useStore();

  useEffect(() => {
    store.fetch(+id);
  }, [id, store]);
};
