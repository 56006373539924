import React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import Dropdown from 'react-dropdown';

import { Option } from 'stores/models/PacakgeDetailModel';
import { usePackageDetailStore } from 'pages/PackageDetail/store';
import { DropDownCloseIcon, DropDownOpenIcon, RelativeDiv } from 'components/atoms';

export const OptionsBoxContainer: React.FC<any> = observer(() => {
  const { select, err, UIoptions, onChangeOption } = usePackageDetailStore();

  return UIoptions.length ? (
    <WrapOption>
      <OptionDropdown
        className={cx({ invalid: err.option })}
        options={UIoptions}
        onChange={onChangeOption}
        value={UIoptions.find((a: Option) => a.id === select.unit.id)}
        placeholder={'옵션을 선택해주세요.'}
        arrowOpen={<DropDownOpenIcon $css={DropDownIcon} />}
        arrowClosed={<DropDownCloseIcon $css={DropDownIcon} />}
      />
    </WrapOption>
  ) : null;
});

const WrapOption = styled(RelativeDiv)`
  margin: 10px 0 0;
`;

const OptionDropdown = styled(Dropdown)`
  width: 100%;

  .Dropdown-control {
    box-sizing: content-box;
    padding: 7px 43px 7px 10px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;
    line-height: 1.5;
    font-size: 12px;
  }

  .Dropdown-menu {
    position: relative;
    max-height: 96px;
    box-shadow: none;

    .Dropdown-option {
      padding: 7px 10px;
      /* border-bottom: 1px solid #d5d5d5; */
      line-height: 1.5;
      font-size: 12px;

      /* &:last-child {
        border-bottom: 0;
      } */
    }
  }

  &.is-open {
    .Dropdown-control {
      border-bottom: 0;
      border-radius: 3px 3px 0 0;
    }

    .Dropdown-menu {
      border-radius: 0 0 3px 3px;
    }
  }

  &.invalid {
    .Dropdown-control,
    .Dropdown-placeholder {
      border-color: ${({ theme }) => theme.mainActive};
      color: ${({ theme }) => theme.mainActive};
    }

    .Dropdown-menu {
      border-color: ${({ theme }) => theme.mainActive};
    }
  }
`;

const DropDownIcon = css`
  position: absolute;
  top: 13px;
  right: 10px;
  width: 11px;
  height: 6px;
`;
