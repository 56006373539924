import React from 'react';
import styled, { css } from 'styled-components';
import {
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemState,
  AccordionItemPanel,
  AccordionItem,
} from 'react-accessible-accordion';

import { DownButton, UpButton } from 'components/atoms';

const FAQAccordion: React.FC<any> = Faq => {
  return (
    <Item>
      <Heading>
        <AccordionItemButton>
          {Faq.question}
          <AccordionItemState>
            {({ expanded }) => (expanded ? <CloseArrowButton /> : <OpenArrowButton />)}
          </AccordionItemState>
        </AccordionItemButton>
      </Heading>
      <ItemPanel>{Faq.answer}</ItemPanel>
    </Item>
  );
};

export default FAQAccordion;

const Item = styled(AccordionItem)`
  border-bottom: solid 2px #eaeaea;

  ${({ theme }) => theme.tablet`
    border-bottom: solid 1px #eaeaea;
    font-size: 14px;
  `}
`;

const Heading = styled(AccordionItemHeading)`
  position: relative;
  padding: 16px 24px;
  cursor: pointer;

  > div {
    padding-right: 30px;
    outline: none;
  }

  ${({ theme }) => theme.tablet`
    margin: 0 20px;
    padding: 14px 0;
  `}
`;

const ItemPanel = styled(AccordionItemPanel)`
  padding: 0 24px 16px;

  ul {
    margin-left: 24px;

    ${({ theme }) => theme.tablet`
      margin-left: 14px;
    `}

    li + li {
      margin-top: 8px;

      ${({ theme }) => theme.tablet`
        margin-top: 4px;
      `}
    }
  }
`;

const ArrowButtonCSS = css`
  position: absolute;
  right: 24px;
  color: #d5d5d5;

  ${({ theme }) => theme.tablet`
    right: 0;
  `}
`;

const CloseArrowButton = styled(UpButton)`
  ${ArrowButtonCSS}
  top: 21px;

  ${({ theme }) => theme.tablet`
    top: 20px;
  `}
`;
const OpenArrowButton = styled(DownButton)`
  ${ArrowButtonCSS}
  top: 21px;

  ${({ theme }) => theme.tablet`
    top: 18px;
  `}
`;
