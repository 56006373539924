import axios from 'axios';
import { API_URL } from 'configs/path';

class PointRepository {
  fetch(): Promise<any> {
    return axios.get(API_URL.POINT);
  }
}

export default new PointRepository();
