import React from 'react';
import styled from 'styled-components';

import ListFrame from 'components/atoms/ListFrame';
import PlaysItem from '../Share/PlaysItem';

interface Props<T> {
  store: Array<T>;
}
type Plays = Play.RandomPlay | Play.RelatedPlay;

const PlaysContainer: React.FC<Props<Plays>> = ({ store }) => {
  return (
    <ContainerWrap>
      <ListFrame ListWrap={WrapList} ListItem={PlaysItem} store={store} />
    </ContainerWrap>
  );
};

export default PlaysContainer;

const ContainerWrap = styled.section`
  margin-top: 16px;

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
  `}
`;

export const WrapList = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${({ theme }) => theme.desktop`
    & > * {
      width: calc(25% - 22.5px);
      margin-left: 30px;
    }
    
    & > *:nth-child(n + 5) {
      margin-top: 30px;
    }

    & > *:nth-child(4n + 1) {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.tablet`
    & > * {
      width: calc(25% - 15px);
      margin-left: 20px;
    }

    & > *:nth-child(n + 5) {
      margin-top: 10px;
    }

    & > *:nth-child(4n + 1) {
      margin-left: 0;
    }
  `}

  ${({ theme }) => theme.mobile`
    & > * {
      width: calc(50% - 5px);
      margin-left: 10px;
    }
    
    & > *:nth-child(n + 3) {
      margin-top: 10px;
    }

    & > *:nth-child(2n + 1) {
      margin-left: 0;
    }
  `}
`;
