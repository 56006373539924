import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { DefaultLayout } from 'layouts/Default';

import { BestProvider, useBestStore, useInitPacakges } from './store';

import { ListMoreButton } from 'components/atoms';
import { WrapList, Title, WrapListItem } from 'components/styled/List';
import BestBreadCrumb from 'components/molecules/Best/BreadCrumb';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import PackagesContainer from 'components/organisms/PackagesContainer';
import { useStores } from 'stores';
import { DISPLAY } from 'interfaces/layout';
import styled from 'styled-components';
import { ATag } from 'components/atoms';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';

const BestContainer: React.FC<any> = observer(() => {
  const { layout } = useStores();
  const { packages, hasNext, fetchNext, selectFilter } = useBestStore();
  const [selected, setSelected] = useState('by_transaction');
  const title = 'BEST';

  useInitPacakges();

  const BestFilter = (
    <FilterWrap>
      <Filter>
        <Button
          className={selected === 'by_transaction' ? 'selected' : ''}
          onClick={() => {
            setSelected('by_transaction');
            selectFilter('by_transaction');
          }}>
          판매량순
        </Button>
        <Button
          className={selected === 'by_popularity' ? 'selected' : ''}
          onClick={() => {
            setSelected('by_popularity');
            selectFilter('by_popularity');
          }}>
          인기도순
        </Button>
        <Button
          className={selected === 'by_instagram' ? 'selected' : ''}
          onClick={() => {
            setSelected('by_instagram');
            selectFilter('by_instagram');
          }}>
          리뷰순
        </Button>
        <Button
          className={selected === 'best' ? 'selected' : ''}
          onClick={() => {
            setSelected('best');
            selectFilter('best');
          }}>
          판매자추천순
        </Button>
      </Filter>
    </FilterWrap>
  );

  return (
    <WrapList>
      <BestBreadCrumb />
      <Title>{title}</Title>
      {useDesktopShow(BestFilter)}
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>{title}</SubHeaderTitle>
      </SubHeader>
      <WrapListItem>
        {useTabletShow(BestFilter)}
        <PackagesContainer store={packages} withNumber={layout.displayType !== DISPLAY.MOBILE} />
        {hasNext ? <ListMoreButton onClick={fetchNext}>더보기</ListMoreButton> : null}
      </WrapListItem>
    </WrapList>
  );
});

const FilterWrap = styled.div`
  position: relative;
`;

const Filter = styled.div`
  display: flex;
  position: absolute;
  right: -20px;
  bottom: -20px;
  ${({ theme }) => theme.tablet`
    justify-content: center;
    position: initial;
  `}
`;

const Button = styled(ATag)`
  display: block;
  margin: 40px 0;
  padding: 0 20px;
  border-right: 1px solid #b3b3b3;
  cursor: pointer;
  &:last-child {
    border: 0;
  }
  &.selected {
    font-weight: bold;
  }
  ${({ theme }) => theme.tablet`
    margin: 20px 0;
    font-size: 12px;
  `}
  ${({ theme }) => theme.mobile`
    padding: 0 14px;
  `}
`;

export default DefaultLayout(BestContainer, { Provider: BestProvider });
