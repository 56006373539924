import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';

import { useStores } from 'stores';
import { WrapInput, Label, Input } from '../styled';
import { InputErr } from 'pages/Mypage/Info/styled';
import { PhoneCertify } from './PhoneCertify';

export const NonMemberForm: React.FC<any> = observer(() => {
  const { order, auth } = useStores();
  const { register, errors, setValue } = useFormContext();

  useEffect(() => {
    const _order = order.savedOrder?.order;
    const _certify_methods = order.savedOrder?._certify_methods;

    setValue('order.orderer_name', _order?.orderer_name ?? '');
    setValue('order.orderer_phone_number', _order?.orderer_phone_number ?? '');
    setValue('order.orderer_email', _order?.orderer_email ?? '');
    setValue('_certify_methods', _certify_methods ?? 'sms');

    // 휴대폰 인증 초기화
    return () => auth.resetUser();
  }, [auth, order.savedOrder, setValue]);

  return (
    <>
      <WrapInput>
        <Label>이름</Label>
        <Input
          name='order.orderer_name'
          type='text'
          ref={register({
            required: '이름을 입력해 주세요',
            minLength: {
              value: 2,
              message: '이름을 입력해 주세요 (최소 2글자 이상)',
            },
          })}
          placeholder='이름을 입력해 주세요'
        />
      </WrapInput>
      {errors.order?.orderer_name && <InputErr>{errors.order.orderer_name.message}</InputErr>}
      <PhoneCertify InputKey='order.orderer_phone_number' />
      <WrapInput>
        <Label>이메일 주소</Label>
        <Input
          name='order.orderer_email'
          type='text'
          ref={register({ required: '이메일 주소를 입력해 주세요' })}
          placeholder='이메일 주소를 입력해 주세요'
        />
      </WrapInput>
      {errors.order?.orderer_email && <InputErr>{errors.order.orderer_email.message}</InputErr>}
    </>
  );
});
