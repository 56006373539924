import React, { MouseEvent, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import { useStores } from 'stores';

import { ListMoreButton, Wrapper } from 'components/atoms';
import { PackagesTitle } from './styled';
import ListFrame from 'components/atoms/ListFrame';
import SlideItem from 'components/molecules/Home/SlideItem';

const ThemaSlideContainer: React.FC = observer(() => {
  const {
    layout: { isDesktop },
    home,
  } = useStores();
  const [MoreFlag, setMoreFlag] = useState(false);

  const handleMoreClick = (ev: MouseEvent): void => {
    ev.preventDefault();
    setMoreFlag(true);
  };

  const FirstLine = 5;
  const SwiperSetting = {
    spaceBetween: 10,
    slidesPerView: 'auto' as 'auto',
    centeredSlides: true,
    centeredSlidesBounds: true,
    scrollbar: { draggable: true },
  };

  return (
    <HomeSlideWrap>
      {isDesktop ? (
        <>
          <PackagesTitle>테마별 추천상품</PackagesTitle>
          {home.recommendeds.map(({ title, packages }, index) =>
            MoreFlag || (!MoreFlag && index < FirstLine) ? (
              <ContainerWrap key={`__homeRecommendedItem_${index}`}>
                <SubTitle>{title}</SubTitle>
                <ListFrame ListWrap={ListWrap} ListItem={SlideItem} store={packages} />
              </ContainerWrap>
            ) : null,
          )}
          {!MoreFlag ? <ListMoreButton onClick={handleMoreClick}>더보기</ListMoreButton> : null}
        </>
      ) : (
        <Swiper {...SwiperSetting}>
          {home.recommendeds.map(({ title, packages }, index) => (
            <SwiperSlide style={{ width: 'auto' }} key={`__homeRecommendedItem_${index}`}>
              <ContainerWrap>
                <SubTitle>{title}</SubTitle>
                <ListFrame ListItem={SlideItem} store={packages} />
              </ContainerWrap>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </HomeSlideWrap>
  );
});

export default ThemaSlideContainer;

const HomeSlideWrap = styled(Wrapper)`
  padding-bottom: 80px;
  text-align: center;

  ${({ theme }) => theme.tablet`
    padding-bottom: 0;
    background-color: #ececec;

    .swiper-container {
      padding: 10px;
    }
  `}
`;

const ContainerWrap = styled.div`
  text-align: left;

  ${({ theme }) => theme.desktop`
    margin-bottom: 64px;


    &:last-of-type {
      margin-bottom: 30px;
    }
  `}

  ${({ theme }) => theme.tablet`
    padding: 12px;
    width: 280px;
    border-radius: 3px;
    background-color: #fff;
  `}
`;

const ListWrap = styled.div`
  ${({ theme }) => theme.desktop`
    display: flex;
    flex-wrap: wrap;

    & > * {
      width: calc(20% - 22.5px);
      margin-left: 28px;
    }

    & > *:nth-child(n - 6) {
      margin-top: 0;
    }

    & > *:nth-child(n + 6) {
      margin-top: 72px;
    }

    & > *:nth-child(5n + 1) {
      margin-left: 0;
    }
  `}
`;

const SubTitle = styled.h2`
  line-height: 1.5;
  font-weight: normal;
  color: #262626;

  ${({ theme }) => theme.desktop`
    margin: 40px 0 32px;
    text-align: center;
    font-size: 24px;
  `}

  ${({ theme }) => theme.tablet`
    margin-bottom: 12px;
    line-height: 1.43;
    font-size: 14px;
  `}
`;
