import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStores } from 'stores';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import cx from 'classnames';
import qs from 'query-string';

import { emailReg, passwdReg } from 'utils';
import { Input, CheckButton, SubmitButton, InputErr } from 'components/atoms/Form';
import { AuthForm } from 'components/atoms';
import { PAGE_URL } from 'configs/path';
import { useCallback } from 'react';

interface IFormInput {
  email: string;
  password: string;
  keep: boolean;
}

const SignInBox: React.FC<any> = ({ history }: any) => {
  const { auth } = useStores();
  const { register, errors, handleSubmit, setValue } = useForm<IFormInput>();
  const [errorMsg, seterrorMsg] = useState('');
  const location = useLocation();
  const parsed = qs.parse(location.search);

  const onSubmit = async (data: any) => {
    try {
      await auth.signIn(data);
      if (parsed?.redirectUrl) {
        history.push(parsed?.redirectUrl);
      } else {
        history.push(PAGE_URL.HOME);
      }
    } catch (error) {
      seterrorMsg(error);
    }
  };
  const validateInput = useCallback(
    ({ target }) => setValue('email', target.value.trim()),
    [setValue],
  );

  return (
    <AuthForm onSubmit={handleSubmit(onSubmit)}>
      <Input
        type='text'
        name='email'
        placeholder='이메일'
        className={cx({ err: errors.email })}
        autoComplete='chaisplay-si-email'
        ref={register({
          required: "'이메일'을 입력해주세요",
          pattern: {
            value: emailReg,
            message: "'이메일'을 입력해주세요",
          },
        })}
        onBlur={validateInput}
      />
      {errors.email && <InputErr>{errors.email.message}</InputErr>}

      <Input
        type='password'
        name='password'
        placeholder='비밀번호'
        className={cx({ err: errors.password })}
        autoComplete='chaisplay-si-passwd'
        ref={register({
          required: "'비밀번호'를 입력해주세요",
          pattern: {
            value: passwdReg,
            message: "'비밀번호'를 6자리 이상 입력해주세요",
          },
        })}
      />
      {errors.password && <InputErr>{errors.password.message}</InputErr>}

      <KeepSignInWrap>
        <CheckButton
          id='chk_keep'
          name='keep'
          injectRef={register({
            required: false,
          })}>
          로그인 상태 유지
        </CheckButton>
      </KeepSignInWrap>
      <SignInButton>로그인</SignInButton>
      {/* <NonUserButton>비회원 구매하기</NonUserButton> */}
      {errorMsg && <InputErr>{errorMsg}</InputErr>}

      <FindInfo>
        <Guide>
          비밀번호가 기억나지 않으시나요?
          <InfoButton to={PAGE_URL.RESETPASSWD} $color={'#b3b3b3'}>
            비밀번호 재설정
          </InfoButton>
        </Guide>
        <Guide>
          아직 차이의 놀이 회원이 아니신가요?
          <InfoButton to={PAGE_URL.SIGNUP}>회원가입</InfoButton>
        </Guide>
      </FindInfo>
    </AuthForm>
  );
};

const KeepSignInWrap = styled.div`
  margin-top: 12px;
`;

const SignInButton = styled(SubmitButton)`
  margin-top: 12px;

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
  `}
`;

// const NonUserButton = styled(SubmitButton)`
//   margin-top: 8px;
//   border: 1px solid ${({ theme }) => theme.subActiveColor};
//   background-color: transparent;
//   color: ${({ theme }) => theme.subActiveColor};

//   ${({ theme }) => theme.tablet`
//     margin-top: 10px;
//   `}
// `;

const FindInfo = styled.div`
  margin-top: 16px;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
  `}
`;

const Guide = styled.div`
  margin: 8px 0;
  line-height: 1.5;
  font-size: 12px;
  color: ${({ theme }) => theme.TextColor};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.tablet`
    margin: 10px 0;
  `}
`;

const InfoButton = styled(Link)<{ $color?: string }>`
  float: right;
  color: ${({ theme, $color }) => ($color ? $color : theme.TextColor)};
  text-decoration: none;
`;

export default SignInBox;
