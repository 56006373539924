import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { withCommas } from 'utils';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { HistoryTop } from 'components/atoms/Mypage';

const CoinTopBox: React.FC<any> = observer(() => {
  const { auth } = useStores();
  const { Wrapper, Value, Desc } = HistoryTop;

  return (
    <Wrapper>
      <Value $prefix={'내 코인'} $suffix={'코인'}>
        {withCommas(auth.user?.coin || 0)}
      </Value>
      <Desc>
        코인은 프리미엄 육아 유료 콘텐츠 '성장교실' 확인 시 필요해요. {useDesktopShow(<br />)}
        차이의 놀이 앱에서 [성장교실]을 확인해 보세요 :)
      </Desc>
    </Wrapper>
  );
});

export default CoinTopBox;
