import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { useStores } from 'stores';
import { DISPLAY } from 'interfaces/layout';

export const CouponCard: React.FC<{ item: Coupon.Model }> = observer(
  ({ item: { period, title, condition, minimumPriceText, discountText } }) => {
    const { layout } = useStores();

    if (layout.displayType === DISPLAY.DESKTOP) {
      return (
        <Card>
          <Time>사용기간 {period}</Time>
          <Name>{title}</Name>
          <Desc>{condition}</Desc>
          <Hr />
          <Condition>사용가능 최소금액 {minimumPriceText}</Condition>
          <Price>{discountText}</Price>
        </Card>
      );
    }

    return (
      <Card>
        <Name>{title}</Name>
        <Time>사용기간 {period}</Time>
        <Condition>사용가능 최소금액 {minimumPriceText}</Condition>
        <Price>{discountText}</Price>
      </Card>
    );
  },
);

const Card = styled.div`
  position: relative;
  background-color: #fff;

  & > * {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${({ theme }) => theme.desktop`
    width: 465px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;

    & > * {
      margin: 0 24px;
    }
  `};

  ${({ theme }) => theme.tablet`
    margin-top: 10px;
    padding: 14px 12px;

    &:first-child {
      margin-top: 15px;
    }
  `}
`;

const Time = styled.div`
  font-size: 12px;

  ${({ theme }) => theme.desktop`
    margin-top: 24px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
    color: #b3b3b3;
  `}
`;

const Name = styled.div`
  font-size: 24px;

  ${({ theme }) => theme.desktop`
    margin-top: 8px;
  `}

  ${({ theme }) => theme.tablet`
    width: calc(100% - 50px);
    font-size: 13px;
    font-weight: bold;
  `}
`;

const Desc = styled.div`
  margin-top: 4px;
`;

const Hr = styled.hr`
  margin: 32px 0 0;
  border: solid 1px #eaeaea;
`;

const Condition = styled.div`
  font-size: 12px;

  ${({ theme }) => theme.desktop`
    margin-top: 16px;
    font-weight: bold;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
    color: ${theme.subActiveColor};
  `}
`;

const Price = styled.div`
  font-weight: bold;

  ${({ theme }) => theme.desktop`
    margin-bottom: 16px;
    font-size: 32px;
  `}

  ${({ theme }) => theme.tablet`
    position: absolute;
    top: 14px;
    right: 12px;
    font-size: 13px;
    color: ${theme.subActiveColor};
  `}
`;
