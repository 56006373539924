import React from 'react';

import LayoutStore from './Layout';
import HomeStore from './Home';
import AuthStore from './Auth';
import CartStore from './Cart';
import ModalStore from './Modal';
import OrderStore from './Order';

export class RootStore {
  layout: LayoutStore;
  modal: ModalStore;
  home: HomeStore;
  auth: AuthStore;
  cart: CartStore;
  order: OrderStore;
  history: any;

  constructor() {
    this.layout = new LayoutStore();
    this.modal = new ModalStore(this);
    this.home = new HomeStore();
    this.auth = new AuthStore();
    this.cart = new CartStore(this);
    this.order = new OrderStore(this);

    this.setHistory = this.setHistory.bind(this);
  }

  setHistory(history: any) {
    this.history = history;
  }
}

export const storesContext = React.createContext<RootStore>(new RootStore());
export const useStores = () => React.useContext(storesContext);

export default storesContext;
