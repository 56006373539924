import React from 'react';
import { observer } from 'mobx-react';
import { usePlayDetailStore } from 'pages/Plays/Detail/store';
import { PlaysContainer } from 'components/molecules/PlaysDetail';
import { Box, Wrapper, H2 } from './styled';

const RandomPlays = observer(() => {
  const { play } = usePlayDetailStore();

  if (play.random_plays.length === 0) return null;

  return (
    <Box $withPad>
      <Wrapper>
        <H2>이런 놀이는 어때요?</H2>
        <PlaysContainer store={play.random_plays} />
      </Wrapper>
    </Box>
  );
});

export default RandomPlays;
