import React, { useCallback } from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { HiShare } from 'react-icons/hi';
import { useToggle } from 'components/hooks/useToggle';
import { ClearButton } from 'components/atoms';

export const KakaoShareButton: React.FC<any> = React.memo(
  ({ title, description, image, link, withText }) => {
    const onClick = useCallback(
      () =>
        Kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
            title,
            description,
            imageUrl: image,
            link: {
              mobileWebUrl: link || window.location.href,
              webUrl: link || window.location.href,
            },
          },
        }),
      [description, image, link, title],
    );

    return (
      <IconButton onClick={onClick}>
        <img src='/icons/sns/share-kakao.svg' alt='kakao share button' />
        {withText ? '카카오톡' : ''}
      </IconButton>
    );
  },
);

// 미사용
export const KakaoStoryShareButton: React.FC<any> = ({ text, link, withText }) => {
  const onClick = () => {
    Kakao.Story.share({
      url: link || window.location.href,
      text,
    });
  };

  return (
    <IconButton onClick={onClick}>
      <img src='/icons/sns/share-kakao_story.svg' alt='kakao story share button' />
      {withText ? '카카오스토리' : ''}
    </IconButton>
  );
};

export const FacebookShareButton: React.FC<any> = React.memo(({ link, withText }) => {
  const onClick = useCallback(() => {
    FB.ui({
      method: 'share',
      href: link || window.location.href,
    });
  }, [link]);

  return (
    <IconButton onClick={onClick}>
      <img src='/icons/sns/share-facebook.svg' alt='facebook share button' />
      {withText ? '페이스북' : ''}
    </IconButton>
  );
});

const IconButton = styled(ClearButton)`
  margin-right: 5px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;

  ${({ theme }) => theme.tablet`
    & > img {
      margin-right: 10px;
      vertical-align: top;
    }
  `}
`;

export const AddressCopy = () => {
  const { href } = global.location;

  return (
    <>
      <CopyToClipboard text={href}>
        <CopyButton onClick={() => alert('주소가 복사되었습니다!')}>주소 복사</CopyButton>
      </CopyToClipboard>
    </>
  );
};

const CopyButton = styled(ClearButton)`
  display: inline-block;
  vertical-align: top;
  width: 60px;
  background-color: #4696d7;

  line-height: 24px;
  font-size: 12px;
  color: #fff;

  ${({ theme }) => theme.tablet`
    font-size: 10px;
  `}
`;

export const ShareButtonWithPopup: React.FC<any> = React.memo(props => {
  const [isOpen, onClickToggle] = useToggle(false);

  return (
    <>
      <ClearButton className={props.className} onClick={onClickToggle}>
        <HiShare />
      </ClearButton>
      {isOpen ? (
        <ShareLayer>
          <KakaoShareButton withText={true} {...props} />
          {/* <KakaoStoryShareButton withText={true} /> */}
          <FacebookShareButton withText={true} />
          <AddressCopy />
        </ShareLayer>
      ) : null}
    </>
  );
});

export const MobileShareButton = styled(ShareButtonWithPopup)`
  position: absolute;
  top: 10px;
  right: 10px;

  & svg {
    width: 24px;
    height: 24px;
    color: #d5d5d5;
  }
`;

const ShareLayer = styled.div`
  position: absolute;
  z-index: 2;
  top: 40px;
  right: 20px;
  padding: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  & > button {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const WrapShareButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 19px;
`;
