import React from 'react';
import { observer } from 'mobx-react';
import { UseFormMethods } from 'react-hook-form';
import styled from 'styled-components';
import { useStores } from 'stores';
import { RadioButton } from './styled';

const CouponCard: React.FC<{
  item: Coupon.Model;
  getValues: UseFormMethods['getValues'];
  handleChange: Function;
}> = observer(({ item, handleChange, getValues }) => {
  const {
    layout: { isDesktop },
    order: { subtotal_price },
  } = useStores();
  const { id, period, title, condition, minimumPriceText, discountText } = item;

  return (
    <RadioButton>
      <input
        id={`ipt-coupon-${id}`}
        type='radio'
        name='coupon'
        onChange={handleChange(`${id}`, `${item.getDiscountPrice(subtotal_price)}`)}
        defaultChecked={+getValues('_coupon.id') === id}
      />
      <label htmlFor={`ipt-coupon-${id}`}>
        <Card>
          {isDesktop ? (
            <>
              <Time>사용기간 {period}</Time>
              <Name>{title}</Name>
              <Desc>{condition}</Desc>
              <Hr />
            </>
          ) : (
            <>
              <Name>{title}</Name>
              <Time>사용기간 {period}</Time>
            </>
          )}
          <Condition>사용가능 최소금액 {minimumPriceText}</Condition>
          {isDesktop ? <Price>{discountText}</Price> : null}
        </Card>
      </label>
    </RadioButton>
  );
});

export default CouponCard;

const Card = styled.div`
  position: relative;
  width: 100%;

  & > * {
    line-height: 1.5;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${({ theme }) => theme.desktop`
    padding: 16px 0 24px;
    width: 462px;
    border-radius: 3px;
    background-color: #fff;

    & + & {
      margin-top: 24px;
    }
  `};
`;

const Time = styled.div`
  ${({ theme }) => theme.desktop`
    margin: 0 16px 0;
    font-size: 12px;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 5px;
    color: #b3b3b3;
  `}
`;

const Name = styled.div`
  ${({ theme }) => theme.desktop`
    margin: 8px 16px 0;
    font-size: 24px;
  `}

  ${({ theme }) => theme.tablet`
    // width: calc(100% - 60px);
    line-height: 20px;
    font-size: 13px;
    font-weight: bold;
  `}
`;

const Desc = styled.div`
  ${({ theme }) => theme.desktop`
    margin: 4px 16px 0;
  `}
`;

const Hr = styled.hr`
  margin: 16px 0 0;
  border: solid 1px #eaeaea;
`;

const Condition = styled.div`
  ${({ theme }) => theme.desktop`
    margin: 16px 16px 0;
    font-size: 12px;
    font-weight: bold;
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 2px;
    color: ${theme.subActiveColor};
  `}
`;

const Price = styled.div`
  font-weight: bold;

  ${({ theme }) => theme.desktop`
    margin: 4px 16px 0;
    font-size: 24px;
  `}/* ${({ theme }) => theme.tablet`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 13px;
    color: ${theme.subActiveColor};
  `} */
`;
