import React, { ChangeEvent, useEffect } from 'react';
import { action, flow, observable } from 'mobx';
import { useLocalStore } from 'mobx-react';
import SearchRepository from 'stores/repository/SearchRepository';
import SearchKeywordModel from 'stores/models/SearchKeywordModel';

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      model: {} as SearchKeywordModel,
      age: '-1',
      fetch() {
        flow(function* (this: any) {
          try {
            const { data } = yield SearchRepository.fetchKeyword();

            this.model = new SearchKeywordModel(data);
            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
      setAge(ev: ChangeEvent<HTMLInputElement>) {
        this.age = ev.target.value;
      },
      get keywords(): string[] {
        const { age } = this;

        if (this.isInit) {
          return this.model.getKeywords(age);
        } else {
          return [];
        }
      },
    },
    {
      fetch: action,
      setAge: action.bound,
    },
  );

  return store;
};
type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const SearchProvider: React.FC<any> = ({ children }) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useSearchStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitSearch = () => {
  const { fetch } = useSearchStore();

  useEffect(() => {
    fetch();
  }, [fetch]);
};
