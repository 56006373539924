import { useEffect, useCallback } from 'react';
import { toFit } from 'utils';

const useScrollEvt = (Evt: any) => {
  const onScroll = useCallback(() => {
    toFit(Evt, {})();
  }, [Evt]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);
};

export default useScrollEvt;
