import React from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { commaNumber } from 'utils';
import { Clear, ClearLink } from 'components/atoms/Clear';

const EventItem: React.FC<any> = props => {
  const { id, image_url, display_period, view_count, title, comments_count } = props;
  const href = generatePath(PAGE_URL.EVENT, { id });

  return (
    <ItemWrap to={href}>
      <Img src={image_url} alt={title} />

      <ContentWrap>
        <Date>{display_period}</Date>
        <Clear />
        <Title>{title}</Title>
        <Comment>
          댓글 <span className='number'>{comments_count}</span>
        </Comment>
        <View>조회수 {commaNumber(view_count)}</View>
      </ContentWrap>
    </ItemWrap>
  );
};

export default EventItem;

const ItemWrap = styled(ClearLink)`
  display: block;
  overflow: hidden;
  line-height: 1.5;
  text-decoration: none;
`;

const Img = styled.img`
  float: left;
  width: 100%;
`;

const ContentWrap = styled.div`
  float: left;
  width: 100%;
  padding-top: 16px;

  ${({ theme }) => theme.tablet`
    padding: 10px;
    background: #fff;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px 20px;
  `}
`;

const Date = styled.div`
  display: inline-block;
  font-size: 12px;

  ${({ theme }) => theme.tablet`
    float: left;
    font-size: 10px;
  `}
`;

const Title = styled.div`
  margin: 4px 0;
  width: 100%;
  font-weight: bold;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.tablet`
    margin: 5px 0;
    font-size: 12px;
    font-weight: bold;
  `}

  ${({ theme }) => theme.mobile`
    margin: 6px 0;
  `}
`;

const Comment = styled.div`
  display: inline-block;
  font-size: 12px;

  .number {
    font-weight: bold;
    color: ${({ theme }) => theme.mainActive};
  }

  ${({ theme }) => theme.tablet`
    font-size: 10px;
  `}
`;

const View = styled.div`
  display: inline-block;
  margin-left: 16px;
  font-size: 12px;
  color: #b3b3b3;

  ${({ theme }) => theme.tablet`
    float: right;
    font-size: 10px;
  `}
`;
