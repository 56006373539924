import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useFieldArray, useForm } from 'react-hook-form';
import { MypageLayout } from 'layouts/Mypage';
import { useStores } from 'stores';
import { KidForm } from 'stores/models/UserModel';

import { SumbitButton, WrapInfo } from '../styled';
import { ClearButton } from 'components/atoms/Clear';
import { Title } from 'components/atoms/Mypage';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { Plus } from './atoms/Icon';
import { FormRow } from './molecules/FormRow';

const ChildInfo: React.FC<any> = observer(({ pgMinHeight }) => {
  const { auth } = useStores();
  const { register, control, handleSubmit, reset, errors } = useForm<KidForm>({});
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'kids',
  });
  const addKid = useCallback(() => append({}), [append]);
  const removeKid = useCallback(index => () => remove(index), [remove]);
  const onSubmit = async (data: KidForm) => {
    try {
      await auth.updateKid(data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    async function fetch() {
      await auth.fetch();
      const kids = auth.user?.kids2JS || [];

      if (kids.length === 0) append({});
      else reset({ kids });
    }

    fetch();
  }, [append, auth, reset]);

  if (!auth.user) return null;
  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>아이정보 설정</SubHeaderTitle>
      </SubHeader>
      <WrapInfo $pgMinHeight={pgMinHeight}>
        <Title>아이정보 설정</Title>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, index) => (
            <FormRow
              key={field.id}
              index={index}
              register={register}
              fields={fields}
              err={errors}
              remove={removeKid(index)}
            />
          ))}
          <ChildButton type='button' $right onClick={addKid}>
            <Plus /> 아이정보 추가하기
          </ChildButton>
          <SumbitButton type='submit'>저장</SumbitButton>
        </Form>
      </WrapInfo>
    </>
  );
});

export default MypageLayout(ChildInfo, { Option: { full: true } });

export const Form = styled.form`
  width: 405px;
  margin-bottom: 40px;

  ${({ theme }) => theme.tablet`
    margin: 0 auto 20px;
    width: 280px;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

export const ChildButton = styled(ClearButton)<{ $right?: boolean }>`
  margin-top: 16px;
  line-height: 1.5;
  color: #b3b3b3;

  ${({ $right }) => ($right ? 'margin-left: auto;' : null)}

  & + ${SumbitButton} {
    margin-top: 32px;
  }
`;
