import React from 'react';
import { generatePath } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import cx from 'classnames';
import { PAGE_URL } from 'configs/path';
import { generateKey, commaNumber } from 'utils';
import { useStores } from 'stores';

import {
  Tbody,
  Tr,
  Td,
  CheckBox,
  PackageImg,
  WrapCartInfo,
  PackageTitleLink,
  Price,
  SubButton,
  Quantity,
  OptionName,
} from 'pages/Cart/styled';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';
import { BoxSize } from 'components/atoms/QuantityBox';
import { ClearLink } from 'components/atoms';
import CartOrderItemModel from 'stores/models/CartOrderItemModel';

const CartTableBody = observer(() => {
  const { cart } = useStores();

  return (
    <Tbody>
      {cart.items.map((item, index) => (
        <CartRow item={item} index={index} key={generateKey(`cart_tableRow_${item.id}`)} />
      ))}
    </Tbody>
  );
});

export default CartTableBody;

const CartRow: React.FC<{ item: CartOrderItemModel; index: number }> = observer(
  ({ item, index }) => {
    const { cart } = useStores();
    const { package_id, square_image_url, title, hasOption, option, max, disabled } = item;

    return (
      <Tr>
        <Td className='checkbox'>
          <CheckBox id={index} checked={item.checked} onChange={cart.toggleCheck(item)}>
            {useTabletShow(<CheckBoxLabel>선택</CheckBoxLabel>)}
          </CheckBox>
          {useTabletShow(
            <SubButton $color='#b3b3b3' $fontColor='#b3b3b3' onClick={cart.delete(item)}>
              삭제
            </SubButton>,
          )}
        </Td>
        <Td className={cx('info', { disabled })}>
          <ClearLink to={generatePath(PAGE_URL.PACKAGE, { id: package_id })}>
            <PackageImg src={square_image_url} alt='images' />
          </ClearLink>
          <WrapCartInfo>
            <PackageTitleLink to={generatePath(PAGE_URL.PACKAGE, { id: package_id })}>
              {title}
            </PackageTitleLink>
            {hasOption ? <OptionName>{`[옵션] ${option}`}</OptionName> : null}
            {useTabletShow(
              disabled ? <Nostock>재고 없음</Nostock> : <Price>{commaNumber(item.price)}원</Price>,
            )}
            {disabled ? useDesktopShow(<Nostock>재고 없음</Nostock>) : null}
          </WrapCartInfo>
        </Td>
        <Td className='vtop'>
          <Quantity
            value={item.items_count}
            onChange={cart.changeQuantity(item)}
            size={BoxSize.SMALL}
            angled
            max={max}
            maxGuideMessage='보유 중인 재고 수를 초과했습니다. 구매 가능한 수량으로 자동 조정됩니다.'
          />
        </Td>
        {useDesktopShow(<Td className={cx('vtop', { disabled })}>{commaNumber(item.price)}원</Td>)}
      </Tr>
    );
  },
);

const CheckBoxLabel = styled.span`
  display: inline-block;
  margin-top: -2px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.64;
`;

const Nostock = styled.div`
  font-weight: bold !important;
  color: ${({ theme }) => theme.mainActive}!important;

  ${({ theme }) => theme.tablet`
    position: absolute;
    bottom: 10px;
    font-size: 12px;
  `}
`;
