import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from 'stores';
import { DefaultLayout } from 'layouts/Default';

import ReactHelmet from 'components/atoms/Helmet';
import { useDesktopShow } from 'components/hooks/useLayoutShow';

import { SideLinkMenu } from 'components/molecules/Home';
import {
  BannerSlider,
  ThemaSlideContainer,
  NewContainer,
  BestContainer,
} from 'components/organisms/Home';
import AppAdBanner from 'components/molecules/AppAdBanner';

const Home = observer(() => {
  const { layout, home } = useStores();
  const meta = {
    description:
      '새로운 놀이 아이디어가 필요할 때. 놀면서 아이 성장발달을 도와주고 싶을 때. 차이의 놀이 패키지를 만나보세요!',
  };

  useEffect(() => {
    home.fecth();
  }, [home, layout]);

  return (
    <>
      <ReactHelmet data={meta} />
      {useDesktopShow(<SideLinkMenu />)}
      <BannerSlider />
      <ThemaSlideContainer />
      {useDesktopShow(<Hr />)}
      <NewContainer />
      {useDesktopShow(<Hr />)}
      <BestContainer />
      <AppAdBanner />
    </>
  );
});
export default DefaultLayout(Home);

const Hr = styled.hr`
  margin: 0;
  border: 0;
  border-bottom: solid 1px #d5d5d5;
`;
