import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStore } from 'pages/PackageDetail/store';

import PackageDetailModel from 'stores/models/PacakgeDetailModel';
import CouponAndDeliveryInfo from 'components/molecules/PackagesDetail/CouponAndDeliveryInfo';
import PurchaseBox from 'components/molecules/PackagesDetail/PurchaseBox';
import { ClearButton } from 'components/atoms';
import {
  KakaoShareButton,
  FacebookShareButton,
  AddressCopy,
} from 'components/molecules/ShareButton';
import { useDesktopShow, useTabletShow } from 'components/hooks/useLayoutShow';
import NewLine from 'components/atoms/NewLine';
import { CouponProvider } from 'stores/local/Coupon';
import { BasketButton } from 'components/molecules/PackagesDetail/BasketButton';

interface Props {
  detail: PackageDetailModel;
}

const PackageInfo: React.FC<Props> = observer(({ detail }) => {
  const { onClickCart } = useStore();

  return (
    <WrapInfo>
      <Title>{detail.title}</Title>
      <WrapPrice>
        <OriginPrice>{detail.display_original_price}</OriginPrice>
        <Price>{detail.display_sales_price}</Price>
        <SalesRate>{detail.display_discount_rate}</SalesRate>
      </WrapPrice>
      <WrapShareButtons>
        <KakaoShareButton
          title={detail.title}
          description={detail.description}
          image={detail.square_image_url}
        />
        <FacebookShareButton />
        <AddressCopy />
      </WrapShareButtons>
      <CouponProvider>
        <CouponAndDeliveryInfo detail={detail} coupon={detail.coupon} />
      </CouponProvider>
      {useDesktopShow(
        <>
          <PurchaseBox />
          <BasketButton Render={CartButton} onClick={onClickCart} model={detail} />
        </>,
      )}
      {useTabletShow(<NewLine Wrapper={MobileDescription} text={detail.description} />)}
    </WrapInfo>
  );
});

export default PackageInfo;

const WrapInfo = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(100% - 582px);
  padding-left: 30px;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    padding: 15px 20px;
  `}
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: normal;

  ${({ theme }) => theme.tablet`
    font-size: 20px;
  `}
`;

const WrapPrice = styled.div`
  position: relative;
  margin-top: 8px;

  & > div {
    display: inline-block;
    vertical-align: middle;
    line-height: 1.5;
  }
`;

const OriginPrice = styled.div`
  margin-right: 8px;
  color: #b3b3b3;
  text-decoration: line-through;

  ${({ theme }) => theme.tablet`
    font-size: 14px;
  `}
`;
const Price = styled.div`
  margin-right: 8px;
  font-size: 24px;
  font-weight: bold;

  ${({ theme }) => theme.tablet`
    font-size: 18px;
  `}
`;
const SalesRate = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.tablet`
    font-size: 14px;
  `}
`;

const WrapShareButtons = styled.div`
  margin-top: 16px;
  overflow: hidden;

  & > * {
    float: left;
    margin-right: 4px;
  }
`;

const CartButton = styled(ClearButton)`
  display: inline-block;
  margin-top: 20px;
  width: 583px;
  height: 56px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-weight: bold;
  color: #fff;

  &:disabled {
    background-color: ${({ theme }) => theme.tabNonActieColor};
    cursor: not-allowed;
  }
`;

const MobileDescription = styled.div`
  margin-top: 15px;
  line-height: 1.5;
  font-size: 12px;
`;
