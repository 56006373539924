/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { observable, flow, action } from 'mobx';
import { useLocalStore } from 'mobx-react';

import { parseLinkHeader } from 'utils';
import PackageModel, { ResPackage } from 'stores/models/PackageModel';
import BestRepository from 'stores/repository/BestRepository';

interface PackagesFilter {
  page: number;
  per_page: number;
  sort: string;
  best: boolean;
}

interface Pagenation {
  next: number;
  last: number;
}

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      packages: [] as PackageModel[],
      filter: {
        page: 1,
        sort: 'by_transaction',
        best: false,
      } as PackagesFilter,
      pagenation: {} as Pagenation,
      fetchPacakges() {
        flow(function* (this: any) {
          this.isInit = false;
          try {
            const { data, headers } = yield BestRepository.fetch(this.filter);
            this.packages = this.packages.concat(
              data.packages.map((item: ResPackage) => new PackageModel(item)),
            );
            this.pagenation = parseLinkHeader(headers.link);
          } catch (error) {
            throw error;
          }
          this.isInit = true;
        }).bind(this)();
      },
      fetchNext() {
        const { next } = this.pagenation;

        if (next) {
          this.filter.page = next;
          this.fetchPacakges();
        }
      },
      get hasNext() {
        return this.isInit && !!this.pagenation.next;
      },
      selectFilter(sort: string) {
        if (sort === 'best') {
          this.filter.sort = '';
          this.filter.best = true;
        } else {
          this.filter.sort = sort;
          this.filter.best = false;
        }
        this.packages = [];
        this.fetchPacakges();
      },
    },
    {
      fetchPacakges: action,
      fetchNext: action,
    },
  );

  return store;
};

type TStore = ReturnType<typeof createStore>;

const storeContext = React.createContext<TStore | null>(null);

export const BestProvider = ({ children }: any) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useBestStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};

export const useInitPacakges = () => {
  const store = useBestStore();

  useEffect(() => {
    store.fetchPacakges();
  }, [store]);
};
