import styled from 'styled-components';
import { Title, MobileTitle } from './Title';
import { MobileHeader } from './MobileHeader';
import { MobileBackButton } from './MobileBackButton';

const Page = styled.div<{ $minHeight: number }>`
  position: relative;
  width: 100%;
  min-height: ${({ $minHeight }) => $minHeight}px;
  overflow: hidden;

  & > .pg-animation {
    position: static;
  }
`;

export { Page, Title, MobileHeader, MobileTitle, MobileBackButton };
