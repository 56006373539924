import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import cx from 'classnames';

import { PAGE_URL } from 'configs/path';
import { useStores } from 'stores';
import { ClearLink, ClearButton, ATag } from 'components/atoms';
import { useToggle } from 'components/hooks/useToggle';

const SideLinkMenu = observer(() => {
  const { auth } = useStores();
  const history = useHistory();
  const [isOpen, onClickToggle] = useToggle(false);

  useEffect(() => {
    setTimeout(() => onClickToggle(true), 450);
  }, [history, onClickToggle]);

  return (
    <Wrapper className={cx({ isOpen: isOpen })}>
      <ToggleButton onClick={onClickToggle} />
      <WrapLink>
        {auth.isSignIn ? (
          <LinkButton to={PAGE_URL.PURCHASE_LIST}>주문배송조회</LinkButton>
        ) : (
          <LinkButton to={PAGE_URL.NONUSER_LOOKUP}>비회원 주문조회</LinkButton>
        )}

        <LinkButton to={PAGE_URL.COMPANY_PURCHASE}>기관구매 안내</LinkButton>
        <KakaoButton href='https://pf.kakao.com/_xdbBvxd' target='_blank'>
          <KakaoIcon />
          카카오톡 1:1 상담
        </KakaoButton>
      </WrapLink>
    </Wrapper>
  );
});

export default SideLinkMenu;

const Wrapper = styled.div`
  position: fixed;
  z-index: 9;
  top: 50%;
  right: -213px;
  transform: translateY(-50%);
  transition: right 0.25s ease-in-out;

  &.isOpen {
    right: 0;
  }
`;

const WrapLink = styled.div`
  padding: 24px;
  border-radius: 3px 0 0 3px;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 6px 3px 6px 0 rgba(0, 0, 0, 0.25);

  line-height: 1.5;
  font-size: 14px;
  text-align: center;
`;

const ToggleButton = styled(ClearButton)`
  position: absolute;
  left: -25px;
  top: 50%;
  width: 25px;
  height: 40px;
  border-radius: 3px 0 0 3px;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateY(-50%);

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;

    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #fff;
    transform: translate(-25%, -50%) rotate(-135deg);
    transition: rotate 0.25s;
  }

  .isOpen & {
    &:before {
      transform: translate(-75%, -50%) rotate(45deg);
    }
  }
`;

const LinkButton = styled(ClearLink)`
  display: block;
  margin-bottom: 8px;
  padding: 9px 0;
  width: 165px;
  border-radius: 3px;
  background-color: #fff;
`;

const KakaoIcon = styled.div`
  display: block;
  margin: 8px auto;
  width: 55px;
  height: 50px;

  background: url('/icons/kakao.png') no-repeat;
  background-position: center;
`;

const KakaoButton = styled(ATag)`
  display: block;
  padding: 10px 0;
  border-radius: 3px;
  background-color: #ffe100;
  font-weight: bold;
`;
