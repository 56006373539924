import { action, flow, observable, computed, reaction, toJS } from 'mobx';
import axios from 'axios';
import { API_URL } from 'configs/path';
import { setAuthToken, removeAuthToken } from 'configs/axios';
import { authStoreKey } from 'configs/localStorage';
import UserModel, { CertifyStep, KidForm } from './models/UserModel';
import { generatePath } from 'react-router-dom';
import AuthRepository from './repository/AuthRepository';

export default class AuthStore {
  @observable user!: UserModel;

  constructor() {
    this.resetUser();

    const existingStore = localStorage.getItem(authStoreKey);
    if (existingStore) {
      const localUser = JSON.parse(existingStore);

      if (localUser) {
        this.user = new UserModel(localUser);
        this.setAuthToken();
        this.fetch();
      }
    }

    reaction(
      () => toJS(this.user),
      data => {
        if (this.isSignIn) {
          localStorage.setItem(authStoreKey, JSON.stringify(toJS(data)));
        } else {
          localStorage.removeItem(authStoreKey);
        }
      },
    );
  }

  resetUser() {
    this.user = new UserModel({
      fullname: '',
      phone_number: '',
      phone_number_certified: '',
    });
  }

  @computed
  get isSignIn() {
    return !!this.user.id;
  }

  setAuthToken(model = this.user) {
    if (model) {
      const { authentication_token, email } = model;

      setAuthToken(authentication_token, email);
    }
  }

  @action
  fetch = () =>
    flow(function* (this: AuthStore) {
      this.user = yield AuthRepository.fetch();
    }).bind(this)();

  @action
  updateInfo = (body: any) =>
    flow(function* (this: AuthStore) {
      try {
        const { success, message } = yield AuthRepository.updateInfo(body);

        if (success) {
          this.user.nickname = body.nickname;
          this.user.fullname = body.fullname;
          this.user.phone_number = body.phone_number;
          this.user.address1 = body.address1;
          this.user.address2 = body.address2;

          alert(message);
        }
      } catch (error) {
        const { data, status } = error.response;
        if (status === 422) {
          throw data.errors;
        } else {
          throw error;
        }
      }
    }).bind(this)();

  @action
  updateKid = (body: KidForm) =>
    flow(function* () {
      try {
        const { data } = yield axios.put(API_URL.AUTH_KIDS, body);
        if (data.success) {
          alert(data.message);
        }
      } catch (error) {
        const { data, status } = error.response;
        if (status === 422) {
          throw data.errors;
        } else {
          throw error;
        }
      }
    }).bind(this)();

  @action.bound
  sendCode = (phone_number: any, target?: 'sms' | 'kakao') =>
    flow(function* (this: AuthStore) {
      if (this.user) {
        const { success, message } = yield AuthRepository.sendCode(
          phone_number,
          target === 'kakao',
        );
        if (!success) throw new Error(message);
      }
    }).bind(this)();

  @action.bound
  certifyCode = (phone_number: any, phone_sms_code: any) =>
    flow(function* (this: AuthStore) {
      if (this.user) {
        const { success, message } = yield AuthRepository.certifyCode(phone_number, phone_sms_code);
        if (success) {
          this.user.setCertifyStep(CertifyStep.INIT);
          this.user.phone_number = phone_number;
          this.user.phone_number_certified = true;
          alert(message); // TODO: 성공 메세지 처리
        } else {
          throw new Error(message);
        }
      }
    }).bind(this)();

  @action
  signUp = ({ email, password, nickname }: any) =>
    flow(function* (this: AuthStore) {
      try {
        const { data } = yield axios.post(API_URL.AUTH_SIGNUP, {
          user: {
            email: email,
            password: password,
            nickname: nickname,
            signup_source: 'web',
          },
        });

        this.user = new UserModel(data.user);
        this.setAuthToken();
      } catch (error) {
        const { data, status } = error.response;
        if (status === 422) {
          throw data.errors;
        } else {
          throw error;
        }
      }
    }).bind(this)();

  @action
  signIn = ({ email, password, keep }: any) =>
    flow(function* (this: AuthStore) {
      try {
        const { data } = yield axios.post(API_URL.AUTH_SIGNIN, {
          session: {
            email: email,
            password: password,
          },
        });

        this.user = new UserModel(data.user);
        this.setAuthToken();
      } catch (error) {
        const { data, status } = error.response;
        if (status === 422) {
          throw data.errors;
        } else {
          throw error;
        }
      }
    }).bind(this)();

  @action
  deactivate = () =>
    flow(function* (this: AuthStore) {
      try {
        yield axios.delete(generatePath(API_URL.AUTH_DEACTIATE, { id: this.user?.id }));
        this.Logout(() => console.log('logout'));
      } catch (error) {
        throw error;
      }
    }).bind(this)();

  @action
  changePasswd = (body: APIBodyForm.ChangePasswdForm) =>
    flow(function* (this: AuthStore) {
      try {
        const { data } = yield axios.put(API_URL.AUTH_CHANGE_PASSWD, body);
        alert(data.message); // TODO: 성공 메세지 처리
      } catch (error) {
        const { data, status } = error.response;
        if (status === 422) {
          throw data.errors;
        } else {
          throw error;
        }
      }
    }).bind(this)();

  resetPasswd = (body: APIBodyForm.ResetPasswdForm) =>
    flow(function* (this: AuthStore) {
      try {
        const {
          data: { success },
        } = yield axios.post(API_URL.AUTH_RESET_PASSWD, body);
        if (success) {
          alert('비밀번호 재설정 지침 메일 발송 완료했습니다.'); // TODO: 성공 메세지 처리
        } else {
          alert('가입하신 이메일이 정확한지 확인 부탁드립니다.');
        }
      } catch (error) {
        const { status } = error.response;
        if (status === 500) {
          alert('가입하신 이메일이 정확한지 확인 부탁드립니다.');
        }
      }
    }).bind(this)();

  @action.bound
  Logout = (cb: any) => {
    this.resetUser();
    removeAuthToken();
    setTimeout(cb, 100);
  };
}
