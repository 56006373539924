import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { MypageLayout } from 'layouts/Mypage';
import { generateKey } from 'utils';
import { MyCommentsProvider, useInitMyComments, useMyCommentsStore } from 'stores/local/MyComments';

import { Wrapper, Title } from 'components/atoms/Mypage';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import MyCommentCard, { WrapLink } from 'components/molecules/MyCommentCard';

const Container: React.FC<any> = () => (
  <MyCommentsProvider>
    <MyComments />
  </MyCommentsProvider>
);

const MyComments: React.FC<any> = observer(({ pgMinHeight }) => {
  const { isInit, comments } = useMyCommentsStore();
  useInitMyComments();

  if (!isInit) return null;

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>나의 놀이댓글</SubHeaderTitle>
      </SubHeader>
      <Wrapper $pgMinHeight={pgMinHeight}>
        <Title>나의 놀이댓글</Title>
        {comments.comments.length === 0 ? (
          <>
            <Info>놀이법에 댓글을 남겨보세요.</Info>
            <Desc>
              놀이법에 댓글을 등록하면
              <br /> 이 곳에서 한번에 확인 가능합니다.
            </Desc>
          </>
        ) : (
          <CardBox>
            {comments.comments.map(items => (
              <MyCommentCard key={generateKey} {...items} />
            ))}
          </CardBox>
        )}
      </Wrapper>
    </>
  );
});

export default MypageLayout(Container, { Option: { full: true } });

const Info = styled.div`
  margin-top: 16px;

  ${({ theme }) => theme.tablet`
    margin-top: 0;
    font-size: 16px;
    text-align: center;
  `}

  ${({ theme }) => theme.mobile`
    text-align: left;
  `}
`;

const Desc = styled.div`
  margin-top: 8px;
  font-size: 12px;

  br {
    display: none;
  }

  ${({ theme }) => theme.tablet`
    margin-top: 12px;
    text-align: center;
  `}

  ${({ theme }) => theme.mobile`
    margin-top: 10px;
    text-align: left;

    br {
      display: block;
    }
  `}
`;

const CardBox = styled.div`
  margin-top: 16px;

  ${({ theme }) => theme.tablet`
    margin-top: 0;
  `}

  & > ${WrapLink} {
    display: inline-block;
    vertical-align: top;

    ${({ theme }) => theme.desktop`
      width: calc(50% - 15px);

      &:nth-child(2n + 1) {
        margin-right: 30px;
      }

      &:nth-child(n + 3) {
        margin-top: 30px;
      }
    `}

    ${({ theme }) => theme.tablet`
      width: calc(50% - 10px);

      &:nth-child(2n + 1) {
        margin-right: 20px;
      }

      &:nth-child(n + 3) {
        margin-top: 20px;
      }
    `}

    ${({ theme }) => theme.mobile`
      width: 100%;

      &:nth-child(n + 2) {
        margin-top: 12px;
      }
    `}
  }
`;
