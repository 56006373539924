import React from 'react';
import styled from 'styled-components';
import { generateKey } from 'utils';
import { Banner } from 'stores/models/PacakgeDetailModel';
import { ATag } from 'components/atoms';

interface Props {
  banners: Banner[];
}

const PackageBanners: React.FC<Props> = ({ banners }) => (
  <WrapBanners>
    {banners.map(({ url, image_url }) => (
      <Banners href={url} key={generateKey()}>
        <img src={image_url} alt='banner images' />
      </Banners>
    ))}
  </WrapBanners>
);
const WrapBanners = styled.div`
  margin-top: 80px;

  ${({ theme }) => theme.tablet`
    margin-top:0;
  `}
`;

const Banners = styled(ATag)`
  display: inline-block;
  & img {
    width: 100%;
  }

  ${({ theme }) => theme.desktop`    
    margin-right: 7px;
    width: 393px;
    height: 123px;

    &:nth-child(3) {
      margin-right: 0;
    }

    &:nth-child(n + 4) {
      display: none;
    }
  `}

  ${({ theme }) => theme.tablet`
    width: 100%;
    &:nth-child(n + 1) {
      margin-top: -2px;
    }
  `}
`;

export default PackageBanners;
