import React, { useEffect, Fragment, useRef, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useStores } from 'stores';
import { DISPLAY, ILayoutOptions } from 'interfaces/layout';
import { Page, Wrapper } from 'components/atoms';
import { ConditionalWrapper } from 'components/atoms/ConditionalWrapper';
import MypageSideLinks from 'components/organisms/MypageSideLinks';
import ModalLayer from './ModalLayer';

export const MypageLayout = (
  ChildComponent: React.ComponentType<any>,
  params = {} as ILayoutOptions,
) =>
  function WrapperFn(props: RouteComponentProps) {
    const { layout } = useStores();
    const { Provider, Modal, Option } = params;

    const PageRef = useRef<HTMLDivElement>(null);
    const [minHeight, setminHeight] = useState(window.innerHeight);
    const onEndTransition = useCallback(() => {
      setTimeout(() => {
        if (Option?.full) {
          setminHeight(window.innerHeight - layout.headerHeight - layout.footerHeight - 1);
        } else {
          const height = PageRef.current?.clientHeight || window.innerHeight;
          if (height < window.innerHeight) setminHeight(0);
        }
      }, 200);
    }, [Option, layout.footerHeight, layout.headerHeight]);

    useEffect(() => {
      layout.CloseSideBar();
      layout.CloseDimmed();
    }, [props, layout]);

    useEffect(() => {
      onEndTransition();
    }, [layout.displayType, onEndTransition]);

    return useObserver(() => {
      return (
        <Page $minHeight={minHeight}>
          <CSSTransition
            in={layout.isInit}
            appear={true}
            timeout={200}
            classNames='pg-animation page-fade'
            unmountOnExit
            addEndListener={onEndTransition}>
            <div ref={PageRef}>
              <ConditionalWrapper
                condition={Provider}
                wrapper={(children: React.ReactNode) => <Provider>{children}</Provider>}>
                <Fragment>
                  <Wrapper>
                    {layout.displayType === DISPLAY.DESKTOP ? <MypageSideLinks /> : null}
                    <WrapPage>
                      <ChildComponent
                        setBodyMinHeight={setminHeight}
                        pgMinHeight={minHeight}
                        {...props}
                      />
                    </WrapPage>
                  </Wrapper>
                </Fragment>
              </ConditionalWrapper>
            </div>
          </CSSTransition>
          <ModalLayer store={Modal} />
        </Page>
      );
    });
  };

const WrapPage = styled.div`
  display: inline-block;
  vertical-align: top;

  ${({ theme }) => theme.desktop`
    width: calc(1194px - 199px);
  `}

  ${({ theme }) => theme.tablet`
    width: 100%;
  `}
`;
