import React, { useEffect } from 'react';
import { PAGE_URL } from 'configs/path';
import { Redirect, generatePath } from 'react-router-dom';
import { DefaultLayout } from 'layouts/Default';
import styled from 'styled-components';
import cx from 'classnames';

import { WrapBox } from 'components/atoms';
import useTabs from 'components/hooks/useTabs';
import { TabButton, TabWrap } from 'components/atoms/Tab';

import Privacy from './Privacy';
import Service from './Service';

const matchType2Tab: any = {
  service: 0,
  privacy: 1,
};
const matchTab2Type = Object.keys(matchType2Tab);

const PolicyContainer = React.memo((props: any) => {
  const { type } = props.match.params;

  if (matchType2Tab[type] === undefined) {
    return <Redirect to={PAGE_URL.POLICY_SERVICE} />;
  } else {
    return <Policy {...props} initTab={matchType2Tab[type]} />;
  }
});

const Policy = (props: any) => {
  const { curIndex, curTab, setTab } = useTabs(props.initTab, Tabs);
  const handleTabClick = (index: number) => (ev: MouseEvent) => {
    ev.preventDefault();

    if (curIndex !== index) {
      setTab(index);
      const path = generatePath(props.match.path, { type: matchTab2Type[index] });
      props.history.replace(path);
    }
  };

  useEffect(() => {
    if (props.initTab !== curIndex) setTab(props.initTab);
  }, [curIndex, props.initTab, setTab]);

  return (
    <WrapPolicy>
      <PolicyBox>
        <WrapPolicyTab>
          {Tabs.map(({ buttonText }, index) => (
            <TabButton
              key={`__SignInTab_${index}`}
              className={cx({ active: curIndex === index })}
              onClick={handleTabClick(index)}>
              {buttonText}
            </TabButton>
          ))}
        </WrapPolicyTab>
        <curTab.content {...props} />
      </PolicyBox>
    </WrapPolicy>
  );
};

const Tabs = [
  {
    buttonText: '서비스 이용약관',
    content: () => <Service />,
  },
  {
    buttonText: '개인정보처리방침',
    content: () => <Privacy />,
  },
];

const WrapPolicy = styled(WrapBox)`
  ${({ theme }) => theme.desktop`
    padding-bottom: 100px;
  `}
`;

const PolicyBox = styled.div`
  margin: 0 auto;
  background-color: #fff;

  ${({ theme }) => theme.desktop`
    padding: 16px 40px 32px;
    width: 990px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 10px 0 50px;
    width: 728px;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px 20px 30px;
    width: 100%;
  `}
`;

const WrapPolicyTab = styled(TabWrap)`
  margin: 0 auto 28px;
  width: 320px;

  ${({ theme }) => theme.tablet`
    width: 280px;
  `}
`;

export default DefaultLayout(PolicyContainer);
