import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { PAGE_URL } from 'configs/path';
import { useStores } from 'stores';
import {
  TopAppBanner,
  TopBar,
  SideMenuButton,
  SearchButton,
  CartButton,
  CategoryBar,
} from 'components/molecules/Header';
import SearchBar from 'components/molecules/SearchBar';
import { useToggle } from 'components/hooks/useToggle';
import { useMobileShow } from 'components/hooks/useLayoutShow';

const Header: React.FC = () => {
  const history = useHistory();
  const { layout } = useStores();
  const [toggleSearch, onClickToggle] = useToggle(false);

  useEffect(() => {
    return history.listen(() => onClickToggle(false));
  }, [history, onClickToggle]);

  return (
    <PageHeader ref={layout.headerRef}>
      {useMobileShow(<TopAppBanner />)}
      <TopBar />
      <WrapLogo>
        <SideMenuButton onClick={layout.OpenSideBar} />
        <SearchButton isOpen={toggleSearch} handleClick={onClickToggle} />
        <CartButton />
        <LinkButton to={PAGE_URL.HOME}>
          <Logo />
        </LinkButton>
      </WrapLogo>
      <SearchBar isOpen={toggleSearch} handleClick={onClickToggle} />
      <CategoryBar />
    </PageHeader>
  );
};
export default Header;

const PageHeader = styled.header`
  position: relative;
  z-index: 10;
  background: #fff;
`;

const WrapLogo = styled.div`
  padding: 40px 0;
  border-bottom: 1px solid #eaeaea;

  ${({ theme }) => theme.tablet`
    padding: 16px 0;
    border-bottom: 0;
  `}
`;
const LinkButton = styled(Link)`
  display: block;
  margin: auto;
  width: 234px;
  height: 45px;

  ${({ theme }) => theme.tablet`
    width: 104px;
    height: 20px;
  `}
`;
const Logo = styled.img.attrs({ src: '/logo.svg' })`
  width: 100%;
  height: 100%;
`;
