import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { commaNumber } from 'utils';
import { Table, Tr, Th, Td, MobileTableWrapper, Price, SubInfo, Em } from './styled';
import styled from 'styled-components';
import { BenefitPopup } from 'components/molecules/Popup';

export default observer(function PaymentsMobile() {
  const {
    order: {
      orderPrice,
      shipping_price,
      extra_shipping_price,
      used_point,
      coupon_price,
      subtotal_price,
      display_status,
      displayPayMethod,
      merchant_uid,
      earning_point,
      status,
    },
  } = useStores();

  return (
    // <Section>
    <MobileTableWrapper>
      <Table>
        <tbody>
          <Tr>
            <Th>총 상품 금액</Th>
            <Td>
              <b>{commaNumber(subtotal_price)}원</b>
            </Td>
          </Tr>
          <Tr>
            <Th>배송비</Th>
            <Td>
              <b> +{commaNumber(shipping_price)}원</b>
            </Td>
          </Tr>
          {extra_shipping_price > 0 && (
            <Tr>
              <Th>추가 배송비</Th>
              <Td>
                <b> +{commaNumber(extra_shipping_price)}원</b>
              </Td>
            </Tr>
          )}
          <Tr>
            <Th>사용 적립금</Th>
            <Td>
              <b>- {commaNumber(used_point)}원</b>
            </Td>
          </Tr>
          <Tr>
            <Th>할인 쿠폰</Th>
            <Td>
              <b>- {commaNumber(coupon_price)}원</b>
            </Td>
          </Tr>
          <Tr>
            <Th></Th>
            <Td>
              {status !== 'completed' && earning_point ? (
                <>
                  <SubInfo>
                    <ExclamationMareIcon />
                    <Em>{commaNumber(earning_point)}</Em> 포인트 적립 예정
                  </SubInfo>
                  {/* <SubInfo>택배 수령확인 후 포인트가 적립됩니다.</SubInfo> */}
                </>
              ) : status === 'completed' ? (
                <>
                  <SubInfo>
                    <Em>{commaNumber(earning_point || 0)}</Em> 포인트 적립 <BenefitPopup />
                  </SubInfo>
                </>
              ) : null}
            </Td>
          </Tr>
        </tbody>
      </Table>
      <br />
      <Table>
        <tbody>
          <Tr>
            <Th>결제 금액</Th>
            <Td>
              <Price $bold $active>
                {commaNumber(orderPrice)}원
              </Price>
              <SubInfo $active>{display_status}</SubInfo>
            </Td>
          </Tr>
          <Tr>
            <Th>결제 방법</Th>
            <Td>{displayPayMethod}</Td>
          </Tr>
          <Tr>
            <Th>주문 번호</Th>
            <Td>{merchant_uid}</Td>
          </Tr>
        </tbody>
      </Table>
    </MobileTableWrapper>
    // </Section>
  );
});

const ExclamationMareIcon = styled.i`
  display: inline-block;
  vertical-align: sub;
  margin-right: 5px;
  width: 18px;
  height: 18px;
  background: url('/icons/exclamation-mark.svg') no-repeat;
  background-size: 100%;
`;
