import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { DefaultLayout } from 'layouts/Default';
import { useStores } from 'stores';
import { SearchPackagesProvider, useInitSearchPackages, useSearchPackagesStore } from './store';
import { DISPLAY } from 'interfaces/layout';

import {
  EmptyImage,
  SearchInfo,
  WrapEmptyList,
  WrapList,
  WrapListItem,
} from 'components/styled/List';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import PackagesContainer from 'components/organisms/PackagesContainer';
import Pagenation from 'components/molecules/Pagenation';

const Page: React.FC = observer(() => {
  const { layout } = useStores();
  const { keyword, totalPackages, isInit, packages, filter, pagenation, setFilter } =
    useSearchPackagesStore();
  const onClickPage = useCallback((page: number) => () => setFilter({ page }), [setFilter]);

  useInitSearchPackages();

  if (!isInit) {
    return null;
  } else if (totalPackages) {
    return (
      <WrapList>
        <SubHeader>
          <MobileBackButton />
          <SubHeaderTitle>&nbsp;</SubHeaderTitle>
        </SubHeader>
        <WrapListItem>
          <SearchInfo>
            <b>'{keyword}'</b>으로 검색된 <b>{totalPackages}개</b>의 검색결과가 있습니다.
          </SearchInfo>
          <PackagesContainer store={packages} />
        </WrapListItem>
        <Pagenation
          curPage={filter.page}
          pageInfo={pagenation}
          pageUnit={layout.displayType === DISPLAY.DESKTOP ? 10 : 5}
          onClickPage={onClickPage}
        />
      </WrapList>
    );
  } else {
    return (
      <WrapEmptyList>
        <SubHeader>
          <MobileBackButton />
          <SubHeaderTitle>&nbsp;</SubHeaderTitle>
        </SubHeader>
        <WrapListItem>
          {layout.displayType === DISPLAY.DESKTOP ? <EmptyImage /> : null}
          <strong>검색 결과가 없습니다.</strong>
          <div>다른 검색어를 입력해보세요.</div>
          {layout.displayType !== DISPLAY.DESKTOP ? <EmptyImage /> : null}
        </WrapListItem>
      </WrapEmptyList>
    );
  }
});

export default DefaultLayout(Page, {
  Provider: SearchPackagesProvider,
  Option: {
    full: true,
  },
});
