import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ClearButton } from 'components/atoms/Clear';

export const MobileBackButton: React.FC<{ url?: string; className?: string }> = React.memo(
  ({ url, className }) => {
    const { goBack, replace } = useHistory();
    const onClickBack = useCallback(
      (e: React.MouseEvent) => {
        e.preventDefault();
        if (url) replace(url);
        else goBack();
      },
      [goBack, replace, url],
    );

    return <BackButtonIcon className={className} onClick={onClickBack} />;
  },
);

const BackButtonIcon = styled(ClearButton)`
  position: absolute;
  top: 10px;
  left: 20px;
  width: 24px;
  height: 24px;
  background: url('/icons/back.svg') no-repeat;
  background-size: 100%;

  ${({ theme }) => theme.mobile`
    left: 10px;
  `}
`;
