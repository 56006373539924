import styled from 'styled-components';

export const WrapPlaysItem = styled.div`
  ${({ theme }) => theme.desktop`
    margin-top: 30px;

    & > * {
      width: calc(25% - 22.5px);
      margin-left: 30px;
    }

    & > *:nth-child(4n + 1) {
      margin-left: 0;
    }

    & > *:nth-child(n - 5) {
      margin-top: 0;
    }

    & > *:nth-child(n + 5) {
      margin-top: 30px;
    }
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
    padding: 0 20px;

    & > * {
      width: calc(25% - 8px);
      margin-left: 10px;
    }

    & > *:nth-child(4n + 1) {
      margin-left: 0;
    }

    & > *:nth-child(n - 5) {
      margin-top: 0;
    }

    & > *:nth-child(n + 5) {
      margin-top: 10px;
    }
  `}

  ${({ theme }) => theme.mobile`
    & > * {
      width: calc(50% - 5px);
      margin-left: 10px;
    }

    & > *:nth-child(2n + 1) {
      margin-left: 0;
    }

    & > *:nth-child(n - 3) {
      margin-top: 0;
    }

    & > *:nth-child(n + 3) {
      margin-top: 10px;
    } 
  `}
`;
