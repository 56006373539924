import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { action, flow, observable } from 'mobx';
import { useLocalStore } from 'mobx-react';
import CoinRepository from './repository/CoinRepository';
import CoinModel from './model/CoinModel';

const createStore = () => {
  const store = observable(
    {
      isInit: false,
      coin: {} as CoinModel,
      fetch() {
        flow(function* (this: any) {
          try {
            const { data } = yield CoinRepository.fetch();

            this.coin = new CoinModel(data);
            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
    },
    {
      fetch: action,
    },
  );

  return store;
};
type TStore = ReturnType<typeof createStore>;
const storeContext = React.createContext<TStore | null>(null);

export const CoinProvider: React.FC<any> = ({ children }) => {
  const store = useLocalStore(() => createStore());

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useCoinStore = () => {
  const store = React.useContext(storeContext);

  if (!store) throw new Error('useStore must be used within a StoreProvider.');

  return store;
};

export const useInitCoinHistory = () => {
  const history = useHistory();
  const { fetch } = useCoinStore();

  useEffect(() => {
    fetch();
  }, [fetch, history]);
};
