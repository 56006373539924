import React from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useStores } from 'stores';
import { useCouponStore } from 'stores/local/Coupon';
import { PAGE_URL } from 'configs/path';
import * as FormAtoms from 'pages/Mypage/Info/styled';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { CouponBody } from 'stores/repository/CouponRepository';
import { MODAL_CLASSIFIER } from 'pages/Mypage/Coupon/Download/constants';

const CouponForm: React.FC<any> = observer(() => {
  const { modal } = useStores();
  const store = useCouponStore();
  const { register, handleSubmit } = useForm<CouponBody>();
  const onSubmit = async (body: CouponBody) => {
    try {
      const { data } = await store.download(body);
      modal.openModal(MODAL_CLASSIFIER.RESULT_MESSAGE, {
        ...data,
        redirectUrl: PAGE_URL.COUPON_LIST,
      });
    } catch ({ message }) {
      modal.openModal(MODAL_CLASSIFIER.RESULT_MESSAGE, {
        message,
        redirectUrl: PAGE_URL.COUPON_LIST,
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <WrapInput $withBtn>
        {useDesktopShow(<Label>쿠폰 등록하기</Label>)}
        <Input
          name='coupon_code'
          type='text'
          ref={register()}
          placeholder='쿠폰 코드를 입력해주세요.'
        />
        <InputButton type='submit'>등록</InputButton>
      </WrapInput>
    </Form>
  );
});
export default CouponForm;

const Form = styled.form`
  margin: 20px;
  ${({ theme }) => theme.desktop`
    margin-top: 20px;
    padding: 16px 24px;
    background-color: #f8f8f8;
  `};
`;

const WrapInput = styled(FormAtoms.WrapInput)`
  margin-top: 0;

  ${({ theme }) => theme.tablet`
    margin-top: 0;
  `};
`;

const Label = styled(FormAtoms.Label)`
  ${({ theme }) => theme.desktop`
    width: 108px;
  `};
`;

const Input = styled(FormAtoms.Input)`
  ${({ theme }) => theme.tablet`
    background-color: transparent;    
  `};
`;

const InputButton = styled(FormAtoms.InputButton)`
  background: ${({ theme }) => theme.subActiveColor};
  font-weight: bold;
  color: #fff;

  ${({ theme }) => theme.tablet`
    top: 0;
    width: 54px;
    height: 30px;
  `};
`;
