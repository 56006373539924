import styled from 'styled-components';
import * as atoms from 'components/atoms';
import * as tabhook from 'components/hooks/useTabs';
import { WrapList } from 'components/organisms/PackagesContainer';

export const Wrapper = styled(atoms.Wrapper)<{ $first?: boolean; $last?: boolean }>`
  position: relative;

  ${({ theme, $first, $last }) => theme.desktop`
    ${$first ? 'margin-top: 30px;' : null}
    ${$last ? 'margin-bottom: 100px;' : null}
  `}

  ${({ theme, $last }) => theme.tablet`
    ${$last ? 'margin-bottom: 40px;' : null}
  `}

  ${({ theme, $last }) => theme.mobile`
    ${$last ? 'margin-bottom: 30px;' : null}
  `}
`;

export const WrapInfo = styled.div`
  display: flex;
  margin-top: 20px;

  ${({ theme }) => theme.tablet`
    flex-direction: column;
    margin-top: 0;
  `}
`;

export const TabContent = styled(tabhook.TabContent)<{ $active?: boolean }>`
  ${({ theme }) => theme.desktop`
    width: 814px;
  `}
`;

export const RelatedPackages = styled.div`
  ${({ theme }) => theme.desktop`
    width: 1194px;
  `}

  h2 {
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 2px solid #272727;
    line-height: 1.5;
    font-size: 24px;
    font-weight: bold;

    ${({ theme }) => theme.tablet`
      margin-bottom: 10px;
      padding-bottom: 10px;
      font-size: 16px;
    `}
  }

  ${WrapList} {
    ${({ theme }) => theme.desktop`
      & > *:nth-child(n + 5) {
        margin-top: 30px;
      }
    `}
  }
`;
