import React from 'react';
import styled from 'styled-components';

import { Title } from 'components/atoms/PurchasePage';
import OrderStep, { ORDER_STEP } from 'components/molecules/OrderStep';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { useStores } from 'stores';

export default function Header() {
  const {
    order: { status },
  } = useStores();

  if (status === 'completed') {
    return (
      <>
        <Title>주문완료</Title>
        <OrderStep step={ORDER_STEP.COMPL} />
        <SubHeader>
          <MobileBackButton />
          <SubHeaderTitle>주문완료</SubHeaderTitle>
        </SubHeader>
        <TopMessage>주문이 완료되었습니다.</TopMessage>
      </>
    );
  } else {
    return <></>;
  }
}

const TopMessage = styled.div`
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.TextColor};
  ${({ theme }) => theme.tablet`
    background-color: #f8f8f8;
    padding: 0px 20px;
    font-size: 12px;
    height: 38px;
    line-height: 38px;
  `}
`;
