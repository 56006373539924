/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch, match } from 'react-router-dom';
import { observable, flow, action } from 'mobx';
import qs from 'query-string';

import { parseLinkHeader } from 'utils';
import PackageModel, { ResPackage } from 'stores/models/PackageModel';
import { packagesSort } from 'configs/filterType';
import PackagesRepository from './repository/PackagesRepository';
import { PackagesMatchParams } from 'configs/path';
import { Location } from 'history';
import { useLocalStore } from 'mobx-react';

export interface PackagesFilter {
  age: string;
  sort: string;
  page: number;
  category: string;
  per_page: number;
}
interface storeProps {
  match: match<PackagesMatchParams>;
  history: any;
  location: Location<unknown>;
}

const createStore = ({ match, history, location }: storeProps) => {
  const store = observable(
    {
      isInit: false,
      match,
      history,
      location,

      totalPackages: 0,
      packages: [] as PackageModel[],
      filter: {
        per_page: 20,
      } as PackagesFilter,
      pagenation: {},

      fetchFilter(search: string) {
        const { main, sub } = this.match.params;
        const { amin, amax, sort, page } = qs.parse(search);

        this.filter.age = amin && amax ? `amin=${amin}&amax=${amax}` : '';
        this.filter.sort = sort ? `${sort}` : packagesSort[0].value;
        this.filter.page = parseInt(page as string) || 1;
        this.filter.category = sub ? sub : main;

        this.fetchPacakges();
      },
      fetchPacakges() {
        const { age, ...other } = this.filter;
        const query = qs.stringify({ ...other, ...qs.parse(this.filter.age) });

        flow(function* (this: any) {
          try {
            this.isInit = false;
            const { data, headers } = yield PackagesRepository.fetch(query);
            this.packages = data.packages.map((item: ResPackage) => new PackageModel(item));
            this.totalPackages = data.count;

            this.pagenation = parseLinkHeader(headers.link);
            this.isInit = true;
          } catch (error) {
            throw error;
          }
        }).bind(this)();
      },
      setFilter(query: any) {
        const newQuery = { ...qs.parse(this.location.search), ...query };

        // 소팅, 연령 변경시 페이지 초기화
        if (!query.page) newQuery.page = 1;

        // 값 없는 쿼리 데이터 제거
        for (const [key, value] of Object.entries(newQuery)) {
          if (!value) delete newQuery[key];
        }

        this.history.push({
          pathname: this.location.pathname,
          search: qs.stringify(newQuery),
        });
      },
      setProps({ match, history, location }: storeProps) {
        this.match = match;
        this.history = history;
        this.location = location;
      },
    },
    {
      fetchFilter: action,
      fetchPacakges: action,
      setProps: action,
    },
  );

  return store;
};

type TStore = ReturnType<typeof createStore>;

const storeContext = React.createContext<TStore | null>(null);

export const PackagesProvider = ({ children }: any) => {
  const match = useRouteMatch<PackagesMatchParams>();
  const history = useHistory();
  const location = useLocation();
  const store = useLocalStore(() => createStore({ match, history, location }));

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const usePackagesStore = () => {
  const store = React.useContext(storeContext);
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};

export const useInitPacakges = () => {
  const match = useRouteMatch<PackagesMatchParams>();
  const history = useHistory();
  const location = useLocation();
  const store = usePackagesStore();

  useEffect(() => {
    store.setProps({ match, history, location });
    store.fetchFilter(location.search);
  }, [history, location, match, store]);
};
