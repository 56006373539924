import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MypageLayout } from 'layouts/Mypage';
import { CoinProvider, useInitCoinHistory } from './store';

import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { Title, WrapperHistory } from 'components/atoms/Mypage';
import { CoinTopBox, CoinHistory } from 'components/organisms/Mypage/CoinHistory';
import { useStores } from 'stores';

const PageCoinHistory: React.FC<any> = observer(({ pgMinHeight }) => {
  useInitCoinHistory();
  const { auth } = useStores();

  useEffect(() => {
    async function fetch() {
      await auth.fetch();
    }

    fetch();
  }, [auth]);

  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>코인 충전/이용 내역</SubHeaderTitle>
      </SubHeader>
      <WrapperHistory $pgMinHeight={pgMinHeight}>
        <Title>코인 충전/이용 내역</Title>
        <CoinTopBox />
        <CoinHistory />
      </WrapperHistory>
    </>
  );
});

export default MypageLayout(PageCoinHistory, { Provider: CoinProvider, Option: { full: true } });
