import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DISPLAY } from '../../interfaces/layout';
import { PAGE_URL } from 'configs/path';

interface Props {
  type: DISPLAY;
}

export const BottomBar: React.FC<Props> = ({ type }: Props) => {
  return (
    <BottomBarWrap>
      <FirstLinkWrap>
        <BarA href={'https://www.chaisplay.com/about'} target='_blank'>
          차이의 놀이 소개
        </BarA>
      </FirstLinkWrap>
      <LinkWrap>
        <BarA href='https://pf.kakao.com/_xdbBvxd' target='_blank'>
          카톡 문의하기
        </BarA>
      </LinkWrap>

      {type === DISPLAY.DESKTOP ? (
        <>
          <LastRightLinkWrap>
            <BarRightLink to={PAGE_URL.POLICY_PRIVACY}>개인정보처리방침</BarRightLink>
          </LastRightLinkWrap>
          <FirstRightLinkWrap>
            <BarRightLink to={PAGE_URL.POLICY_SERVICE}>서비스 이용약관</BarRightLink>
          </FirstRightLinkWrap>
        </>
      ) : null}
    </BottomBarWrap>
  );
};

const withPipe = styled.li`
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 1px;
    height: 70%;
    border-left: 1px solid #242424;
  }
`;

const BottomBarWrap = styled.ul`
  overflow: hidden;

  ${({ theme }) => theme.tablet`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 15px;
  `}
`;
const LinkWrap = styled(withPipe)`
  position: relative;
  display: inline-block;
  margin: 16px 0;
  padding: 0 32px;
  line-height: 1.5;

  ${({ theme }) => theme.tablet`
    margin: 5px;
    padding: 0;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 135px;
    border-radius: 3px;
    border: solid 1px #d5d5d5;

    &:after {
      content: none;
    }
  `}

  ${({ theme }) => theme.mobile`
    flex-basis: calc(50% - 10px);
  `}
`;
const FirstLinkWrap = styled(LinkWrap)`
  padding-left: 0 !important;

  &:after {
    content: none;
  }
`;

export const RightLinkWrap = styled(withPipe)`
  position: relative;
  float: right;
  display: block;
  margin: 19px 0;
  padding: 0 13px;

  ${({ theme }) => theme.tablet`
    margin: 13px 0;
    padding: 0 10px;
    line-height: 1;
  `}
`;
export const FirstRightLinkWrap = styled(RightLinkWrap)`
  &:after {
    content: none;
  }
`;
export const LastRightLinkWrap = styled(RightLinkWrap)`
  padding-right: 0 !important;
`;

const BarButtonCss = css`
  font-size: 16px;
  color: #262626;
  text-decoration: none;

  ${({ theme }) => theme.tablet`
    display: inline-block;
    padding: 8px 0;
    width: 100%;
    line-height: 18px;
    font-size: 12px;
    text-align: center;
  `}
`;
const BarA = styled.a`
  ${BarButtonCss}
`;
const BarLink = styled(Link)`
  ${BarButtonCss}
`;

export const BarRightLink = styled(BarLink)`
  font-size: 12px;

  ${({ theme }) => theme.tablet`
    font-size: 10px;
    line-height: 14px;
  `}
`;
