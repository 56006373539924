import React from 'react';
import styled from 'styled-components';
import { ClearButton } from 'components/atoms/Clear';
import { useHistory } from 'react-router-dom';

export default React.memo(function BackButton() {
  const { goBack } = useHistory();

  return <Button onClick={() => goBack()}>목록으로 돌아가기</Button>;
});

const Button = styled(ClearButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  width: 248px;
  height: 56px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  ${({ theme }) => theme.tablet`
    margin: 0 auto;
    width: 280px;
    height: 44px;
  `}
`;
