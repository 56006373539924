import React from 'react';
import styled from 'styled-components';

export const InfoBubble: React.FC = () => {
  return (
    <Bubble>
      <NoticeIcon />
      활동지 다운로드가 안 되시나요?
      <br />
      크롬 브라우저에서 다운로드 하세요.
    </Bubble>
  );
};

const Bubble = styled.div`
  color: ${({ theme }) => theme.mainActive};

  ${({ theme }) => theme.desktop`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    padding: 16px 16px 16px 46px;
    width: 305px;
    border: solid 1px ${theme.mainActive};
    border-radius: 3px;
    line-height: 1.75;
    text-align: left;
    font-weight: 100;

    &:before {
      content: '';
      position: absolute;
      top: -9px;
      left: 50%;
      width: 16px;
      height: 16px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      background: #fff;
      transform: translateX(-50%) rotate3d(0, 0, 1, -135deg);
    }
  `};

  ${({ theme }) => theme.tablet`
    font-size: 12px;
    line-height: 1.67;
  `};
`;

const NoticeIcon = styled.i`
  background: url('/icons/play/notice.svg') no-repeat;
  background-size: 100% 100%;

  ${({ theme }) => theme.desktop`
    position: absolute;
    top: 20px;
    left: 16px;
    width: 22px;
    height: 22px;
  `};

  ${({ theme }) => theme.tablet`
    display: block;
    margin: 12px auto 4px;
    width: 16px;
    height: 16px;
  `}
`;
