import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';
import { useStores } from 'stores';
import { DISPLAY } from 'interfaces/layout';
import { ClearButton, Wrapper } from 'components/atoms';

interface Props {
  isOpen: boolean;
  handleClick: any;
}

const SearchButton: React.FC<Props> = observer(({ isOpen, handleClick }) => {
  const { layout } = useStores();

  if (layout.displayType === DISPLAY.DESKTOP) {
    return isOpen ? null : (
      <WrapSearchBox>
        <SearchBox onClick={handleClick}>
          <SearchPcIcon />
        </SearchBox>
      </WrapSearchBox>
    );
  } else {
    return (
      <ClearButton onClick={handleClick}>
        <SearchMobileIcon />
      </ClearButton>
    );
  }
});
export default SearchButton;

const SearchMobileIcon = styled(AiOutlineSearch)`
  position: absolute;
  right: 56px;
  margin-top: -2px;
  width: 24px;
  height: 24px;
  color: #d5d5d5;
`;

const WrapSearchBox = styled(Wrapper)`
  position: relative;
`;

const SearchBox = styled(ClearButton)`
  position: absolute;
  top: 3px;
  right: 0;
  width: 175px;
  height: 40px;
  border-radius: 50px;
  border: solid 1px #d5d5d5;
  text-align: right;
`;

const SearchPcIcon = styled.i`
  display: inline-block;
  margin-right: 16px;
  width: 24px;
  height: 24px;
  background-image: url('/icons/header/search.svg');
`;
