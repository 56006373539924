import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Page = styled.div<{ $minHeight: number }>`
  position: relative;
  width: 100%;
  min-height: ${({ $minHeight }) => $minHeight}px;

  & > .pg-animation {
    position: static;
  }
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme.desktop`
    margin-left: auto;
    margin-right: auto;
    width: 1194px;
  `}

  ${({ theme }) => theme.tablet`
    width: 100%;
  `}
`;

export const ATag = styled.a`
  color: ${({ theme }) => theme.TextColor};
  text-decoration: none;
`;
export const ClearLink = styled(Link)`
  color: ${({ theme }) => theme.TextColor};
  text-decoration: none;
`;

export const SmallLinkButton = styled(ClearLink)`
  display: inline-block;
  margin-left: 8px;
  padding: 4px 0;
  width: 72px;
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.subActiveColor};
  background-color: #fff;

  text-align: center;
  line-height: 1.5;
  font-size: 12px;
  color: ${({ theme }) => theme.subActiveColor};

  &:first-child {
    margin-left: 0;
  }
`;

export const ClearButton = styled.button`
  display: block;
  padding: 0;
  border: 0;
  background: transparent;
  outline: none;
  white-space: nowrap;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
`;

export const LinkButton = styled(Link)`
  display: block;
  margin: 0 auto;
  padding: 10px 0;
  width: 305px;
  height: 44px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};

  line-height: 1.5;
  font-size: 16px;
  text-decoration: none;
  color: #fff;

  ${({ theme }) => theme.tablet`
    width: 280px;
  `}
`;

interface BgImgBox {
  $height?: number;
  $heightPercent?: number;
}
export const BgImgBox = styled.div<BgImgBox>`
  position: relative;
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  padding-bottom: ${({ $heightPercent }) => $heightPercent || 0}%;
`;
interface BgImg {
  $src: string;
  $height?: number;
  $heightPercent?: number;
  $size?: string;
}
export const BgImg = styled.div<BgImg>`
  position: absolute;

  width: 100%;
  height: ${({ $height }) => ($height ? `${$height}px` : 'auto')};
  padding-bottom: ${({ $heightPercent }) => $heightPercent || 0}%;

  background-image: url(${({ $src }) => $src});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${({ $size }) => $size || 'contain'};
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
`;

export const AButton = styled.a`
  display: block;
  text-decoration: none;
  text-align: center;
`;

const ListMoreCSS = css`
  display: block;
  margin: 30px auto 0;
  padding: 14px 0;
  width: 582px;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  background-color: #fff;
  line-height: 1.5;
  font-size: 16px;
  text-align: center;

  ${({ theme }) => theme.tablet`
    margin-top: 20px;
    padding: 8px 0;
    width: calc(50% - 10px);
    font-size: 12px;
  `}

  ${({ theme }) => theme.mobile`
    margin: 10px 0 0;
    padding: 8px 0;
    width: 100%;
  `}
`;
export const ListMoreButton = styled(ClearButton)`
  ${ListMoreCSS}
`;
export const ListMoreLink = styled(ClearLink)`
  ${ListMoreCSS}
`;

interface WrapBoxProps {
  $background?: string;
}
export const WrapBox = styled.div<WrapBoxProps>`
  ${({ theme }) => theme.desktop`
    padding: 40px 0 260px;
    background-color: ${({ $background }: WrapBoxProps) => ($background ? $background : '#f2f2f2')};
  `}

  ${({ theme }) => theme.tablet`
    min-height: 613px;
  `}

  ${({ theme }) => theme.mobile`
    min-height: auto;
    padding: 0;
  `}
`;
export const AuthBox = styled.div`
  margin: 0 auto;
  padding: 24px 138px 32px;
  background-color: #fff;

  ${({ theme }) => theme.desktop`
    width: 582px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 10px 0;
    width: 280px;
    min-height: 390px;
  `}

  ${({ theme }) => theme.mobile`
    padding: 10px 20px 30px;
    width: 100%;
    min-height: 0;
  `}
`;
export const AuthTitle = styled.h1`
  padding: 8px 0;
  border-bottom: 2px solid ${({ theme }) => theme.TextColor};

  line-height: 1.5;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.TextColor};

  ${({ theme }) => theme.tablet`
    padding: 10px 0;
    font-size: 14px;
  `}
`;
export const AuthForm = styled.form`
  margin-top: 20px;

  ${({ theme }) => theme.tablet`
    margin-top: 15px;
  `}
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const PackageDetailDl = styled.dl`
  margin: 24px 0 0;
  padding: 24px 0 0;
  border-top: 2px solid #e9e9e9;
  line-height: 1.5;
  font-size: 16px;

  & > div {
    display: flex;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }

    > dt {
      display: flex;
      width: 102px;
      align-items: center;

      &.top {
        align-items: start;
      }
    }

    > dd {
      margin-left: 0%;
    }
  }

  ${({ theme }) => theme.tablet`
    margin: 15px 0;
    padding: 15px 0;
    border-bottom: 2px solid #e9e9e9;
    font-size: 12px;
  `}
`;

interface DropDownIconProps {
  $css: any;
}
export const DropDownOpenIcon = styled.div<DropDownIconProps>`
  ${({ $css }) => $css}
  background: url('/icons/select-up.svg') no-repeat;
  background-size: 100%;
`;
export const DropDownCloseIcon = styled.div<DropDownIconProps>`
  ${({ $css }) => $css}
  background: url('/icons/select-down.svg') no-repeat;
  background-size: 100%;
`;

export const PackageDeatilContentsBox = styled.div`
  line-height: 1.5;

  ${({ theme }) => theme.desktop`
    margin-top: 24px;
  `}

  ${({ theme }) => theme.tablet`
    font-size: 12px;
  `};
`;

export const Iframe = css`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
`;

export const VideoContainer = styled.div`
  position: relative;
  margin: 32px 0;
  padding-bottom: 56.25%;
  width: 100%;
  height: 0;
  overflow: hidden;

  & iframe {
    ${Iframe}
  }
`;

export const LeftRightButton = styled(ClearButton)`
  position: absolute;
  top: 0;
  top: 20px;
  width: 14px;
  height: 14px;

  transform: rotate3d(0, 0, 1, 45deg) skew(5deg, 5deg);

  ${({ theme }) => theme.tablet`
    width: 10px;
    height: 10px;
  `}
`;

export const UpDownButton = styled(ClearButton)`
  position: absolute;
  top: 0;
  top: 20px;
  width: 14px;
  height: 14px;

  transform: rotate3d(0, 0, 1, -45deg) skew(5deg, 5deg);

  ${({ theme }) => theme.tablet`
    width: 10px;
    height: 10px;
  `}
`;

export const UpButton = styled(UpDownButton)`
  border-right: 3px solid;
  border-top: 3px solid;

  ${({ theme }) => theme.tablet`
    border-right: 2px solid;
    border-top: 2px solid;
  `}
`;
export const DownButton = styled(UpDownButton)`
  border-left: 3px solid;
  border-bottom: 3px solid;

  ${({ theme }) => theme.tablet`
    border-left: 2px solid;
    border-bottom: 2px solid;
  `}
`;

export const DesktopBr = styled.br`
  ${({ theme }) => theme.tablet`
    display:none;
  `}
`;

export const ShowDiv = styled.div<{ $show?: boolean }>`
  ${props => (props.$show ? '' : 'display:none')};
`;

export const BreadCrumbLink = styled(ClearLink)`
  display: inline-block;
  line-height: 1.5;
  font-size: 12px;
  color: #b3b3b3;
`;

const ArrowLeftRight = css`
  position: absolute;
  width: 14px;
  height: 14px;

  transform: rotate3d(0, 0, 1, 45deg) skew(5deg, 5deg);
`;

export const ArrowRight = css`
  ${ArrowLeftRight}
  border-right: 2px solid;
  border-top: 2px solid;
`;
