import axios from 'axios';
import { generatePath } from 'react-router-dom';
import { API_URL } from 'configs/path';
import { COMMENTES_TYPE, COMMENTABLE_TYPE } from 'interfaces/Commetes';

class CommentsRepository {
  fetchType(type: COMMENTES_TYPE, id: number): Promise<Commenet.RootObject> {
    return axios.get(generatePath(API_URL.COMMENTES, { type, id }));
  }

  fetchMine(): Promise<Commenet.RootObject> {
    return axios.get(API_URL.MY_COMMENTS);
  }

  post(params: any): Promise<{ comment: Commenet.Commenet }> {
    return axios.post(API_URL.COMMENTES_POST, params);
  }

  delete(id: number): Promise<{ success: boolean }> {
    return axios.delete(generatePath(API_URL.COMMENTES_DELETE, { id }));
  }

  more(lastCommentId: number, type: COMMENTABLE_TYPE, id: number): Promise<Commenet.RootObject> {
    return axios.get(
      generatePath(
        `${API_URL.COMMENTES_MORE}?comment%5Bcommentable_type%5D=${type}&comment%5Bcommentable_id%5D=${id}`,
        { lastCommentId },
      ),
    );
  }
}

export default new CommentsRepository();
