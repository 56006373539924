import { ClearButton } from 'components/atoms';
import styled, { css } from 'styled-components';
export interface ButtonProps {
  $fill?: boolean;
}

export const ModalButtonCss = css<ButtonProps>`
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 0;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.subActiveColor};
  background-color: ${({ $fill, theme }) => ($fill ? theme.subActiveColor : '#fff')};
  line-height: 1.5;
  color: ${({ $fill, theme }) => ($fill ? '#fff' : theme.subActiveColor)};
  text-align: center;
`;

interface WrapModalProps {
  $txtCenter?: boolean;
  $show: boolean;
  $zIndex: number;
  $isSignIn?: boolean;
}
export const WrapModal = styled.div<WrapModalProps>`
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  z-index: ${({ $zIndex }) => $zIndex};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: ${({ $isSignIn }) => ($isSignIn ? '75px 20px 40px' : '20px 17px')};
  min-width: 280px;
  border-radius: 5px;
  background-color: #fff;

  line-height: 1.5;
  ${({ $txtCenter }) => ($txtCenter ? 'text-align: center' : '')};

  ${({ theme }) => theme.tablet`
    font-size: 12px;
  `}
`;

export const WrapButtons = styled.div`
  margin-top: 16px;

  ${({ theme }) => theme.tablet`
    font-size: 10px;
  `}
`;

export const ModalButton = styled(ClearButton)<ButtonProps>`
  display: inline-block;
  width: 235px;
  height: 44px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.subActiveColor};
  background-color: ${({ $fill, theme }) => ($fill ? theme.subActiveColor : '#fff')};

  line-height: 1.5;
  font-weight: bold;
  color: ${({ $fill, theme }) => ($fill ? '#fff' : theme.subActiveColor)};

  & + & {
    margin-left: 8px;
  }

  ${({ theme }) => theme.tablet`
    width: 110px;
    height: 35px;
    font-size: 12px;

    & + & {
      margin-left: 6px;
    }
  `}
`;

export const PointBar = styled.div`
  position: relative;
  margin: 0 auto 8px;
  width: 100%;
  height: 34px;
  background-color: #f8f8f8;
`;

export const WrapBar = styled.div`
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
`;

export const PointInfo = styled.div`
  margin-bottom: 32px;

  strong {
    font-weight: bold;
    color: ${({ theme }) => theme.mainActive};
  }
`;

interface Point {
  $ratio: number;
}
const PointTxt = css<Point>`
  position: absolute;
  line-height: 34px;
`;

export const BottomTxt = styled.div<Point>`
  ${PointTxt}
  left: 10px;
  color: ${({ $ratio }) => ($ratio * 100 > 12 ? '#fff' : '#b3b3b3')};
`;

export const UpperTxt = styled.div<Point>`
  ${PointTxt}
  right: 10px;
  color: ${({ $ratio }) => ($ratio * 100 >= 95 ? '#fff' : '#b3b3b3')};
`;

export const PointBubble = styled.div<Point>`
  position: absolute;
  top: -43px;
  left: calc(${({ $ratio }) => $ratio * 100}% - 34px);
  padding: 7px 0;
  width: 67px;
  border-radius: 3px;
  border: solid 1px;
  background-color: #fff;

  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.mainActive};
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 10px;
    height: 10px;
    border-bottom: 1px solid;
    border-right: 1px solid;
    background: #fff;
    color: ${({ theme }) => theme.mainActive};
    transform: translateX(-50%) rotate3d(0, 0, 1, 45deg) skew(10deg, 10deg);
  }
`;

export const Progress = styled.div<Point>`
  width: ${({ $ratio }) => $ratio * 100}%;
  height: 34px;
  border-radius: 3px 0 0 3px;
  background-color: ${({ theme }) => theme.mainActive};
`;
