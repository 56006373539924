import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { generateKey } from 'utils';

interface IGenderRadio {
  ref: React.RefObject<HTMLInputElement>;
  text?: string;
  name: string;
  value: string;
  defaultChecked?: boolean;
}
export const GenderRadio = forwardRef<HTMLInputElement, IGenderRadio>(
  ({ text = '', name, value, defaultChecked }, ref) => {
    const id = generateKey();

    return (
      <Wrap>
        <Input
          id={id}
          ref={ref}
          type='radio'
          name={name}
          value={value}
          defaultChecked={defaultChecked}
        />
        <Label htmlFor={id}>{text}</Label>
      </Wrap>
    );
  },
);

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block;
  margin-left: -1px;
  padding: 8px;
  width: 152px;
  border-radius: 0 3px 3px 0;
  border: solid 1px #d5d5d5;
  background-color: #f8f8f8;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #b3b3b3;
  cursor: pointer;

  ${Input}:checked + & {
    border-color: ${({ theme }) => theme.subActiveColor};
    background-color: #fff;
    color: ${({ theme }) => theme.subActiveColor};
  }

  ${({ theme }) => theme.tablet`
    width: 140px;
    font-size: 14px;
  `}

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

const Wrap = styled.div`
  display: inline-block;

  &:first-of-type {
    ${Label} {
      margin-left: 0;
      border-radius: 3px 0 0 3px;
    }

    ${Input}:checked + ${Label} {
      position: relative;
      z-index: 1;
    }
  }

  ${({ theme }) => theme.mobile`
    width: 50%;
  `}
`;
