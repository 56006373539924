import React from 'react';
import styled from 'styled-components';

import { ATag, RelativeDiv } from 'components/atoms';

interface Props {
  childRef?: React.RefObject<HTMLDivElement>;
}

const KakaotalkBanners: React.FC<Props> = ({ childRef }) => {
  return (
    <Banners ref={childRef}>
      <Title>카카오톡 상담 가능합니다.</Title>
      <Info>
        <b>해빛</b> &nbsp;|&nbsp; <span>평일 AM 9:30 ~ PM 6:30</span>
      </Info>
      <QuestionButton href='https://pf.kakao.com/_xdbBvxd' target='_blank'>
        문의하기
      </QuestionButton>
    </Banners>
  );
};

const Banners = styled(RelativeDiv)`
  margin: 72px 0;
  padding: 18px 32px 18px 110px;
  height: 100px;
  line-height: 1.5;
  background-color: #ffe600;
  background-image: url('/images/kakaotalk-banners.svg');
  background-repeat: no-repeat;
  background-position: 32px 22px;

  ${({ theme }) => theme.tablet`
    margin: 40px 0;
    margin-left: -20px;
    width: calc(100% + 40px);
    height: 62px;
    font-size: 12px;
  `}

  ${({ theme }) => theme.mobile`
    margin: 30px 0;
    margin-left: -20px;
    background-position: 20px 18px;
    background-size: 33px 30px;
    padding: 9px 32px 18px 62px;
  `}
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  ${({ theme }) => theme.tablet`
    font-size: 16px;
  `}
`;

const Info = styled.div``;

const QuestionButton = styled(ATag)`
  position: absolute;
  top: 28px;
  right: 32px;
  padding: 10px 0;
  width: 153px;
  height: 44px;
  border-radius: 3px;
  background-color: #381e1e;
  color: #fff;
  font-weight: bold;
  text-align: center;
  ${({ theme }) => theme.mobile`
    width: 63px;
    height: 32px;
    padding: 7px 0;
    top: 16px;
    right: 20px;
  `}
`;

export default KakaotalkBanners;
