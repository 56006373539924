import React from 'react';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import Dropdown from 'react-dropdown';
import cx from 'classnames';

import { commaNumber } from 'utils';
import { usePackageDetailStore } from 'pages/PackageDetail/store';
import { Option } from 'stores/models/PacakgeDetailModel';

import { DropDownCloseIcon, PackageDetailDl, RelativeDiv } from 'components/atoms';
import QuantityBox from 'components/atoms/QuantityBox';
import { ErrBubble } from 'components/atoms/Form';

const PurchaseBox = observer(() => {
  const store = usePackageDetailStore();
  const { select, UIoptions, onChangeOption, err } = store;

  return (
    <PackageDetailDl>
      {UIoptions.length ? (
        <div>
          <dt>옵션</dt>
          <dd>
            <OptionsBoxContainer
              options={UIoptions}
              store={select}
              err={err}
              onChange={onChangeOption}
            />
          </dd>
        </div>
      ) : null}
      <div>
        <dt>수량</dt>
        <dd>
          <QuantityBoxContainer />
        </dd>
      </div>
      <div>
        <dt>총 금액</dt>
        <dd>
          <TotalPriceContainer store={store} />
        </dd>
      </div>
    </PackageDetailDl>
  );
});

export default PurchaseBox;

const OptionsBoxContainer: React.FC<any> = observer(({ options, onChange, store, err }) => (
  <RelativeDiv>
    <OptionDropdown
      className={cx({ invalid: err.option })}
      options={options}
      onChange={onChange}
      value={options.find((a: Option) => a.id === store.unit.id)}
      placeholder={'옵션을 선택해주세요.'}
      arrowOpen={<DropDownCloseIcon $css={DropDownIcon} />}
      arrowClosed={<DropDownCloseIcon $css={DropDownIcon} />}
    />
    {err.option ? <ErrBubble>옵션을 선택해주세요.</ErrBubble> : null}
  </RelativeDiv>
));

const QuantityBoxContainer: React.FC<any> = observer(() => {
  const {
    select,
    onChangeQuantity,
    packageDetail: { inventory_count },
  } = usePackageDetailStore();

  return (
    <QuantityBox
      value={select.quantity}
      onChange={onChangeQuantity}
      max={inventory_count ?? 1000}
      maxGuideMessage={`패키지 구매 안내 / 현재 선택해 주신 상품의 구매 가능한 수량은 ${inventory_count}개 입니다. 상품 재고 관련 문의는 카카오톡 '해빛' 상담채널로 문의 주세요.`}
      angled
    />
  );
});

const TotalPriceContainer: React.FC<any> = observer(({ store }: any) => (
  <TotalPrice>{commaNumber(store.totalPrice)}</TotalPrice>
));

const OptionDropdown = styled(Dropdown)`
  width: 480px;

  .Dropdown-control {
    box-sizing: content-box;
    padding: 8px 43px 8px 16px;
    height: auto;
    border-radius: 3px;
    border: solid 1px #d5d5d5;

    line-height: 1.5;
    color: ${({ theme }) => theme.TextColor};
  }

  .Dropdown-option {
    padding: 8px 16px;
  }

  &.invalid {
    .Dropdown-control,
    .Dropdown-placeholder {
      border-color: ${({ theme }) => theme.mainActive};
      color: ${({ theme }) => theme.mainActive};
    }
  }
`;

const DropDownIcon = css`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 11px;
  height: 6px;
`;

const TotalPrice = styled.div`
  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;

  &:after {
    content: '원';
    font-weight: normal;
  }
`;
