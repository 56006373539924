import React from 'react';
import styled from 'styled-components';

import { useTabletShow } from 'components/hooks/useLayoutShow';
import { AiOutlineMenu } from 'react-icons/ai';
import { ClearButton } from 'components/atoms';

const SideMenuButton: React.FC<any> = props =>
  useTabletShow(
    <ClearButton {...props}>
      <MenuIcon />
    </ClearButton>,
  );

const MenuIcon = styled(AiOutlineMenu)`
  position: absolute;
  left: 20px;
  width: 20px;
  height: 20px;
  color: #000;
`;

export default SideMenuButton;
