import React from 'react';
import styled from 'styled-components';

import { useNotMobileShow, useMobileShow } from 'components/hooks/useLayoutShow';
import { RelativeDiv } from 'components/atoms';
import {
  AppleDonwloadButton,
  GoogleDonwloadButton,
  DownloadButton,
} from 'components/atoms/AppDownloadButtons';

const AppAdBanner: React.FC<any> = React.memo(() => (
  <WrapAdImg>
    {useNotMobileShow(
      <img src='/images/packages-detail-banner.png' width='750px' alt='Ad Images' />,
    )}
    {useMobileShow(
      <img src='/images/packages-detail-banner-mobile.png' width='100%' alt='Ad Images' />,
    )}
    <GoogleDonwloadButton Wrapper={WrapAndroidButton} />
    <AppleDonwloadButton Wrapper={WrapAppleButton} />
    <DownloadButton Wrapper={WrapDownloadButton} />
  </WrapAdImg>
));

export default AppAdBanner;

const WrapAdImg = styled(RelativeDiv)`
  width: 100%;
  text-align: center;
  background-color: #fdecbe;
  overflow: hidden;

  & > div {
    position: absolute;
    bottom: 81px;

    ${({ theme }) => theme.mobile`
      bottom: 82px;
    `}
  }
`;
const WrapAndroidButton = styled.div`
  left: calc(50% - 171px);

  ${({ theme }) => theme.mobile`
    left: calc(50% - 125px);
  `}
`;

const WrapAppleButton = styled.div`
  right: calc(50% + 174px);

  ${({ theme }) => theme.mobile`
    right: calc(50% + -125px);
  `}
`;

const WrapDownloadButton = styled.div`
  div > & {
    bottom: 133px;
    right: calc(50% + 11px);

    ${({ theme }) => theme.mobile`
      left: calc(50% - 125px);
      bottom: 33px;
    `}
  }
`;
