import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { theme } from 'styles/theme';
import { DimmedType } from 'interfaces/layout';
import { SearchProvider } from 'stores/local/Search';
import SearchForm from './SearchForm';

export interface Props {
  isOpen: boolean;
  handleClick: any;
}

const index: React.FC<Props> = observer(({ isOpen, handleClick }) => {
  const { layout } = useStores();

  useEffect(() => {
    if (isOpen) {
      layout.OpenDimmed({
        dimmedZIndex: theme.headerZindex - 1,
        dismissible: true,
        type: DimmedType.Normal,
        onClose: () => handleClick(false),
      });
    } else {
      layout.CloseDimmed();
    }
  }, [handleClick, isOpen, layout]);

  return <SearchProvider>{isOpen ? <SearchForm /> : null}</SearchProvider>;
});

export default index;
