import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { GrClose } from 'react-icons/gr';

import { useStores } from 'stores';
import * as atoms from 'components/atoms';

const TopAppBanner: React.FC<any> = observer(() => {
  const { layout } = useStores();

  return layout.topAppBanner.show ? (
    <Background>
      <Wrapper>
        <Logo src={'/icons/header/chai.png'} alt='chai logo' />
        <b>차이의 놀이앱</b> 설치하고 <br />
        놀이팁 &amp; 포인트 1,000원 받기!
        <DonwloadButton href={layout.AppDownloadLink} target='_blank'>
          다운로드
        </DonwloadButton>
        <CloseButton onClick={layout.CloseTopAppBanner}>
          <GrClose />
        </CloseButton>
      </Wrapper>
    </Background>
  ) : null;
});
export default TopAppBanner;

const Background = styled.div`
  height: 60px;
  background-color: #262626;

  ${({ theme }) => theme.tablet`
    height: 50px;
  `}
`;

const Wrapper = styled(atoms.Wrapper)`
  position: relative;
  padding: 16px 0;
  letter-spacing: -0.4px;
  color: #fff;

  text-align: center;

  b {
    color: #fff;
  }

  br {
    display: none;
  }

  ${({ theme }) => theme.tablet`
    padding: 19px 0 19px 60px;
    font-size: 12px;
    text-align: left;
  `}

  ${({ theme }) => theme.mobile`
    padding: 9px 0 9px 60px;
    line-height: 1.33;

    br {
      display: block;
    }
  `}
`;

const Logo = styled.img`
  ${({ theme }) => theme.desktop`
    display: none;
  `}

  ${({ theme }) => theme.tablet`
    position: absolute;
    top: 10px;
    left: 20px;
  `}
`;

const DonwloadButton = styled(atoms.ATag)`
  display: inline-block;
  margin-left: 14px;
  width: 72px;
  line-height: 28px;
  border-radius: 20px;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.3px;

  ${({ theme }) => theme.tablet`
    position: absolute;
    top: 10px;
    right: 20px;
  
    font-size: 12px;
    text-align: center;
  `}

  ${({ theme }) => theme.tablet`
    font-size: 11px;
  `}
`;

const CloseButton = styled(atoms.ClearButton)`
  position: absolute;
  top: 18px;
  right: 0;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: #fff;
    }
  }

  ${({ theme }) => theme.tablet`
    display: none;
  `}
`;
