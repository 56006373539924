import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { generateKey } from 'utils';

import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { Tab } from 'components/hooks/useTabs';
import { ClearButton } from 'components/atoms';

interface TabContainerProps {
  store: Tab[];
  Button: any;
  curIndex: number;
  onClick: any;
}

export const TabContainer = React.memo(
  ({ store, Button, curIndex, onClick }: TabContainerProps) => (
    <>
      {store.map(({ buttonText }, idx) => (
        <Button
          key={generateKey(`_Tab_${idx}`)}
          className={cx({ active: curIndex === idx, 'prev-active': curIndex - 1 === idx })}
          onClick={onClick(idx)}>
          {buttonText}
        </Button>
      ))}
    </>
  ),
);

interface DesktopTabProps {
  childRef?: React.RefObject<HTMLDivElement>;
  curIndex: number;
  onClick: any;
  store: Tab[];
}

export const DesktopTab: React.FC<DesktopTabProps> = ({ childRef, store, curIndex, onClick }) =>
  useDesktopShow(
    <Background>
      <WrapDestopTab ref={childRef}>
        <TabContainer store={store} Button={TabButtonPC} curIndex={curIndex} onClick={onClick} />
      </WrapDestopTab>
    </Background>,
  );

export const Background = styled.div`
  background-color: #f8f8f8;
`;

export const WrapDestopTab = styled.div<{ $isStickey?: boolean }>`
  display: flex;
  margin: ${({ $isStickey }) => ($isStickey ? 'auto' : '80px auto 0')};
  padding-right: 380px;
  width: 1194px;
`;

export const TabButtonPC = styled(ClearButton)`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 25%;
  padding: 16px 0;
  line-height: 1.5;
  color: #b3b3b3;

  &.active {
    border-bottom: 5px solid ${({ theme }) => theme.subActiveColor};
    font-weight: bold;
    color: ${({ theme }) => theme.subActiveColor};
  }
`;

export const MobileTab: React.FC<DesktopTabProps> = ({ store, curIndex, onClick }) => (
  <WrapMobileTab>
    <TabContainer store={store} Button={TabButtonMobile} curIndex={curIndex} onClick={onClick} />
  </WrapMobileTab>
);

export const WrapMobileTab = styled.div`
  display: flex;
  margin: 0 auto;
  width: 320px;
`;

const TabButtonMobile = styled(ClearButton)`
  padding: 10px 0;
  width: 80px;
  font-size: 12px;
  color: #b3b3b3;

  &.active {
    border-bottom: 2px solid;
    font-weight: bold;
    color: ${({ theme }) => theme.subActiveColor};
  }
`;
