import React, { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';
import cx from 'classnames';
import { PAGE_URL } from 'configs/path';
import { generateKey } from 'utils';
import CategoriesModel from 'layouts/models/CategoriesModel';
import { ClearLink } from 'components/atoms/Clear';

interface Props {
  isOpen: boolean;
  store?: CategoriesModel;
}

const CategoryBox: React.FC<Props> = React.memo(({ isOpen, store }) => {
  if (!store) return null;

  return (
    <WrapBox className={cx({ open: isOpen })}>
      {useMemo(
        () =>
          store.children.map(({ category, categoryName }) => (
            <Link
              key={generateKey()}
              to={generatePath(PAGE_URL.PACKAGES_SUB, { main: store.me.category, sub: category })}>
              {categoryName}
            </Link>
          )),
        [store.children, store.me.category],
      )}
    </WrapBox>
  );
});

export default CategoryBox;

const WrapBox = styled.div`
  position: absolute;
  z-index: 997;
  top: 41px;
  padding: 12px 24px;
  width: 200px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d5d5d5;
  background-color: #fff;

  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.25s, transform 0.05s linear 0.25s;

  &.open {
    opacity: 1;
    transform: scaleY(1);
    transition: transform 0.05s, opacity 0.25s linear 0.05s;
  }
`;

const Link = styled(ClearLink)`
  display: block;
  margin: 12px 0;
  line-height: 20px;
  font-size: 14px;
  text-align: left;

  &:hover {
    color: ${({ theme }) => theme.mainActive};
  }
`;
