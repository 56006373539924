import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';

import { PageLayout } from 'layouts';
import { useStores } from 'stores';
import { WrapPage } from 'components/atoms/PurchasePage';
import OrderStep, { ORDER_STEP } from 'components/molecules/OrderStep';
import {
  PaymentForm,
  PaymentMethods,
  PurchaseInfo,
  PurchaseTable,
  ShippingForm,
  UserForm,
} from 'components/organisms/Purchase';
import { MODAL_CLASSIFIER } from './constants';
import { IdMatchParams, PAGE_URL } from 'configs/path';
import { CouponeModal } from 'components/modals';
import { OrderButton } from './styled';
import { SiginInGuideBox } from 'components/organisms/SiginInGuideBox';

const PurchaseOrder: React.FC<any> = React.memo(() => {
  const { push } = useHistory();
  const {
    params: { id },
  } = useRouteMatch<IdMatchParams>();
  const { order, auth } = useStores();
  const methods = useForm<Order.ReqValidate>();

  useEffect(() => {
    async function fetch() {
      if (auth.isSignIn) {
        await auth.fetch();
      }

      try {
        await order.fetch(+id, true);
      } catch (error) {
        push(PAGE_URL.ERROR);
      }
    }

    fetch();

    return () => order.reset();
  }, [auth, id, order, push]);

  useEffect(() => {
    const listener = () => order.saveOrderToSessionStorage(methods.getValues());
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  }, [id, order, methods]);

  return (
    <>
      <OrderStep step={ORDER_STEP.ORDER} />
      <SiginInGuideBox />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(order.purchase)}>
          <UserForm />
          <ShippingForm />
          <PaymentMethods />
          <PurchaseTable />
          <PaymentForm />
          <PurchaseInfo />
          <OrderButton>결제하기</OrderButton>
        </form>
      </FormProvider>
    </>
  );
});

export default PageLayout(PurchaseOrder, {
  Title: '주문/결제',
  Wrapper: WrapPage,
  Modal: {
    [MODAL_CLASSIFIER.USABLE_COUPON]: CouponeModal,
  },
});
