import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FormProvider, useForm } from 'react-hook-form';
import { MypageLayout } from 'layouts/Mypage';
import { useStores } from 'stores';
import UserModel from 'stores/models/UserModel';
import { Title } from 'components/atoms/Mypage';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { usePostModal } from 'components/modals/DaumPostcodeModal';
import {
  WrapInfo,
  WrapInput,
  Label,
  Input,
  SumbitButton,
  InputErr,
  WrapTextArea,
  AddrArea,
  AddrButton,
  SearchIcon,
  AddrInput,
} from '../styled';
import { PhoneCertify } from './PhoneCertify';

interface UserInfo extends UserModel {
  code: string;
  _certify_methods: string;
}

const MyInfo: React.FC<any> = observer(() => {
  const { auth } = useStores();
  const methods = useForm<UserInfo>();
  const { register, errors, setValue, setError, handleSubmit, reset } = methods;

  const setFormError = useCallback(
    (field: string, message: string) =>
      setError(field, {
        type: 'manual',
        message,
      }),
    [setError],
  );

  const onSubmit = async (data: any) => {
    try {
      await auth.updateInfo(data);
    } catch (err) {
      const errors = err as APIFormError[];
      errors.forEach(({ field, message }) => setFormError(field, message));
    }
  };

  const [DaumPostcode, handleAddress] = usePostModal(({ address, buildingName, zonecode }) => {
    setValue('address1', `${address} ${buildingName ? `(${buildingName})` : ''}`);
    setValue('zonecode', zonecode);
  });

  useEffect(() => {
    async function fetch() {
      await auth.fetch();
      reset(auth.user);
      setValue('_certify_methods', 'sms');
      handleSubmit(() => {})(); // 에러 활성화
    }

    fetch();
  }, [auth, reset, setValue, handleSubmit]);

  if (!auth.user) return null;

  return (
    <FormProvider {...methods}>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>개인정보 설정</SubHeaderTitle>
      </SubHeader>
      <WrapInfo>
        <Title>개인정보 설정</Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <WrapInput $withBtn>
            <Label>닉네임</Label>
            <Input
              name='nickname'
              type='text'
              ref={register({ required: '닉네임을 입력해 주세요' })}
              placeholder='닉네임을 입력해 주세요'
            />
            {errors.nickname && <InputErr>{errors.nickname.message}</InputErr>}
          </WrapInput>
          <WrapInput $withBtn>
            <Label>이름</Label>
            <Input
              name='fullname'
              type='text'
              ref={register({
                required: '이름을 입력해 주세요',
                minLength: {
                  value: 2,
                  message: '이름을 입력해 주세요 (최소 2글자 이상)',
                },
              })}
              placeholder='이름을 입력해 주세요'
            />
            {errors.fullname && <InputErr>{errors.fullname.message}</InputErr>}
          </WrapInput>
          <PhoneCertify InputKey='phone_number' />
          <WrapInput>
            <Label>주소</Label>
            <WrapTextArea>
              <AddrArea
                name='address1'
                ref={register()}
                placeholder='주소를 검색해주세요'
                readOnly
              />
              {errors.address1 && <InputErr>{errors.address1.message}</InputErr>}
            </WrapTextArea>
            <input type='hidden' name='zonecode' ref={register()} />
            <AddrButton type='button' onClick={handleAddress}>
              <SearchIcon />
              검색
            </AddrButton>
          </WrapInput>
          <WrapInput>
            <Label>상세주소</Label>
            <AddrInput
              name='address2'
              type='text'
              ref={register()}
              placeholder='상세주소를 입력해 주세요'
            />
            {errors.address2 && <InputErr>{errors.address2.message}</InputErr>}
          </WrapInput>
          <SumbitButton type='submit'>저장</SumbitButton>
        </form>
      </WrapInfo>
      <DaumPostcode />
    </FormProvider>
  );
});

export default MypageLayout(MyInfo);
