import { useStores } from 'stores';
import { useObserver } from 'mobx-react';
import { DISPLAY } from 'interfaces/layout';

export const useDesktopShow = (clidren: any): any => {
  const { layout } = useStores();

  return useObserver(() => {
    if (layout.displayType !== DISPLAY.DESKTOP) return null;
    else return { ...clidren };
  });
};

export const useTabletShow = (clidren: any): any => {
  const { layout } = useStores();

  return useObserver(() => {
    if (layout.displayType > DISPLAY.TABLET) return null;
    else return { ...clidren };
  });
};

export const useMobileShow = (clidren: any): any => {
  const { layout } = useStores();

  return useObserver(() => {
    if (layout.displayType !== DISPLAY.MOBILE) return null;
    else return { ...clidren };
  });
};

export const useNotMobileShow = (clidren: any): any => {
  const { layout } = useStores();

  return useObserver(() => {
    if (layout.displayType === DISPLAY.MOBILE) return null;
    else return { ...clidren };
  });
};
