import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import { RiUser3Fill } from 'react-icons/ri';
import { FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';

import CategoriesModel, { PackageCategory } from 'layouts/models/CategoriesModel';
import { useStores } from 'stores';
import { PAGE_URL } from 'configs/path';
import { useToggleList } from 'components/hooks/useToggle';
import { ClearA, ClearLink, ClearButton } from 'components/atoms/Clear';
import { HeightClose, HeightOpen } from 'components/atoms/Animation';

const SideMenuContainer = () => {
  const { layout } = useStores();

  return useObserver(() => (
    <WrapSideMenu className={cx({ open: layout.sideMenuOpen })}>
      <AuthButtons />
      <SubButtons />
      <CategoryList store={layout.packageCategory} />
    </WrapSideMenu>
  ));
};

const AuthButtons = observer(() => {
  const history = useHistory();
  const { auth, layout } = useStores();
  const handleLogout = useCallback(() => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      auth.Logout(() => history.push('/'));
      layout.CloseSideBar();
    }
  }, [auth, history, layout]);

  return (
    <WrapAuthButton>
      {auth.isSignIn ? (
        <AuthButton onClick={handleLogout}>로그아웃</AuthButton>
      ) : (
        <>
          <LoginLink to='/users/signin'>로그인</LoginLink>
          <AuthLink to='/users/signup'>회원가입</AuthLink>
        </>
      )}
      <CloseButton onClick={layout.CloseSideBar}>
        <FaTimes />
      </CloseButton>
    </WrapAuthButton>
  );
});

const SubButtons = observer(() => {
  const {
    auth: { isSignIn },
    layout: { isDesktop },
  } = useStores();
  return (
    <>
      <WrapInfoButton $isSignIn={isSignIn}>
        {isSignIn ? (
          <>
            <InfoLinkButton to={isDesktop ? PAGE_URL.PURCHASE_LIST : PAGE_URL.MYPAGE}>
              <IconInfoMypage />
              마이페이지
            </InfoLinkButton>
            <InfoLinkButton to={PAGE_URL.PURCHASE_LIST}>
              <IconInfoButton
                src={'/icons/delivery.png'}
                alt='Delivery Icon Img'
                $type={'Delivery'}
              />
              주문배송조회
            </InfoLinkButton>
            <InfoAButton href={'https://pf.kakao.com/_xdbBvxd'} target='_blank'>
              <IconInfoButton src={'/icons/kakao.png'} alt='Kakao Icon Img' $type={'Kakao'} />
              카카오톡 상담
            </InfoAButton>
          </>
        ) : (
          <>
            <InfoLinkButton to={PAGE_URL.NONUSER_LOOKUP}>
              <IconInfoButton
                src={'/icons/delivery.png'}
                alt='Delivery Icon Img'
                $type={'Delivery'}
              />
              비회원 주문조회
            </InfoLinkButton>
            <InfoAButton href={'https://pf.kakao.com/_xdbBvxd'} target='_blank'>
              <IconInfoButton src={'/icons/kakao.png'} alt='Kakao Icon Img' $type={'Kakao'} />
              카카오톡 상담
            </InfoAButton>
          </>
        )}
      </WrapInfoButton>
    </>
  );
});

const CategoryList = React.memo(({ store }: any) => {
  const [toggleSubCategory, setToggle] = useToggleList([]);
  const isToggle = (key: any) => {
    return toggleSubCategory.findIndex((a: any) => a === key) !== -1;
  };

  return (
    <ul>
      {store.map(({ me, children }: CategoriesModel) => (
        <WrapCategoryItem key={`__sideMenu_mainCategory_${me.id}`}>
          <MainCategoryItem me={me} isOpen={isToggle(me.id)} handleClick={setToggle} />
          <WrapSubCategoryList className={cx({ open: isToggle(me.id), close: !isToggle(me.id) })}>
            <SubCategoryList main={me} store={children} />
          </WrapSubCategoryList>
        </WrapCategoryItem>
      ))}
    </ul>
  );
});

const MainCategoryItem = React.memo(({ me, isOpen, handleClick }: any) => (
  <LinkWrap>
    <CategoryLink to={generatePath(PAGE_URL.PACKAGES_MAIN, { main: me.category })}>
      <CategoryLinkIcon src={me.imageUrl} />
      {me.categoryName}
    </CategoryLink>
    <CategoryToggleButton onClick={handleClick(me.id)}>
      {!isOpen ? <FaChevronDown /> : <FaChevronUp />}
    </CategoryToggleButton>
  </LinkWrap>
));

const SubCategoryList = React.memo(({ main, store }: any) =>
  store.map(({ id, category, categoryName }: PackageCategory) => (
    <WrapCategoryItem key={`__sideMenu_subCategory_${id}`}>
      <SubLinkWrap>
        <CategoryLink
          to={generatePath(PAGE_URL.PACKAGES_SUB, { main: main.category, sub: category })}>
          {categoryName}
        </CategoryLink>
      </SubLinkWrap>
    </WrapCategoryItem>
  )),
);

const WrapSideMenu = styled.div`
  overflow: auto;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background-color: #fff;

  transform: translateX(-100%);
  transition: transform linear 0.2s;
  &.open {
    transform: translateX(0);
  }

  ${({ theme }) => theme.mobile`
    width: 100%;
  `}
`;

const WrapAuthButton = styled.div`
  padding: 12px 19px;
  border-bottom: 1px solid #eaeaea;
`;
const AuthLink = styled(ClearLink)`
  display: inline-block;
  margin-right: 10px;
  padding: 3px 12px;
  line-height: 1.5;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  background-color: #ffffff;
  font-size: 12px;
  color: ${({ theme }) => theme.TextColor};
`;
const LoginLink = styled(AuthLink)`
  padding: 3px 18px;
  border-color: ${({ theme }) => theme.subActiveColor};
  background-color: ${({ theme }) => theme.subActiveColor};
  color: #fff;
`;
const AuthButton = styled(ClearButton)`
  display: inline-block;
  margin-right: 10px;
  padding: 3px 12px;
  line-height: 1.5;
  border-radius: 3px;
  border: solid 1px #d5d5d5;
  background-color: #ffffff;
  font-size: 12px;
  color: ${({ theme }) => theme.TextColor};
`;
const CloseButton = styled(ClearButton)`
  float: right;
  height: 24px;

  & svg {
    width: 24px;
    height: 24px;
    color: ${({ theme }) => theme.subActiveColor};
  }
`;

const InfoButtonCss = css`
  display: inline-block;
  vertical-align: top;
  padding: 11px 0 12px;
  height: 84px;
  border-radius: 10px;
  background-color: #fff;
  line-height: 1.5;
  font-size: 12px;
  text-align: center;
`;
const InfoLinkButton = styled(ClearLink)`
  ${InfoButtonCss}
`;
const InfoAButton = styled(ClearA)`
  ${InfoButtonCss}
`;
const WrapInfoButton = styled.div<{ $isSignIn?: boolean }>`
  padding: ${({ $isSignIn }) => ($isSignIn ? '10px 16px' : '10px 14px')};
  background-color: #f8f8f8;

  ${InfoLinkButton},
  ${InfoAButton} {
    width: ${({ $isSignIn }) => ($isSignIn ? 'calc(33.33% - 8px)' : 'calc(50% - 12px)')};
    margin: ${({ $isSignIn }) => ($isSignIn ? '0 4px' : '0 6px')};
  }
`;

const IconInfoButton = styled.img<{ $type: string }>`
  display: block;
  margin: auto;

  ${({ $type }) => {
    if ($type === 'Kakao') {
      return `
        margin: 4px auto;
        width: 40px;
        height: 36px;
      `;
    } else if ($type === 'Delivery') {
      return `
        margin: auto;
        width: 69px;
        height: 43px;
      `;
    }
  }}
`;

const IconInfoMypage = styled(RiUser3Fill)`
  display: block;
  margin: 4px auto 3px;
  width: 36px;
  height: 36px;

  g {
    color: ${({ theme }) => theme.subActiveColor};
  }
`;

const WrapCategoryItem = styled.li``;
const LinkWrap = styled.div`
  padding: 9px 20px;
  border-bottom: 1px solid #eaeaea;
`;
const SubLinkWrap = styled(LinkWrap)`
  background-color: #f2f2f2;
  border-bottom: 1px solid #e3e3e3;
`;
const CategoryLink = styled(ClearLink)`
  line-height: 24px;
  font-size: 12px;
`;
const CategoryLinkIcon = styled.img`
  margin-right: 8px;
  width: 24px;
  height: 24px;
  vertical-align: bottom;
`;
const CategoryToggleButton = styled(ClearButton)`
  float: right;
  padding: 5px 0;

  & svg {
    width: 14px;
    height: 14px;
    color: #d5d5d5;
  }
`;

const WrapSubCategoryList = styled.ul`
  &.open {
    animation: ${HeightOpen} 0.25s;
    animation-timing-function: ease-out;
  }

  &.close {
    animation: ${HeightClose} 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in;
  }
`;

export default SideMenuContainer;
