import React from 'react';
import styled from 'styled-components';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { observer } from 'mobx-react';

const H1 = styled.h1`
  margin-top: 32px;
  padding-bottom: 16px;
  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
`;

export const Title: React.FC<any> = observer(({ children }) => useDesktopShow(<H1>{children}</H1>));

export const MobileTitle = styled.h1`
  padding: 12px 0;
  border-bottom: 0;

  line-height: 1.5;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
`;
