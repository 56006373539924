import React from 'react';
import styled from 'styled-components';
import { useDesktopShow } from 'components/hooks/useLayoutShow';
import { Wrapper } from 'components/atoms';

export const WrapList = styled(Wrapper)``;

const H1 = styled.h1`
  margin-top: 32px;
  padding-bottom: 24px;

  line-height: 1.5;
  font-size: 24px;
  font-weight: bold;
`;

export const Title: React.FC<any> = React.memo(props => useDesktopShow(<H1>{props.children}</H1>));
