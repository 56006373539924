declare global {
  interface Window {
    fbAsyncInit: Function;
    kakaoAsyncInit: Function;
    __igEmbedLoaded?: Function;
  }
}

window.fbAsyncInit = () => {
  FB.init({
    appId: process.env.REACT_APP_FACEBOOK_APP_ID,
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v8.0',
  });
};

window.kakaoAsyncInit = () => {
  Kakao.init(process.env.REACT_APP_KAKAO_JS_APP_KEY);
};

export {};
