import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import styled from 'styled-components';
import { MypageLayout } from 'layouts/Mypage';

import { Wrapper, Title } from 'components/atoms/Mypage';
import { SubHeader, SubHeaderTitle } from 'components/atoms/SubHeader';
import MobileBackButton from 'components/atoms/MobileBackButton';
import { Faq } from 'components/atoms/FaqText';
import FAQAccordion from 'components/molecules/FAQAccordion';
import { generateKey } from 'utils';
import { ATag } from 'components/atoms';

const FAQ: React.FC<any> = () => {
  return (
    <>
      <SubHeader>
        <MobileBackButton />
        <SubHeaderTitle>자주묻는 질문 FAQ</SubHeaderTitle>
      </SubHeader>
      <FaqWrapper>
        <Title>자주묻는 질문 FAQ</Title>
        {Faq.map(({ category, items }) => (
          <AccordionBox allowZeroExpanded key={generateKey()}>
            <SubTitle>{category}</SubTitle>
            {items.map(faq => (
              <FAQAccordion {...faq} key={generateKey()} />
            ))}
          </AccordionBox>
        ))}
        <ContactButton href='https://pf.kakao.com/_xdbBvxd' target='_blank'>
          이 외 궁금한 내용 문의하기
        </ContactButton>
      </FaqWrapper>
    </>
  );
};

export default MypageLayout(FAQ);

const FaqWrapper = styled(Wrapper)`
  ${({ theme }) => theme.tablet`
    padding: 0 0 10px;
    background: #f8f8f8;
  `}
`;

const AccordionBox = styled(Accordion)`
  margin-top: 16px;
  line-height: 1.5;

  ${({ theme }) => theme.desktop`
    & + & {
      margin-top: 40px;
    }
  `}

  ${({ theme }) => theme.tablet`
    margin-top: 0;
    background: #fff;
  `}
`;

const SubTitle = styled.h2`
  background: #f8f8f8;

  ${({ theme }) => theme.desktop`
    padding: 17px 24px;
    border-top: 2px solid #262626;
    border-bottom: 2px solid #eaeaea;
    font-size: 16px;
  `}

  ${({ theme }) => theme.tablet`
    padding: 20px 20px 5px;
    font-size: 12px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
  `}
`;

const ContactButton = styled(ATag)`
  display: block;
  margin: 35px auto 117px;
  width: 248px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.subActiveColor};

  line-height: 44px;
  font-weight: bold;
  text-align: center;
  color: #fff;

  ${({ theme }) => theme.tablet`
    margin: 20px auto 0;
    width: 280px;
  `}
`;
