import React, { useEffect } from 'react';
import { DefaultLayout } from 'layouts/Default';
import { WrapBox, AuthBox } from 'components/atoms';
import SignInBox from './SignInBox';
import SearchNonMembersBox from './SearchNonMembersBox';
import useTabs from 'components/hooks/useTabs';
import { TabButton, TabWrap } from 'components/atoms/Tab';

import cx from 'classnames';
import { PAGE_URL } from 'configs/path';

const tabIdx = {
  [PAGE_URL.SIGNIN]: 0,
  [PAGE_URL.NONUSER_LOOKUP]: 1,
};

const SignIn = React.memo((props: any) => {
  const { location } = props;
  const { curIndex, curTab, setTab } = useTabs(tabIdx[location.pathname] || 0, Tabs);
  const handleTabClick = (index: number) => (ev: MouseEvent) => {
    ev.preventDefault();
    setTab(index);
  };

  useEffect(() => {
    setTab(tabIdx[location.pathname] || 0);
  }, [location.pathname, setTab]);

  return (
    <WrapBox>
      <AuthBox>
        <TabWrap>
          {Tabs.map(({ buttonText }, index) => (
            <TabButton
              key={`__SignInTab_${index}`}
              className={cx({ active: curIndex === index })}
              onClick={handleTabClick(index)}>
              {buttonText}
            </TabButton>
          ))}
        </TabWrap>
        <curTab.content {...props} />
      </AuthBox>
    </WrapBox>
  );
});

const Tabs = [
  {
    buttonText: '로그인',
    content: (props: any) => <SignInBox {...props} />,
  },
  {
    buttonText: '비회원 주문 조회',
    content: (props: any) => <SearchNonMembersBox {...props} />,
  },
];

export default DefaultLayout(SignIn);
