import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useSearchStore } from 'stores/local/Search';
import { PAGE_URL } from 'configs/path';
import { generateKey } from 'utils';
import { ClearLink } from 'components/atoms/Clear';

export default observer(function KeywardList() {
  const { age, keywords } = useSearchStore();

  return (
    <WrapKeywords>
      {keywords.map(keyword => (
        <KeywordLink key={generateKey()} to={`${PAGE_URL.PACKAGE_SEARCH}?q=${keyword}&age=${age}`}>
          {keyword}
        </KeywordLink>
      ))}
    </WrapKeywords>
  );
});

const WrapKeywords = styled.div`
  overflow: hidden;

  ${({ theme }) => theme.desktop`
    padding: 10px;
    height: 392px;
    overflow-y: auto;
    background-color: #f8f8f8;
  `}
`;

const KeywordLink = styled(ClearLink)`
  float: left;
  border-radius: 7px;
  background-color: #fff;
  line-height: 1.5;
  font-size: 16px;
  color: ${({ theme }) => theme.TextColor};

  ${({ theme }) => theme.desktop`
    margin: 6px;
    padding: 7px 15px;
  `}

  ${({ theme }) => theme.tablet`
    margin-right: 7px;
    margin-bottom: 8px;
    padding: 5px 15px;
    font-size: 12px;
  `};
`;
