import React from 'react';
import { observer } from 'mobx-react';
import { commaNumber } from 'utils';
import { useStores } from 'stores';
import {
  Section,
  SectionTitle,
  Table,
  Tr,
  Th,
  Td,
  Price,
  SubInfo,
  Minus,
  Plus,
  Equal,
  Em,
} from './styled';
import styled from 'styled-components';

export default observer(function Payments() {
  const {
    order: {
      subtotal_price,
      shipping_price,
      extra_shipping_price,
      discount_price,
      used_point,
      coupon_price,
      displayPayMethod,
      isVbank,
      vbank_holder,
      vbank_name,
      vbank_num,
      earning_point,
      orderPrice,
    },
  } = useStores();

  return (
    <Section>
      <SectionTitle>결제 금액 정보</SectionTitle>
      <Table>
        <tbody>
          <Tr $borderLess>
            <Th $center>총 상품 가격</Th>
            <Th $center>할인 금액</Th>
            <Th $center>배송비</Th>
            {extra_shipping_price > 0 && <Th $center>추가 배송비</Th>}
            <Th $center>결제 금액</Th>
          </Tr>
          <Tr>
            <Td $center>
              <Price>{commaNumber(subtotal_price)}원</Price>
              <Minus />
            </Td>
            <Td $center>
              <Price>{commaNumber(discount_price)}원</Price>
              <SubInfo $prefix={'사용 적립금'}>{commaNumber(used_point)}원</SubInfo>
              <br />
              <SubInfo $prefix={'할인 쿠폰'}>{commaNumber(coupon_price)}원</SubInfo>
              <br />
              <Plus />
            </Td>
            <Td $center>
              <Price>{commaNumber(shipping_price)}원</Price>
              {extra_shipping_price > 0 ? <Plus /> : <Equal />}
            </Td>
            {extra_shipping_price > 0 && (
              <Td $center>
                <Price>{commaNumber(extra_shipping_price)}원</Price>
                <Equal />
              </Td>
            )}
            <Td $center>
              <Price $bold $active>
                {commaNumber(orderPrice)}원
              </Price>
              {earning_point ? (
                <>
                  <SubInfo>
                    <ExclamationMareIcon />
                    <Em>{commaNumber(earning_point)}</Em> 포인트 적립
                  </SubInfo>
                </>
              ) : null}
            </Td>
          </Tr>
          <Tr>
            <Th>결제 방법</Th>
            <Td colSpan={3}>
              {displayPayMethod}{' '}
              {isVbank ? `(예금주 : ${vbank_holder} / 입금계좌 : ${vbank_name} ${vbank_num})` : ''}
            </Td>
          </Tr>
        </tbody>
      </Table>
    </Section>
  );
});

const ExclamationMareIcon = styled.i`
  display: inline-block;
  vertical-align: sub;
  margin-right: 5px;
  width: 18px;
  height: 18px;
  background: url('/icons/exclamation-mark.svg') no-repeat;
  background-size: 100%;
`;
